import React, { useState } from "react";
import { SparkIcon } from "../../../../Icons/SparkIcon";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../../../../service/apiService";

const ForgotPasswordMobileOtp = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      let body = {
        otp,
      };
      const response = await apiServiceHandler(
        "POST",
        "api/auth/forgot/verify",
        body
      );

      const { status, message } = response;

      if (status) {
        // Redirect to login page
        toast.success("Password set successfully");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while verifying OTP");
    }
  };

  return (
    <div className="w-screen h-auto flex flex-col justify-center items-center">
      <ToastContainer />
      <div className="w-screen bg-white px-6 py-12 rounded-xl overflow-y-scroll">
        <div className="text-gray-500 text-xs font-medium leading-5">
          RESET PASSWORD
        </div>
        <div className="text-gray-900 font-sans text-xl font-medium leading-8">
          Enter OTP and New Password
        </div>
        <form onSubmit={handleVerify}>
          <div className="mt-4 flex flex-col items-start gap-4">
            <div className="text-gray-500 font-sans text-sm font-normal leading-5">
              OTP
            </div>
            <input
              className="w-full h-12 px-4 border-2 border-gray-200 rounded-md"
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </div>

          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            type="submit"
            className="w-full h-12 mt-6 bg-[#403ADD] text-white rounded-md text-base font-sans font-medium leading-7"
          >
            RESET PASSWORD
          </button>
        </form>
        <div className="mt-6 flex justify-between items-center">
          <div className="text-gray-500 font-sans text-sm font-normal leading-5">
            Already have an account?{" "}
            <Link to="/login" className="underline text-blue-600">
              Log in
            </Link>
          </div>
          <div className="text-gray-500 font-sans text-sm font-normal leading-5">
            Create an account?{" "}
            <Link to="/register" className="underline text-blue-600">
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className="relative h-[42vh] bg-[#142157] w-full mt-24">
        <div className="font-sans h-fit flex flex-col sm:flex-row text-3xl non-italic font-medium text-[rgba(255,255,255,0.75)] leading-10 absolute top-[20%] left-[12%]">
          <div className="w-[24.4444px] h-5 absolute top-[-60px] left-[-60px] transform-[rotate(-53.559deg)]">
            <SparkIcon className="w-full h-full" />
          </div>
          <span className="flex-shrink-0">Find your next cotraveller</span>
          <div className="w-[24.4444px] h-5 absolute top-3 left-64"></div>
        </div>
        <div className="flex flex-col absolute top-[40%] sm:top-[35%] left-[12%] mt-6 sm:mt-0">
          <div className="text-white font-sans text-2xl non-italic font-semibold leading-[30px]">
            With
          </div>
          <div className="text-white font-sans text-2xl non-italic font-semibold leading-[30px]">
            ShareWheelz
          </div>
        </div>
        <div className="flex w-full absolute bottom-[10%]">
          <div className="flex w-full justify-center">
            <div className="flex gap-4 text-xs leading-4 text-right text-white">
              <div className="grow">© 2024 — Copyright</div>
              <Link to="/privacy">
                <div className="underline">Privacy Policies</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordMobileOtp;
