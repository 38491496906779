import React from "react";
import { AccordionCustomIcon } from "./Accordion";

const FaqQuestions = ({workRef, setAccordionOpen}) => {
  return (
    <div ref={workRef} className="absolute flex justify-end md:w-[1090px] w-screen md:min-h-[356px] min-h-[85%] bg-white items-start overflow-hidden -translate-x-2/4 left-2/4 md:top-[2325px] top-[3530px]" style={window.screen.width < 400 ? {top: '3350px'}: {}}>
      <div className="absolute w-min h-min flex flex-col items-start gap-4 left-0 top-0">
        <div className="text-[#1d2939] flex text-[40px] leading-[52px] font-bold md:w-[342px] w-screen overflow-hidden h-min flex-col items-center md:items-start font-sans">
          FAQ’s
        </div>
        <div className="relative md:w-[233px] flex flex-wrap justify-center items-center w-screen overflow-hidden h-5 md:items-start">
          <div className="text-[#4a5264] text-base md:leading-8 leading-10 font-medium absolute w-min h-min whitespace-nowrap flex-col md:left-0 -top-2 font-sans">
            Curious? Find your answers now
          </div>
        </div>
      </div>
      {/* Accordion */}

      <div className={ window.screen.width < 768 ? "w-screen overflow-hidden items-center justify-center top-[100px] flex flex-col absolute" : ""}>
        <AccordionCustomIcon onOpenChange={setAccordionOpen} />
      </div>
    </div>
  );
};

export default FaqQuestions;
