import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import apiServiceHandler from "../service/apiService";

const ChooseModal = ({ poolModalRef }) => {
  const rideData = useSelector((state) => state.ride.rideData);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [option, setOption] = useState("existing_pool");
  const navigate = useNavigate()
  const [ totalRides, setTotalRides] = useState(0)
  // let totalRides = useSelector(state => state.ride.totalRides)

  const fetchRideMatch = async() => {
    const body = {
      origin: rideData.Origin,
      destination: rideData.Destination,
      departureTime: rideData['Selected Date'],
    };

    const response = await apiServiceHandler(
      "POST",
      "api/ride/match",
      body
    );
    if(response.status) {
      setTotalRides(response.data?.ride.length)
      if(!response.data?.ride.length) {
        if (!totalRides) {
          toast.error("No rides found for the requested route.", {
            theme: 'dark'
          })
        }
      }
    }
 
  }
  useEffect(() => {
    fetchRideMatch()
  }, [])

  const onOptionChange = (e) => {
    setOption(e.target.value);
  };

  const [passengers, setPassengers] = useState(1);

  const increasePassenger = () => {
    setPassengers((prev) => prev + 1);
  };

  const decreasePassenger = () => {
    if (passengers > 1 ) {
      setPassengers((prev) => prev - 1);
    }
  };

  const CreateCarPool = async () => {
    setIsButtonDisabled(true)
    const body = {
      pickup: rideData.Origin,
      drop: rideData.Destination,
      date_time: rideData["Selected Date"],
      seat: passengers,
    };
    const response = await apiServiceHandler(
      "POST",
      "api/ride/pool/submit",
      body
    );
    
    if(response.status) {
      toast.success("Carpool created successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const Explore = async () => {
    if (!totalRides) {
      toast.error("No rides found for the requested route.", {
        theme: "dark",
      });
    } else {
      const user_ride_data = JSON.parse(sessionStorage.getItem("user_ride_data"));
      const body = {
        origin: user_ride_data.Origin,
        destination: user_ride_data.Destination,
        departureTime: user_ride_data["Selected Date"],
        seatrequired: user_ride_data["Number of Seats"],
      };
  
      const response = await apiServiceHandler(
        "POST",
        "api/ride/inquiry",
        body
      );
      
      if(response.status) {
        sessionStorage.setItem(
          "rideInquiryId",
          JSON.stringify(response.data)
        );
        navigate('/rides', { state: { noOfcotraveller: rideData["Number of Seats"]} })
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        id="timeline-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div
          className="flex flex-col px-6 pb-6 bg-white rounded-xl md:max-w-[480px] w-[95%] max-md:px-5"
          ref={poolModalRef}
        >
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/efc31c53e453f536133b7275847dc0c801d3780bae10d64b7072de4a04393d75?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
            className="self-center w-60 max-w-full aspect-[1.28]"
          />
          <div className="self-center mt-1.5 text-lg font-semibold text-center text-black">
            Choose what is best for you
          </div>
          <div className="flex gap-4 justify-center px-4 py-4 mt-7 rounded-lg border border-gray-200 border-solid bg-slate-50 max-md:flex-wrap">
            <input
              type="radio"
              className="shrink-0 cursor-pointer my-auto w-4 h-4 border border-indigo-700 border-solid rounded-2xl fill-slate-50 stroke-[1px] stroke-indigo-700"
              value="existing_pool"
              name="existing_pool"
              id="existing_pool"
              checked={option === "existing_pool"}
              onChange={onOptionChange}
            />

            <div className="flex flex-col">
              <div className="flex gap-2">
                <div className="text-base font-medium leading-6 text-black text-opacity-80">
                  Book from existing car pool
                </div>
                <div className="justify-center px-1.5 py-1 text-xs leading-4 text-black bg-yellow-400 rounded">
                  {totalRides} Car Pools Available
                </div>
              </div>
              <div className="mt-1 text-sm leading-5 text-gray-500">
                Find a ride from the already created car pool of the ShareWheelz
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center px-4 py-4 mt-4 rounded-lg border border-gray-200 border-solid bg-slate-50 max-md:pr-5 max-md:max-w-full">
            <div className="flex gap-4 self-stretch max-md:flex-wrap">
              <input
                type="radio"
                className="shrink-0 cursor-pointer my-auto w-4 h-4 rounded-2xl border border-gray-300 border-solid"
                value="own_pool"
                name="own_pool"
                id="own_pool"
                checked={option === "own_pool"}
                onChange={onOptionChange}
              />
              <div className="flex flex-col">
                <div className="text-base font-medium leading-6 text-black text-opacity-80">
                  Create your own car pool
                </div>
                <div className="mt-1 text-sm leading-5 text-gray-500">
                  Create a car pool and decide the number of passengers as well
                </div>
              </div>
            </div>
            {option === "own_pool" && (
              <div className="flex justify-center gap-5 pr-4 mt-7 max-w-full text-sm font-medium leading-4 text-neutral-900 w-[350px] max-md:pr-5">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b10c57ea49d546b216c66f13b48a4cfde7997d2d8dd8198cbddf549bf832e7f?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                  className="shrink-0 w-6 aspect-square cursor-pointer"
                  onClick={decreasePassenger}
                />
                <div className="my-auto">{passengers} passengers</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/839488a7df0fb573104538c10368488b4163cbdf046052d5e582a52b9aefde5d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                  className="shrink-0 w-6 aspect-square cursor-pointer"
                  onClick={increasePassenger}
                />
              </div>
            )}
          </div>
          {option === "own_pool" ? (
            <button 
              onClick={CreateCarPool}
               disabled={isButtonDisabled}
               className={`justify-center px-2.5 py-3.5 mt-6 text-sm font-medium text-center text-white rounded-md max-md:max-w-full ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-700 cursor-pointer'}`}
               >
              <div>
                Create
              </div>
            </button>
          ) : (
            // <Link to={totalRides && totalRides > 0 ? "/rides" : "#"}>

            <button onClick={Explore} className="w-[-webkit-fill-available]">
              <div className="justify-center px-2.5 py-3.5 mt-6 text-sm font-medium text-center text-white bg-indigo-700 rounded-md max-md:max-w-full cursor-pointer">
                Explore & Book
              </div>{" "}
            </button>
          )}
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ChooseModal;
