import React, { useEffect, useRef, useState } from "react";
import Rectangle from "../Components/Rectangle";
import SearchRideForm from "../Components/SearchRideForm";
import ReferFriend from "../Components/ReferFriend";
import Heading from "../Components/Heading";
import AboutCompany from "../Components/AboutCompany";
import FaqQuestions from "../Components/FaqQuestions";
import Users from "../Components/Users";
import RideForm from "../Components/RideForm";
import ReferFriends from "../Components/ReferFriends";
import Footer from "../Components/Footer";
import Requests from "../Components/Requests";
import FindRideModal from "../Components/FindRideModal";
import ChooseModal from "../Components/ChooseModal";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../service/apiService";
import UserProfile from "../Components/UserProfile";
import ChatwootWidget from "../Components/ChatwootWidget";
import ReferModal from "../Components/ReferModal";
import ReferEmailModal from "../Components/ReferEmailModal";
import { useNavigate } from "react-router-dom";

const HomePage = ({
  rideRef,
  referRef,
  offerRef,
  workRef,
  scrollToSection,
  setUserProfileOpen,
  userProfileOpen,
}) => {
  const [open, setOpen] = useState(false);
  const [poolOpen, setPoolOpen] = useState(false);
  const [referOpen, setReferOpen] = useState(false);
  const [referEmailOpen, setReferEmailOpen] = useState(false);
  const [requests, setRequests] = useState(0);
  const navigate = useNavigate();

  const modalRef = useRef();
  const poolModalRef = useRef();
  const userProfileModalRef = useRef();
  const referModalRef = useRef();
  const referEmailModalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen(false);
    }

    if (poolModalRef.current && !poolModalRef.current.contains(event.target)) {
      setPoolOpen(false);
    }

    if (
      userProfileModalRef.current &&
      !userProfileModalRef.current.contains(event.target)
    ) {
      setUserProfileOpen(false);
    }

    if (
      referModalRef.current &&
      !referModalRef.current.contains(event.target)
    ) {
      setReferOpen(false);
    }

    if (
      referEmailModalRef.current &&
      !referEmailModalRef.current.contains(event.target)
    ) {
      setReferEmailOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (poolOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [poolOpen]);

  useEffect(() => {
    if (userProfileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userProfileOpen]);

  useEffect(() => {
    if (referOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [referOpen]);

  useEffect(() => {
    if (referEmailOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [referEmailOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
        setPoolOpen(false);
        setUserProfileOpen(false);
        setReferOpen(false);
        setReferEmailOpen(false)
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchRequestList = async () => {
    try {
      const response = await apiServiceHandler("GET", "api/ride/request/list");

      if (response?.status && response?.data?.length) {
        setRequests(response?.data?.[0].inquiryData.length);
      }
    } catch (error) {
      console.log(error, "error value is here");
    }
  };

  useEffect(() => {
    fetchRequestList();
  }, []);

  const rideData = useSelector((state) => state.ride.rideData);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handlePoolModalOpen = () => {
    if (Object.keys(rideData).length < 4) {
      toast.error("Please fill in all the fields");
    } else {
      const token = localStorage.getItem("JWT_TOKEN");
      if (!token) {
        toast.error("Please log in to use this feature.");
        setTimeout(() => {
          navigate("/login")
        }, 2000)
      } else {
        setPoolOpen(!poolOpen);
        sessionStorage.setItem("user_ride_data", JSON.stringify(rideData));
      }
    }
  };

  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <>
      {open ? (
        <>
          <Rectangle />
          <SearchRideForm handleOpen={handlePoolModalOpen} />
          {requests > 0 && (
            <Requests handleOpen={handleOpen} requests={requests} />
          )}
          <ReferFriend />
          <Heading />
          <AboutCompany />
          <FaqQuestions />
          <Users />
          <RideForm handleOpen={handlePoolModalOpen} />
          <ReferFriends />
          <Footer />
          <FindRideModal modalRef={modalRef} setOpen={setOpen} />
          <ChatwootWidget />
        </>
      ) : poolOpen ? (
        <>
          <Rectangle />
          <SearchRideForm handleOpen={handlePoolModalOpen} />
          {requests > 0 && (
            <Requests handleOpen={handleOpen} requests={requests} />
          )}
          <ReferFriend />
          <Heading />
          <AboutCompany />
          <FaqQuestions />
          <Users />
          <RideForm handleOpen={handlePoolModalOpen} />
          <ReferFriends />
          <Footer />
          <ChooseModal poolModalRef={poolModalRef} />
          <ChatwootWidget />
        </>
      ) : userProfileOpen ? (
        <>
          <Rectangle />
          <SearchRideForm handleOpen={handlePoolModalOpen} rideRef={rideRef} />
          {requests > 0 && (
            <Requests handleOpen={handleOpen} requests={requests} />
          )}
          <ReferFriend referRef={referRef} />
          <UserProfile
            userProfileModalRef={userProfileModalRef}
            setUserProfileOpen={setUserProfileOpen}
          />
          <Heading />
          <AboutCompany offerRef={offerRef} />
          <FaqQuestions workRef={workRef} setAccordionOpen={setAccordionOpen} />
          <Users accordionOpen={accordionOpen} />
          <RideForm handleOpen={handlePoolModalOpen} />
          <ReferFriends />
          <Footer />
          <ToastContainer />
          <ChatwootWidget />
        </>
      ) : referOpen ? (
        <>
          <Rectangle />
          <SearchRideForm handleOpen={handlePoolModalOpen} rideRef={rideRef} />
          {requests > 0 && (
            <Requests handleOpen={handleOpen} requests={requests} />
          )}
          <ReferFriend referRef={referRef} setReferOpen={setReferOpen} />
          <Heading />
          <AboutCompany offerRef={offerRef} />
          <FaqQuestions workRef={workRef} setAccordionOpen={setAccordionOpen} />
          <Users accordionOpen={accordionOpen} />
          <RideForm handleOpen={handlePoolModalOpen} />
          <ReferFriends setReferOpen={setReferOpen} />
          <ReferModal referModalRef={referModalRef} setReferOpen={setReferOpen} setReferEmailOpen={setReferEmailOpen} />
          <Footer />
          <ToastContainer />
          <ChatwootWidget />
        </>
      ) : referEmailOpen ? (
        <>
          <Rectangle />
          <SearchRideForm handleOpen={handlePoolModalOpen} rideRef={rideRef} />
          {requests > 0 && (
            <Requests handleOpen={handleOpen} requests={requests} />
          )}
          <ReferFriend referRef={referRef} />
          <Heading />
          <AboutCompany offerRef={offerRef} />
          <FaqQuestions workRef={workRef} setAccordionOpen={setAccordionOpen} />
          <Users accordionOpen={accordionOpen} />
          <RideForm handleOpen={handlePoolModalOpen} />
          <ReferFriends setReferOpen={setReferOpen} />
          <ReferEmailModal referEmailModalRef={referEmailModalRef} />
          <Footer
            rideRef={rideRef}
            referRef={referRef}
            offerRef={offerRef}
            workRef={workRef}
            scrollToSection={scrollToSection}
          />
          <ToastContainer />
          <ChatwootWidget />
        </>
      ) : (
        <>
          <Rectangle />
          <SearchRideForm handleOpen={handlePoolModalOpen} rideRef={rideRef} />
          {requests > 0 && (
            <Requests handleOpen={handleOpen} requests={requests} />
          )}
          <ReferFriend referRef={referRef} setReferOpen={setReferOpen} />
          <Heading />
          <AboutCompany offerRef={offerRef} />
          <FaqQuestions workRef={workRef} setAccordionOpen={setAccordionOpen} />
          <Users accordionOpen={accordionOpen} />
          <RideForm handleOpen={handlePoolModalOpen} />
          <ReferFriends setReferOpen={setReferOpen} />
          <Footer
            rideRef={rideRef}
            referRef={referRef}
            offerRef={offerRef}
            workRef={workRef}
            scrollToSection={scrollToSection}
          />
          <ToastContainer />
          <ChatwootWidget />
        </>
      )}
    </>
  );
};

export default HomePage;
