import { memo } from 'react';

const SafeIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.3475 5.49608C16.3475 5.15038 16.3612 4.80077 16.3475 4.45507V4.44335C16.3475 4.26171 16.2245 4.12303 16.0604 4.06639C15.3846 3.83202 14.7069 3.59764 14.0311 3.36327C12.9647 2.99413 11.9003 2.62303 10.8338 2.25389C10.5975 2.17186 10.3631 2.07811 10.1229 2.0078C10.0038 1.97264 9.92174 1.99999 9.81822 2.03514C9.69713 2.07811 9.57408 2.11913 9.45299 2.1621C8.4315 2.51561 7.41197 2.86913 6.39049 3.22264C5.59166 3.49999 4.79478 3.77538 3.99596 4.05272C3.98228 4.05663 3.97057 4.06249 3.95689 4.06639C3.79283 4.12303 3.66978 4.26366 3.66978 4.44335V8.06249C3.66978 8.82421 3.66783 9.58592 3.66978 10.3476C3.67174 10.9941 3.78502 11.6445 3.99986 12.2539C4.43932 13.5098 5.23814 14.6133 6.18932 15.5332C7.12877 16.4414 8.22838 17.2012 9.40807 17.7598C9.54283 17.8223 9.6776 17.8828 9.81236 17.9414C9.82017 17.9453 9.82994 17.9492 9.83775 17.9531H9.83971C9.85924 17.9609 9.88072 17.9687 9.90025 17.9746C9.994 18 10.0975 17.9863 10.201 17.9433C10.203 17.9433 10.2049 17.9414 10.2049 17.9414C11.3963 17.4355 12.5018 16.7207 13.4706 15.8633C14.4471 14.998 15.3085 13.9453 15.8241 12.7402C16.0897 12.1172 16.2655 11.4726 16.3241 10.7969C16.3553 10.4355 16.3436 10.0703 16.3436 9.70702V5.49608H16.3475ZM13.4764 7.89452L12.9901 8.38085L10.8827 10.4883L9.55064 11.8203C9.32603 12.0449 8.94517 12.0449 8.72252 11.8203L8.45299 11.5508L6.56432 9.6621C6.33189 9.42968 6.34947 9.06835 6.56432 8.83397C6.77916 8.60155 7.17564 8.61717 7.39244 8.83397C7.48228 8.92382 7.57213 9.01561 7.66197 9.10546C8.15221 9.59569 8.64244 10.0898 9.13267 10.5801L9.20689 10.5058C9.91002 9.80272 10.6112 9.0996 11.3143 8.39647L12.6463 7.06444C12.8788 6.83202 13.242 6.8496 13.4745 7.06444C13.7088 7.27928 13.6932 7.67772 13.4764 7.89452Z'
      fill='black'
    />
  </svg>
);
const Memo = memo(SafeIcon);
export { Memo as SafeIcon };
