import React, { useState, useRef } from "react";
import { SparkIcon } from "../../../Icons/SparkIcon";
import { Vector1 } from "../../../Icons/Vector1";
import { GoogleIcon } from "../../../Icons/GoogleIcon";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../../../service/apiService";
import { googleProvider } from "../../../firebase";
import { signInWithPopup, getAuth } from "firebase/auth";
import { EyeOffIcon, EyeIcon } from "lucide-react";
import Spinner from "../../Spinner";
import AgeModal from "../AgeModal";
import { useDispatch } from "react-redux";
import { handleGoogleLogin } from "../../../store/rideSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const [ageModalOpen, setAgeModalOpen] = useState(false);
  const ageModalRef = useRef();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setClick(true);
    try {
      const payload = {
        email: email,
        password: password,
      };

      const response = await apiServiceHandler(
        "POST",
        "api/auth/verify/email/login",
        payload
      );

      if (response.status) {

        localStorage.setItem("JWT_TOKEN", response.secret);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("userIdWheelz", response.userId);
        localStorage.setItem("userName", response.name);
        localStorage.setItem("email", email);
        navigate("/");
        toast.success(response.message);
        setClick(false);
      } else {
        toast.error(response.message || "Login failed");
        setClick(false);
      }
    } catch (err) {
      toast.error(err.message || "An error occurred");
      setClick(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await apiServiceHandler(
        "POST",
        "api/auth/forgot/sendemail",
        {
          email,
        }
      );

      if (response.status) {
        toast.success("OTP sent successfully");
        setTimeout(() => {
          navigate("/forgot-password");
        }, 2000);
        // Navigate to forgot password page
      } else {
        toast.error(
          response.message || "Something went wrong. Please try again later."
        );
      }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while sending reset email"
      );
    }
  };

  const auth = getAuth();

  const checkIfUserExists = async (userData) => {
    if (userData.metadata.creationTime === userData.metadata.lastSignInTime) {
      console.log("user for the first time");
      return false
    } else {
      console.log("user already present");
      return true
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const userExists = await checkIfUserExists(result.user);
        console.log(userExists, "userExists....");

        let body = {
          email: result.user.email,
          givenName: result.user.displayName,
          device_token: "",
          gender: "",
          contactNumber: result.user.contactNumber,
        };

        const response = await apiServiceHandler(
          "POST",
          "api/auth/signIn/google",
          body
        );

        if (response.status) {
          setIsLoading(true);
          if (userExists) {
            toast.success(response.message);
          } else {
            toast.info("Please Enter your gender and contact number");
          }

          localStorage.setItem("JWT_TOKEN", response.secret);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("email", result.user.email);
          localStorage.setItem("userIdWheelz", response.userId);
          localStorage.setItem("userName", result.user.displayName);

          setTimeout(() => {
            setIsLoading(false);
            console.log(userExists, 'is UserExist???')
            if (userExists) {
              navigate("/");
              dispatch(handleGoogleLogin(true));
            } else {
              navigate("/login");
              setAgeModalOpen(true);
            }
          }, 2000); // Navigate after 2 seconds
        } else {
          setIsLoading(false);
          toast.error(response.message); // OTP verification failed
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleOpenAgeModal = () => {
    setAgeModalOpen(true);
  };

  return (
    <div>
      <ToastContainer />
      {ageModalOpen && (
        <AgeModal ageModalRef={ageModalRef} setAgeModalOpen={setAgeModalOpen} />
      )}
      {isLoading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="pr-9 pl-20 h-screen bg-[#142157] w-[50%] max-md:px-5">
            <div className="font-sans flex text-3xl non-italic font-medium text-[rgba(255,255,255,0.75)] leading-10 h-fit w-[-webkit-fill-available] absolute top-[250px]">
              <div className="w-[24.4444px] h-5 absolute top-[-60px] left-[-60px] transform-[rotate(-53.559deg)]">
                <SparkIcon className="w-full h-full" />
              </div>
              <span>Find your next cotraveller</span>
              <div className="w-[24.4444px] h-5 absolute top-3 left-64">
                {/* <WindIcon className="w-full h-full" /> */}
              </div>
            </div>
            <div className="flex flex-col absolute top-[300px]">
              <div className="text-white font-sans text-6xl non-italic font-semibold leading-[70px]">
                With
              </div>
              <div className="text-white font-sans text-6xl non-italic font-semibold leading-[70px]">
                ShareWheelz
              </div>
            </div>
            <div className="text-[rgba(255,255,255,0.60)] font-sans text-xl non-italic font-normal leading-8 absolute top-[450px] text-wrap w-[40%]">
              Reach your destination in a faster, safer, cheaper, and more
              comfortable way with ShareWheelz
            </div>
            <div className="flex gap-16 max-md:flex-col max-md:gap-0 absolute left-[-30px]">
              <div className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
                <div className="flex gap-4 text-xs leading-4 text-right text-white mt-[750px] max-md:mt-10">
                  <div className="grow">© 2024 — Copyright</div>
                  <Link to="/privacy">
                    <div className="underline">Privacy Policies</div>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                  className="w-full aspect-square mt-[550px] max-md:mt-10"
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-100 w-[50%] flex flex-col justify-center items-center">
            <div className="w-[620px] h-[720px] gap-6 bg-white p-[56px] rounded-xl">
              <div className="text-[#667085] text-xs non-italic font-medium leading-5">
                WELCOME!
              </div>
              <div className="text-[#101828] font-sans non-italic text-xl font-medium leading-8">
                Login Into Your Account
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mt-10 flex flex-col items-start gap-[7px]">
                  <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                    Email Address{" "}
                    <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">
                      *
                    </span>
                  </div>
                  <input
                    className="w-[508px] h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4 outline-none"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mt-4 flex flex-col items-start gap-[7px]">
                  <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                    Password{" "}
                    <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">
                      *
                    </span>
                  </div>
                  <div className="flex border-2 border-[#EAECF0] text-gray-900 rounded-md w-full p-2.5 ">
                    <input
                      type={visible ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="outline-none border-[#EAECF0] text-gray-900 rounded-md block w-full"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => setVisible(!visible)}
                    >
                      {visible ? <EyeIcon /> : <EyeOffIcon />}
                    </span>
                  </div>
                </div>
                <button
                  className="flex w-full px-[28px] py-[14px] justify-center items-center gap-2 bg-[#403ADD] rounded-md mt-10"
                  type="submit"
                >
                  <span className="text-white text-center">
                    {click ? "Logging in..." : "Submit"}
                  </span>
                  <div className="text-white">
                    {click ? <Spinner /> : <Vector1 />}
                  </div>
                </button>
              </form>
              <div className="mt-6 text-center text-sm">
                {/* onClick handler to trigger handleForgotPassword */}
                <button
                  className="mt-4 w-full flex items-center justify-center gap-2 border-2 border-[#EAECF0] py-2 rounded-md text-[#667085] text-sm font-medium"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </button>
              </div>
              {error && <p className="mt-4 text-red-600">{error}</p>}
              <div className="flex items-center my-4">
                <div className="border-b w-full border-gray-300"></div>
                <div className="px-2 text-sm text-gray-400">OR</div>
                <div className="border-b w-full border-gray-300"></div>
              </div>
              <div className="flex mt-10 w-[508px] h-[48px] px-[20px] py-[4px] justify-center items-center gap-3 rounded-md border-2 border-[#F2F4F7]">
                <div>
                  <GoogleIcon />
                </div>
                <button
                  className="text-[#344054] font-sans text-sm non-italic font-medium leading-5"
                  onClick={signInWithGoogle}
                  // onClick={ onClick={handleOpenAgeModal}}
                >
                  Continue with Google
                </button>
              </div>
              <div className="mt-6 text-center text-sm">
                Don't have an account?{" "}
                <Link to="/signup" className="text-[#1D2939] font-semibold">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
