import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to ShareWheelz! At ShareWheelz, we prioritize your privacy and strive to protect your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our service. By using ShareWheelz, you agree to the collection and use of information in accordance with this policy.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <h3>Personal Information</h3>
        <p>
          We may collect the following personal information from you:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Profile picture</li>
          <li>Ride details (origin, destination, time, and date)</li>
          <li>Payment information (if applicable)</li>
        </ul>
        <h3>Usage Data</h3>
        <p>
          We may collect information about how you access and use ShareWheelz, including:
        </p>
        <ul>
          <li>Your IP address</li>
          <li>Browser type and version</li>
          <li>Device information</li>
          <li>Pages visited</li>
          <li>Time and date of visits</li>
          <li>Time spent on pages</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect in the following ways:
        </p>
        <ul>
          <li>To provide, operate, and maintain ShareWheelz</li>
          <li>To connect you with other users traveling in the same direction</li>
          <li>To send you notifications and updates</li>
          <li>To process transactions and send receipts</li>
          <li>To improve our services and develop new features</li>
          <li>To communicate with you for customer support and feedback</li>
          <li>To monitor usage and analyze trends to enhance user experience</li>
        </ul>
      </section>

      <section>
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
        </p>
        <ul>
          <li>With other users: To facilitate connections with users traveling in the same direction.</li>
          <li>With service providers: We may employ third-party companies and individuals to facilitate our service, provide the service on our behalf, perform service-related tasks, or assist us in analyzing how our service is used.</li>
          <li>For legal reasons: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>
      </section>

      <section>
        <h2>Data Security</h2>
        <p>
          We take the security of your personal information seriously. We implement reasonable security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section>
        <h2>Your Data Protection Rights</h2>
        <p>
          You have the right to:
        </p>
        <ul>
          <li>Access: You can request a copy of your personal data we hold.</li>
          <li>Rectification: You can request that we correct any inaccurate or incomplete data.</li>
          <li>Erasure: You can request that we delete your personal data under certain conditions.</li>
          <li>Restrict processing: You can request that we restrict the processing of your personal data under certain conditions.</li>
          <li>Object to processing: You can object to our processing of your personal data under certain conditions.</li>
          <li>Data portability: You can request that we transfer your data to another organization or directly to you under certain conditions.</li>
        </ul>
        <p>
          To exercise these rights, please contact us using the contact details provided below.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
