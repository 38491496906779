import React, { useState } from "react";
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

export function AccordionCustomIcon({ onOpenChange }) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    const newValue = open === value ? 0 : value;
    setOpen(newValue);
    onOpenChange(newValue !== 0);
  };

  return (
    <>
      <Accordion
        className="md:w-[700px] w-[330px]"
        open={open === 1}
        icon={<Icon id={1} open={open} />}
      >
        <AccordionHeader onClick={() => handleOpen(1)}>
          Why should we choose ShareWheelz app?
        </AccordionHeader>
        <AccordionBody>
          Sharewheelz is an excellent choice for anyone looking to make their commute more affordable, eco-friendly, and enjoyable. By carpooling with Sharewheelz, you can save money, reduce your carbon footprint, and connect with like-minded individuals, all while enjoying a safe and convenient travel experience. Choose Sharewheelz for a smarter, greener way to travel.
        </AccordionBody>
      </Accordion>
      <Accordion
        className="md:w-[700px] w-[330px]"
        open={open === 2}
        icon={<Icon id={2} open={open} />}
      >
        <AccordionHeader onClick={() => handleOpen(2)}>
          How does ShareWheelz helps to maintain safe rides?
        </AccordionHeader>
        <AccordionBody>
          Sharewheelz ensures user safety with a thorough verification process, including email, phone, and ID checks. Users can rate and review each other, fostering transparency and trust. The app features in-app messaging to discuss details without sharing personal information and real-time ride tracking for added security. Emergency access is available, along with clear ride details like route and vehicle information. These measures enhance safety and trust, promoting secure and reliable carpooling experiences.
        </AccordionBody>
      </Accordion>
      <Accordion
        className="md:w-[700px] w-[330px]"
        open={open === 3}
        icon={<Icon id={3} open={open} />}
      >
        <AccordionHeader onClick={() => handleOpen(3)}>
          How we commit to be more affordable?
        </AccordionHeader>
        <AccordionBody>
          Sharewheelz keeps costs down by connecting travelers heading in the same direction. This allows users to book their own vehicles at competitive rates, similar to services like Ola and Uber. By matching users with similar routes, we reduce travel costs and ensure a more economical ride experience. Our approach maximizes efficiency and savings, making carpooling more affordable while still offering the convenience of private transportation.
        </AccordionBody>
      </Accordion>
    </>
  );
}
