import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { handleRideData } from "../store/rideSlice";
import '../styles/DatePicker.css'

const DatePickerComponent = ({ label, selectedDate, onDateChange }) => {
  const [startDate, setStartDate] = useState(selectedDate || new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    if (label) {
      dispatch(handleRideData([label, selectedDate.toISOString()]));
    }
  }, []);

  const handleChange = (date) => {
    setStartDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
    dispatch(handleRideData([label, date.toISOString()]));
    sessionStorage.setItem(label, JSON.stringify(date))
  };

  return (
    <div className="block absolute left-3">
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        dateFormat="yyyy/MM/dd h:mm aa"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        className="block focus:outline-none md:left-2 md:absolute cursor-pointer" 
        onFocus={(e) => e.target.readOnly = true}
      />
    </div>
  );
};

export default DatePickerComponent;
