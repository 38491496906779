import React from "react";
import { ItemIcon } from "../Icons/ItemIcon";
import { ItemIcon2 } from "../Icons/ItemIcon2";
import { RiLinkedinFillIcon } from "../Icons/RiLinkedinFillIcon";
import { ItemIcon3 } from "../Icons/ItemIcon3";
import { Frame1686553372Icon2 } from "../Icons/Frame1686553372Icon2";
import Image22 from "../assets/image22.jpg";
import GooglePlay from "../assets/google-play-badge-logo-svgrepo-com.png";
import { Link } from "react-router-dom/dist";

const Footer = ({ rideRef, referRef, offerRef, workRef }) => {
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="absolute md:w-[1368px] w-screen h-[435px] flex items-start bg-[#f3f3f3] overflow-hidden -translate-x-2/4 left-2/4 md:top-[4448px] top-[5850px]"
      style={window.screen.width < 400 ? { top: "5890px" } : {}}
    >
      {window.screen.width > 768 ? (
        <>
          {" "}
          <div className="absolute w-min h-min flex-col items-start gap-8 left-[88px] top-[88px]">
            <div
              className="w-[274px] h-[110px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${Image22})` }}
            ></div>
            <div className="w-min h-min flex place-content-center items-center gap-3">
              <div className="w-[50px] h-[50px] overflow-visible">
                <a href="https://www.facebook.com/profile.php?id=61554005242399"> <ItemIcon className="w-full h-full" /> </a>
              </div>
              <div className="w-[50px] h-[50px] overflow-visible">
                <a href="https://www.instagram.com/sharewheelzin"><ItemIcon2 className="w-full h-full" /> </a>
              </div>
              <div
                className="w-[50px] h-[50px] flex place-content-center items-center -outline-offset-1 rounded-[50px]"
                style={{ outline: "1px solid #00000080" }}
              >
                <div className="z-0 w-[18px] h-[18px]">
                  <a href="https://www.linkedin.com/company/sharewheelzz/"><RiLinkedinFillIcon className="w-full h-full"/></a>
                </div>
              </div>
              {/* <div className="w-[50px] h-[50px] overflow-visible">
                <ItemIcon3 className="w-full h-full" />
              </div> */}
            </div>
          </div>
          <div className="absolute w-min h-min flex items-start gap-8 left-[459px] top-[119px]">
            <div className="w-[200px] h-min flex flex-col">
              <div className="h-min self-stretch flex flex-col gap-[5px]">
                <div
                  onClick={() => scrollToSection(rideRef)}
                  className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans cursor-pointer"
                >
                  Book A Ride
                </div>
                {/* <div className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                  Drive With Us
                </div> */}
                <div onClick={() => scrollToSection(referRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans cursor-pointer">
                  Refer & Earn
                </div>
                <div onClick={() => scrollToSection(offerRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans cursor-pointer">
                  Our Offerings
                </div>
                <div onClick={() => scrollToSection(workRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans cursor-pointer">
                  About Us
                </div>
              </div>
            </div>
            <div className="w-[200px] h-min flex flex-col">
              <div className="h-min self-stretch flex flex-col items-start gap-[15px]">
                <div className="text-[#000000b2] text-sm leading-[130%] font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                  support@sharewheelz.com
                </div>
                <div className="w-[181px] h-min flex-col">
                  <div className="text-[#000000b2] text-sm leading-[130%] font-semibold font-sans">
                    Company LLP.
                  </div>
                  <div className="text-[#000000b2] text-sm leading-[130%] font-semibold mt-0 font-sans">
                    150, Jamuna Vihar,Khatauli,Khatauli
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-[124px] h-[110px] left-[1170px] top-48 bg-center bg-no-repeat bg-cover absolute z-10"
            style={{ backgroundImage: `url(${GooglePlay})` }}
          ></div>
          <div className="absolute top-[-109px] w-[200.1802px] h-[404px] items-start left-[1131px]">
            <div className="absolute bg-[#1a1a1a] shadow-[inset_0px_0px_3.6396px_0px_#ffffffe5] rounded-[28.2072px] left-[1.3652px] right-[1.3645px] inset-y-0"></div>
            <div className="absolute bg-black rounded-[26.3874px] left-[3.1855px] right-[3.1838px] inset-y-[1.8198px]"></div>
            <div className="absolute w-[1.3649px] h-[13.6486px] bg-[#333] shadow-[inset_0.455px_0px_0.9099px_0px_#ffffff80] rounded-[0.455px_0_0_0.455px] left-0 top-[65.5137px]"></div>
            <div className="absolute w-[1.3649px] h-[28.6622px] bg-[#333] shadow-[inset_0.455px_0px_0.9099px_0px_#ffffff80] rounded-[0.455px_0_0_0.455px] left-0 top-[93.7207px]"></div>
            <div className="absolute w-[1.3649px] h-[28.6622px] bg-[#333] shadow-[inset_0.455px_0px_0.9099px_0px_#ffffff80] rounded-[0.455px_0_0_0.455px] left-0 top-[130.1171px]"></div>
            <div className="absolute w-[1.3649px] h-[47.3153px] bg-[#333] shadow-[inset_-0.455px_0px_0.9099px_0px_#ffffff80] rounded-[0_0.455px_0.455px_0] right-[0.0009px] top-[103.7295px]"></div>
            <div className="absolute items-start bg-[#003fe4] overflow-hidden rounded-[18.1982px] left-[11px] right-[11.7477px] top-2.5 bottom-[10.018px]">
              <div className="absolute left-[calc(50%_-_0.2266px)] top-[calc(50%_-_11.8286px)] w-[170.6081px] h-[369.4234px] items-start bg-[#003fe4] overflow-hidden -translate-x-2/4 -translate-y-2/4">
                <div className="text-white text-base absolute left-[calc(50%_-_-0.0104px)] w-min h-min whitespace-nowrap flex-col -translate-x-2/4 top-[298.5493px] font-sans">
                  ShareWheelz App
                </div>
                <div className="absolute top-[calc(50%_-_-6.7488px)] w-min h-min flex flex-col items-center gap-[8.4486px] -translate-y-2/4 left-[32.8145px]">
                  <div className="w-[82.3738px] h-[82.3738px] overflow-visible">
                    <Frame1686553372Icon2 className="w-full h-full" />
                  </div>
                  <div className="text-[#fffc] text-[12.6729px] leading-[16.8972px] font-medium w-min h-min whitespace-nowrap flex-col font-sans">
                    Scan &amp; Download{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute w-[1368px] max-w-full h-px max-h-full bg-[#d9d9d9] left-0 top-[339px]"></div>
          <div className="absolute w-min h-min flex place-content-center items-center gap-[73px] left-[88px] top-96">
            <div className="text-[#000000b2] text-sm leading-[130%] font-medium w-min h-min whitespace-nowrap flex-col">
              @ 2024 ShareWheelz
            </div>
            <div className="w-min h-min flex items-start gap-10">
              <Link to="/information/policy">
                <div className="text-[#000000b2] text-sm leading-[130%] font-semibold underline w-min h-min whitespace-nowrap flex-col font-sans">
                  Information Security policies{" "}
                </div>
              </Link>
              <Link to="/privacy">
              <div className="text-[#000000b2] text-sm leading-[130%] font-semibold underline w-min h-min whitespace-nowrap flex-col font-sans">
                 Privacy Policy
              </div>
              </Link>
              {/* <div className="text-[#000000b2] text-sm leading-[130%] font-semibold underline w-min h-min whitespace-nowrap flex-col font-sans">
                Site map
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="absolute w-min h-min flex flex-col items-start gap-36 left-[88px] top-[10px]">
            <div
              className="w-[254px] h-[110px] bg-center bg-no-repeat bg-cover absolute top-[40px] left-[-25px]"
              style={{ backgroundImage: `url(${Image22})` }}
            ></div>
            <div className="absolute w-min h-min flex items-start left-[-75px] top-[120px]">
              <div
                className="h-min flex flex-col"
                style={
                  window.screen.width < 400
                    ? { width: "155px" }
                    : { width: "200px" }
                }
              >
                <div className="h-min self-stretch flex flex-col gap-[5px]">
                  <div onClick={() => scrollToSection(rideRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                    Book A Ride
                  </div>
                  {/* <div className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                  Refer & Earn
                  </div> */}
                  <div onClick={() => scrollToSection(referRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                    Refer & Earn
                  </div>
                  <div onClick={() => scrollToSection(offerRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                    Our Offerings
                  </div>
                  <div onClick={() => scrollToSection(workRef)} className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                    About Us
                  </div>
                </div>
              </div>
              <div className="w-[200px] h-min flex flex-col">
                <div className="h-min self-stretch flex flex-col items-start gap-[15px]">
                  <div className="text-[#000000b2] text-sm leading-[130%] font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                    support@sharewheelz.com
                  </div>
                  <div className="w-[181px] h-min flex-col">
                    <div className="text-[#000000b2] text-sm leading-[130%] font-semibold font-sans">
                      Company LLP.
                    </div>
                    <div className="text-[#000000b2] text-sm leading-[130%] font-semibold mt-0 font-sans">
                      150, Jamuna Vihar,Khatauli,Khatauli
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-min h-min flex place-content-center items-center gap-3 absolute top-[257px] left-[-10px]">
              <div className="w-[50px] h-[50px] overflow-visible">
                <a href="https://www.facebook.com/profile.php?id=61554005242399"><ItemIcon className="w-full h-full" /></a>
              </div>
              <div className="w-[50px] h-[50px] overflow-visible">
                <a href="https://www.instagram.com/sharewheelzin"><ItemIcon2 className="w-full h-full" /></a>
              </div>
              <div
                className="w-[50px] h-[50px] flex place-content-center items-center -outline-offset-1 rounded-[50px]"
                style={{ outline: "1px solid #00000080" }}
              >
                <div className="z-0 w-[18px] h-[18px]">
                <a href="https://www.linkedin.com/company/sharewheelzz/"> <RiLinkedinFillIcon className="w-full h-full" /> </a>
                </div>
              </div>
              {/* <div className="w-[50px] h-[50px] overflow-visible">
                <ItemIcon3 className="w-full h-full" />
              </div> */}
            </div>
          </div>
          {/* <div className="absolute w-min h-min flex items-start gap-8 left-[459px] top-[119px]">
            <div className="w-[200px] h-min flex flex-col">
              <div className="h-min self-stretch flex flex-col gap-[5px]">
                <div className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                  Book A Ride
                </div>
                <div className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                  Drive With Us
                </div>
                <div className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                  Careers
                </div>
                <div className="text-[#000000b2] text-sm leading-[140%] font-medium h-min self-stretch flex-col font-sans">
                  About Us
                </div>
              </div>
            </div>
            <div className="w-[200px] h-min flex flex-col">
              <div className="h-min self-stretch flex flex-col items-start gap-[15px]">
                <div className="text-[#000000b2] text-sm leading-[130%] font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                  support@sharewheelz.com
                </div>
                <div className="w-[181px] h-min flex-col">
                  <div className="text-[#000000b2] text-sm leading-[130%] font-semibold font-sans">
                    Company Pvt. Ltd.
                  </div>
                  <div className="text-[#000000b2] text-sm leading-[130%] font-semibold mt-0 font-sans">
                    45/1, Magrath Rd, Ashok Nagar Bengaluru, Karnataka 560025
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="absolute w-[1368px] max-w-full h-px max-h-full bg-[#d9d9d9] left-0 top-[339px]"></div>
          <div className="absolute flex flex-col w-max h-min place-content-center justify-center items-center gap-4 left-2 top-[350px]">
            <div className="text-[#000000b2] text-sm leading-[130%] font-medium w-min h-min whitespace-nowrap flex-col">
              @ 2024 ShareWheelz
            </div>
            <div className="w-min h-min flex items-start gap-10">
              <Link to="/information/policy">
                <div className="text-[#000000b2] text-sm leading-[130%] font-semibold underline w-min h-min whitespace-nowrap flex-col font-sans">
                  Information Security policies{" "}
                </div>
              </Link>
              <Link to="/privacy">
              <div className="text-[#000000b2] text-sm leading-[130%] font-semibold underline w-min h-min whitespace-nowrap flex-col font-sans">
                 Privacy Policy
              </div>
              </Link>
              {/* <div className="text-[#000000b2] text-sm leading-[130%] font-semibold underline w-min h-min whitespace-nowrap flex-col font-sans">
                Site map
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
