import React from "react";
import { Vector } from "../Icons/Vector";

const Requests = ({handleOpen, requests}) => {

  return (
    <div className="absolute md:w-[60%] w-full md:left-[20%] left-0 md:top-[550px] top-[730px] flex md:flex-row flex-col bg-[#050209] md:gap-32 gap-4 md:justify-between px-6 py-3 font-medium md:rounded-lg shadow-lg bg-zinc-950">
      <div className="flex md:flex-row flex-col gap-3 my-auto max-md:flex-wrap">
        <div className="text-base leading-6 text-white">
          We are finding the right cotraveller for your request.
        </div>
        <div className="justify-center self-start p-1.5 text-xs leading-3 text-black bg-amber-400 rounded">
          {requests} cotraveller Requests
        </div>
      </div>
      <div className="flex gap-1 justify-center px-2 py-1.5 text-sm leading-5 text-blue-700 capitalize bg-white rounded-lg">
        <button onClick={handleOpen} className="cursor-pointer outline-none">View Now</button>
        <div className="text-blue-900 md:m-auto my-auto">
          <Vector />
        </div>
      </div>
    </div>
  );
};

export default Requests;
