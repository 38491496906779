import React, { useState } from "react";
import { SparkIcon } from "../../../../Icons/SparkIcon";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../../../../service/apiService";
import { EyeIcon } from "lucide-react";
import { EyeOffIcon } from "lucide-react";

const ForgotPasswordMobile = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      let body = {
        email,
        password,
      };
      const response = await apiServiceHandler(
        "POST",
        "api/auth/forgot/verify",
        body
      );

      const { status, message, secret } = response;

      if (status) {
        // Redirect to login page
        toast.success("Password set successfully");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while verifying OTP");
    }
  };

  const navigateToOtp = () => {
    if (email && password) {
      navigate("/forgot-password-otp");
    } else {
      toast.error("Please fill all the fields");
    }
  };

  return (
    <div className="w-screen h-auto flex flex-col justify-center items-center">
      <ToastContainer />
      <div className="w-screen bg-white px-6 py-12 rounded-xl overflow-y-scroll">
        <div className="text-gray-500 text-xs font-medium leading-5">
          RESET PASSWORD
        </div>
        <div className="text-gray-900 font-sans text-xl font-medium leading-8">
          Enter OTP and New Password
        </div>
        <form>
          <div className="mt-6 flex flex-col items-start gap-4">
            <div className="text-gray-500 font-sans text-sm font-normal leading-5">
              Email Address
            </div>
            <input
              className="w-full h-12 px-4 border-2 border-gray-200 rounded-md"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mt-3 flex flex-col items-start gap-1">
                <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                  New Password{" "}
                  <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">
                    *
                  </span>
                </div>
                <div className="flex border-2 border-[#EAECF0] text-gray-900 rounded-md w-full p-2.5 ">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="outline-none border-[#EAECF0] text-gray-900 rounded-md block w-full"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="cursor-pointer"
                    onClick={() => setVisible(!visible)}
                  >
                    {visible ? <EyeIcon /> : <EyeOffIcon />}
                  </span>
                </div>
              </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            type="submit"
            className="w-full h-12 mt-6 bg-[#403ADD] text-white rounded-md text-base font-sans font-medium leading-7"
            onClick={navigateToOtp}
          >
            SEND OTP
          </button>
        </form>
        <div className="mt-6 flex justify-between items-center">
          <div className="text-gray-500 font-sans text-sm font-normal leading-5">
            Already have an account?{" "}
            <Link to="/login" className="underline text-blue-600">
              Log in
            </Link>
          </div>
          <div className="text-gray-500 font-sans text-sm font-normal leading-5">
            Create an account?{" "}
            <Link to="/signup" className="underline text-blue-600">
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className="relative h-[42vh] bg-[#142157] w-full mt-24">
        <div className="font-sans h-fit flex flex-col sm:flex-row text-3xl non-italic font-medium text-[rgba(255,255,255,0.75)] leading-10 absolute top-[20%] left-[12%]">
          <div className="w-[24.4444px] h-5 absolute top-[-60px] left-[-60px] transform-[rotate(-53.559deg)]">
            <SparkIcon className="w-full h-full" />
          </div>
          <span className="flex-shrink-0">Find your next cotraveller</span>
          <div className="w-[24.4444px] h-5 absolute top-3 left-64"></div>
        </div>
        <div className="flex flex-col absolute top-[40%] sm:top-[35%] left-[12%] mt-6 sm:mt-0">
          <div className="text-white font-sans text-2xl non-italic font-semibold leading-[30px]">
            With
          </div>
          <div className="text-white font-sans text-2xl non-italic font-semibold leading-[30px]">
            ShareWheelz
          </div>
        </div>
        <div className="flex w-full absolute bottom-[10%]">
          <div className="flex w-full justify-center">
            <div className="flex gap-4 text-xs leading-4 text-right text-white">
              <div className="grow">© 2024 — Copyright</div>
              <Link to="/privacy">
                <div className="underline">Privacy Policies</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordMobile;
