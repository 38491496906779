import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getDocs
} from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import "./chat.css";
import sendIcon from "../assets/send.svg";
import emojiIcon from "../assets/emoji.svg";
import EmojiPicker from "emoji-picker-react";
import apiServiceHandler from "../service/apiService";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  handleCurrentGroup,
  handleCurrentUserDate,
  handleCurrentUserMessages,
  setUnreadMessagesCount,
} from "../store/rideSlice";
import ConfirmModal from "./ConfirmModal";
import { useSelector } from "react-redux";
import ListModal from "./ListModal";
import { firestore } from "../firebase";
import UserProfile from "./UserProfile";

const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color;
};
const formatTime = (timestamp) => {
  if (!timestamp) return "Unknown Time";
  const date = timestamp.toDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
  return `${day}/${month}/${year}`; // Return formatted date
};
// Helper function to group messages by date
const groupMessagesByDate = (messages) => {
  if (messages.length === 0) return ["", []]

  const data = messages?.reduce((groups, message) => {
    const date = message.createdAt
      ? formatDate(message.createdAt.toDate())
      : "Unknown Date";
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  return data;
};

const Chat = ({userProfileModalRef, setUserProfileOpen, userProfileOpen}) => {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState([]);
  const [activeGroup, setActiveGroup] = useState("");
  const [isAdmiOfActiveGroup, setAdmin] = useState(false);
  const [ loading, setLoading] = useState(true)
  const pickerRef = useRef(null)
  const [modelText, setModelText] = useState("Are you sure you want to leave this group!")

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }

    if (userProfileModalRef.current && !userProfileModalRef.current.contains(event.target)) {
      setUserProfileOpen(false);
    }
  };

  useEffect(() => {
    if (userProfileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userProfileOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setUserProfileOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const currentUserDate = useSelector((state) => state.ride.currentUserDate);
  const unreadMessagesCount = useSelector((state) => state.ride.unreadMessagesCount);
  const currentUserMessages = useSelector(
    (state) => state.ride.currentUserMessages
  );

  const currentGroup = useSelector((state) => state.ride.currentGroup);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sortAccordingToTimeStampOfMessage = async() => {
    // Reference to the "group" collection
    const response = await apiServiceHandler("GET", "api/group/list");

    if(response.data && response.data.length) {
      const groupCollectionRef = collection(firestore, "group");

      // Query to get contacts sorted by last message timestamp
      const sortedContactsQuery = query(groupCollectionRef, orderBy("lastMessageTimestamp", "desc"));
  
      // Execute the query
      const querySnapshot = await getDocs(sortedContactsQuery);
  
      // Extract the contact IDs
      const sortedContactIds = querySnapshot.docs.map(doc => doc.id);
  
      const sortedArray = response.data.sort((a, b) => {
        return sortedContactIds.indexOf(a._id) - sortedContactIds.indexOf(b._id);
      });
      setContact(sortedArray);
      return sortedArray
    }
    setContact([]);
    return []
  }

  const fetchChatGroupData = async (newUserId) => {
    setLoading(true)
    const data = await sortAccordingToTimeStampOfMessage()
    if (data && data?.length > 0) {

      //now Fetch all the data and will check 
      dispatch(handleCurrentGroup(data[0]));
    }
    if (data && data[0]?.owner == newUserId) {
      setAdmin(true);
    }
    if (!activeGroup && data?.length) {
      setActiveGroup(data[0]._id);
    }
    setLoading(false)
  };

  useEffect(() => {
    const unsubscribes = [];

    const fetchDocuments = () => {
      contact?.forEach((contact) => {
        let groupRefId = `group/${contact._id}/messages`;
        const q = query(
          collection(firestore, groupRefId),
          orderBy("createdAt")
        );

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const messages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const updatedMessages = await Promise.all(
            messages?.map(async (message) => {
              if (contact._id === currentGroup?._id) {
                const newLastSeen = message?.lastSeen?.map((seenInfo) => {
                  if (seenInfo.id === userId && !seenInfo.seen) {
                    return { ...seenInfo, seen: true };
                  }
                  return seenInfo;
                });

                if (
                  JSON.stringify(newLastSeen) !==
                  JSON.stringify(message.lastSeen)
                ) {
                  const messageRef = doc(
                    firestore,
                    `group/${contact._id}/messages/${message.id}`
                  );
                  await updateDoc(messageRef, { lastSeen: newLastSeen });
                  message.lastSeen = newLastSeen;
                }
              }
              return message;
            })
          );

          const unreadCount = updatedMessages.reduce((count, message) => {
            message?.lastSeen?.forEach((seenInfo) => {
              if (seenInfo.id === userId && !seenInfo.seen) {
                count++;
              }
            });

            return count;
          }, 0);

          dispatch(setUnreadMessagesCount({ groupId: contact._id, count: unreadCount }));

          if (currentGroup && contact._id === currentGroup._id) {
            const groupedMessages = groupMessagesByDate(updatedMessages);
            const groupedMessageDates = Object.keys(groupedMessages);
            if (groupedMessageDates?.length > 0) {
              groupedMessageDates?.forEach((firstDate) => {
                dispatch(handleCurrentUserDate(firstDate));
                dispatch(handleCurrentUserMessages(groupedMessages));
              });
            }
          }
          scrollToBottom();
        });

        unsubscribes.push(unsubscribe);
      });
    };

    if (contact?.length > 0) {
      fetchDocuments();
    }

    return () => {
      unsubscribes?.forEach((unsubscribe) => unsubscribe());
    };
  }, [contact, activeGroup, currentGroup, userId, dispatch]);

  useEffect(() => {
    const newUserId = localStorage.getItem("userIdWheelz");
    const userName = localStorage.getItem("userName");
    setUserId(newUserId);
    setUsername(userName);
    fetchChatGroupData(newUserId);
  }, []);

  const sendMessage = async (text, isLeave = false) => {
    if (!activeGroup) {
      setActiveGroup(contact[0]._id);
    }

    const members = [];

    const activeGroupId = activeGroup ? activeGroup : contact[0]._id;
    currentGroup?.members?.map((member) => {
      if (userId === member) {
        members.push({ id: member, seen: true });
      } else {
        members.push({ id: member, seen: false });
      }
    });

    if (text.trim() !== "") {
      const docRef = doc(firestore, "group", activeGroupId);
      const messagesRef = collection(docRef, "messages");
      const body = {
        text,
        createdAt: serverTimestamp(),
        uid: userId,
        displayName: username,
        lastSeen: members,
        group: activeGroupId,
      };

      if (isLeave) {
        body.leaveMessage = true;
      }

      await addDoc(messagesRef, body);

      const groupRef = doc(firestore, "group", activeGroupId);

      await setDoc(groupRef, {
        lastMessageTimestamp: serverTimestamp()
    }, { merge: true });
      // await updateDoc(groupRef, {
      //     lastMessageTimestamp: serverTimestamp()
      // });

      setMessage(""); // Clear the message input
      sortAccordingToTimeStampOfMessage()
      // scrollToBottom(); // Scroll to bottom after sending message
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentUserMessages]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage(message);
    }
  };

  const onEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
  };

  const getActiveGroupChatName = () => {
    const activeGroupId = activeGroup
      ? activeGroup
      : contact?.length
      ? contact[0]._id
      : "";
    const contactObj = contact.find((c) => c._id === activeGroupId);

    return contactObj
      ? contactObj?.members_name?.map((member) => member.name).join(", ")
      : "Unknown";
  };

  const getActiveGroupOriginAndDestination = () => {
    const activeGroupId = activeGroup
      ? activeGroup
      : contact?.length
      ? contact[0]._id
      : "";
    const contactObj = contact.find((c) => c._id === activeGroupId);
    return contactObj
      ? contactObj.origin + " to " + contactObj.destination
      : "Unknown";
  };

  const onLeaveGroup = async () => {
    await apiServiceHandler("POST", "api/group/leave", {
      groupId: activeGroup,
    });
    sendMessage(`${username} Leave`, true);
    toast.success("Successfully left the group.");
    window.location.reload();
    return true;
  };

  const handleOpenList = () => {
    setListModal(true);
  };

  const handleCloseList = () => {
    setListModal(false);
  };

  const removeGroupMember = async (id, name) => {
    if (!id) {
      return null;
    }

    await apiServiceHandler("POST", "api/group/removed", {
      groupId: activeGroup,
      memberId: id,
    });
    sendMessage(`${username} removed ${name}`, true);
    toast.success(`successfully removed ${name} from the group`);
    window.location.reload();
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirm = () => {
    setShowModal(false);
    onLeaveGroup();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleAdmin = (contact) => {
    const newUserId = localStorage.getItem("userIdWheelz");

    if (contact.owner == newUserId) {
      setAdmin(true);
      setModelText("Are you sure you want to leave this group? If you leave, your listed carpooling rides will be removed")
    } else {
      setAdmin(false)
      setModelText("Are you sure you want to leave this group!")
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <ToastContainer />
      {/* <Navbar /> */}
      <ConfirmModal
        text= {modelText}
        show={showModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
      <ListModal
        isOpen={listModal}
        onClose={handleCloseList}
        items={currentGroup}
        removeGroupMember={removeGroupMember}
      />
      {userProfileOpen && <UserProfile userProfileModalRef={userProfileModalRef} setUserProfileOpen= {setUserProfileOpen} />}
      {
      contact?.length ? (
        <div className="flex flex-col md:flex-row flex-grow mt-24">
        <div className="md:w-1/3 flex-shrink-0 bg-gray-100 dark:bg-gray-800 p-4">
          <div className="chat-header mb-4 flex flex-col">
            <span className="text-lg font-bold">Messages & Requests</span>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Chat with people who want to travel with you.
            </span>
          </div>
      
          <div className="max-h-[73vh] overflow-y-scroll" id="chat-sidebar">
            {contact?.map((contact, index) => (
              <div
                className={`contact p-4 mb-2 rounded-lg cursor-pointer ${
                  currentGroup._id === contact._id
                    ? "bg-blue-50 dark:bg-blue-600"
                    : "bg-white dark:bg-gray-700"
                }`}
                key={index}
                onClick={() => {
                  setActiveGroup(contact._id);
                  handleAdmin(contact)
                  dispatch(handleCurrentGroup(contact));
                }}
              >
                <div
                  className="contact-icon w-10 h-10 rounded-full flex items-center justify-center text-white"
                  style={{ backgroundColor: stringToColor(contact.name) }}
                >
                  {contact.name.charAt(0)}
                </div>
                <div className="contact-info ml-4">
                  <span className="contact-name font-semibold">
                    {contact.name}
                  </span>
                  <span className="contact-subtitle text-sm text-gray-500 dark:text-gray-400">
                    {contact.origin + " to"} {contact.destination}
                  </span>
                </div>
                {unreadMessagesCount[contact._id] > 0 && (
                  <span className="bg-blue-600 text-white flex items-center justify-center rounded-3xl w-7 h-7 text-xs">
                    {unreadMessagesCount[contact._id]}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

          <div className="flex flex-col flex-grow bg-white dark:bg-gray-900 p-4 max-h-[88vh]">
            <div className="chat-header1 flex flex-col items-center justify-between mb-4">
              <div className="flex flex-col">
                <span className="text-lg font-bold">
                  {getActiveGroupChatName()}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {getActiveGroupOriginAndDestination()}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {isAdmiOfActiveGroup ? "Admin" : "Member"}
                </span>
              </div>
              <div
                className={
                  isAdmiOfActiveGroup ? "flex justify-between w-full" : "w-full"
                }
              >
                {isAdmiOfActiveGroup && (
                  <button
                    className={`${
                      currentGroup?.members?.length === 1
                        ? "bg-blue-100"
                        : "bg-blue-300 hover:bg-blue-500"
                    } text-white p-1 rounded-lg mr-2 text-xs`}
                    onClick={handleOpenList}
                    disabled={currentGroup?.members?.length === 1}
                  >
                    Co-Travellers
                  </button>
                )}
                <span
                  onClick={handleDelete}
                  className="flex items-center justify-end cursor-pointer text-sm"
                >
                  Leave Group
                  <svg
                    className="ml-2 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="chat-messages flex-grow overflow-y-auto mb-4">
                <div className="date-label text-center my-2 text-gray-500 dark:text-gray-400">
                { currentUserMessages.length == 2  && currentUserMessages[0] == ""  ? 'No Messages' :
                  currentUserMessages &&
                  Object.keys(currentUserMessages)?.map((date, index) => (
                    <>
                    <div key={date}>
                      <div className="date-label text-center my-2 text-gray-500 dark:text-gray-400">
                        {date && date}
                      </div>
                    </div>
                    {currentUserMessages[date] && currentUserMessages[date].map((msg, index) => (
                      <>
                      <div
                        key={index}
                        className="date-label text-center my-2 text-gray-500 dark:text-gray-400"
                      >
                        {msg.leaveMessage ? msg.text : null}
                      </div>

                      <div
                        key={msg.id}
                        className={`message flex items-start mb-4 ${
                          msg.uid === userId ? "justify-end" : ""
                        }`}
                      >
                        {msg.uid !== userId && (
                          <>
                            {msg.leaveMessage == true ? null : (
                              <div
                                className="message-icon w-8 h-8 rounded-full flex items-center justify-center text-white mr-2"
                                style={{
                                  backgroundColor: stringToColor(
                                    msg.displayName
                                  ),
                                }}
                              >
                                {msg.displayName.charAt(0)}
                              </div>
                            )}
                          </>
                        )}
                        {msg.leaveMessage == true ? null : (
                          <div className="flex flex-col max-w-[50%]">
                            <div className="flex items-center space-x-2 rtl:space-x-reverse mb-1">
                              {msg.uid !== userId ? (
                                <span
                                  className="text-sm font-normal"
                                  style={{
                                    color: stringToColor(msg.displayName),
                                  }}
                                >
                                  {msg.displayName}
                                </span>
                              ) : (
                                <span className="text-sm font-semibold text-gray-700 dark:text-white">
                                  You
                                </span>
                              )}
                              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                {formatTime(msg.createdAt)}
                              </span>
                            </div>
                            <div
                              className="flex flex-col leading-1.5 py-2 px-3 rounded-e-xl rounded-es-xl"
                              style={
                                msg.uid !== userId
                                  ? {
                                      background: "#fff",
                                      boxShadow:
                                        "0px 5px 10px 0px rgba(0, 0, 0, 0.1)",
                                    }
                                  : {
                                      background: "#38bdf8",
                                      boxShadow:
                                        "0px 5px 10px 0px rgba(0, 0, 0, 0.2)",
                                    }
                              }
                            >
                              <p
                                className="text-sm font-normal flex justify-start text-start"
                                style={
                                  msg.uid !== userId
                                    ? { color: "black" }
                                    : { color: "white" }
                                }
                              >
                                {msg.text}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                    ))}
                    </>
                  ))}
            </div>
            <div ref={messagesEndRef} />
            </div>
            <div className="chat-input flex items-center">
              <div className="input-icons flex items-center">
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="mr-2"
                >
                  <img src={emojiIcon} alt="Emoji" height="30px" width="30px" />
                </button>
                {showEmojiPicker && (
                  <div ref={pickerRef} className="emoji-picker absolute bottom-12 left-0">
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                  </div>
                )}
              </div>
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Start typing..."
                className="flex-grow border rounded-lg p-2"
              />
              <button onClick={() => sendMessage(message)} className="ml-2">
                <img src={sendIcon} alt="Send" />
              </button>
            </div>
            </div>
        </div>

       ) :
        loading ?
        (
          <div className="flex justify-center items-center h-screen">
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ):
        (
          <section className="bg-white dark:bg-gray-900 mt-16">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                  No Groups Found.
                </p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                  Sorry, we can't find any groups that you are a part of. To be a
                  part of a group kindly request a ride.{" "}
                </p>
                <Link
                  to="/"
                  className="inline-flex text-white bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                >
                  Back to Homepage
                </Link>
              </div>
            </div>
          </section>
        )
      }
    </div>
  );
};

export default Chat;
