import React, { useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from '../service/apiService';

const ReferEmailModal = ({referEmailModalRef}) => {
  const [email, setEmail] = useState()

  const sendLink = async() => {
    try {
      if(!email) {
        toast.error('Please enter email')
        return
      }
      const data = await apiServiceHandler("POST", "api/user/referral/code", { friendEmail: email})
      if(data.status) {
        toast.success('Successfull sent an invite to your friend!')

      } else {
        toast.success('Something went wrong Please try again later!')
      }

      setTimeout(() => {
        window.location.reload()
      }, 2000)

    }
    catch (error) {
      toast.error('Something went wrong Please try again later')
    }
  }

  return (
    <>
    <ToastContainer />
    <div
    id="timeline-modal"
    tabIndex="-1"
    aria-hidden="true"
    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
  >
    <div
      className="flex flex-col px-6 pb-6 bg-white rounded-xl md:max-w-[480px] w-[95%] max-md:px-5"
      ref={referEmailModalRef}
    >
      <div className='mt-4 text-xl font-bold text-gray-600 text-center'>
        <h2>ENTER FRIENDS EMAIL</h2>
      </div>
      <div className="flex flex-col gap-5 mt-4">
        <div class="relative w-full">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 26 26"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-user-round-pen w-4 h-4 text-gray-500 dark:text-gray-400"
            >
              <path d="M2 21a8 8 0 0 1 10.821-7.487" />
              <path d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
              <circle cx="10" cy="8" r="5" />
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            class="bg-white h-12 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Email"
            onChange={(e) => { setEmail(e.target.value)}}
            required
          />
        </div>
      </div>

      <button className="w-[-webkit-fill-available]" onClick={sendLink}>
        <div className="justify-center px-2.5 py-3.5 mt-6 text-sm font-medium text-center text-white bg-indigo-700 rounded-md max-md:max-w-full cursor-pointer">
          Send Link
        </div>{" "}
      </button>
    </div>
  </div>
  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
  )
}

export default ReferEmailModal