import React from 'react'
import { Frame1686553372Icon } from '../Icons/Frame1686553372Icon'
import { ElectricCarChargingPowerIcon } from '../Icons/ElectricCarChargingPowerIcon'
import { VectorIcon3 } from '../Icons/VectorIcon3'

const ReferFriend = ({referRef, setReferOpen}) => {
  return (
    <div ref={referRef} className="absolute left-[calc(50%_-_0.5px)] md:w-[867px] w-full md:h-[142px] h-3/4 items-start bg-[#008b0b] overflow-hidden -translate-x-2/4 md:rounded-xl md:top-[655px] top-[920px]">
        <div className="absolute right-[-14.1411%] top-[-32.6236%] bottom-[-18.554%] overflow-visible left-[65.4299%] md:block hidden">
          <VectorIcon3 className="w-full h-full" />
        </div>
        <div className="absolute md:top-[-25.3521%] top-[-10.3521%] bottom-[-26.0563%] md:left-0 flex md:w-auto w-screen justify-center md:right-[75.2018%]">
          <ElectricCarChargingPowerIcon className="md:w-full w-[80%] md:h-full h-[50%]" />
        </div>
        <div className="absolute flex md:top-[calc(50%_-_-0.5px)] top-[calc(50%_-_-10.5px)] md:w-min w-screen h-min flex-col md:items-start items-center gap-3 -translate-y-2/4 md:left-[259px]">
          <div className="w-min h-min flex flex-col items-start gap-[7px]">
            <div className="text-white text-xl leading-5 font-semibold w-min h-min whitespace-nowrap flex-col font-sans">We reduced 120 tones carbon footprint</div>
            <div className="text-[#fffc] text-sm leading-4 font-medium w-min h-min whitespace-nowrap flex-col font-sans">Join our cause by downloading ShareWheelz App</div>
          </div>
          <button className="relative w-[113px] h-8 place-content-center items-center rounded bg-white overflow-hidden p-2" onClick={() => setReferOpen(true)}>
            <div className="text-black text-sm leading-4 font-medium absolute w-min h-min text-center items-center whitespace-nowrap flex-col left-2 top-2 font-sans">Refer A Friend</div>
          </button>
        </div>
        <div className="absolute flex md:w-min w-screen h-min flex-col gap-2 -translate-y-2/4 md:left-[724px] items-center justify-center md:top-2/4 top-[80%]">
          <div className="md:w-[78px] w-[42%] md:h-[78px] h-[42%] overflow-visible">
            <Frame1686553372Icon className="w-full h-full" />
          </div>
          <div className="text-[#fffc] text-xs leading-4 font-medium w-min md:h-min whitespace-nowrap flex-col font-sans">Scan &amp; Download </div>
        </div>
    </div>
  )
}

export default ReferFriend