import { memo } from 'react';

const SaveMoneyIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.0546 44.6514L25.1209 39.5776C25.3798 39.4159 25.6689 39.3086 25.9706 39.2622C26.2723 39.2158 26.5803 39.2312 26.8759 39.3076C29.7334 40.0389 38.3509 42.4126 41.5009 43.5151C43.8971 44.3589 44.8759 46.7101 43.8971 48.2739C42.5921 50.3664 40.6121 50.1301 39.1834 49.7363C38.9917 49.6768 38.8038 49.6055 38.6209 49.5226L32.9959 47.1264M16.9647 59.5011L9.30345 47.9699C9.2133 47.8334 9.15106 47.6805 9.12035 47.5199C9.08964 47.3593 9.09107 47.1942 9.12453 47.0342C9.15801 46.8741 9.22287 46.7223 9.31538 46.5875C9.40788 46.4526 9.52618 46.3375 9.66345 46.2486L14.4897 43.1099C14.7615 42.9355 15.0904 42.8733 15.4071 42.9363C15.7239 42.9992 16.0039 43.1823 16.1885 43.4474L24.0635 54.7649C24.157 54.8997 24.2228 55.0516 24.257 55.2121C24.2913 55.3725 24.2932 55.5382 24.2629 55.6994C24.2326 55.8606 24.1705 56.0142 24.0801 56.1512C23.9899 56.2881 23.8734 56.4058 23.7372 56.4974L18.7422 59.8724C18.6032 59.9711 18.4453 60.0401 18.2784 60.075C18.1116 60.1099 17.9392 60.1099 17.7724 60.075C17.6054 60.0401 17.4476 59.9711 17.3086 59.8724C17.1696 59.7736 17.0525 59.6473 16.9647 59.5011Z'
      stroke='#3D67D6'
      strokeWidth={2.25}
      strokeMiterlimit={10}
      strokeLinecap='round'
    />
    <path
      d='M23.3102 53.7975L25.9427 52.29L40.8602 56.3738C41.0138 56.4152 41.1745 56.4236 41.3315 56.3984C41.4886 56.3732 41.6385 56.3149 41.7715 56.2275C43.8752 54.8775 52.8752 48.9263 57.3077 45.7875C57.6745 45.591 57.9858 45.3052 58.2129 44.9565C58.4399 44.6078 58.5754 44.2076 58.6068 43.7927C58.6382 43.3778 58.5645 42.9616 58.3925 42.5827C58.2205 42.2038 57.9557 41.8744 57.6227 41.625C57.1052 41.1625 56.4521 40.8797 55.7606 40.8189C55.0693 40.7581 54.3768 40.9224 53.7865 41.2875C52.2227 42.1538 47.914 45.36 47.914 45.36M42.8516 37.6199C50.9288 37.6199 57.4766 31.0721 57.4766 22.9949C57.4766 14.9177 50.9288 8.36993 42.8516 8.36993C34.7744 8.36993 28.2266 14.9177 28.2266 22.9949C28.2266 31.0721 34.7744 37.6199 42.8516 37.6199Z'
      stroke='#262626'
      strokeWidth={2.25}
      strokeMiterlimit={10}
      strokeLinecap='round'
    />
    <path
      d='M38.0363 15.5814H40.3763C42.6263 15.5814 45.1688 16.3351 45.1688 19.8114C45.1688 23.5801 41.9738 25.0201 38.5876 24.6376C38.5479 24.6327 38.5076 24.6403 38.4725 24.6594C38.4374 24.6786 38.4091 24.7083 38.3918 24.7443C38.3744 24.7803 38.3688 24.8208 38.3756 24.8602C38.3825 24.8996 38.4015 24.9358 38.4301 24.9639L45.6301 32.7151M47.9248 15.5814H39.8586M38.1598 19.935H47.9811'
      stroke='#3D67D6'
      strokeWidth={2.25}
      strokeMiterlimit={10}
      strokeLinecap='round'
    />
  </svg>
);
const Memo = memo(SaveMoneyIcon);
export { Memo as SaveMoneyIcon };
