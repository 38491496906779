import React from "react";

const ListModal = ({ isOpen, onClose, items, removeGroupMember }) => {
  if (!isOpen) return null;

  const memberName = items.members_name.filter(item => !item.isOwner);
  if(!memberName.length) {
    return null;
  }
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg w-full mx-4 sm:mx-0">
        <div className="bg-white p-4">
          <div className="flex justify-between items-center pb-4 border-b border-gray-300">
            <h2 className="text-xl font-semibold">Co-Travellers</h2>
            <button
              onClick={onClose}
              className="text-white bg-gray-500 hover:bg-gray-600 w-6 h-6 flex items-center justify-center rounded-full"
            >
              &times;
            </button>
          </div>
          <ul className="mt-4 space-y-2">
            {memberName.map((item, index) => (
              <div key={index} className="flex justify-between items-center border-b-2 pb-2">
                <li className="p-2 rounded w-full">
                  {item.name}
                </li>
                <button 
                  className="bg-red-200 w-20 h-10 rounded-full hover:bg-red-400 flex items-center justify-center  text-sm text-white" 
                  onClick={() => removeGroupMember(item.id, item.name)}
                >
                  Remove
                </button>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ListModal;

