import { memo } from 'react';

const Frame1686553372Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 79 78' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x={0.5} width={78} height={78} rx={4} fill='white' />
    <path d='M9.76531 8H8.5V9.26531H9.76531V8Z' fill='black' />
    <path d='M11.0306 8H9.76531V9.26531H11.0306V8Z' fill='black' />
    <path d='M12.2959 8H11.0306V9.26531H12.2959V8Z' fill='black' />
    <path d='M13.5612 8H12.2959V9.26531H13.5612V8Z' fill='black' />
    <path d='M14.8265 8H13.5612V9.26531H14.8265V8Z' fill='black' />
    <path d='M16.0918 8H14.8265V9.26531H16.0918V8Z' fill='black' />
    <path d='M17.3571 8H16.0918V9.26531H17.3571V8Z' fill='black' />
    <path d='M19.8878 8H18.6224V9.26531H19.8878V8Z' fill='black' />
    <path d='M21.1531 8H19.8878V9.26531H21.1531V8Z' fill='black' />
    <path d='M24.949 8H23.6837V9.26531H24.949V8Z' fill='black' />
    <path d='M28.7449 8H27.4796V9.26531H28.7449V8Z' fill='black' />
    <path d='M30.0102 8H28.7449V9.26531H30.0102V8Z' fill='black' />
    <path d='M33.8061 8H32.5408V9.26531H33.8061V8Z' fill='black' />
    <path d='M37.602 8H36.3367V9.26531H37.602V8Z' fill='black' />
    <path d='M40.1327 8H38.8673V9.26531H40.1327V8Z' fill='black' />
    <path d='M47.7245 8H46.4592V9.26531H47.7245V8Z' fill='black' />
    <path d='M50.2551 8H48.9898V9.26531H50.2551V8Z' fill='black' />
    <path d='M54.051 8H52.7857V9.26531H54.051V8Z' fill='black' />
    <path d='M55.3163 8H54.051V9.26531H55.3163V8Z' fill='black' />
    <path d='M56.5816 8H55.3163V9.26531H56.5816V8Z' fill='black' />
    <path d='M60.3775 8H59.1122V9.26531H60.3775V8Z' fill='black' />
    <path d='M62.9082 8H61.6429V9.26531H62.9082V8Z' fill='black' />
    <path d='M64.1735 8H62.9082V9.26531H64.1735V8Z' fill='black' />
    <path d='M65.4388 8H64.1735V9.26531H65.4388V8Z' fill='black' />
    <path d='M66.7041 8H65.4388V9.26531H66.7041V8Z' fill='black' />
    <path d='M67.9694 8H66.7041V9.26531H67.9694V8Z' fill='black' />
    <path d='M69.2347 8H67.9694V9.26531H69.2347V8Z' fill='black' />
    <path d='M70.5 8H69.2347V9.26531H70.5V8Z' fill='black' />
    <path d='M9.76531 9.26531H8.5V10.5306H9.76531V9.26531Z' fill='black' />
    <path d='M17.3571 9.26531H16.0918V10.5306H17.3571V9.26531Z' fill='black' />
    <path d='M32.5408 9.26531H31.2755V10.5306H32.5408V9.26531Z' fill='black' />
    <path d='M33.8061 9.26531H32.5408V10.5306H33.8061V9.26531Z' fill='black' />
    <path d='M37.602 9.26531H36.3367V10.5306H37.602V9.26531Z' fill='black' />
    <path d='M38.8673 9.26531H37.602V10.5306H38.8673V9.26531Z' fill='black' />
    <path d='M45.1939 9.26531H43.9286V10.5306H45.1939V9.26531Z' fill='black' />
    <path d='M54.051 9.26531H52.7857V10.5306H54.051V9.26531Z' fill='black' />
    <path d='M55.3163 9.26531H54.051V10.5306H55.3163V9.26531Z' fill='black' />
    <path d='M57.8469 9.26531H56.5816L56.5816 10.5306H57.8469V9.26531Z' fill='black' />
    <path d='M59.1122 9.26531H57.8469V10.5306H59.1122L59.1122 9.26531Z' fill='black' />
    <path d='M60.3775 9.26531H59.1122L59.1122 10.5306H60.3775V9.26531Z' fill='black' />
    <path d='M62.9082 9.26531H61.6429V10.5306H62.9082V9.26531Z' fill='black' />
    <path d='M70.5 9.26531H69.2347L69.2347 10.5306H70.5V9.26531Z' fill='black' />
    <path d='M9.76531 10.5306H8.5V11.7959H9.76531V10.5306Z' fill='black' />
    <path d='M12.2959 10.5306H11.0306V11.7959H12.2959V10.5306Z' fill='black' />
    <path d='M13.5612 10.5306H12.2959V11.7959H13.5612V10.5306Z' fill='black' />
    <path d='M14.8265 10.5306H13.5612V11.7959H14.8265V10.5306Z' fill='black' />
    <path d='M17.3571 10.5306H16.0918V11.7959H17.3571V10.5306Z' fill='black' />
    <path d='M21.1531 10.5306H19.8878V11.7959H21.1531V10.5306Z' fill='black' />
    <path d='M22.4184 10.5306H21.1531V11.7959H22.4184V10.5306Z' fill='black' />
    <path d='M24.949 10.5306H23.6837V11.7959H24.949V10.5306Z' fill='black' />
    <path d='M30.0102 10.5306H28.7449V11.7959H30.0102V10.5306Z' fill='black' />
    <path d='M31.2755 10.5306H30.0102V11.7959H31.2755V10.5306Z' fill='black' />
    <path d='M32.5408 10.5306H31.2755V11.7959H32.5408V10.5306Z' fill='black' />
    <path d='M33.8061 10.5306H32.5408V11.7959H33.8061V10.5306Z' fill='black' />
    <path d='M36.3367 10.5306H35.0714V11.7959H36.3367V10.5306Z' fill='black' />
    <path d='M37.602 10.5306H36.3367V11.7959H37.602V10.5306Z' fill='black' />
    <path d='M38.8673 10.5306H37.602V11.7959H38.8673V10.5306Z' fill='black' />
    <path d='M40.1327 10.5306H38.8673V11.7959H40.1327V10.5306Z' fill='black' />
    <path d='M42.6633 10.5306H41.398V11.7959H42.6633V10.5306Z' fill='black' />
    <path d='M43.9286 10.5306H42.6633V11.7959H43.9286L43.9286 10.5306Z' fill='black' />
    <path d='M45.1939 10.5306H43.9286L43.9286 11.7959H45.1939V10.5306Z' fill='black' />
    <path d='M51.5204 10.5306H50.2551V11.7959H51.5204V10.5306Z' fill='black' />
    <path d='M52.7857 10.5306H51.5204V11.7959H52.7857L52.7857 10.5306Z' fill='black' />
    <path d='M55.3163 10.5306H54.051L54.051 11.7959H55.3163V10.5306Z' fill='black' />
    <path d='M59.1122 10.5306H57.8469L57.8469 11.7959H59.1122V10.5306Z' fill='black' />
    <path d='M60.3775 10.5306H59.1122V11.7959H60.3775V10.5306Z' fill='black' />
    <path d='M62.9082 10.5306H61.6429V11.7959H62.9082V10.5306Z' fill='black' />
    <path d='M65.4388 10.5306H64.1735V11.7959H65.4388V10.5306Z' fill='black' />
    <path d='M66.7041 10.5306H65.4388V11.7959H66.7041V10.5306Z' fill='black' />
    <path d='M67.9694 10.5306H66.7041V11.7959H67.9694V10.5306Z' fill='black' />
    <path d='M70.5 10.5306H69.2347V11.7959H70.5V10.5306Z' fill='black' />
    <path d='M9.76531 11.7959H8.5V13.0612H9.76531V11.7959Z' fill='black' />
    <path d='M12.2959 11.7959H11.0306V13.0612H12.2959V11.7959Z' fill='black' />
    <path d='M13.5612 11.7959H12.2959V13.0612H13.5612V11.7959Z' fill='black' />
    <path d='M14.8265 11.7959H13.5612V13.0612H14.8265V11.7959Z' fill='black' />
    <path d='M17.3571 11.7959H16.0918V13.0612H17.3571V11.7959Z' fill='black' />
    <path d='M19.8878 11.7959H18.6224V13.0612H19.8878V11.7959Z' fill='black' />
    <path d='M21.1531 11.7959H19.8878V13.0612H21.1531V11.7959Z' fill='black' />
    <path d='M26.2143 11.7959H24.949V13.0612H26.2143V11.7959Z' fill='black' />
    <path d='M27.4796 11.7959H26.2143V13.0612H27.4796V11.7959Z' fill='black' />
    <path d='M28.7449 11.7959H27.4796V13.0612H28.7449V11.7959Z' fill='black' />
    <path d='M30.0102 11.7959H28.7449V13.0612H30.0102V11.7959Z' fill='black' />
    <path d='M38.8673 11.7959H37.602V13.0612H38.8673V11.7959Z' fill='black' />
    <path d='M40.1327 11.7959H38.8673V13.0612H40.1327V11.7959Z' fill='black' />
    <path d='M45.1939 11.7959H43.9286L43.9286 13.0612H45.1939V11.7959Z' fill='black' />
    <path d='M46.4592 11.7959H45.1939V13.0612H46.4592V11.7959Z' fill='black' />
    <path d='M47.7245 11.7959H46.4592V13.0612H47.7245V11.7959Z' fill='black' />
    <path d='M50.2551 11.7959H48.9898V13.0612H50.2551V11.7959Z' fill='black' />
    <path d='M51.5204 11.7959H50.2551V13.0612H51.5204V11.7959Z' fill='black' />
    <path d='M55.3163 11.7959H54.051V13.0612H55.3163V11.7959Z' fill='black' />
    <path d='M59.1122 11.7959H57.8469V13.0612H59.1122V11.7959Z' fill='black' />
    <path d='M62.9082 11.7959H61.6429V13.0612H62.9082V11.7959Z' fill='black' />
    <path d='M65.4388 11.7959H64.1735V13.0612H65.4388V11.7959Z' fill='black' />
    <path d='M66.7041 11.7959H65.4388V13.0612H66.7041V11.7959Z' fill='black' />
    <path d='M67.9694 11.7959H66.7041V13.0612H67.9694V11.7959Z' fill='black' />
    <path d='M70.5 11.7959H69.2347V13.0612H70.5V11.7959Z' fill='black' />
    <path d='M9.76531 13.0612H8.5V14.3265H9.76531V13.0612Z' fill='black' />
    <path d='M12.2959 13.0612H11.0306V14.3265H12.2959V13.0612Z' fill='black' />
    <path d='M13.5612 13.0612H12.2959V14.3265H13.5612V13.0612Z' fill='black' />
    <path d='M14.8265 13.0612H13.5612V14.3265H14.8265V13.0612Z' fill='black' />
    <path d='M17.3571 13.0612H16.0918V14.3265H17.3571V13.0612Z' fill='black' />
    <path d='M21.1531 13.0612H19.8878V14.3265H21.1531V13.0612Z' fill='black' />
    <path d='M24.949 13.0612H23.6837V14.3265H24.949V13.0612Z' fill='black' />
    <path d='M32.5408 13.0612H31.2755V14.3265H32.5408V13.0612Z' fill='black' />
    <path d='M37.602 13.0612H36.3367V14.3265H37.602V13.0612Z' fill='black' />
    <path d='M38.8673 13.0612H37.602V14.3265H38.8673V13.0612Z' fill='black' />
    <path d='M40.1327 13.0612H38.8673V14.3265H40.1327V13.0612Z' fill='black' />
    <path d='M41.398 13.0612H40.1327V14.3265H41.398V13.0612Z' fill='black' />
    <path d='M42.6633 13.0612H41.398V14.3265H42.6633V13.0612Z' fill='black' />
    <path d='M47.7245 13.0612H46.4592L46.4592 14.3265H47.7245V13.0612Z' fill='black' />
    <path d='M52.7857 13.0612H51.5204L51.5204 14.3265H52.7857V13.0612Z' fill='black' />
    <path d='M54.051 13.0612H52.7857V14.3265H54.051L54.051 13.0612Z' fill='black' />
    <path d='M62.9082 13.0612H61.6429V14.3265H62.9082V13.0612Z' fill='black' />
    <path d='M65.4388 13.0612H64.1735V14.3265H65.4388V13.0612Z' fill='black' />
    <path d='M66.7041 13.0612H65.4388V14.3265H66.7041V13.0612Z' fill='black' />
    <path d='M67.9694 13.0612H66.7041V14.3265H67.9694V13.0612Z' fill='black' />
    <path d='M70.5 13.0612H69.2347V14.3265H70.5V13.0612Z' fill='black' />
    <path d='M9.76531 14.3265H8.5V15.5918H9.76531V14.3265Z' fill='black' />
    <path d='M17.3571 14.3265H16.0918V15.5918H17.3571V14.3265Z' fill='black' />
    <path d='M19.8878 14.3265H18.6224V15.5918H19.8878V14.3265Z' fill='black' />
    <path d='M22.4184 14.3265H21.1531V15.5918H22.4184V14.3265Z' fill='black' />
    <path d='M23.6837 14.3265H22.4184V15.5918H23.6837V14.3265Z' fill='black' />
    <path d='M26.2143 14.3265H24.949V15.5918H26.2143V14.3265Z' fill='black' />
    <path d='M31.2755 14.3265H30.0102V15.5918H31.2755V14.3265Z' fill='black' />
    <path d='M32.5408 14.3265H31.2755V15.5918H32.5408V14.3265Z' fill='black' />
    <path d='M33.8061 14.3265H32.5408V15.5918H33.8061V14.3265Z' fill='black' />
    <path d='M35.0714 14.3265H33.8061V15.5918H35.0714V14.3265Z' fill='black' />
    <path d='M36.3367 14.3265H35.0714V15.5918H36.3367V14.3265Z' fill='black' />
    <path d='M37.602 14.3265H36.3367V15.5918H37.602V14.3265Z' fill='black' />
    <path d='M42.6633 14.3265H41.398V15.5918H42.6633V14.3265Z' fill='black' />
    <path d='M43.9286 14.3265H42.6633V15.5918H43.9286V14.3265Z' fill='black' />
    <path d='M45.1939 14.3265H43.9286V15.5918H45.1939V14.3265Z' fill='black' />
    <path d='M48.9898 14.3265H47.7245L47.7245 15.5918H48.9898V14.3265Z' fill='black' />
    <path d='M51.5204 14.3265H50.2551V15.5918H51.5204L51.5204 14.3265Z' fill='black' />
    <path d='M54.051 14.3265H52.7857L52.7857 15.5918H54.051V14.3265Z' fill='black' />
    <path d='M55.3163 14.3265H54.051V15.5918H55.3163V14.3265Z' fill='black' />
    <path d='M57.8469 14.3265H56.5816V15.5918H57.8469V14.3265Z' fill='black' />
    <path d='M62.9082 14.3265H61.6429V15.5918H62.9082V14.3265Z' fill='black' />
    <path d='M70.5 14.3265H69.2347V15.5918H70.5V14.3265Z' fill='black' />
    <path d='M9.76531 15.5918H8.5V16.8571H9.76531V15.5918Z' fill='black' />
    <path d='M11.0306 15.5918H9.76531V16.8571H11.0306V15.5918Z' fill='black' />
    <path d='M12.2959 15.5918H11.0306V16.8571H12.2959V15.5918Z' fill='black' />
    <path d='M13.5612 15.5918H12.2959V16.8571H13.5612V15.5918Z' fill='black' />
    <path d='M14.8265 15.5918H13.5612V16.8571H14.8265V15.5918Z' fill='black' />
    <path d='M16.0918 15.5918H14.8265V16.8571H16.0918V15.5918Z' fill='black' />
    <path d='M17.3571 15.5918H16.0918V16.8571H17.3571V15.5918Z' fill='black' />
    <path d='M19.8878 15.5918H18.6224V16.8571H19.8878V15.5918Z' fill='black' />
    <path d='M22.4184 15.5918H21.1531V16.8571H22.4184V15.5918Z' fill='black' />
    <path d='M24.949 15.5918H23.6837V16.8571H24.949V15.5918Z' fill='black' />
    <path d='M27.4796 15.5918H26.2143V16.8571H27.4796V15.5918Z' fill='black' />
    <path d='M30.0102 15.5918H28.7449V16.8571H30.0102V15.5918Z' fill='black' />
    <path d='M32.5408 15.5918H31.2755V16.8571H32.5408V15.5918Z' fill='black' />
    <path d='M35.0714 15.5918H33.8061V16.8571H35.0714V15.5918Z' fill='black' />
    <path d='M37.602 15.5918H36.3367V16.8571H37.602V15.5918Z' fill='black' />
    <path d='M40.1327 15.5918H38.8673V16.8571H40.1327V15.5918Z' fill='black' />
    <path d='M42.6633 15.5918H41.398V16.8571H42.6633V15.5918Z' fill='black' />
    <path d='M45.1939 15.5918H43.9286L43.9286 16.8571H45.1939V15.5918Z' fill='black' />
    <path d='M47.7245 15.5918H46.4592V16.8571H47.7245L47.7245 15.5918Z' fill='black' />
    <path d='M50.2551 15.5918H48.9898L48.9898 16.8571H50.2551V15.5918Z' fill='black' />
    <path d='M52.7857 15.5918H51.5204L51.5204 16.8571H52.7857L52.7857 15.5918Z' fill='black' />
    <path d='M55.3163 15.5918H54.051L54.051 16.8571H55.3163V15.5918Z' fill='black' />
    <path d='M57.8469 15.5918H56.5816V16.8571H57.8469V15.5918Z' fill='black' />
    <path d='M60.3775 15.5918H59.1122V16.8571H60.3775V15.5918Z' fill='black' />
    <path d='M62.9082 15.5918H61.6429V16.8571H62.9082V15.5918Z' fill='black' />
    <path d='M64.1735 15.5918H62.9082V16.8571H64.1735V15.5918Z' fill='black' />
    <path d='M65.4388 15.5918H64.1735V16.8571H65.4388V15.5918Z' fill='black' />
    <path d='M66.7041 15.5918H65.4388V16.8571H66.7041V15.5918Z' fill='black' />
    <path d='M67.9694 15.5918H66.7041V16.8571H67.9694V15.5918Z' fill='black' />
    <path d='M69.2347 15.5918H67.9694V16.8571H69.2347L69.2347 15.5918Z' fill='black' />
    <path d='M70.5 15.5918H69.2347L69.2347 16.8571H70.5V15.5918Z' fill='black' />
    <path d='M21.1531 16.8571H19.8878V18.1224H21.1531V16.8571Z' fill='black' />
    <path d='M23.6837 16.8571H22.4184V18.1224H23.6837V16.8571Z' fill='black' />
    <path d='M28.7449 16.8571H27.4796V18.1224H28.7449V16.8571Z' fill='black' />
    <path d='M30.0102 16.8571H28.7449V18.1224H30.0102V16.8571Z' fill='black' />
    <path d='M32.5408 16.8571H31.2755V18.1224H32.5408V16.8571Z' fill='black' />
    <path d='M33.8061 16.8571H32.5408V18.1224H33.8061V16.8571Z' fill='black' />
    <path d='M35.0714 16.8571H33.8061V18.1224H35.0714V16.8571Z' fill='black' />
    <path d='M37.602 16.8571H36.3367V18.1224H37.602V16.8571Z' fill='black' />
    <path d='M42.6633 16.8571H41.398V18.1224H42.6633V16.8571Z' fill='black' />
    <path d='M43.9286 16.8571H42.6633V18.1224H43.9286L43.9286 16.8571Z' fill='black' />
    <path d='M45.1939 16.8571H43.9286L43.9286 18.1224H45.1939V16.8571Z' fill='black' />
    <path d='M47.7245 16.8571H46.4592V18.1224H47.7245V16.8571Z' fill='black' />
    <path d='M51.5204 16.8571H50.2551V18.1224H51.5204L51.5204 16.8571Z' fill='black' />
    <path d='M52.7857 16.8571H51.5204L51.5204 18.1224H52.7857V16.8571Z' fill='black' />
    <path d='M55.3163 16.8571H54.051V18.1224H55.3163V16.8571Z' fill='black' />
    <path d='M56.5816 16.8571H55.3163V18.1224H56.5816L56.5816 16.8571Z' fill='black' />
    <path d='M57.8469 16.8571H56.5816L56.5816 18.1224H57.8469V16.8571Z' fill='black' />
    <path d='M59.1122 16.8571H57.8469V18.1224H59.1122L59.1122 16.8571Z' fill='black' />
    <path d='M16.0918 18.1224H14.8265V19.3878H16.0918V18.1224Z' fill='black' />
    <path d='M17.3571 18.1224H16.0918V19.3878H17.3571V18.1224Z' fill='black' />
    <path d='M19.8878 18.1224H18.6224V19.3878H19.8878V18.1224Z' fill='black' />
    <path d='M21.1531 18.1224H19.8878V19.3878H21.1531V18.1224Z' fill='black' />
    <path d='M24.949 18.1224H23.6837V19.3878H24.949V18.1224Z' fill='black' />
    <path d='M26.2143 18.1224H24.949V19.3878H26.2143V18.1224Z' fill='black' />
    <path d='M27.4796 18.1224H26.2143V19.3878H27.4796V18.1224Z' fill='black' />
    <path d='M28.7449 18.1224H27.4796V19.3878H28.7449V18.1224Z' fill='black' />
    <path d='M30.0102 18.1224H28.7449V19.3878H30.0102V18.1224Z' fill='black' />
    <path d='M37.602 18.1224H36.3367V19.3878H37.602V18.1224Z' fill='black' />
    <path d='M38.8673 18.1224H37.602V19.3878H38.8673V18.1224Z' fill='black' />
    <path d='M40.1327 18.1224H38.8673V19.3878H40.1327V18.1224Z' fill='black' />
    <path d='M41.398 18.1224H40.1327V19.3878H41.398V18.1224Z' fill='black' />
    <path d='M42.6633 18.1224H41.398V19.3878H42.6633V18.1224Z' fill='black' />
    <path d='M43.9286 18.1224H42.6633V19.3878H43.9286V18.1224Z' fill='black' />
    <path d='M46.4592 18.1224H45.1939V19.3878H46.4592L46.4592 18.1224Z' fill='black' />
    <path d='M47.7245 18.1224H46.4592L46.4592 19.3878H47.7245V18.1224Z' fill='black' />
    <path d='M52.7857 18.1224H51.5204L51.5204 19.3878H52.7857V18.1224Z' fill='black' />
    <path d='M54.051 18.1224H52.7857V19.3878H54.051L54.051 18.1224Z' fill='black' />
    <path d='M55.3163 18.1224H54.051L54.051 19.3878H55.3163V18.1224Z' fill='black' />
    <path d='M56.5816 18.1224H55.3163V19.3878H56.5816V18.1224Z' fill='black' />
    <path d='M59.1122 18.1224H57.8469L57.8469 19.3878H59.1122V18.1224Z' fill='black' />
    <path d='M60.3775 18.1224H59.1122V19.3878H60.3775V18.1224Z' fill='black' />
    <path d='M61.6429 18.1224H60.3775V19.3878H61.6429V18.1224Z' fill='black' />
    <path d='M64.1735 18.1224H62.9082V19.3878H64.1735V18.1224Z' fill='black' />
    <path d='M66.7041 18.1224H65.4388V19.3878H66.7041V18.1224Z' fill='black' />
    <path d='M70.5 18.1224H69.2347V19.3878H70.5V18.1224Z' fill='black' />
    <path d='M9.76531 19.3878H8.5V20.6531H9.76531V19.3878Z' fill='black' />
    <path d='M12.2959 19.3878H11.0306V20.6531H12.2959V19.3878Z' fill='black' />
    <path d='M18.6224 19.3878H17.3571V20.6531H18.6224V19.3878Z' fill='black' />
    <path d='M19.8878 19.3878H18.6224V20.6531H19.8878V19.3878Z' fill='black' />
    <path d='M21.1531 19.3878H19.8878V20.6531H21.1531V19.3878Z' fill='black' />
    <path d='M22.4184 19.3878H21.1531V20.6531H22.4184V19.3878Z' fill='black' />
    <path d='M26.2143 19.3878H24.949V20.6531H26.2143V19.3878Z' fill='black' />
    <path d='M33.8061 19.3878H32.5408V20.6531H33.8061V19.3878Z' fill='black' />
    <path d='M37.602 19.3878H36.3367V20.6531H37.602V19.3878Z' fill='black' />
    <path d='M41.398 19.3878H40.1327V20.6531H41.398V19.3878Z' fill='black' />
    <path d='M48.9898 19.3878H47.7245L47.7245 20.6531H48.9898V19.3878Z' fill='black' />
    <path d='M51.5204 19.3878H50.2551V20.6531H51.5204L51.5204 19.3878Z' fill='black' />
    <path d='M55.3163 19.3878H54.051L54.051 20.6531H55.3163V19.3878Z' fill='black' />
    <path d='M61.6429 19.3878H60.3775V20.6531H61.6429V19.3878Z' fill='black' />
    <path d='M64.1735 19.3878H62.9082V20.6531H64.1735V19.3878Z' fill='black' />
    <path d='M66.7041 19.3878H65.4388V20.6531H66.7041V19.3878Z' fill='black' />
    <path d='M69.2347 19.3878H67.9694V20.6531H69.2347L69.2347 19.3878Z' fill='black' />
    <path d='M13.5612 20.6531H12.2959V21.9184H13.5612V20.6531Z' fill='black' />
    <path d='M16.0918 20.6531H14.8265V21.9184H16.0918V20.6531Z' fill='black' />
    <path d='M17.3571 20.6531H16.0918V21.9184H17.3571V20.6531Z' fill='black' />
    <path d='M19.8878 20.6531H18.6224V21.9184H19.8878V20.6531Z' fill='black' />
    <path d='M21.1531 20.6531H19.8878V21.9184H21.1531V20.6531Z' fill='black' />
    <path d='M27.4796 20.6531H26.2143V21.9184H27.4796V20.6531Z' fill='black' />
    <path d='M35.0714 20.6531H33.8061V21.9184H35.0714V20.6531Z' fill='black' />
    <path d='M45.1939 20.6531H43.9286V21.9184H45.1939V20.6531Z' fill='black' />
    <path d='M47.7245 20.6531H46.4592V21.9184H47.7245L47.7245 20.6531Z' fill='black' />
    <path d='M48.9898 20.6531H47.7245L47.7245 21.9184H48.9898V20.6531Z' fill='black' />
    <path d='M51.5204 20.6531H50.2551V21.9184H51.5204V20.6531Z' fill='black' />
    <path d='M54.051 20.6531H52.7857V21.9184H54.051L54.051 20.6531Z' fill='black' />
    <path d='M59.1122 20.6531H57.8469V21.9184H59.1122V20.6531Z' fill='black' />
    <path d='M61.6429 20.6531H60.3775V21.9184H61.6429V20.6531Z' fill='black' />
    <path d='M64.1735 20.6531H62.9082V21.9184H64.1735V20.6531Z' fill='black' />
    <path d='M67.9694 20.6531H66.7041L66.7041 21.9184H67.9694L67.9694 20.6531Z' fill='black' />
    <path d='M70.5 20.6531H69.2347L69.2347 21.9184H70.5V20.6531Z' fill='black' />
    <path d='M11.0306 21.9184H9.76531V23.1837H11.0306V21.9184Z' fill='black' />
    <path d='M12.2959 21.9184H11.0306V23.1837H12.2959V21.9184Z' fill='black' />
    <path d='M13.5612 21.9184H12.2959V23.1837H13.5612V21.9184Z' fill='black' />
    <path d='M16.0918 21.9184H14.8265V23.1837H16.0918V21.9184Z' fill='black' />
    <path d='M18.6224 21.9184H17.3571V23.1837H18.6224V21.9184Z' fill='black' />
    <path d='M19.8878 21.9184H18.6224V23.1837H19.8878V21.9184Z' fill='black' />
    <path d='M26.2143 21.9184H24.949V23.1837H26.2143V21.9184Z' fill='black' />
    <path d='M27.4796 21.9184H26.2143V23.1837H27.4796V21.9184Z' fill='black' />
    <path d='M28.7449 21.9184H27.4796V23.1837H28.7449V21.9184Z' fill='black' />
    <path d='M30.0102 21.9184H28.7449V23.1837H30.0102V21.9184Z' fill='black' />
    <path d='M31.2755 21.9184H30.0102V23.1837H31.2755V21.9184Z' fill='black' />
    <path d='M32.5408 21.9184H31.2755V23.1837H32.5408V21.9184Z' fill='black' />
    <path d='M33.8061 21.9184H32.5408V23.1837H33.8061V21.9184Z' fill='black' />
    <path d='M38.8673 21.9184H37.602V23.1837H38.8673V21.9184Z' fill='black' />
    <path d='M42.6633 21.9184H41.398V23.1837H42.6633V21.9184Z' fill='black' />
    <path d='M43.9286 21.9184H42.6633V23.1837H43.9286L43.9286 21.9184Z' fill='black' />
    <path d='M45.1939 21.9184H43.9286L43.9286 23.1837H45.1939V21.9184Z' fill='black' />
    <path d='M46.4592 21.9184H45.1939V23.1837H46.4592L46.4592 21.9184Z' fill='black' />
    <path d='M48.9898 21.9184H47.7245L47.7245 23.1837H48.9898V21.9184Z' fill='black' />
    <path d='M54.051 21.9184H52.7857V23.1837H54.051V21.9184Z' fill='black' />
    <path d='M56.5816 21.9184H55.3163V23.1837H56.5816V21.9184Z' fill='black' />
    <path d='M57.8469 21.9184H56.5816V23.1837H57.8469L57.8469 21.9184Z' fill='black' />
    <path d='M59.1122 21.9184H57.8469L57.8469 23.1837H59.1122V21.9184Z' fill='black' />
    <path d='M62.9082 21.9184H61.6429L61.6429 23.1837H62.9082L62.9082 21.9184Z' fill='black' />
    <path d='M64.1735 21.9184H62.9082L62.9082 23.1837H64.1735V21.9184Z' fill='black' />
    <path d='M66.7041 21.9184H65.4388V23.1837H66.7041L66.7041 21.9184Z' fill='black' />
    <path d='M69.2347 21.9184H67.9694L67.9694 23.1837H69.2347L69.2347 21.9184Z' fill='black' />
    <path d='M9.76531 23.1837H8.5V24.449H9.76531V23.1837Z' fill='black' />
    <path d='M11.0306 23.1837H9.76531V24.449H11.0306V23.1837Z' fill='black' />
    <path d='M13.5612 23.1837H12.2959V24.449H13.5612V23.1837Z' fill='black' />
    <path d='M16.0918 23.1837H14.8265V24.449H16.0918V23.1837Z' fill='black' />
    <path d='M17.3571 23.1837H16.0918V24.449H17.3571V23.1837Z' fill='black' />
    <path d='M18.6224 23.1837H17.3571V24.449H18.6224V23.1837Z' fill='black' />
    <path d='M19.8878 23.1837H18.6224V24.449H19.8878V23.1837Z' fill='black' />
    <path d='M23.6837 23.1837H22.4184V24.449H23.6837V23.1837Z' fill='black' />
    <path d='M24.949 23.1837H23.6837V24.449H24.949V23.1837Z' fill='black' />
    <path d='M28.7449 23.1837H27.4796V24.449H28.7449V23.1837Z' fill='black' />
    <path d='M35.0714 23.1837H33.8061V24.449H35.0714V23.1837Z' fill='black' />
    <path d='M37.602 23.1837H36.3367V24.449H37.602V23.1837Z' fill='black' />
    <path d='M41.398 23.1837H40.1327V24.449H41.398V23.1837Z' fill='black' />
    <path d='M43.9286 23.1837H42.6633V24.449H43.9286V23.1837Z' fill='black' />
    <path d='M47.7245 23.1837H46.4592L46.4592 24.449H47.7245L47.7245 23.1837Z' fill='black' />
    <path d='M48.9898 23.1837H47.7245L47.7245 24.449H48.9898V23.1837Z' fill='black' />
    <path d='M52.7857 23.1837H51.5204V24.449H52.7857L52.7857 23.1837Z' fill='black' />
    <path d='M54.051 23.1837H52.7857L52.7857 24.449H54.051V23.1837Z' fill='black' />
    <path d='M56.5816 23.1837H55.3163V24.449H56.5816V23.1837Z' fill='black' />
    <path d='M57.8469 23.1837H56.5816V24.449H57.8469V23.1837Z' fill='black' />
    <path d='M62.9082 23.1837H61.6429V24.449H62.9082V23.1837Z' fill='black' />
    <path d='M64.1735 23.1837H62.9082V24.449H64.1735V23.1837Z' fill='black' />
    <path d='M70.5 23.1837H69.2347L69.2347 24.449H70.5V23.1837Z' fill='black' />
    <path d='M11.0306 24.449H9.76531V25.7143H11.0306V24.449Z' fill='black' />
    <path d='M12.2959 24.449H11.0306V25.7143H12.2959V24.449Z' fill='black' />
    <path d='M14.8265 24.449H13.5612V25.7143H14.8265V24.449Z' fill='black' />
    <path d='M16.0918 24.449H14.8265V25.7143H16.0918V24.449Z' fill='black' />
    <path d='M23.6837 24.449H22.4184V25.7143H23.6837V24.449Z' fill='black' />
    <path d='M24.949 24.449H23.6837V25.7143H24.949V24.449Z' fill='black' />
    <path d='M26.2143 24.449H24.949V25.7143H26.2143V24.449Z' fill='black' />
    <path d='M27.4796 24.449H26.2143V25.7143H27.4796V24.449Z' fill='black' />
    <path d='M28.7449 24.449H27.4796V25.7143H28.7449V24.449Z' fill='black' />
    <path d='M30.0102 24.449H28.7449V25.7143H30.0102V24.449Z' fill='black' />
    <path d='M31.2755 24.449H30.0102V25.7143H31.2755V24.449Z' fill='black' />
    <path d='M35.0714 24.449H33.8061V25.7143H35.0714V24.449Z' fill='black' />
    <path d='M37.602 24.449H36.3367V25.7143H37.602V24.449Z' fill='black' />
    <path d='M40.1327 24.449H38.8673V25.7143H40.1327V24.449Z' fill='black' />
    <path d='M41.398 24.449H40.1327V25.7143H41.398V24.449Z' fill='black' />
    <path d='M42.6633 24.449H41.398V25.7143H42.6633V24.449Z' fill='black' />
    <path d='M45.1939 24.449H43.9286L43.9286 25.7143H45.1939V24.449Z' fill='black' />
    <path d='M46.4592 24.449H45.1939V25.7143H46.4592L46.4592 24.449Z' fill='black' />
    <path d='M50.2551 24.449H48.9898L48.9898 25.7143H50.2551V24.449Z' fill='black' />
    <path d='M54.051 24.449H52.7857L52.7857 25.7143H54.051V24.449Z' fill='black' />
    <path d='M56.5816 24.449H55.3163V25.7143H56.5816V24.449Z' fill='black' />
    <path d='M57.8469 24.449H56.5816V25.7143H57.8469V24.449Z' fill='black' />
    <path d='M60.3775 24.449H59.1122V25.7143H60.3775V24.449Z' fill='black' />
    <path d='M62.9082 24.449H61.6429V25.7143H62.9082V24.449Z' fill='black' />
    <path d='M67.9694 24.449H66.7041V25.7143H67.9694V24.449Z' fill='black' />
    <path d='M69.2347 24.449H67.9694V25.7143H69.2347L69.2347 24.449Z' fill='black' />
    <path d='M70.5 24.449H69.2347L69.2347 25.7143H70.5V24.449Z' fill='black' />
    <path d='M9.76531 25.7143H8.5V26.9796H9.76531V25.7143Z' fill='black' />
    <path d='M16.0918 25.7143H14.8265V26.9796H16.0918V25.7143Z' fill='black' />
    <path d='M17.3571 25.7143H16.0918V26.9796H17.3571V25.7143Z' fill='black' />
    <path d='M21.1531 25.7143H19.8878V26.9796H21.1531V25.7143Z' fill='black' />
    <path d='M27.4796 25.7143H26.2143V26.9796H27.4796V25.7143Z' fill='black' />
    <path d='M30.0102 25.7143H28.7449V26.9796H30.0102V25.7143Z' fill='black' />
    <path d='M33.8061 25.7143H32.5408V26.9796H33.8061V25.7143Z' fill='black' />
    <path d='M35.0714 25.7143H33.8061V26.9796H35.0714V25.7143Z' fill='black' />
    <path d='M36.3367 25.7143H35.0714V26.9796H36.3367V25.7143Z' fill='black' />
    <path d='M40.1327 25.7143H38.8673V26.9796H40.1327V25.7143Z' fill='black' />
    <path d='M42.6633 25.7143H41.398V26.9796H42.6633V25.7143Z' fill='black' />
    <path d='M43.9286 25.7143H42.6633V26.9796H43.9286L43.9286 25.7143Z' fill='black' />
    <path d='M45.1939 25.7143H43.9286L43.9286 26.9796H45.1939V25.7143Z' fill='black' />
    <path d='M46.4592 25.7143H45.1939V26.9796H46.4592V25.7143Z' fill='black' />
    <path d='M48.9898 25.7143H47.7245V26.9796H48.9898L48.9898 25.7143Z' fill='black' />
    <path d='M51.5204 25.7143H50.2551V26.9796H51.5204V25.7143Z' fill='black' />
    <path d='M55.3163 25.7143H54.051L54.051 26.9796H55.3163V25.7143Z' fill='black' />
    <path d='M56.5816 25.7143H55.3163V26.9796H56.5816V25.7143Z' fill='black' />
    <path d='M60.3775 25.7143H59.1122V26.9796H60.3775V25.7143Z' fill='black' />
    <path d='M61.6429 25.7143H60.3775V26.9796H61.6429L61.6429 25.7143Z' fill='black' />
    <path d='M62.9082 25.7143H61.6429L61.6429 26.9796H62.9082V25.7143Z' fill='black' />
    <path d='M65.4388 25.7143H64.1735V26.9796H65.4388V25.7143Z' fill='black' />
    <path d='M66.7041 25.7143H65.4388V26.9796H66.7041L66.7041 25.7143Z' fill='black' />
    <path d='M69.2347 25.7143H67.9694L67.9694 26.9796H69.2347V25.7143Z' fill='black' />
    <path d='M70.5 25.7143H69.2347V26.9796H70.5V25.7143Z' fill='black' />
    <path d='M13.5612 26.9796H12.2959V28.2449H13.5612V26.9796Z' fill='black' />
    <path d='M16.0918 26.9796H14.8265V28.2449H16.0918V26.9796Z' fill='black' />
    <path d='M21.1531 26.9796H19.8878V28.2449H21.1531V26.9796Z' fill='black' />
    <path d='M24.949 26.9796H23.6837V28.2449H24.949V26.9796Z' fill='black' />
    <path d='M27.4796 26.9796H26.2143V28.2449H27.4796V26.9796Z' fill='black' />
    <path d='M28.7449 26.9796H27.4796V28.2449H28.7449V26.9796Z' fill='black' />
    <path d='M30.0102 26.9796H28.7449V28.2449H30.0102V26.9796Z' fill='black' />
    <path d='M31.2755 26.9796H30.0102V28.2449H31.2755V26.9796Z' fill='black' />
    <path d='M35.0714 26.9796H33.8061V28.2449H35.0714V26.9796Z' fill='black' />
    <path d='M36.3367 26.9796H35.0714V28.2449H36.3367V26.9796Z' fill='black' />
    <path d='M38.8673 26.9796H37.602V28.2449H38.8673V26.9796Z' fill='black' />
    <path d='M40.1327 26.9796H38.8673V28.2449H40.1327V26.9796Z' fill='black' />
    <path d='M41.398 26.9796H40.1327V28.2449H41.398V26.9796Z' fill='black' />
    <path d='M47.7245 26.9796H46.4592L46.4592 28.2449H47.7245L47.7245 26.9796Z' fill='black' />
    <path d='M51.5204 26.9796H50.2551V28.2449H51.5204V26.9796Z' fill='black' />
    <path d='M54.051 26.9796H52.7857V28.2449H54.051L54.051 26.9796Z' fill='black' />
    <path d='M56.5816 26.9796H55.3163V28.2449H56.5816V26.9796Z' fill='black' />
    <path d='M59.1122 26.9796H57.8469V28.2449H59.1122L59.1122 26.9796Z' fill='black' />
    <path d='M61.6429 26.9796H60.3775V28.2449H61.6429V26.9796Z' fill='black' />
    <path d='M65.4388 26.9796H64.1735V28.2449H65.4388V26.9796Z' fill='black' />
    <path d='M66.7041 26.9796H65.4388V28.2449H66.7041V26.9796Z' fill='black' />
    <path d='M70.5 26.9796H69.2347L69.2347 28.2449H70.5V26.9796Z' fill='black' />
    <path d='M11.0306 28.2449H9.76531V29.5102H11.0306V28.2449Z' fill='black' />
    <path d='M12.2959 28.2449H11.0306V29.5102H12.2959V28.2449Z' fill='black' />
    <path d='M17.3571 28.2449H16.0918V29.5102H17.3571V28.2449Z' fill='black' />
    <path d='M19.8878 28.2449H18.6224V29.5102H19.8878V28.2449Z' fill='black' />
    <path d='M22.4184 28.2449H21.1531V29.5102H22.4184V28.2449Z' fill='black' />
    <path d='M23.6837 28.2449H22.4184V29.5102H23.6837V28.2449Z' fill='black' />
    <path d='M27.4796 28.2449H26.2143V29.5102H27.4796V28.2449Z' fill='black' />
    <path d='M31.2755 28.2449H30.0102V29.5102H31.2755V28.2449Z' fill='black' />
    <path d='M33.8061 28.2449H32.5408V29.5102H33.8061V28.2449Z' fill='black' />
    <path d='M35.0714 28.2449H33.8061V29.5102H35.0714V28.2449Z' fill='black' />
    <path d='M40.1327 28.2449H38.8673V29.5102H40.1327V28.2449Z' fill='black' />
    <path d='M41.398 28.2449H40.1327V29.5102H41.398V28.2449Z' fill='black' />
    <path d='M43.9286 28.2449H42.6633V29.5102H43.9286V28.2449Z' fill='black' />
    <path d='M45.1939 28.2449H43.9286V29.5102H45.1939V28.2449Z' fill='black' />
    <path d='M46.4592 28.2449H45.1939V29.5102H46.4592L46.4592 28.2449Z' fill='black' />
    <path d='M48.9898 28.2449H47.7245L47.7245 29.5102H48.9898V28.2449Z' fill='black' />
    <path d='M52.7857 28.2449H51.5204L51.5204 29.5102H52.7857L52.7857 28.2449Z' fill='black' />
    <path d='M54.051 28.2449H52.7857L52.7857 29.5102H54.051V28.2449Z' fill='black' />
    <path d='M57.8469 28.2449H56.5816L56.5816 29.5102H57.8469L57.8469 28.2449Z' fill='black' />
    <path d='M59.1122 28.2449H57.8469L57.8469 29.5102H59.1122V28.2449Z' fill='black' />
    <path d='M62.9082 28.2449H61.6429L61.6429 29.5102H62.9082V28.2449Z' fill='black' />
    <path d='M9.76531 29.5102H8.5V30.7755H9.76531V29.5102Z' fill='black' />
    <path d='M13.5612 29.5102H12.2959V30.7755H13.5612V29.5102Z' fill='black' />
    <path d='M22.4184 29.5102H21.1531V30.7755H22.4184V29.5102Z' fill='black' />
    <path d='M24.949 29.5102H23.6837V30.7755H24.949V29.5102Z' fill='black' />
    <path d='M26.2143 29.5102H24.949V30.7755H26.2143V29.5102Z' fill='black' />
    <path d='M31.2755 29.5102H30.0102V30.7755H31.2755V29.5102Z' fill='black' />
    <path d='M32.5408 29.5102H31.2755V30.7755H32.5408V29.5102Z' fill='black' />
    <path d='M33.8061 29.5102H32.5408V30.7755H33.8061V29.5102Z' fill='black' />
    <path d='M37.602 29.5102H36.3367V30.7755H37.602V29.5102Z' fill='black' />
    <path d='M38.8673 29.5102H37.602V30.7755H38.8673V29.5102Z' fill='black' />
    <path d='M45.1939 29.5102H43.9286L43.9286 30.7755H45.1939V29.5102Z' fill='black' />
    <path d='M46.4592 29.5102H45.1939V30.7755H46.4592V29.5102Z' fill='black' />
    <path d='M48.9898 29.5102H47.7245V30.7755H48.9898V29.5102Z' fill='black' />
    <path d='M50.2551 29.5102H48.9898V30.7755H50.2551V29.5102Z' fill='black' />
    <path d='M51.5204 29.5102H50.2551V30.7755H51.5204L51.5204 29.5102Z' fill='black' />
    <path d='M54.051 29.5102H52.7857L52.7857 30.7755H54.051V29.5102Z' fill='black' />
    <path d='M55.3163 29.5102H54.051V30.7755H55.3163V29.5102Z' fill='black' />
    <path d='M56.5816 29.5102H55.3163V30.7755H56.5816L56.5816 29.5102Z' fill='black' />
    <path d='M57.8469 29.5102H56.5816L56.5816 30.7755H57.8469V29.5102Z' fill='black' />
    <path d='M61.6429 29.5102H60.3775V30.7755H61.6429L61.6429 29.5102Z' fill='black' />
    <path d='M62.9082 29.5102H61.6429L61.6429 30.7755H62.9082V29.5102Z' fill='black' />
    <path d='M65.4388 29.5102H64.1735V30.7755H65.4388V29.5102Z' fill='black' />
    <path d='M67.9694 29.5102H66.7041V30.7755H67.9694V29.5102Z' fill='black' />
    <path d='M69.2347 29.5102H67.9694V30.7755H69.2347V29.5102Z' fill='black' />
    <path d='M70.5 29.5102H69.2347V30.7755H70.5V29.5102Z' fill='black' />
    <path d='M12.2959 30.7755H11.0306V32.0408H12.2959V30.7755Z' fill='black' />
    <path d='M13.5612 30.7755H12.2959V32.0408H13.5612V30.7755Z' fill='black' />
    <path d='M17.3571 30.7755H16.0918V32.0408H17.3571V30.7755Z' fill='black' />
    <path d='M19.8878 30.7755H18.6224V32.0408H19.8878V30.7755Z' fill='black' />
    <path d='M22.4184 30.7755H21.1531V32.0408H22.4184V30.7755Z' fill='black' />
    <path d='M26.2143 30.7755H24.949V32.0408H26.2143V30.7755Z' fill='black' />
    <path d='M27.4796 30.7755H26.2143V32.0408H27.4796V30.7755Z' fill='black' />
    <path d='M28.7449 30.7755H27.4796V32.0408H28.7449V30.7755Z' fill='black' />
    <path d='M30.0102 30.7755H28.7449V32.0408H30.0102V30.7755Z' fill='black' />
    <path d='M31.2755 30.7755H30.0102V32.0408H31.2755V30.7755Z' fill='black' />
    <path d='M32.5408 30.7755H31.2755V32.0408H32.5408V30.7755Z' fill='black' />
    <path d='M35.0714 30.7755H33.8061V32.0408H35.0714V30.7755Z' fill='black' />
    <path d='M37.602 30.7755H36.3367V32.0408H37.602V30.7755Z' fill='black' />
    <path d='M41.398 30.7755H40.1327V32.0408H41.398V30.7755Z' fill='black' />
    <path d='M45.1939 30.7755H43.9286V32.0408H45.1939V30.7755Z' fill='black' />
    <path d='M50.2551 30.7755H48.9898L48.9898 32.0408H50.2551V30.7755Z' fill='black' />
    <path d='M51.5204 30.7755H50.2551V32.0408H51.5204V30.7755Z' fill='black' />
    <path d='M54.051 30.7755H52.7857V32.0408H54.051V30.7755Z' fill='black' />
    <path d='M55.3163 30.7755H54.051V32.0408H55.3163V30.7755Z' fill='black' />
    <path d='M62.9082 30.7755H61.6429L61.6429 32.0408H62.9082V30.7755Z' fill='black' />
    <path d='M64.1735 30.7755H62.9082V32.0408H64.1735L64.1735 30.7755Z' fill='black' />
    <path d='M12.2959 32.0408H11.0306V33.3061H12.2959V32.0408Z' fill='black' />
    <path d='M13.5612 32.0408H12.2959V33.3061H13.5612V32.0408Z' fill='black' />
    <path d='M16.0918 32.0408H14.8265V33.3061H16.0918V32.0408Z' fill='black' />
    <path d='M18.6224 32.0408H17.3571V33.3061H18.6224V32.0408Z' fill='black' />
    <path d='M19.8878 32.0408H18.6224V33.3061H19.8878V32.0408Z' fill='black' />
    <path d='M22.4184 32.0408H21.1531V33.3061H22.4184V32.0408Z' fill='black' />
    <path d='M23.6837 32.0408H22.4184V33.3061H23.6837V32.0408Z' fill='black' />
    <path d='M24.949 32.0408H23.6837V33.3061H24.949V32.0408Z' fill='black' />
    <path d='M27.4796 32.0408H26.2143V33.3061H27.4796V32.0408Z' fill='black' />
    <path d='M28.7449 32.0408H27.4796V33.3061H28.7449V32.0408Z' fill='black' />
    <path d='M37.602 32.0408H36.3367V33.3061H37.602V32.0408Z' fill='black' />
    <path d='M40.1327 32.0408H38.8673V33.3061H40.1327V32.0408Z' fill='black' />
    <path d='M43.9286 32.0408H42.6633V33.3061H43.9286L43.9286 32.0408Z' fill='black' />
    <path d='M45.1939 32.0408H43.9286L43.9286 33.3061H45.1939V32.0408Z' fill='black' />
    <path d='M48.9898 32.0408H47.7245V33.3061H48.9898L48.9898 32.0408Z' fill='black' />
    <path d='M50.2551 32.0408H48.9898L48.9898 33.3061H50.2551V32.0408Z' fill='black' />
    <path d='M52.7857 32.0408H51.5204L51.5204 33.3061H52.7857L52.7857 32.0408Z' fill='black' />
    <path d='M56.5816 32.0408H55.3163V33.3061H56.5816V32.0408Z' fill='black' />
    <path d='M61.6429 32.0408H60.3775V33.3061H61.6429L61.6429 32.0408Z' fill='black' />
    <path d='M62.9082 32.0408H61.6429L61.6429 33.3061H62.9082V32.0408Z' fill='black' />
    <path d='M64.1735 32.0408H62.9082V33.3061H64.1735V32.0408Z' fill='black' />
    <path d='M65.4388 32.0408H64.1735V33.3061H65.4388V32.0408Z' fill='black' />
    <path d='M66.7041 32.0408H65.4388V33.3061H66.7041V32.0408Z' fill='black' />
    <path d='M69.2347 32.0408H67.9694V33.3061H69.2347V32.0408Z' fill='black' />
    <path d='M17.3571 33.3061H16.0918V34.5714H17.3571V33.3061Z' fill='black' />
    <path d='M18.6224 33.3061H17.3571V34.5714H18.6224V33.3061Z' fill='black' />
    <path d='M23.6837 33.3061H22.4184V34.5714H23.6837V33.3061Z' fill='black' />
    <path d='M24.949 33.3061H23.6837V34.5714H24.949V33.3061Z' fill='black' />
    <path d='M31.2755 33.3061H30.0102V34.5714H31.2755V33.3061Z' fill='black' />
    <path d='M32.5408 33.3061H31.2755V34.5714H32.5408V33.3061Z' fill='black' />
    <path d='M33.8061 33.3061H32.5408V34.5714H33.8061V33.3061Z' fill='black' />
    <path d='M35.0714 33.3061H33.8061V34.5714H35.0714V33.3061Z' fill='black' />
    <path d='M42.6633 33.3061H41.398V34.5714H42.6633V33.3061Z' fill='black' />
    <path d='M45.1939 33.3061H43.9286L43.9286 34.5714H45.1939V33.3061Z' fill='black' />
    <path d='M48.9898 33.3061H47.7245V34.5714H48.9898V33.3061Z' fill='black' />
    <path d='M50.2551 33.3061H48.9898V34.5714H50.2551V33.3061Z' fill='black' />
    <path d='M57.8469 33.3061H56.5816L56.5816 34.5714H57.8469V33.3061Z' fill='black' />
    <path d='M61.6429 33.3061H60.3775V34.5714H61.6429V33.3061Z' fill='black' />
    <path d='M62.9082 33.3061H61.6429V34.5714H62.9082V33.3061Z' fill='black' />
    <path d='M67.9694 33.3061H66.7041L66.7041 34.5714H67.9694L67.9694 33.3061Z' fill='black' />
    <path d='M69.2347 33.3061H67.9694L67.9694 34.5714H69.2347V33.3061Z' fill='black' />
    <path d='M21.1531 34.5714H19.8878V35.8367H21.1531V34.5714Z' fill='black' />
    <path d='M26.2143 34.5714H24.949V35.8367H26.2143V34.5714Z' fill='black' />
    <path d='M27.4796 34.5714H26.2143V35.8367H27.4796V34.5714Z' fill='black' />
    <path d='M28.7449 34.5714H27.4796V35.8367H28.7449V34.5714Z' fill='black' />
    <path d='M30.0102 34.5714H28.7449V35.8367H30.0102V34.5714Z' fill='black' />
    <path d='M35.0714 34.5714H33.8061V35.8367H35.0714V34.5714Z' fill='black' />
    <path d='M37.602 34.5714H36.3367V35.8367H37.602V34.5714Z' fill='black' />
    <path d='M42.6633 34.5714H41.398V35.8367H42.6633V34.5714Z' fill='black' />
    <path d='M45.1939 34.5714H43.9286V35.8367H45.1939V34.5714Z' fill='black' />
    <path d='M46.4592 34.5714H45.1939V35.8367H46.4592V34.5714Z' fill='black' />
    <path d='M47.7245 34.5714H46.4592V35.8367H47.7245L47.7245 34.5714Z' fill='black' />
    <path d='M50.2551 34.5714H48.9898L48.9898 35.8367H50.2551V34.5714Z' fill='black' />
    <path d='M54.051 34.5714H52.7857V35.8367H54.051V34.5714Z' fill='black' />
    <path d='M56.5816 34.5714H55.3163V35.8367H56.5816L56.5816 34.5714Z' fill='black' />
    <path d='M57.8469 34.5714H56.5816L56.5816 35.8367H57.8469V34.5714Z' fill='black' />
    <path d='M60.3775 34.5714H59.1122V35.8367H60.3775V34.5714Z' fill='black' />
    <path d='M64.1735 34.5714H62.9082L62.9082 35.8367H64.1735V34.5714Z' fill='black' />
    <path d='M66.7041 34.5714H65.4388V35.8367H66.7041L66.7041 34.5714Z' fill='black' />
    <path d='M70.5 34.5714H69.2347L69.2347 35.8367H70.5V34.5714Z' fill='black' />
    <path d='M9.76531 35.8367H8.5V37.102H9.76531V35.8367Z' fill='black' />
    <path d='M14.8265 35.8367H13.5612V37.102H14.8265V35.8367Z' fill='black' />
    <path d='M16.0918 35.8367H14.8265V37.102H16.0918V35.8367Z' fill='black' />
    <path d='M17.3571 35.8367H16.0918V37.102H17.3571V35.8367Z' fill='black' />
    <path d='M18.6224 35.8367H17.3571V37.102H18.6224V35.8367Z' fill='black' />
    <path d='M19.8878 35.8367H18.6224V37.102H19.8878V35.8367Z' fill='black' />
    <path d='M21.1531 35.8367H19.8878V37.102H21.1531V35.8367Z' fill='black' />
    <path d='M22.4184 35.8367H21.1531V37.102H22.4184V35.8367Z' fill='black' />
    <path d='M26.2143 35.8367H24.949V37.102H26.2143V35.8367Z' fill='black' />
    <path d='M27.4796 35.8367H26.2143V37.102H27.4796V35.8367Z' fill='black' />
    <path d='M32.5408 35.8367H31.2755V37.102H32.5408V35.8367Z' fill='black' />
    <path d='M33.8061 35.8367H32.5408V37.102H33.8061V35.8367Z' fill='black' />
    <path d='M35.0714 35.8367H33.8061V37.102H35.0714V35.8367Z' fill='black' />
    <path d='M37.602 35.8367H36.3367V37.102H37.602V35.8367Z' fill='black' />
    <path d='M38.8673 35.8367H37.602V37.102H38.8673V35.8367Z' fill='black' />
    <path d='M40.1327 35.8367H38.8673V37.102H40.1327V35.8367Z' fill='black' />
    <path d='M41.398 35.8367H40.1327V37.102H41.398V35.8367Z' fill='black' />
    <path d='M42.6633 35.8367H41.398V37.102H42.6633V35.8367Z' fill='black' />
    <path d='M46.4592 35.8367H45.1939V37.102H46.4592V35.8367Z' fill='black' />
    <path d='M47.7245 35.8367H46.4592V37.102H47.7245V35.8367Z' fill='black' />
    <path d='M52.7857 35.8367H51.5204V37.102H52.7857L52.7857 35.8367Z' fill='black' />
    <path d='M56.5816 35.8367H55.3163V37.102H56.5816V35.8367Z' fill='black' />
    <path d='M57.8469 35.8367H56.5816V37.102H57.8469V35.8367Z' fill='black' />
    <path d='M59.1122 35.8367H57.8469V37.102H59.1122L59.1122 35.8367Z' fill='black' />
    <path d='M60.3775 35.8367H59.1122L59.1122 37.102H60.3775V35.8367Z' fill='black' />
    <path d='M61.6429 35.8367H60.3775V37.102H61.6429V35.8367Z' fill='black' />
    <path d='M62.9082 35.8367H61.6429V37.102H62.9082L62.9082 35.8367Z' fill='black' />
    <path d='M64.1735 35.8367H62.9082L62.9082 37.102H64.1735V35.8367Z' fill='black' />
    <path d='M65.4388 35.8367H64.1735V37.102H65.4388V35.8367Z' fill='black' />
    <path d='M66.7041 35.8367H65.4388V37.102H66.7041V35.8367Z' fill='black' />
    <path d='M69.2347 35.8367H67.9694V37.102H69.2347L69.2347 35.8367Z' fill='black' />
    <path d='M70.5 35.8367H69.2347L69.2347 37.102H70.5V35.8367Z' fill='black' />
    <path d='M9.76531 37.102H8.5V38.3673H9.76531V37.102Z' fill='black' />
    <path d='M11.0306 37.102H9.76531V38.3673H11.0306V37.102Z' fill='black' />
    <path d='M12.2959 37.102H11.0306V38.3673H12.2959V37.102Z' fill='black' />
    <path d='M14.8265 37.102H13.5612V38.3673H14.8265V37.102Z' fill='black' />
    <path d='M19.8878 37.102H18.6224V38.3673H19.8878V37.102Z' fill='black' />
    <path d='M21.1531 37.102H19.8878V38.3673H21.1531V37.102Z' fill='black' />
    <path d='M22.4184 37.102H21.1531V38.3673H22.4184V37.102Z' fill='black' />
    <path d='M23.6837 37.102H22.4184V38.3673H23.6837V37.102Z' fill='black' />
    <path d='M24.949 37.102H23.6837V38.3673H24.949V37.102Z' fill='black' />
    <path d='M28.7449 37.102H27.4796V38.3673H28.7449V37.102Z' fill='black' />
    <path d='M30.0102 37.102H28.7449V38.3673H30.0102V37.102Z' fill='black' />
    <path d='M31.2755 37.102H30.0102V38.3673H31.2755V37.102Z' fill='black' />
    <path d='M36.3367 37.102H35.0714V38.3673H36.3367V37.102Z' fill='black' />
    <path d='M37.602 37.102H36.3367V38.3673H37.602V37.102Z' fill='black' />
    <path d='M42.6633 37.102H41.398V38.3673H42.6633V37.102Z' fill='black' />
    <path d='M43.9286 37.102H42.6633V38.3673H43.9286V37.102Z' fill='black' />
    <path d='M45.1939 37.102H43.9286V38.3673H45.1939V37.102Z' fill='black' />
    <path d='M47.7245 37.102H46.4592L46.4592 38.3673H47.7245V37.102Z' fill='black' />
    <path d='M48.9898 37.102H47.7245V38.3673H48.9898V37.102Z' fill='black' />
    <path d='M51.5204 37.102H50.2551V38.3673H51.5204L51.5204 37.102Z' fill='black' />
    <path d='M54.051 37.102H52.7857L52.7857 38.3673H54.051V37.102Z' fill='black' />
    <path d='M55.3163 37.102H54.051V38.3673H55.3163V37.102Z' fill='black' />
    <path d='M60.3775 37.102H59.1122L59.1122 38.3673H60.3775V37.102Z' fill='black' />
    <path d='M65.4388 37.102H64.1735L64.1735 38.3673H65.4388V37.102Z' fill='black' />
    <path d='M66.7041 37.102H65.4388V38.3673H66.7041V37.102Z' fill='black' />
    <path d='M67.9694 37.102H66.7041V38.3673H67.9694L67.9694 37.102Z' fill='black' />
    <path d='M69.2347 37.102H67.9694L67.9694 38.3673H69.2347V37.102Z' fill='black' />
    <path d='M9.76531 38.3673H8.5V39.6327H9.76531V38.3673Z' fill='black' />
    <path d='M11.0306 38.3673H9.76531V39.6327H11.0306V38.3673Z' fill='black' />
    <path d='M12.2959 38.3673H11.0306V39.6327H12.2959V38.3673Z' fill='black' />
    <path d='M13.5612 38.3673H12.2959V39.6327H13.5612V38.3673Z' fill='black' />
    <path d='M14.8265 38.3673H13.5612V39.6327H14.8265V38.3673Z' fill='black' />
    <path d='M17.3571 38.3673H16.0918V39.6327H17.3571V38.3673Z' fill='black' />
    <path d='M19.8878 38.3673H18.6224V39.6327H19.8878V38.3673Z' fill='black' />
    <path d='M21.1531 38.3673H19.8878V39.6327H21.1531V38.3673Z' fill='black' />
    <path d='M24.949 38.3673H23.6837V39.6327H24.949V38.3673Z' fill='black' />
    <path d='M28.7449 38.3673H27.4796V39.6327H28.7449V38.3673Z' fill='black' />
    <path d='M30.0102 38.3673H28.7449V39.6327H30.0102V38.3673Z' fill='black' />
    <path d='M31.2755 38.3673H30.0102V39.6327H31.2755V38.3673Z' fill='black' />
    <path d='M32.5408 38.3673H31.2755V39.6327H32.5408V38.3673Z' fill='black' />
    <path d='M35.0714 38.3673H33.8061V39.6327H35.0714V38.3673Z' fill='black' />
    <path d='M36.3367 38.3673H35.0714V39.6327H36.3367V38.3673Z' fill='black' />
    <path d='M37.602 38.3673H36.3367V39.6327H37.602V38.3673Z' fill='black' />
    <path d='M40.1327 38.3673H38.8673V39.6327H40.1327V38.3673Z' fill='black' />
    <path d='M42.6633 38.3673H41.398V39.6327H42.6633V38.3673Z' fill='black' />
    <path d='M43.9286 38.3673H42.6633V39.6327H43.9286V38.3673Z' fill='black' />
    <path d='M45.1939 38.3673H43.9286V39.6327H45.1939V38.3673Z' fill='black' />
    <path d='M47.7245 38.3673H46.4592V39.6327H47.7245V38.3673Z' fill='black' />
    <path d='M52.7857 38.3673H51.5204L51.5204 39.6327H52.7857L52.7857 38.3673Z' fill='black' />
    <path d='M57.8469 38.3673H56.5816V39.6327H57.8469V38.3673Z' fill='black' />
    <path d='M60.3775 38.3673H59.1122V39.6327H60.3775V38.3673Z' fill='black' />
    <path d='M62.9082 38.3673H61.6429V39.6327H62.9082V38.3673Z' fill='black' />
    <path d='M65.4388 38.3673H64.1735V39.6327H65.4388V38.3673Z' fill='black' />
    <path d='M66.7041 38.3673H65.4388V39.6327H66.7041V38.3673Z' fill='black' />
    <path d='M67.9694 38.3673H66.7041V39.6327H67.9694V38.3673Z' fill='black' />
    <path d='M69.2347 38.3673H67.9694V39.6327H69.2347V38.3673Z' fill='black' />
    <path d='M70.5 38.3673H69.2347V39.6327H70.5V38.3673Z' fill='black' />
    <path d='M9.76531 39.6327H8.5V40.898H9.76531V39.6327Z' fill='black' />
    <path d='M14.8265 39.6327H13.5612V40.898H14.8265V39.6327Z' fill='black' />
    <path d='M19.8878 39.6327H18.6224V40.898H19.8878V39.6327Z' fill='black' />
    <path d='M22.4184 39.6327H21.1531V40.898H22.4184V39.6327Z' fill='black' />
    <path d='M24.949 39.6327H23.6837V40.898H24.949V39.6327Z' fill='black' />
    <path d='M26.2143 39.6327H24.949V40.898H26.2143V39.6327Z' fill='black' />
    <path d='M30.0102 39.6327H28.7449V40.898H30.0102V39.6327Z' fill='black' />
    <path d='M31.2755 39.6327H30.0102V40.898H31.2755V39.6327Z' fill='black' />
    <path d='M35.0714 39.6327H33.8061V40.898H35.0714V39.6327Z' fill='black' />
    <path d='M36.3367 39.6327H35.0714V40.898H36.3367V39.6327Z' fill='black' />
    <path d='M37.602 39.6327H36.3367V40.898H37.602V39.6327Z' fill='black' />
    <path d='M42.6633 39.6327H41.398V40.898H42.6633V39.6327Z' fill='black' />
    <path d='M47.7245 39.6327H46.4592V40.898H47.7245V39.6327Z' fill='black' />
    <path d='M48.9898 39.6327H47.7245V40.898H48.9898V39.6327Z' fill='black' />
    <path d='M50.2551 39.6327H48.9898V40.898H50.2551V39.6327Z' fill='black' />
    <path d='M51.5204 39.6327H50.2551V40.898H51.5204L51.5204 39.6327Z' fill='black' />
    <path d='M54.051 39.6327H52.7857L52.7857 40.898H54.051V39.6327Z' fill='black' />
    <path d='M55.3163 39.6327H54.051V40.898H55.3163V39.6327Z' fill='black' />
    <path d='M57.8469 39.6327H56.5816V40.898H57.8469V39.6327Z' fill='black' />
    <path d='M60.3775 39.6327H59.1122V40.898H60.3775V39.6327Z' fill='black' />
    <path d='M65.4388 39.6327H64.1735V40.898H65.4388V39.6327Z' fill='black' />
    <path d='M67.9694 39.6327H66.7041L66.7041 40.898H67.9694V39.6327Z' fill='black' />
    <path d='M13.5612 40.898H12.2959V42.1633H13.5612V40.898Z' fill='black' />
    <path d='M14.8265 40.898H13.5612V42.1633H14.8265V40.898Z' fill='black' />
    <path d='M16.0918 40.898H14.8265V42.1633H16.0918V40.898Z' fill='black' />
    <path d='M17.3571 40.898H16.0918V42.1633H17.3571V40.898Z' fill='black' />
    <path d='M18.6224 40.898H17.3571V42.1633H18.6224V40.898Z' fill='black' />
    <path d='M19.8878 40.898H18.6224V42.1633H19.8878V40.898Z' fill='black' />
    <path d='M24.949 40.898H23.6837V42.1633H24.949V40.898Z' fill='black' />
    <path d='M27.4796 40.898H26.2143V42.1633H27.4796V40.898Z' fill='black' />
    <path d='M28.7449 40.898H27.4796V42.1633H28.7449V40.898Z' fill='black' />
    <path d='M31.2755 40.898H30.0102V42.1633H31.2755V40.898Z' fill='black' />
    <path d='M37.602 40.898H36.3367V42.1633H37.602V40.898Z' fill='black' />
    <path d='M38.8673 40.898H37.602V42.1633H38.8673V40.898Z' fill='black' />
    <path d='M40.1327 40.898H38.8673V42.1633H40.1327V40.898Z' fill='black' />
    <path d='M41.398 40.898H40.1327V42.1633H41.398V40.898Z' fill='black' />
    <path d='M42.6633 40.898H41.398V42.1633H42.6633V40.898Z' fill='black' />
    <path d='M46.4592 40.898H45.1939V42.1633H46.4592L46.4592 40.898Z' fill='black' />
    <path d='M47.7245 40.898H46.4592L46.4592 42.1633H47.7245V40.898Z' fill='black' />
    <path d='M52.7857 40.898H51.5204L51.5204 42.1633H52.7857L52.7857 40.898Z' fill='black' />
    <path d='M55.3163 40.898H54.051L54.051 42.1633H55.3163V40.898Z' fill='black' />
    <path d='M56.5816 40.898H55.3163V42.1633H56.5816L56.5816 40.898Z' fill='black' />
    <path d='M59.1122 40.898H57.8469L57.8469 42.1633H59.1122L59.1122 40.898Z' fill='black' />
    <path d='M60.3775 40.898H59.1122L59.1122 42.1633H60.3775V40.898Z' fill='black' />
    <path d='M61.6429 40.898H60.3775V42.1633H61.6429V40.898Z' fill='black' />
    <path d='M62.9082 40.898H61.6429V42.1633H62.9082V40.898Z' fill='black' />
    <path d='M64.1735 40.898H62.9082V42.1633H64.1735L64.1735 40.898Z' fill='black' />
    <path d='M65.4388 40.898H64.1735L64.1735 42.1633H65.4388V40.898Z' fill='black' />
    <path d='M66.7041 40.898H65.4388V42.1633H66.7041L66.7041 40.898Z' fill='black' />
    <path d='M70.5 40.898H69.2347V42.1633H70.5V40.898Z' fill='black' />
    <path d='M14.8265 42.1633H13.5612V43.4286H14.8265V42.1633Z' fill='black' />
    <path d='M22.4184 42.1633H21.1531V43.4286H22.4184V42.1633Z' fill='black' />
    <path d='M23.6837 42.1633H22.4184V43.4286H23.6837V42.1633Z' fill='black' />
    <path d='M24.949 42.1633H23.6837V43.4286H24.949V42.1633Z' fill='black' />
    <path d='M27.4796 42.1633H26.2143V43.4286H27.4796V42.1633Z' fill='black' />
    <path d='M33.8061 42.1633H32.5408V43.4286H33.8061V42.1633Z' fill='black' />
    <path d='M35.0714 42.1633H33.8061V43.4286H35.0714V42.1633Z' fill='black' />
    <path d='M38.8673 42.1633H37.602V43.4286H38.8673V42.1633Z' fill='black' />
    <path d='M40.1327 42.1633H38.8673V43.4286H40.1327V42.1633Z' fill='black' />
    <path d='M41.398 42.1633H40.1327V43.4286H41.398V42.1633Z' fill='black' />
    <path d='M43.9286 42.1633H42.6633V43.4286H43.9286V42.1633Z' fill='black' />
    <path d='M47.7245 42.1633H46.4592L46.4592 43.4286H47.7245V42.1633Z' fill='black' />
    <path d='M48.9898 42.1633H47.7245V43.4286H48.9898V42.1633Z' fill='black' />
    <path d='M50.2551 42.1633H48.9898V43.4286H50.2551V42.1633Z' fill='black' />
    <path d='M51.5204 42.1633H50.2551V43.4286H51.5204L51.5204 42.1633Z' fill='black' />
    <path d='M52.7857 42.1633H51.5204L51.5204 43.4286H52.7857V42.1633Z' fill='black' />
    <path d='M54.051 42.1633H52.7857V43.4286H54.051L54.051 42.1633Z' fill='black' />
    <path d='M55.3163 42.1633H54.051L54.051 43.4286H55.3163V42.1633Z' fill='black' />
    <path d='M60.3775 42.1633H59.1122L59.1122 43.4286H60.3775V42.1633Z' fill='black' />
    <path d='M64.1735 42.1633H62.9082L62.9082 43.4286H64.1735V42.1633Z' fill='black' />
    <path d='M65.4388 42.1633H64.1735V43.4286H65.4388V42.1633Z' fill='black' />
    <path d='M66.7041 42.1633H65.4388V43.4286H66.7041V42.1633Z' fill='black' />
    <path d='M67.9694 42.1633H66.7041V43.4286H67.9694V42.1633Z' fill='black' />
    <path d='M14.8265 43.4286H13.5612V44.6939H14.8265V43.4286Z' fill='black' />
    <path d='M17.3571 43.4286H16.0918V44.6939H17.3571V43.4286Z' fill='black' />
    <path d='M19.8878 43.4286H18.6224V44.6939H19.8878V43.4286Z' fill='black' />
    <path d='M23.6837 43.4286H22.4184V44.6939H23.6837V43.4286Z' fill='black' />
    <path d='M24.949 43.4286H23.6837V44.6939H24.949V43.4286Z' fill='black' />
    <path d='M32.5408 43.4286L31.2755 43.4286V44.6939H32.5408V43.4286Z' fill='black' />
    <path d='M35.0714 43.4286H33.8061V44.6939H35.0714V43.4286Z' fill='black' />
    <path d='M37.602 43.4286L36.3367 43.4286V44.6939H37.602V43.4286Z' fill='black' />
    <path d='M38.8673 43.4286H37.602V44.6939H38.8673V43.4286Z' fill='black' />
    <path d='M40.1327 43.4286H38.8673V44.6939H40.1327V43.4286Z' fill='black' />
    <path d='M41.398 43.4286H40.1327V44.6939H41.398V43.4286Z' fill='black' />
    <path d='M42.6633 43.4286H41.398V44.6939H42.6633V43.4286Z' fill='black' />
    <path d='M43.9286 43.4286H42.6633V44.6939H43.9286V43.4286Z' fill='black' />
    <path d='M47.7245 43.4286H46.4592V44.6939H47.7245V43.4286Z' fill='black' />
    <path d='M48.9898 43.4286H47.7245V44.6939H48.9898V43.4286Z' fill='black' />
    <path d='M51.5204 43.4286H50.2551V44.6939H51.5204V43.4286Z' fill='black' />
    <path d='M52.7857 43.4286H51.5204V44.6939H52.7857V43.4286Z' fill='black' />
    <path d='M54.051 43.4286H52.7857V44.6939H54.051V43.4286Z' fill='black' />
    <path d='M55.3163 43.4286H54.051V44.6939H55.3163V43.4286Z' fill='black' />
    <path d='M57.8469 43.4286H56.5816V44.6939H57.8469V43.4286Z' fill='black' />
    <path d='M59.1122 43.4286L57.8469 43.4286V44.6939H59.1122L59.1122 43.4286Z' fill='black' />
    <path d='M65.4388 43.4286H64.1735L64.1735 44.6939H65.4388V43.4286Z' fill='black' />
    <path d='M67.9694 43.4286H66.7041L66.7041 44.6939H67.9694V43.4286Z' fill='black' />
    <path d='M70.5 43.4286H69.2347V44.6939H70.5V43.4286Z' fill='black' />
    <path d='M9.76531 44.6939H8.5V45.9592H9.76531V44.6939Z' fill='black' />
    <path d='M11.0306 44.6939H9.76531V45.9592H11.0306V44.6939Z' fill='black' />
    <path d='M14.8265 44.6939H13.5612V45.9592H14.8265V44.6939Z' fill='black' />
    <path d='M19.8878 44.6939H18.6224V45.9592H19.8878V44.6939Z' fill='black' />
    <path d='M21.1531 44.6939H19.8878V45.9592H21.1531V44.6939Z' fill='black' />
    <path d='M22.4184 44.6939H21.1531V45.9592H22.4184V44.6939Z' fill='black' />
    <path d='M23.6837 44.6939H22.4184V45.9592H23.6837V44.6939Z' fill='black' />
    <path d='M24.949 44.6939H23.6837V45.9592H24.949V44.6939Z' fill='black' />
    <path d='M27.4796 44.6939H26.2143V45.9592H27.4796V44.6939Z' fill='black' />
    <path d='M28.7449 44.6939H27.4796V45.9592H28.7449V44.6939Z' fill='black' />
    <path d='M30.0102 44.6939H28.7449V45.9592H30.0102V44.6939Z' fill='black' />
    <path d='M32.5408 44.6939H31.2755V45.9592H32.5408V44.6939Z' fill='black' />
    <path d='M35.0714 44.6939H33.8061V45.9592H35.0714V44.6939Z' fill='black' />
    <path d='M38.8673 44.6939H37.602V45.9592H38.8673V44.6939Z' fill='black' />
    <path d='M40.1327 44.6939H38.8673V45.9592H40.1327V44.6939Z' fill='black' />
    <path d='M46.4592 44.6939H45.1939V45.9592H46.4592L46.4592 44.6939Z' fill='black' />
    <path d='M50.2551 44.6939H48.9898L48.9898 45.9592H50.2551V44.6939Z' fill='black' />
    <path d='M55.3163 44.6939H54.051L54.051 45.9592H55.3163V44.6939Z' fill='black' />
    <path d='M57.8469 44.6939H56.5816V45.9592H57.8469V44.6939Z' fill='black' />
    <path d='M61.6429 44.6939H60.3775V45.9592H61.6429V44.6939Z' fill='black' />
    <path d='M62.9082 44.6939H61.6429V45.9592H62.9082V44.6939Z' fill='black' />
    <path d='M67.9694 44.6939H66.7041V45.9592H67.9694V44.6939Z' fill='black' />
    <path d='M70.5 44.6939H69.2347V45.9592H70.5V44.6939Z' fill='black' />
    <path d='M13.5612 45.9592L12.2959 45.9592V47.2245H13.5612V45.9592Z' fill='black' />
    <path d='M14.8265 45.9592H13.5612V47.2245H14.8265V45.9592Z' fill='black' />
    <path d='M16.0918 45.9592L14.8265 45.9592V47.2245H16.0918V45.9592Z' fill='black' />
    <path d='M17.3571 45.9592H16.0918V47.2245H17.3571V45.9592Z' fill='black' />
    <path d='M19.8878 45.9592H18.6224V47.2245H19.8878V45.9592Z' fill='black' />
    <path d='M23.6837 45.9592H22.4184V47.2245H23.6837V45.9592Z' fill='black' />
    <path d='M24.949 45.9592H23.6837V47.2245H24.949V45.9592Z' fill='black' />
    <path d='M30.0102 45.9592H28.7449V47.2245H30.0102V45.9592Z' fill='black' />
    <path d='M32.5408 45.9592H31.2755V47.2245H32.5408V45.9592Z' fill='black' />
    <path d='M38.8673 45.9592H37.602V47.2245H38.8673V45.9592Z' fill='black' />
    <path d='M45.1939 45.9592L43.9286 45.9592V47.2245H45.1939V45.9592Z' fill='black' />
    <path d='M48.9898 45.9592L47.7245 45.9592V47.2245H48.9898L48.9898 45.9592Z' fill='black' />
    <path d='M51.5204 45.9592L50.2551 45.9592V47.2245H51.5204V45.9592Z' fill='black' />
    <path d='M52.7857 45.9592H51.5204V47.2245H52.7857V45.9592Z' fill='black' />
    <path d='M54.051 45.9592L52.7857 45.9592V47.2245H54.051L54.051 45.9592Z' fill='black' />
    <path d='M55.3163 45.9592H54.051L54.051 47.2245H55.3163V45.9592Z' fill='black' />
    <path d='M60.3775 45.9592L59.1122 45.9592V47.2245H60.3775V45.9592Z' fill='black' />
    <path d='M65.4388 45.9592H64.1735V47.2245H65.4388V45.9592Z' fill='black' />
    <path d='M70.5 45.9592H69.2347V47.2245H70.5V45.9592Z' fill='black' />
    <path d='M11.0306 47.2245H9.76531V48.4898H11.0306V47.2245Z' fill='black' />
    <path d='M12.2959 47.2245L11.0306 47.2245V48.4898H12.2959V47.2245Z' fill='black' />
    <path d='M13.5612 47.2245H12.2959V48.4898H13.5612V47.2245Z' fill='black' />
    <path d='M18.6224 47.2245H17.3571V48.4898H18.6224V47.2245Z' fill='black' />
    <path d='M19.8878 47.2245H18.6224V48.4898H19.8878V47.2245Z' fill='black' />
    <path d='M21.1531 47.2245L19.8878 47.2245V48.4898H21.1531V47.2245Z' fill='black' />
    <path d='M22.4184 47.2245L21.1531 47.2245V48.4898H22.4184V47.2245Z' fill='black' />
    <path d='M23.6837 47.2245H22.4184V48.4898H23.6837V47.2245Z' fill='black' />
    <path d='M26.2143 47.2245L24.949 47.2245V48.4898H26.2143V47.2245Z' fill='black' />
    <path d='M27.4796 47.2245H26.2143V48.4898H27.4796V47.2245Z' fill='black' />
    <path d='M30.0102 47.2245H28.7449V48.4898H30.0102V47.2245Z' fill='black' />
    <path d='M35.0714 47.2245H33.8061V48.4898H35.0714V47.2245Z' fill='black' />
    <path d='M36.3367 47.2245H35.0714V48.4898H36.3367V47.2245Z' fill='black' />
    <path d='M40.1327 47.2245L38.8673 47.2245V48.4898H40.1327V47.2245Z' fill='black' />
    <path d='M41.398 47.2245H40.1327V48.4898H41.398V47.2245Z' fill='black' />
    <path d='M42.6633 47.2245H41.398V48.4898H42.6633V47.2245Z' fill='black' />
    <path d='M43.9286 47.2245L42.6633 47.2245V48.4898H43.9286L43.9286 47.2245Z' fill='black' />
    <path d='M47.7245 47.2245L46.4592 47.2245V48.4898H47.7245L47.7245 47.2245Z' fill='black' />
    <path d='M50.2551 47.2245H48.9898L48.9898 48.4898H50.2551V47.2245Z' fill='black' />
    <path d='M51.5204 47.2245H50.2551V48.4898H51.5204V47.2245Z' fill='black' />
    <path d='M54.051 47.2245H52.7857L52.7857 48.4898H54.051V47.2245Z' fill='black' />
    <path d='M55.3163 47.2245H54.051V48.4898H55.3163V47.2245Z' fill='black' />
    <path d='M56.5816 47.2245L55.3163 47.2245V48.4898H56.5816V47.2245Z' fill='black' />
    <path d='M57.8469 47.2245H56.5816V48.4898H57.8469V47.2245Z' fill='black' />
    <path d='M59.1122 47.2245L57.8469 47.2245V48.4898H59.1122L59.1122 47.2245Z' fill='black' />
    <path d='M62.9082 47.2245H61.6429V48.4898H62.9082V47.2245Z' fill='black' />
    <path d='M65.4388 47.2245H64.1735V48.4898H65.4388V47.2245Z' fill='black' />
    <path d='M67.9694 47.2245H66.7041V48.4898H67.9694V47.2245Z' fill='black' />
    <path d='M11.0306 48.4898H9.76531V49.7551H11.0306V48.4898Z' fill='black' />
    <path d='M12.2959 48.4898H11.0306V49.7551H12.2959V48.4898Z' fill='black' />
    <path d='M14.8265 48.4898L13.5612 48.4898V49.7551H14.8265V48.4898Z' fill='black' />
    <path d='M16.0918 48.4898H14.8265V49.7551H16.0918V48.4898Z' fill='black' />
    <path d='M17.3571 48.4898L16.0918 48.4898V49.7551H17.3571V48.4898Z' fill='black' />
    <path d='M22.4184 48.4898H21.1531V49.7551H22.4184V48.4898Z' fill='black' />
    <path d='M24.949 48.4898H23.6837V49.7551H24.949V48.4898Z' fill='black' />
    <path d='M27.4796 48.4898H26.2143V49.7551H27.4796V48.4898Z' fill='black' />
    <path d='M30.0102 48.4898H28.7449V49.7551H30.0102V48.4898Z' fill='black' />
    <path d='M31.2755 48.4898L30.0102 48.4898V49.7551H31.2755V48.4898Z' fill='black' />
    <path d='M32.5408 48.4898H31.2755V49.7551H32.5408V48.4898Z' fill='black' />
    <path d='M33.8061 48.4898L32.5408 48.4898V49.7551H33.8061V48.4898Z' fill='black' />
    <path d='M37.602 48.4898L36.3367 48.4898V49.7551H37.602V48.4898Z' fill='black' />
    <path d='M38.8673 48.4898L37.602 48.4898V49.7551H38.8673V48.4898Z' fill='black' />
    <path d='M40.1327 48.4898H38.8673V49.7551H40.1327V48.4898Z' fill='black' />
    <path d='M41.398 48.4898H40.1327V49.7551H41.398V48.4898Z' fill='black' />
    <path d='M43.9286 48.4898H42.6633V49.7551H43.9286V48.4898Z' fill='black' />
    <path d='M45.1939 48.4898L43.9286 48.4898V49.7551H45.1939V48.4898Z' fill='black' />
    <path d='M50.2551 48.4898H48.9898V49.7551H50.2551V48.4898Z' fill='black' />
    <path d='M51.5204 48.4898H50.2551V49.7551H51.5204V48.4898Z' fill='black' />
    <path d='M55.3163 48.4898H54.051L54.051 49.7551H55.3163V48.4898Z' fill='black' />
    <path d='M56.5816 48.4898H55.3163V49.7551H56.5816V48.4898Z' fill='black' />
    <path d='M57.8469 48.4898H56.5816V49.7551H57.8469V48.4898Z' fill='black' />
    <path d='M60.3775 48.4898L59.1122 48.4898L59.1122 49.7551H60.3775V48.4898Z' fill='black' />
    <path d='M61.6429 48.4898L60.3775 48.4898V49.7551H61.6429L61.6429 48.4898Z' fill='black' />
    <path d='M64.1735 48.4898H62.9082L62.9082 49.7551H64.1735L64.1735 48.4898Z' fill='black' />
    <path d='M66.7041 48.4898H65.4388V49.7551H66.7041L66.7041 48.4898Z' fill='black' />
    <path d='M67.9694 48.4898H66.7041L66.7041 49.7551H67.9694V48.4898Z' fill='black' />
    <path d='M69.2347 48.4898L67.9694 48.4898V49.7551H69.2347V48.4898Z' fill='black' />
    <path d='M70.5 48.4898H69.2347V49.7551H70.5V48.4898Z' fill='black' />
    <path d='M9.76531 49.7551H8.5V51.0204H9.76531V49.7551Z' fill='black' />
    <path d='M11.0306 49.7551H9.76531V51.0204H11.0306V49.7551Z' fill='black' />
    <path d='M12.2959 49.7551H11.0306V51.0204H12.2959V49.7551Z' fill='black' />
    <path d='M14.8265 49.7551H13.5612V51.0204H14.8265V49.7551Z' fill='black' />
    <path d='M16.0918 49.7551H14.8265V51.0204H16.0918V49.7551Z' fill='black' />
    <path d='M19.8878 49.7551H18.6224V51.0204H19.8878V49.7551Z' fill='black' />
    <path d='M23.6837 49.7551H22.4184V51.0204H23.6837V49.7551Z' fill='black' />
    <path d='M26.2143 49.7551H24.949V51.0204H26.2143V49.7551Z' fill='black' />
    <path d='M28.7449 49.7551H27.4796V51.0204H28.7449V49.7551Z' fill='black' />
    <path d='M30.0102 49.7551H28.7449V51.0204H30.0102V49.7551Z' fill='black' />
    <path d='M31.2755 49.7551H30.0102V51.0204H31.2755V49.7551Z' fill='black' />
    <path d='M32.5408 49.7551H31.2755V51.0204H32.5408V49.7551Z' fill='black' />
    <path d='M33.8061 49.7551H32.5408V51.0204H33.8061V49.7551Z' fill='black' />
    <path d='M35.0714 49.7551H33.8061V51.0204H35.0714V49.7551Z' fill='black' />
    <path d='M36.3367 49.7551H35.0714V51.0204H36.3367V49.7551Z' fill='black' />
    <path d='M42.6633 49.7551H41.398V51.0204H42.6633V49.7551Z' fill='black' />
    <path d='M61.6429 49.7551H60.3775V51.0204H61.6429V49.7551Z' fill='black' />
    <path d='M65.4388 49.7551H64.1735L64.1735 51.0204H65.4388V49.7551Z' fill='black' />
    <path d='M69.2347 49.7551H67.9694L67.9694 51.0204H69.2347V49.7551Z' fill='black' />
    <path d='M70.5 49.7551H69.2347V51.0204H70.5V49.7551Z' fill='black' />
    <path d='M11.0306 51.0204H9.76531V52.2857H11.0306V51.0204Z' fill='black' />
    <path d='M16.0918 51.0204H14.8265V52.2857H16.0918V51.0204Z' fill='black' />
    <path d='M17.3571 51.0204L16.0918 51.0204V52.2857H17.3571V51.0204Z' fill='black' />
    <path d='M18.6224 51.0204L17.3571 51.0204V52.2857H18.6224V51.0204Z' fill='black' />
    <path d='M19.8878 51.0204H18.6224V52.2857H19.8878V51.0204Z' fill='black' />
    <path d='M22.4184 51.0204L21.1531 51.0204V52.2857H22.4184V51.0204Z' fill='black' />
    <path d='M23.6837 51.0204H22.4184V52.2857H23.6837V51.0204Z' fill='black' />
    <path d='M24.949 51.0204H23.6837V52.2857H24.949V51.0204Z' fill='black' />
    <path d='M26.2143 51.0204H24.949V52.2857H26.2143V51.0204Z' fill='black' />
    <path d='M32.5408 51.0204H31.2755V52.2857H32.5408V51.0204Z' fill='black' />
    <path d='M33.8061 51.0204H32.5408V52.2857H33.8061V51.0204Z' fill='black' />
    <path d='M35.0714 51.0204H33.8061V52.2857H35.0714V51.0204Z' fill='black' />
    <path d='M37.602 51.0204L36.3367 51.0204V52.2857H37.602V51.0204Z' fill='black' />
    <path d='M38.8673 51.0204H37.602V52.2857H38.8673V51.0204Z' fill='black' />
    <path d='M41.398 51.0204L40.1327 51.0204V52.2857H41.398V51.0204Z' fill='black' />
    <path d='M45.1939 51.0204H43.9286V52.2857H45.1939V51.0204Z' fill='black' />
    <path d='M51.5204 51.0204H50.2551V52.2857H51.5204V51.0204Z' fill='black' />
    <path d='M54.051 51.0204H52.7857V52.2857H54.051V51.0204Z' fill='black' />
    <path d='M56.5816 51.0204H55.3163V52.2857H56.5816V51.0204Z' fill='black' />
    <path d='M65.4388 51.0204H64.1735V52.2857H65.4388V51.0204Z' fill='black' />
    <path d='M67.9694 51.0204L66.7041 51.0204V52.2857H67.9694L67.9694 51.0204Z' fill='black' />
    <path d='M70.5 51.0204H69.2347L69.2347 52.2857H70.5V51.0204Z' fill='black' />
    <path d='M13.5612 52.2857H12.2959V53.551H13.5612V52.2857Z' fill='black' />
    <path d='M18.6224 52.2857H17.3571V53.551H18.6224V52.2857Z' fill='black' />
    <path d='M21.1531 52.2857H19.8878V53.551H21.1531V52.2857Z' fill='black' />
    <path d='M22.4184 52.2857H21.1531V53.551H22.4184V52.2857Z' fill='black' />
    <path d='M24.949 52.2857H23.6837V53.551H24.949V52.2857Z' fill='black' />
    <path d='M31.2755 52.2857L30.0102 52.2857V53.551H31.2755V52.2857Z' fill='black' />
    <path d='M35.0714 52.2857H33.8061V53.551H35.0714V52.2857Z' fill='black' />
    <path d='M37.602 52.2857H36.3367V53.551H37.602V52.2857Z' fill='black' />
    <path d='M40.1327 52.2857H38.8673V53.551H40.1327V52.2857Z' fill='black' />
    <path d='M42.6633 52.2857L41.398 52.2857V53.551H42.6633V52.2857Z' fill='black' />
    <path d='M43.9286 52.2857L42.6633 52.2857V53.551H43.9286L43.9286 52.2857Z' fill='black' />
    <path d='M45.1939 52.2857H43.9286L43.9286 53.551H45.1939V52.2857Z' fill='black' />
    <path d='M46.4592 52.2857L45.1939 52.2857V53.551H46.4592V52.2857Z' fill='black' />
    <path d='M48.9898 52.2857H47.7245V53.551H48.9898V52.2857Z' fill='black' />
    <path d='M51.5204 52.2857H50.2551V53.551H51.5204V52.2857Z' fill='black' />
    <path d='M52.7857 52.2857H51.5204V53.551H52.7857L52.7857 52.2857Z' fill='black' />
    <path d='M54.051 52.2857H52.7857L52.7857 53.551H54.051V52.2857Z' fill='black' />
    <path d='M57.8469 52.2857L56.5816 52.2857L56.5816 53.551H57.8469V52.2857Z' fill='black' />
    <path d='M59.1122 52.2857H57.8469V53.551H59.1122V52.2857Z' fill='black' />
    <path d='M60.3775 52.2857H59.1122V53.551H60.3775V52.2857Z' fill='black' />
    <path d='M62.9082 52.2857H61.6429V53.551H62.9082V52.2857Z' fill='black' />
    <path d='M64.1735 52.2857L62.9082 52.2857V53.551H64.1735L64.1735 52.2857Z' fill='black' />
    <path d='M66.7041 52.2857H65.4388V53.551H66.7041L66.7041 52.2857Z' fill='black' />
    <path d='M67.9694 52.2857H66.7041L66.7041 53.551H67.9694V52.2857Z' fill='black' />
    <path d='M69.2347 52.2857H67.9694V53.551H69.2347L69.2347 52.2857Z' fill='black' />
    <path d='M11.0306 53.551H9.76531V54.8163H11.0306V53.551Z' fill='black' />
    <path d='M14.8265 53.551L13.5612 53.551V54.8163H14.8265V53.551Z' fill='black' />
    <path d='M16.0918 53.551H14.8265V54.8163H16.0918V53.551Z' fill='black' />
    <path d='M17.3571 53.551L16.0918 53.551V54.8163H17.3571V53.551Z' fill='black' />
    <path d='M18.6224 53.551H17.3571V54.8163H18.6224V53.551Z' fill='black' />
    <path d='M19.8878 53.551H18.6224V54.8163H19.8878V53.551Z' fill='black' />
    <path d='M23.6837 53.551H22.4184V54.8163H23.6837V53.551Z' fill='black' />
    <path d='M24.949 53.551H23.6837V54.8163H24.949V53.551Z' fill='black' />
    <path d='M26.2143 53.551L24.949 53.551V54.8163H26.2143V53.551Z' fill='black' />
    <path d='M27.4796 53.551H26.2143V54.8163H27.4796V53.551Z' fill='black' />
    <path d='M30.0102 53.551L28.7449 53.551V54.8163H30.0102V53.551Z' fill='black' />
    <path d='M32.5408 53.551L31.2755 53.551V54.8163H32.5408V53.551Z' fill='black' />
    <path d='M33.8061 53.551L32.5408 53.551V54.8163H33.8061V53.551Z' fill='black' />
    <path d='M35.0714 53.551H33.8061V54.8163H35.0714V53.551Z' fill='black' />
    <path d='M36.3367 53.551H35.0714V54.8163H36.3367V53.551Z' fill='black' />
    <path d='M37.602 53.551H36.3367V54.8163H37.602V53.551Z' fill='black' />
    <path d='M38.8673 53.551H37.602V54.8163H38.8673V53.551Z' fill='black' />
    <path d='M40.1327 53.551H38.8673V54.8163H40.1327V53.551Z' fill='black' />
    <path d='M42.6633 53.551H41.398V54.8163H42.6633V53.551Z' fill='black' />
    <path d='M43.9286 53.551H42.6633V54.8163H43.9286V53.551Z' fill='black' />
    <path d='M45.1939 53.551H43.9286V54.8163H45.1939V53.551Z' fill='black' />
    <path d='M51.5204 53.551H50.2551V54.8163H51.5204V53.551Z' fill='black' />
    <path d='M54.051 53.551H52.7857L52.7857 54.8163H54.051V53.551Z' fill='black' />
    <path d='M55.3163 53.551L54.051 53.551V54.8163H55.3163V53.551Z' fill='black' />
    <path d='M59.1122 53.551H57.8469L57.8469 54.8163H59.1122V53.551Z' fill='black' />
    <path d='M60.3775 53.551H59.1122V54.8163H60.3775V53.551Z' fill='black' />
    <path d='M62.9082 53.551H61.6429V54.8163H62.9082V53.551Z' fill='black' />
    <path d='M64.1735 53.551H62.9082V54.8163H64.1735V53.551Z' fill='black' />
    <path d='M65.4388 53.551H64.1735V54.8163H65.4388V53.551Z' fill='black' />
    <path d='M66.7041 53.551H65.4388V54.8163H66.7041V53.551Z' fill='black' />
    <path d='M67.9694 53.551H66.7041V54.8163H67.9694V53.551Z' fill='black' />
    <path d='M70.5 53.551L69.2347 53.551L69.2347 54.8163H70.5V53.551Z' fill='black' />
    <path d='M9.76531 54.8163H8.5V56.0816H9.76531V54.8163Z' fill='black' />
    <path d='M11.0306 54.8163H9.76531V56.0816H11.0306V54.8163Z' fill='black' />
    <path d='M21.1531 54.8163H19.8878V56.0816H21.1531V54.8163Z' fill='black' />
    <path d='M24.949 54.8163H23.6837V56.0816H24.949V54.8163Z' fill='black' />
    <path d='M27.4796 54.8163H26.2143V56.0816H27.4796V54.8163Z' fill='black' />
    <path d='M28.7449 54.8163H27.4796V56.0816H28.7449V54.8163Z' fill='black' />
    <path d='M31.2755 54.8163H30.0102V56.0816H31.2755V54.8163Z' fill='black' />
    <path d='M33.8061 54.8163H32.5408V56.0816H33.8061V54.8163Z' fill='black' />
    <path d='M35.0714 54.8163H33.8061V56.0816H35.0714V54.8163Z' fill='black' />
    <path d='M36.3367 54.8163H35.0714V56.0816H36.3367V54.8163Z' fill='black' />
    <path d='M37.602 54.8163H36.3367V56.0816H37.602V54.8163Z' fill='black' />
    <path d='M43.9286 54.8163H42.6633V56.0816H43.9286V54.8163Z' fill='black' />
    <path d='M48.9898 54.8163H47.7245V56.0816H48.9898V54.8163Z' fill='black' />
    <path d='M52.7857 54.8163H51.5204L51.5204 56.0816H52.7857L52.7857 54.8163Z' fill='black' />
    <path d='M56.5816 54.8163H55.3163V56.0816H56.5816V54.8163Z' fill='black' />
    <path d='M62.9082 54.8163H61.6429V56.0816H62.9082V54.8163Z' fill='black' />
    <path d='M65.4388 54.8163H64.1735L64.1735 56.0816H65.4388V54.8163Z' fill='black' />
    <path d='M66.7041 54.8163H65.4388V56.0816H66.7041V54.8163Z' fill='black' />
    <path d='M69.2347 54.8163H67.9694L67.9694 56.0816H69.2347L69.2347 54.8163Z' fill='black' />
    <path d='M70.5 54.8163H69.2347L69.2347 56.0816H70.5V54.8163Z' fill='black' />
    <path d='M11.0306 56.0816H9.76531V57.3469H11.0306V56.0816Z' fill='black' />
    <path d='M16.0918 56.0816H14.8265V57.3469H16.0918V56.0816Z' fill='black' />
    <path d='M17.3571 56.0816H16.0918V57.3469H17.3571V56.0816Z' fill='black' />
    <path d='M18.6224 56.0816H17.3571V57.3469H18.6224V56.0816Z' fill='black' />
    <path d='M19.8878 56.0816L18.6224 56.0816V57.3469H19.8878V56.0816Z' fill='black' />
    <path d='M23.6837 56.0816L22.4184 56.0816V57.3469H23.6837V56.0816Z' fill='black' />
    <path d='M24.949 56.0816H23.6837V57.3469H24.949V56.0816Z' fill='black' />
    <path d='M27.4796 56.0816H26.2143V57.3469H27.4796V56.0816Z' fill='black' />
    <path d='M30.0102 56.0816H28.7449V57.3469H30.0102V56.0816Z' fill='black' />
    <path d='M31.2755 56.0816H30.0102V57.3469H31.2755V56.0816Z' fill='black' />
    <path d='M32.5408 56.0816H31.2755V57.3469H32.5408V56.0816Z' fill='black' />
    <path d='M35.0714 56.0816H33.8061V57.3469H35.0714V56.0816Z' fill='black' />
    <path d='M38.8673 56.0816L37.602 56.0816V57.3469H38.8673V56.0816Z' fill='black' />
    <path d='M42.6633 56.0816L41.398 56.0816V57.3469H42.6633V56.0816Z' fill='black' />
    <path d='M46.4592 56.0816H45.1939V57.3469H46.4592V56.0816Z' fill='black' />
    <path d='M47.7245 56.0816L46.4592 56.0816V57.3469H47.7245L47.7245 56.0816Z' fill='black' />
    <path d='M48.9898 56.0816H47.7245L47.7245 57.3469H48.9898V56.0816Z' fill='black' />
    <path d='M50.2551 56.0816L48.9898 56.0816V57.3469H50.2551V56.0816Z' fill='black' />
    <path d='M51.5204 56.0816L50.2551 56.0816V57.3469H51.5204L51.5204 56.0816Z' fill='black' />
    <path d='M52.7857 56.0816H51.5204L51.5204 57.3469H52.7857V56.0816Z' fill='black' />
    <path d='M57.8469 56.0816L56.5816 56.0816L56.5816 57.3469H57.8469V56.0816Z' fill='black' />
    <path d='M59.1122 56.0816H57.8469V57.3469H59.1122V56.0816Z' fill='black' />
    <path d='M61.6429 56.0816L60.3775 56.0816V57.3469H61.6429L61.6429 56.0816Z' fill='black' />
    <path d='M62.9082 56.0816H61.6429L61.6429 57.3469H62.9082V56.0816Z' fill='black' />
    <path d='M69.2347 56.0816H67.9694V57.3469H69.2347V56.0816Z' fill='black' />
    <path d='M70.5 56.0816H69.2347V57.3469H70.5V56.0816Z' fill='black' />
    <path d='M11.0306 57.3469H9.76531V58.6122H11.0306V57.3469Z' fill='black' />
    <path d='M12.2959 57.3469L11.0306 57.3469V58.6122H12.2959V57.3469Z' fill='black' />
    <path d='M13.5612 57.3469H12.2959V58.6122H13.5612V57.3469Z' fill='black' />
    <path d='M19.8878 57.3469H18.6224V58.6122H19.8878V57.3469Z' fill='black' />
    <path d='M21.1531 57.3469L19.8878 57.3469V58.6122H21.1531V57.3469Z' fill='black' />
    <path d='M22.4184 57.3469L21.1531 57.3469V58.6122H22.4184V57.3469Z' fill='black' />
    <path d='M26.2143 57.3469H24.949V58.6122H26.2143V57.3469Z' fill='black' />
    <path d='M27.4796 57.3469H26.2143V58.6122H27.4796V57.3469Z' fill='black' />
    <path d='M33.8061 57.3469H32.5408V58.6122H33.8061V57.3469Z' fill='black' />
    <path d='M35.0714 57.3469H33.8061V58.6122H35.0714V57.3469Z' fill='black' />
    <path d='M37.602 57.3469L36.3367 57.3469V58.6122H37.602V57.3469Z' fill='black' />
    <path d='M40.1327 57.3469L38.8673 57.3469V58.6122H40.1327V57.3469Z' fill='black' />
    <path d='M41.398 57.3469L40.1327 57.3469V58.6122H41.398V57.3469Z' fill='black' />
    <path d='M46.4592 57.3469H45.1939V58.6122H46.4592V57.3469Z' fill='black' />
    <path d='M47.7245 57.3469H46.4592V58.6122H47.7245V57.3469Z' fill='black' />
    <path d='M51.5204 57.3469H50.2551V58.6122H51.5204V57.3469Z' fill='black' />
    <path d='M54.051 57.3469L52.7857 57.3469L52.7857 58.6122H54.051V57.3469Z' fill='black' />
    <path d='M62.9082 57.3469H61.6429L61.6429 58.6122H62.9082V57.3469Z' fill='black' />
    <path d='M65.4388 57.3469H64.1735V58.6122H65.4388V57.3469Z' fill='black' />
    <path d='M67.9694 57.3469L66.7041 57.3469V58.6122H67.9694L67.9694 57.3469Z' fill='black' />
    <path d='M9.76531 58.6122L8.5 58.6122V59.8775H9.76531V58.6122Z' fill='black' />
    <path d='M11.0306 58.6122H9.76531V59.8775H11.0306V58.6122Z' fill='black' />
    <path d='M12.2959 58.6122H11.0306V59.8775H12.2959V58.6122Z' fill='black' />
    <path d='M17.3571 58.6122H16.0918V59.8775H17.3571V58.6122Z' fill='black' />
    <path d='M18.6224 58.6122L17.3571 58.6122V59.8775H18.6224V58.6122Z' fill='black' />
    <path d='M23.6837 58.6122L22.4184 58.6122V59.8775H23.6837V58.6122Z' fill='black' />
    <path d='M26.2143 58.6122H24.949V59.8775H26.2143V58.6122Z' fill='black' />
    <path d='M30.0102 58.6122H28.7449V59.8775H30.0102V58.6122Z' fill='black' />
    <path d='M32.5408 58.6122L31.2755 58.6122V59.8775H32.5408V58.6122Z' fill='black' />
    <path d='M37.602 58.6122H36.3367V59.8775H37.602V58.6122Z' fill='black' />
    <path d='M38.8673 58.6122H37.602V59.8775H38.8673V58.6122Z' fill='black' />
    <path d='M40.1327 58.6122H38.8673V59.8775H40.1327V58.6122Z' fill='black' />
    <path d='M41.398 58.6122H40.1327V59.8775H41.398V58.6122Z' fill='black' />
    <path d='M42.6633 58.6122L41.398 58.6122V59.8775H42.6633V58.6122Z' fill='black' />
    <path d='M48.9898 58.6122L47.7245 58.6122L47.7245 59.8775H48.9898V58.6122Z' fill='black' />
    <path d='M51.5204 58.6122H50.2551V59.8775H51.5204V58.6122Z' fill='black' />
    <path d='M52.7857 58.6122H51.5204V59.8775H52.7857L52.7857 58.6122Z' fill='black' />
    <path d='M54.051 58.6122H52.7857L52.7857 59.8775H54.051V58.6122Z' fill='black' />
    <path d='M55.3163 58.6122L54.051 58.6122V59.8775H55.3163V58.6122Z' fill='black' />
    <path d='M56.5816 58.6122H55.3163V59.8775H56.5816V58.6122Z' fill='black' />
    <path d='M59.1122 58.6122H57.8469V59.8775H59.1122V58.6122Z' fill='black' />
    <path d='M60.3775 58.6122H59.1122V59.8775H60.3775V58.6122Z' fill='black' />
    <path d='M61.6429 58.6122L60.3775 58.6122V59.8775H61.6429L61.6429 58.6122Z' fill='black' />
    <path d='M62.9082 58.6122H61.6429L61.6429 59.8775H62.9082V58.6122Z' fill='black' />
    <path d='M64.1735 58.6122H62.9082V59.8775H64.1735L64.1735 58.6122Z' fill='black' />
    <path d='M65.4388 58.6122H64.1735L64.1735 59.8775H65.4388V58.6122Z' fill='black' />
    <path d='M70.5 58.6122H69.2347V59.8775H70.5V58.6122Z' fill='black' />
    <path d='M19.8878 59.8775H18.6224V61.1429H19.8878V59.8775Z' fill='black' />
    <path d='M22.4184 59.8775H21.1531V61.1429H22.4184V59.8775Z' fill='black' />
    <path d='M26.2143 59.8775H24.949V61.1429H26.2143V59.8775Z' fill='black' />
    <path d='M27.4796 59.8775H26.2143V61.1429H27.4796V59.8775Z' fill='black' />
    <path d='M28.7449 59.8775H27.4796V61.1429H28.7449V59.8775Z' fill='black' />
    <path d='M32.5408 59.8775H31.2755V61.1429H32.5408V59.8775Z' fill='black' />
    <path d='M33.8061 59.8775H32.5408V61.1429H33.8061V59.8775Z' fill='black' />
    <path d='M36.3367 59.8775H35.0714V61.1429H36.3367V59.8775Z' fill='black' />
    <path d='M37.602 59.8775H36.3367V61.1429H37.602V59.8775Z' fill='black' />
    <path d='M42.6633 59.8775H41.398V61.1429H42.6633V59.8775Z' fill='black' />
    <path d='M45.1939 59.8775H43.9286V61.1429H45.1939V59.8775Z' fill='black' />
    <path d='M46.4592 59.8775H45.1939V61.1429H46.4592V59.8775Z' fill='black' />
    <path d='M56.5816 59.8775H55.3163V61.1429H56.5816V59.8775Z' fill='black' />
    <path d='M60.3775 59.8775H59.1122L59.1122 61.1429H60.3775V59.8775Z' fill='black' />
    <path d='M65.4388 59.8775H64.1735L64.1735 61.1429H65.4388V59.8775Z' fill='black' />
    <path d='M66.7041 59.8775H65.4388V61.1429H66.7041V59.8775Z' fill='black' />
    <path d='M70.5 59.8775H69.2347V61.1429H70.5V59.8775Z' fill='black' />
    <path d='M9.76531 61.1429H8.5V62.4082H9.76531V61.1429Z' fill='black' />
    <path d='M11.0306 61.1429H9.76531V62.4082H11.0306V61.1429Z' fill='black' />
    <path d='M12.2959 61.1429H11.0306V62.4082H12.2959V61.1429Z' fill='black' />
    <path d='M13.5612 61.1429H12.2959V62.4082H13.5612V61.1429Z' fill='black' />
    <path d='M14.8265 61.1429H13.5612V62.4082H14.8265V61.1429Z' fill='black' />
    <path d='M16.0918 61.1429H14.8265V62.4082H16.0918V61.1429Z' fill='black' />
    <path d='M17.3571 61.1429H16.0918V62.4082H17.3571V61.1429Z' fill='black' />
    <path d='M23.6837 61.1429L22.4184 61.1429V62.4082H23.6837V61.1429Z' fill='black' />
    <path d='M30.0102 61.1429L28.7449 61.1429V62.4082H30.0102V61.1429Z' fill='black' />
    <path d='M35.0714 61.1429H33.8061V62.4082H35.0714V61.1429Z' fill='black' />
    <path d='M37.602 61.1429H36.3367V62.4082H37.602V61.1429Z' fill='black' />
    <path d='M40.1327 61.1429H38.8673V62.4082H40.1327V61.1429Z' fill='black' />
    <path d='M42.6633 61.1429H41.398V62.4082H42.6633V61.1429Z' fill='black' />
    <path d='M43.9286 61.1429H42.6633V62.4082H43.9286L43.9286 61.1429Z' fill='black' />
    <path d='M45.1939 61.1429H43.9286L43.9286 62.4082H45.1939V61.1429Z' fill='black' />
    <path d='M46.4592 61.1429H45.1939V62.4082H46.4592V61.1429Z' fill='black' />
    <path d='M47.7245 61.1429L46.4592 61.1429V62.4082H47.7245V61.1429Z' fill='black' />
    <path d='M48.9898 61.1429H47.7245V62.4082H48.9898V61.1429Z' fill='black' />
    <path d='M51.5204 61.1429H50.2551V62.4082H51.5204V61.1429Z' fill='black' />
    <path d='M52.7857 61.1429H51.5204V62.4082H52.7857V61.1429Z' fill='black' />
    <path d='M54.051 61.1429H52.7857V62.4082H54.051V61.1429Z' fill='black' />
    <path d='M55.3163 61.1429L54.051 61.1429V62.4082H55.3163V61.1429Z' fill='black' />
    <path d='M56.5816 61.1429H55.3163V62.4082H56.5816V61.1429Z' fill='black' />
    <path d='M57.8469 61.1429L56.5816 61.1429V62.4082H57.8469V61.1429Z' fill='black' />
    <path d='M60.3775 61.1429H59.1122V62.4082H60.3775V61.1429Z' fill='black' />
    <path d='M62.9082 61.1429H61.6429V62.4082H62.9082V61.1429Z' fill='black' />
    <path d='M65.4388 61.1429H64.1735V62.4082H65.4388V61.1429Z' fill='black' />
    <path d='M67.9694 61.1429L66.7041 61.1429L66.7041 62.4082H67.9694V61.1429Z' fill='black' />
    <path d='M9.76531 62.4082H8.5V63.6735H9.76531V62.4082Z' fill='black' />
    <path d='M17.3571 62.4082H16.0918V63.6735H17.3571V62.4082Z' fill='black' />
    <path d='M19.8878 62.4082H18.6224V63.6735H19.8878V62.4082Z' fill='black' />
    <path d='M22.4184 62.4082L21.1531 62.4082V63.6735H22.4184V62.4082Z' fill='black' />
    <path d='M26.2143 62.4082H24.949V63.6735H26.2143V62.4082Z' fill='black' />
    <path d='M27.4796 62.4082H26.2143V63.6735H27.4796V62.4082Z' fill='black' />
    <path d='M32.5408 62.4082H31.2755V63.6735H32.5408V62.4082Z' fill='black' />
    <path d='M33.8061 62.4082L32.5408 62.4082V63.6735H33.8061V62.4082Z' fill='black' />
    <path d='M37.602 62.4082H36.3367V63.6735H37.602V62.4082Z' fill='black' />
    <path d='M42.6633 62.4082H41.398V63.6735H42.6633V62.4082Z' fill='black' />
    <path d='M43.9286 62.4082H42.6633V63.6735H43.9286V62.4082Z' fill='black' />
    <path d='M47.7245 62.4082H46.4592L46.4592 63.6735H47.7245V62.4082Z' fill='black' />
    <path d='M50.2551 62.4082H48.9898L48.9898 63.6735H50.2551V62.4082Z' fill='black' />
    <path d='M51.5204 62.4082H50.2551V63.6735H51.5204V62.4082Z' fill='black' />
    <path d='M52.7857 62.4082H51.5204V63.6735H52.7857V62.4082Z' fill='black' />
    <path d='M56.5816 62.4082H55.3163V63.6735H56.5816V62.4082Z' fill='black' />
    <path d='M59.1122 62.4082H57.8469L57.8469 63.6735H59.1122L59.1122 62.4082Z' fill='black' />
    <path d='M60.3775 62.4082H59.1122L59.1122 63.6735H60.3775V62.4082Z' fill='black' />
    <path d='M65.4388 62.4082H64.1735V63.6735H65.4388V62.4082Z' fill='black' />
    <path d='M69.2347 62.4082L67.9694 62.4082L67.9694 63.6735H69.2347V62.4082Z' fill='black' />
    <path d='M9.76531 63.6735H8.5V64.9388H9.76531V63.6735Z' fill='black' />
    <path d='M12.2959 63.6735H11.0306V64.9388H12.2959V63.6735Z' fill='black' />
    <path d='M13.5612 63.6735H12.2959V64.9388H13.5612V63.6735Z' fill='black' />
    <path d='M14.8265 63.6735H13.5612V64.9388H14.8265V63.6735Z' fill='black' />
    <path d='M17.3571 63.6735H16.0918V64.9388H17.3571V63.6735Z' fill='black' />
    <path d='M24.949 63.6735L23.6837 63.6735V64.9388H24.949V63.6735Z' fill='black' />
    <path d='M27.4796 63.6735H26.2143V64.9388H27.4796V63.6735Z' fill='black' />
    <path d='M28.7449 63.6735L27.4796 63.6735V64.9388H28.7449V63.6735Z' fill='black' />
    <path d='M30.0102 63.6735H28.7449V64.9388H30.0102V63.6735Z' fill='black' />
    <path d='M31.2755 63.6735L30.0102 63.6735V64.9388H31.2755V63.6735Z' fill='black' />
    <path d='M32.5408 63.6735H31.2755V64.9388H32.5408V63.6735Z' fill='black' />
    <path d='M33.8061 63.6735H32.5408V64.9388H33.8061V63.6735Z' fill='black' />
    <path d='M36.3367 63.6735L35.0714 63.6735V64.9388H36.3367V63.6735Z' fill='black' />
    <path d='M37.602 63.6735H36.3367V64.9388H37.602V63.6735Z' fill='black' />
    <path d='M38.8673 63.6735L37.602 63.6735V64.9388H38.8673V63.6735Z' fill='black' />
    <path d='M40.1327 63.6735H38.8673V64.9388H40.1327V63.6735Z' fill='black' />
    <path d='M41.398 63.6735L40.1327 63.6735V64.9388H41.398V63.6735Z' fill='black' />
    <path d='M42.6633 63.6735H41.398V64.9388H42.6633V63.6735Z' fill='black' />
    <path d='M45.1939 63.6735L43.9286 63.6735L43.9286 64.9388H45.1939V63.6735Z' fill='black' />
    <path d='M46.4592 63.6735L45.1939 63.6735V64.9388H46.4592L46.4592 63.6735Z' fill='black' />
    <path d='M47.7245 63.6735H46.4592L46.4592 64.9388H47.7245V63.6735Z' fill='black' />
    <path d='M50.2551 63.6735H48.9898V64.9388H50.2551V63.6735Z' fill='black' />
    <path d='M52.7857 63.6735H51.5204L51.5204 64.9388H52.7857V63.6735Z' fill='black' />
    <path d='M54.051 63.6735L52.7857 63.6735V64.9388H54.051V63.6735Z' fill='black' />
    <path d='M57.8469 63.6735H56.5816L56.5816 64.9388H57.8469L57.8469 63.6735Z' fill='black' />
    <path d='M60.3775 63.6735H59.1122L59.1122 64.9388H60.3775V63.6735Z' fill='black' />
    <path d='M61.6429 63.6735L60.3775 63.6735V64.9388H61.6429V63.6735Z' fill='black' />
    <path d='M62.9082 63.6735H61.6429V64.9388H62.9082V63.6735Z' fill='black' />
    <path d='M64.1735 63.6735L62.9082 63.6735V64.9388H64.1735L64.1735 63.6735Z' fill='black' />
    <path d='M65.4388 63.6735H64.1735L64.1735 64.9388H65.4388V63.6735Z' fill='black' />
    <path d='M66.7041 63.6735L65.4388 63.6735V64.9388H66.7041V63.6735Z' fill='black' />
    <path d='M67.9694 63.6735L66.7041 63.6735V64.9388H67.9694L67.9694 63.6735Z' fill='black' />
    <path d='M69.2347 63.6735H67.9694L67.9694 64.9388H69.2347V63.6735Z' fill='black' />
    <path d='M70.5 63.6735L69.2347 63.6735V64.9388H70.5V63.6735Z' fill='black' />
    <path d='M9.76531 64.9388H8.5V66.2041H9.76531V64.9388Z' fill='black' />
    <path d='M12.2959 64.9388H11.0306V66.2041H12.2959V64.9388Z' fill='black' />
    <path d='M13.5612 64.9388H12.2959V66.2041H13.5612V64.9388Z' fill='black' />
    <path d='M14.8265 64.9388H13.5612V66.2041H14.8265V64.9388Z' fill='black' />
    <path d='M17.3571 64.9388H16.0918V66.2041H17.3571V64.9388Z' fill='black' />
    <path d='M22.4184 64.9388H21.1531V66.2041H22.4184V64.9388Z' fill='black' />
    <path d='M23.6837 64.9388H22.4184V66.2041H23.6837V64.9388Z' fill='black' />
    <path d='M35.0714 64.9388H33.8061V66.2041H35.0714V64.9388Z' fill='black' />
    <path d='M37.602 64.9388H36.3367V66.2041H37.602V64.9388Z' fill='black' />
    <path d='M42.6633 64.9388H41.398V66.2041H42.6633V64.9388Z' fill='black' />
    <path d='M46.4592 64.9388H45.1939V66.2041H46.4592V64.9388Z' fill='black' />
    <path d='M47.7245 64.9388H46.4592V66.2041H47.7245V64.9388Z' fill='black' />
    <path d='M50.2551 64.9388H48.9898V66.2041H50.2551V64.9388Z' fill='black' />
    <path d='M55.3163 64.9388H54.051L54.051 66.2041H55.3163V64.9388Z' fill='black' />
    <path d='M57.8469 64.9388H56.5816V66.2041H57.8469V64.9388Z' fill='black' />
    <path d='M61.6429 64.9388H60.3775V66.2041H61.6429V64.9388Z' fill='black' />
    <path d='M62.9082 64.9388H61.6429V66.2041H62.9082V64.9388Z' fill='black' />
    <path d='M70.5 64.9388H69.2347L69.2347 66.2041H70.5V64.9388Z' fill='black' />
    <path d='M9.76531 66.2041H8.5V67.4694H9.76531V66.2041Z' fill='black' />
    <path d='M12.2959 66.2041H11.0306V67.4694H12.2959V66.2041Z' fill='black' />
    <path d='M13.5612 66.2041H12.2959V67.4694H13.5612V66.2041Z' fill='black' />
    <path d='M14.8265 66.2041H13.5612V67.4694H14.8265V66.2041Z' fill='black' />
    <path d='M17.3571 66.2041H16.0918V67.4694H17.3571V66.2041Z' fill='black' />
    <path d='M27.4796 66.2041H26.2143V67.4694H27.4796V66.2041Z' fill='black' />
    <path d='M32.5408 66.2041H31.2755V67.4694H32.5408V66.2041Z' fill='black' />
    <path d='M33.8061 66.2041L32.5408 66.2041V67.4694H33.8061V66.2041Z' fill='black' />
    <path d='M37.602 66.2041H36.3367V67.4694H37.602V66.2041Z' fill='black' />
    <path d='M40.1327 66.2041H38.8673V67.4694H40.1327V66.2041Z' fill='black' />
    <path d='M42.6633 66.2041H41.398V67.4694H42.6633V66.2041Z' fill='black' />
    <path d='M45.1939 66.2041L43.9286 66.2041V67.4694H45.1939V66.2041Z' fill='black' />
    <path d='M47.7245 66.2041H46.4592L46.4592 67.4694H47.7245V66.2041Z' fill='black' />
    <path d='M48.9898 66.2041H47.7245V67.4694H48.9898L48.9898 66.2041Z' fill='black' />
    <path d='M50.2551 66.2041H48.9898L48.9898 67.4694H50.2551V66.2041Z' fill='black' />
    <path d='M56.5816 66.2041H55.3163V67.4694H56.5816L56.5816 66.2041Z' fill='black' />
    <path d='M57.8469 66.2041H56.5816L56.5816 67.4694H57.8469V66.2041Z' fill='black' />
    <path d='M61.6429 66.2041H60.3775V67.4694H61.6429V66.2041Z' fill='black' />
    <path d='M62.9082 66.2041H61.6429V67.4694H62.9082V66.2041Z' fill='black' />
    <path d='M64.1735 66.2041L62.9082 66.2041V67.4694H64.1735V66.2041Z' fill='black' />
    <path d='M67.9694 66.2041H66.7041V67.4694H67.9694V66.2041Z' fill='black' />
    <path d='M70.5 66.2041H69.2347V67.4694H70.5V66.2041Z' fill='black' />
    <path d='M9.76531 67.4694H8.5V68.7347H9.76531V67.4694Z' fill='black' />
    <path d='M17.3571 67.4694H16.0918V68.7347H17.3571V67.4694Z' fill='black' />
    <path d='M24.949 67.4694H23.6837V68.7347H24.949V67.4694Z' fill='black' />
    <path d='M28.7449 67.4694L27.4796 67.4694V68.7347H28.7449V67.4694Z' fill='black' />
    <path d='M32.5408 67.4694H31.2755V68.7347H32.5408V67.4694Z' fill='black' />
    <path d='M33.8061 67.4694H32.5408V68.7347H33.8061V67.4694Z' fill='black' />
    <path d='M35.0714 67.4694L33.8061 67.4694V68.7347H35.0714V67.4694Z' fill='black' />
    <path d='M36.3367 67.4694L35.0714 67.4694V68.7347H36.3367V67.4694Z' fill='black' />
    <path d='M37.602 67.4694H36.3367V68.7347H37.602V67.4694Z' fill='black' />
    <path d='M40.1327 67.4694H38.8673V68.7347H40.1327V67.4694Z' fill='black' />
    <path d='M41.398 67.4694H40.1327V68.7347H41.398V67.4694Z' fill='black' />
    <path d='M43.9286 67.4694H42.6633V68.7347H43.9286L43.9286 67.4694Z' fill='black' />
    <path d='M47.7245 67.4694H46.4592V68.7347H47.7245V67.4694Z' fill='black' />
    <path d='M52.7857 67.4694H51.5204V68.7347H52.7857V67.4694Z' fill='black' />
    <path d='M56.5816 67.4694H55.3163V68.7347H56.5816V67.4694Z' fill='black' />
    <path d='M60.3775 67.4694L59.1122 67.4694V68.7347H60.3775V67.4694Z' fill='black' />
    <path d='M62.9082 67.4694H61.6429L61.6429 68.7347H62.9082V67.4694Z' fill='black' />
    <path d='M64.1735 67.4694H62.9082V68.7347H64.1735V67.4694Z' fill='black' />
    <path d='M65.4388 67.4694L64.1735 67.4694V68.7347H65.4388V67.4694Z' fill='black' />
    <path d='M67.9694 67.4694H66.7041V68.7347H67.9694V67.4694Z' fill='black' />
    <path d='M70.5 67.4694H69.2347V68.7347H70.5V67.4694Z' fill='black' />
    <path d='M9.76531 68.7347H8.5V70H9.76531V68.7347Z' fill='black' />
    <path d='M11.0306 68.7347L9.76531 68.7347V70H11.0306V68.7347Z' fill='black' />
    <path d='M12.2959 68.7347H11.0306V70H12.2959V68.7347Z' fill='black' />
    <path d='M13.5612 68.7347H12.2959V70H13.5612V68.7347Z' fill='black' />
    <path d='M14.8265 68.7347H13.5612V70H14.8265V68.7347Z' fill='black' />
    <path d='M16.0918 68.7347L14.8265 68.7347V70H16.0918V68.7347Z' fill='black' />
    <path d='M17.3571 68.7347H16.0918V70H17.3571V68.7347Z' fill='black' />
    <path d='M21.1531 68.7347H19.8878V70H21.1531V68.7347Z' fill='black' />
    <path d='M22.4184 68.7347H21.1531V70H22.4184V68.7347Z' fill='black' />
    <path d='M23.6837 68.7347L22.4184 68.7347V70H23.6837V68.7347Z' fill='black' />
    <path d='M24.949 68.7347H23.6837V70H24.949V68.7347Z' fill='black' />
    <path d='M26.2143 68.7347L24.949 68.7347V70H26.2143V68.7347Z' fill='black' />
    <path d='M27.4796 68.7347L26.2143 68.7347V70H27.4796V68.7347Z' fill='black' />
    <path d='M32.5408 68.7347H31.2755V70H32.5408V68.7347Z' fill='black' />
    <path d='M36.3367 68.7347H35.0714V70H36.3367V68.7347Z' fill='black' />
    <path d='M37.602 68.7347H36.3367V70H37.602V68.7347Z' fill='black' />
    <path d='M40.1327 68.7347H38.8673V70H40.1327V68.7347Z' fill='black' />
    <path d='M42.6633 68.7347H41.398V70H42.6633V68.7347Z' fill='black' />
    <path d='M43.9286 68.7347H42.6633V70H43.9286V68.7347Z' fill='black' />
    <path d='M46.4592 68.7347L45.1939 68.7347V70H46.4592L46.4592 68.7347Z' fill='black' />
    <path d='M47.7245 68.7347H46.4592L46.4592 70H47.7245V68.7347Z' fill='black' />
    <path d='M52.7857 68.7347H51.5204V70H52.7857V68.7347Z' fill='black' />
    <path d='M55.3163 68.7347L54.051 68.7347V70H55.3163V68.7347Z' fill='black' />
    <path d='M56.5816 68.7347H55.3163V70H56.5816V68.7347Z' fill='black' />
    <path d='M57.8469 68.7347L56.5816 68.7347V70H57.8469V68.7347Z' fill='black' />
    <path d='M59.1122 68.7347L57.8469 68.7347V70H59.1122L59.1122 68.7347Z' fill='black' />
    <path d='M62.9082 68.7347H61.6429V70H62.9082V68.7347Z' fill='black' />
    <path d='M66.7041 68.7347H65.4388V70H66.7041L66.7041 68.7347Z' fill='black' />
    <path d='M67.9694 68.7347H66.7041L66.7041 70H67.9694V68.7347Z' fill='black' />
    <path d='M70.5 68.7347H69.2347V70H70.5V68.7347Z' fill='black' />
  </svg>
);
const Memo = memo(Frame1686553372Icon);
export { Memo as Frame1686553372Icon };
