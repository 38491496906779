import React, { useState } from "react";
import { WindIcon } from "../../../Icons/WindIcon";
import { SparkIcon } from "../../../Icons/SparkIcon";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../../../service/apiService";
import { EyeOffIcon } from "lucide-react";
import { EyeIcon } from "lucide-react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [visible, setVisible] = useState(false);

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      let body = {
        email,
        // otp,
        password,
      };
      const response = await apiServiceHandler(
        "POST",
        "api/auth/forgot/verify",
        body
      );

      const { status, message, secret } = response;

      if (status) {
        // Redirect to login page
        toast.success("Password set successfully");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while verifying OTP");
    }
  };

  const navigateToOtp = () => {
    if (email && password) {
      navigate("/forgot-password-otp");
    } else {
      toast.error("Please fill all the fields");
    }
  };

  return (
    <div className="flex">
      <ToastContainer />
      <div className="pr-9 pl-20 h-screen bg-[#142157] w-[50%] max-md:px-5">
        <div className="font-sans flex text-3xl non-italic font-medium text-[rgba(255,255,255,0.75)] leading-10 h-fit w-[-webkit-fill-available] absolute top-[250px]">
          <div className="w-[24.4444px] h-5 absolute top-[-60px] left-[-60px] transform-[rotate(-53.559deg)]">
            <SparkIcon className="w-full h-full" />
          </div>
          <span>Find your next cotraveller</span>
          <div className="w-[24.4444px] h-5 absolute top-3 left-64">
            <WindIcon className="w-full h-full" />
          </div>
        </div>
        <div className="flex flex-col absolute top-[300px]">
          <div className="text-white font-sans text-6xl non-italic font-semibold leading-[70px]">
            With
          </div>
          <div className="text-white font-sans text-6xl non-italic font-semibold leading-[70px]">
            ShareWheelz
          </div>
        </div>
        <div className="text-[rgba(255,255,255,0.60)] font-sans text-xl non-italic font-normal leading-8 absolute top-[450px] text-wrap w-[40%]">
          Reach your destination in a faster, safer, cheaper, and more
          comfortable way with ShareWheelz
        </div>
        <div className="flex gap-16 max-md:flex-col max-md:gap-0 absolute left-[-30px]">
          <div className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
            <div className="flex gap-4 text-xs leading-4 text-right text-white mt-[750px] max-md:mt-10">
              <div className="grow">© 2024 — Copyright</div>
              <Link to="/privacy">
                <div className="underline">Privacy Policies</div>
              </Link>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/74c70cb46eadbe8a03379d7b9d65707cb85c9f0a653a064eb7acf7edefd775c7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
              className="w-full aspect-square mt-[550px] max-md:mt-10"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-100 w-[50%] flex flex-col justify-center items-center">
        <div className="w-[620px] h-[480px] gap-6 bg-white p-[56px] rounded-xl">
          <div className="text-[#667085] text-xs non-italic font-medium leading-5">
            RESET PASSWORD
          </div>
          <div className="text-[#101828] font-sans non-italic text-xl font-medium leading-8">
            Enter Email and New Password
          </div>
          <form>
            <div className="mt-10 flex flex-col items-start gap-[7px]">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                Email Address{" "}
                <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">
                  *
                </span>
              </div>
              <input
                className="w-[508px] h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {/* <div className="mt-4 flex flex-col items-start gap-[7px]">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">OTP <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">*</span></div>
              <input className="w-[508px] h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4" type="text" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} required />
            </div> */}
            <div className="mt-4 flex flex-col items-start gap-[7px]">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                New Password{" "}
                <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">
                  *
                </span>
              </div>

              <div className="flex border-2 border-[#EAECF0] text-gray-900 rounded-md w-full p-2.5 ">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="outline-none border-[#EAECF0] text-gray-900 rounded-md block w-full"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="cursor-pointer"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? <EyeIcon /> : <EyeOffIcon />}
                </span>
              </div>
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}

            <button
              type="submit"
              className="bg-[#403ADD] w-[508px] h-[46px] mt-6 rounded-md text-white font-sans text-base non-italic font-medium leading-[28px]"
              onClick={navigateToOtp}
            >
              SEND OTP
            </button>
          </form>
          <div className="mt-5 flex flex-row justify-between items-center">
            <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
              Already have an account?{" "}
              <Link to="/login" className="underline text-[#667085]">
                Log in
              </Link>
            </div>
            <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
              Create an account?{" "}
              <Link to="/signup" className="underline text-[#667085]">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
