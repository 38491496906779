import { memo } from 'react';

const DashiconsSearchIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.1401 4.17939C13.0313 5.07159 13.589 6.24273 13.7201 7.4969C13.8511 8.75108 13.5475 10.0122 12.8601 11.0694C12.9801 11.1694 13.0801 11.2794 13.2201 11.3794C13.4201 11.5394 13.6901 11.7394 14.0301 11.9694C14.3701 12.2094 14.5901 12.3594 14.6901 12.4394C15.1101 12.7494 15.4201 13.0094 15.6301 13.2194C15.9501 13.5394 16.2301 13.8694 16.4701 14.2194C16.7201 14.5694 16.9101 14.9094 17.0601 15.2594C17.2001 15.6094 17.2701 15.9394 17.2401 16.2594C17.2201 16.5794 17.1001 16.8494 16.8801 17.0694C16.6601 17.2894 16.3901 17.4094 16.0701 17.4294C15.7601 17.4494 15.4201 17.3894 15.0801 17.2394C14.7301 17.0994 14.3801 16.8994 14.0401 16.6494C13.6901 16.4094 13.3601 16.1294 13.0401 15.8094C12.8301 15.5994 12.5701 15.2894 12.2701 14.8794C12.1701 14.7494 12.0201 14.5294 11.8001 14.2194C11.5801 13.8994 11.4001 13.6494 11.2401 13.4394C11.0801 13.2394 10.9501 13.0894 10.8001 12.9394C9.76217 13.4829 8.57785 13.6809 7.41954 13.5046C6.26124 13.3284 5.18943 12.787 4.36013 11.9594C2.22013 9.80939 2.22013 6.31939 4.36013 4.17939C4.87075 3.66815 5.47714 3.26258 6.14461 2.98587C6.81209 2.70916 7.52757 2.56673 8.25013 2.56673C8.97269 2.56673 9.68816 2.70916 10.3556 2.98587C11.0231 3.26258 11.6295 3.66815 12.1401 4.17939V4.17939ZM10.7301 10.5394C11.3834 9.88132 11.75 8.99166 11.75 8.06439C11.75 7.13711 11.3834 6.24745 10.7301 5.58939C10.4055 5.26375 10.0197 5.00538 9.595 4.82909C9.17029 4.6528 8.71497 4.56206 8.25513 4.56206C7.79528 4.56206 7.33996 4.6528 6.91525 4.82909C6.49055 5.00538 6.1048 5.26375 5.78013 5.58939C5.45449 5.91406 5.19612 6.29981 5.01983 6.72451C4.84354 7.14922 4.7528 7.60454 4.7528 8.06439C4.7528 8.52423 4.84354 8.97955 5.01983 9.40426C5.19612 9.82897 5.45449 10.2147 5.78013 10.5394C6.1048 10.865 6.49055 11.1234 6.91525 11.2997C7.33996 11.476 7.79528 11.5667 8.25513 11.5667C8.71497 11.5667 9.17029 11.476 9.595 11.2997C10.0197 11.1234 10.4055 10.865 10.7301 10.5394V10.5394Z'
      fill='white'
    />
  </svg>
);
const Memo = memo(DashiconsSearchIcon);
export { Memo as DashiconsSearchIcon };
