import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinkedInSvg from '../assets/linkedin.svg'

const CarPoolProfile = ({ carPoolRef, setCarPoolOpen }) => {
  return (
    <>
      <ToastContainer />
      <div
        id="timeline-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div
          className="flex flex-col px-6 pb-6 bg-white rounded-xl md:max-w-[480px] w-[95%] max-md:px-5"
          ref={carPoolRef}
        >
          <div className="mt-4 flex flex-col gap-2 items-center justify-center">
            <img
              class="w-[72px] h-[72px] rounded-full"
              src="https://i2.pickpik.com/photos/711/14/431/smile-profile-face-male-preview.jpg"
              alt="Rounded avatar"
            />
            <div className="font-semibold text-lg text-[#262626]">Richie Rich</div>
            <div className="font-medium text-sm text-center text-[#666666]">Pool Creator</div>
          </div>

          <div className="flex justify-center gap-2 mt-6">
            <img src={LinkedInSvg} />
            <span className="font-medium text-base text-black text-center">Connect Now</span>
          </div>

          <button className="w-[-webkit-fill-available] cursor-pointer" onClick={() => setCarPoolOpen(false)}>
            <div className="justify-center px-2.5 py-3.5 mt-6 text-sm font-medium text-center text-white bg-indigo-700 rounded-md max-md:max-w-full cursor-pointer">
              Got it
            </div>{" "}
          </button>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default CarPoolProfile;
