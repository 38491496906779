import React from "react";

const Card = ({name, url, description}) => {
  return (
    <div className="mx-2 bg-white rounded-xl shadow-md md:border-none border border-gray-200 h-full px-4">
      <div className="flex flex-col p-2">
        <div className="flex items-center px-2 gap-2">
          {/* <img
            className="h-11 w-11 rounded-full md:rounded-full"
            src={url}
            alt="Profile"
          /> */}
          <div className="rounded-full bg-gray-200 h-11 w-11"></div>
          <div className="uppercase tracking-wide text-sm text-[#323232] font-semibold">
            {name}
          </div>
        </div>
        <div className="p-2">
          <p className="mt-1 text-[#161616D1]">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
