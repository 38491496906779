import React from 'react';
import './InformationSecurityPolicy.css';

const InformationSecurityPolicy = () => {
  return (
    <div className="information-security-policy">
      <h1>Information Security Policies</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          At ShareWheelz, protecting your information is our top priority. These Information Security Policies outline the measures we take to ensure the security and integrity of your personal data.
        </p>
      </section>

      <section>
        <h2>Data Protection</h2>
        <p>
          We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes encryption, firewalls, and secure server infrastructure.
        </p>
      </section>

      <section>
        <h2>Access Control</h2>
        <p>
          Access to personal data is restricted to authorized personnel only. We enforce strict access control measures to ensure that only individuals with a legitimate need to access data can do so.
        </p>
      </section>

      <section>
        <h2>Data Encryption</h2>
        <p>
          All sensitive data transmitted between your device and our servers is encrypted using Secure Sockets Layer (SSL) technology. Additionally, we encrypt personal data stored on our servers.
        </p>
      </section>

      <section>
        <h2>Incident Response</h2>
        <p>
          We have a comprehensive incident response plan to address any security breaches promptly. In the event of a data breach, we will notify affected users and take appropriate measures to mitigate the impact.
        </p>
      </section>

      <section>
        <h2>Employee Training</h2>
        <p>
          Our employees undergo regular training on information security best practices. We emphasize the importance of protecting user data and maintaining the highest security standards.
        </p>
      </section>

      <section>
        <h2>Data Retention</h2>
        <p>
          We retain personal data only as long as necessary to fulfill the purposes for which it was collected, or as required by law. Once the data is no longer needed, we securely delete or anonymize it.
        </p>
      </section>

      <section>
        <h2>Third-Party Services</h2>
        <p>
          We carefully select third-party service providers who adhere to high security standards. We require these providers to implement adequate security measures to protect your data.
        </p>
      </section>

      <section>
        <h2>Compliance</h2>
        <p>
          We comply with all applicable data protection and privacy laws. Our security practices are regularly reviewed and updated to ensure compliance with legal and regulatory requirements.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our Information Security Policies, please contact us at:
        </p>
        <ul>
          <li>Email: support@sharewheelz.com</li>
          <li>Address: 150, Jamuna Vihar,Khatauli,Khatauli </li>
        </ul>
      </section>
    </div>
  );
};

export default InformationSecurityPolicy;
