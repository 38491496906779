import { memo } from 'react';

const ItemIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width={50} height={50} rx={25} stroke='black' strokeOpacity={0.5} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31 17H19C17.897 17 17 17.897 17 19V31C17 32.103 17.897 33 19 33H25V27.5H23V25H25V23C25 22.2044 25.3161 21.4413 25.8787 20.8787C26.4413 20.3161 27.2044 20 28 20H30V22.5H29C28.448 22.5 28 22.448 28 23V25H30.5L29.5 27.5H28V33H31C32.103 33 33 32.103 33 31V19C33 17.897 32.103 17 31 17Z'
      fill='black'
      fillOpacity={0.7}
    />
  </svg>
);
const Memo = memo(ItemIcon);
export { Memo as ItemIcon };
