import { useEffect } from 'react';

const ChatwootWidget = () => {
  useEffect(() => {
    (function(d, t) {
      var BASE_URL = "https://app.chatwoot.com";
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function() {
        window.chatwootSDK.run({
          websiteToken: '31pC9PpKSbk4aEeV9z8wtYBi',
          baseUrl: BASE_URL
        });
      };
    })(document, "script");
  }, []);

  return null; // This component doesn't need to render anything
};

export default ChatwootWidget;
