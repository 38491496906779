import React from "react";
import { Vector1Icon } from "../Icons/Vector1Icon";
import giftPic from "../assets/gift1.jpg";
import { ReferAFriendIcon } from "../Icons/ReferAFriendIcon";
import { VectorIcon6 } from "../Icons/VectorIcon6";
import ReferModal from "./ReferModal";

const ReferFriends = ({setReferOpen}) => {
  return (
    
    <div className="absolute md:w-[880px] w-screen h-[223px] flex items-start bg-[#c5d5ff] overflow-hidden -translate-x-2/4 md:rounded-xl left-2/4 md:top-[4165px] top-[5580px]" style={window.screen.width < 400 ? {top: '5590px'} : {}}>
      {window.screen.width > 768 && <div className="absolute right-[-259.0167px] top-[calc(50%_-_560.8949px)] w-[396.5169px] h-[297.5255px] overflow-visible -translate-y-2/4">
        <VectorIcon6 className="w-full h-full" />
      </div>}
      {window.screen.width > 768 && <div className="absolute w-[196px] h-[196px] left-[691px] top-[42px]">
        <ReferAFriendIcon className="w-full h-full" />
      </div>}
      <div className="absolute w-min h-min flex flex-col items-start gap-4 top-6 left-6">
        <div className="w-min h-min flex-col items-start gap-1.5">
          <div className="w-min h-min flex-col items-start gap-0.5">
            <div className="w-min h-min flex place-content-center items-start gap-px">
              <div className="text-[#2a2b3d] text-sm font-medium w-min h-min whitespace-nowrap flex-col font-sans">
                Refer friends
              </div>
              <div
                className="w-[17.1429px] h-4 bg-center bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${giftPic})` }}
              ></div>
            </div>
            <div className="text-[#2a2b3d] text-xl font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
            Receive some exciting goodies!
            </div>
          </div>
          <div className="text-[#2a2b3dcc] text-base leading-[22px] w-[446px] h-min flex-col font-sans" style={window.screen.width < 640 ? {textWrap: 'balance'}: {}}>
            Share the ShareWheelz app with your friends and we’ll send you goodies! Share now and enjoy the rewards.
          </div>
        </div>
        <button className="relative w-[109px] h-8 place-content-center items-center gap-1.5 bg-[#3d67d6] overflow-hidden p-2 rounded-lg" onClick={() => setReferOpen(true)}>
          <div className="text-white text-sm leading-4 font-medium absolute w-min h-min text-center items-center whitespace-nowrap flex-col left-2 top-2 font-sans">
            Refer Now
          </div>
          <div className="absolute w-4 max-w-full h-[1.5px] max-h-full overflow-visible left-[85px] top-4">
            <Vector1Icon className="w-full h-full" />
          </div>
        </button>
      </div>
      <div className="text-[#2a2b3dbf] text-[10px] leading-[10px] absolute w-min h-min whitespace-nowrap flex-col left-6 top-[189px] font-sans">
        *Terms &amp; Conditions Applied
      </div>
    </div>
  );
};

export default ReferFriends;
