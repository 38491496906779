import { memo } from 'react';

const VectorIcon3 = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 423 216' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M293.295 1.67069L212.233 199.313L127.963 2.94253L0.277466 3.93087L90.7204 214.659L334.013 212.776L420.967 0.674559L293.295 1.6628L293.295 1.67069Z'
        fill='#F6F6F6'
      />
    </g>
  </svg>
);
const Memo = memo(VectorIcon3);
export { Memo as VectorIcon3 };
