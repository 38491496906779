import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import referSvg from "../assets/Group.svg";
import giftSvg from "../assets/gift.svg";
import imageSvg from "../assets/image.svg";
import taxiSvg from "../assets/taxi.svg";
import shareSvg from "../assets/share.svg";
import { useNavigate } from "react-router-dom";

const ReferModal = ({ referModalRef, setReferEmailOpen, setReferOpen }) => {
  const navigate = useNavigate();
  
  const referFriendNow = () => {
    const token = localStorage.getItem("JWT_TOKEN");
    if(!token) {
      toast.error("Please log in to use this feature.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setReferEmailOpen(true);
      setReferOpen(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        id="timeline-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="justify-center items-center rounded-xl flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div
          className="flex flex-col pb-6 bg-white rounded-xl md:max-w-[800px] w-[95%] max-w-[700px] mx-auto"
          ref={referModalRef}
        >
          <div className="w-full md:w-[700px] h-[140px] bg-[#DAE6FB] rounded-t-xl relative px-4 py-2">
            <h2 className="font-semibold text-xl md:text-3xl text-[#2A2B3D]">
              Earn goodies when you invite a friend to ShareWheelz.
            </h2>
            <div className="absolute top-0 right-0 md:left-[500px]">
              <img className="w-24 h-24 md:w-48 md:h-48" src={referSvg} />
            </div>
            <p className="text-[rgba(42, 43, 61, 0.25)] text-xs md:text-sm">
              Works if someone books a ride by your invite.
            </p>
          </div>

          <div className="mt-6 w-full md:w-[70%] m-auto px-4 md:px-0">
            <div className="flex flex-col gap-3">
              <div className="text-lg md:text-2xl font-bold">How it Works?</div>
              <div className="flex flex-col">
                <div className="flex items-start gap-3 text-sm md:text-lg">
                  <img className="w-4 h-4 md:w-6 md:h-6" src={shareSvg} />
                  <span>Invite your friend</span>
                </div>
                <div className="border-l-2 border-gray-500 h-8 my-2 ml-4"></div>

                <div className="flex items-start gap-3 text-sm md:text-lg">
                  <img className="w-4 h-4 md:w-6 md:h-6" src={imageSvg} />
                  <span>Friend will create an account on ShareWheelz</span>
                </div>
                <div className="border-l-2 border-gray-500 h-8 my-2 ml-4"></div>

                <div className="flex items-start gap-3 text-sm md:text-lg">
                  <img className="w-4 h-4 md:w-6 md:h-6" src={taxiSvg} />
                  <span>Your friend tries out ShareWheelz.</span>
                </div>
                <div className="border-l-2 border-gray-500 h-8 my-2 ml-4"></div>

                <div className="flex items-start gap-3 text-sm md:text-lg">
                  <img className="w-4 h-4 md:w-6 md:h-6" src={giftSvg} />
                  <span>You & friend get exciting rewards</span>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <button className="w-full md:w-[80%]" onClick={() => referFriendNow()}>
              <div className="justify-center w-full px-2.5 py-3.5 mt-6 text-base font-medium text-center text-white bg-indigo-700 rounded-md cursor-pointer">
                REFER FRIENDS NOW
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};


export default ReferModal;
