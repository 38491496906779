import { memo } from 'react';

const Vector1Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 16 1.5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.5303 0.53033C16.8232 0.237437 16.8232 -0.237437 16.5303 -0.53033L11.7574 -5.3033C11.4645 -5.59619 10.9896 -5.59619 10.6967 -5.3033C10.4038 -5.01041 10.4038 -4.53553 10.6967 -4.24264L14.9393 0L10.6967 4.24264C10.4038 4.53553 10.4038 5.01041 10.6967 5.3033C10.9896 5.59619 11.4645 5.59619 11.7574 5.3033L16.5303 0.53033ZM0 0.75H16V-0.75H0V0.75Z'
      fill='white'
    />
  </svg>
);
const Memo = memo(Vector1Icon);
export { Memo as Vector1Icon };
