import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive } from "../data";
import Card from "./Card";
import apiServiceHandler from "../service/apiService";

const Users = ({ accordionOpen }) => {
  const [testimonial, setTestimonial] = useState([]);
  const [userReview, setUserReview] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const fetchTestiMonials = async () => {
    const response = await apiServiceHandler("GET", "api/testimonial");

    if (response.status) {
      setTestimonial(response.data);
    }

    let user = response.data.map((user) => (
      <Card key={user.name} name={user.name} url={user.image} description={user.content} />
    ));

    setUserReview(user);
  };

  useEffect(() => {
    fetchTestiMonials();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [accordionOpen]);

  return (
    <div
      className="absolute left-0 flex w-screen h-min flex-col justify-center items-center gap-10"
      style={{
        top: window.innerWidth < 400
          ? accordionOpen ? "4050px" : "3800px"
          : window.innerWidth >= 400 && window.innerWidth < 768
            ? accordionOpen ? "4200px" : "3930px"
            : accordionOpen ? "3050px" : "3030px"
      }}
    >
      <div className="w-min h-min flex flex-col items-center md:gap-8 gap-16 mb-5 md:mb-0">
        <div className="md:relative md:w-[823px] w-full h-10">
          <div className="text-black md:text-[40px] text-[30px] md:text-nowrap text-wrap leading-10 font-bold absolute md:w-min w-full h-min text-center items-center whitespace-nowrap flex-col inset-0 font-sans">
            See what others are saying about us
          </div>
        </div>
      </div>
      {!isMobile ? (
        <Carousel
          containerClass="w-[80%] flex justify-center"
          responsive={responsive}
          centerMode={true}
          slidesToSlide={2}
          infinite={true}
        >
          {userReview}
        </Carousel>
      ) : (
        <>
          {accordionOpen ? testimonial.slice(0, 3).map((e, index) => (
            <Card
              key={index}
              name={e.name}
              url={e.image}
              description={e.content}
            />
          )) : testimonial.map((e, index) => (
            <Card
              key={index}
              name={e.name}
              url={e.image}
              description={e.content}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Users;
