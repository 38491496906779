import React, { useState } from "react";
import { SparkIcon } from "../../../../Icons/SparkIcon";
import { Vector1 } from "../../../../Icons/Vector1";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../../../../service/apiService";

const SignUpWithGoogleMobile = () => {
  const [formData, setFormData] = useState({
    contactNumber: "",
    gender: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const genderOptions = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { contactNumber, gender } = formData;

    try {
      let body = {
        contactNumber,
        gender,
      };

      const response = await apiServiceHandler(
        "POST",
        "api/auth/send/email/otp",
        body
      );

      if (response.status) {
        toast.success("OTP sent successfully!");
        setTimeout(() => {
          navigate("/signup-otp", {
            state: {
              //   email,
              jwtSecret: response.secret,
            },
          });
        }, 2000); // Navigate after 2 seconds
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again later.");
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="w-screen h-auto flex flex-col justify-center items-center">
          <div className="w-screen bg-white px-[10px] py-[56px] rounded-xl overflow-y-scroll">
            <div className="text-[#667085] text-xs non-italic font-medium leading-5">
              WELCOME!
            </div>
            <div className="text-[#101828] font-sans non-italic text-xl font-medium leading-8">
              Create Your Account
            </div>
            <div className="mt-10 flex flex-col items-start gap-[7px]">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                Gender
                <span className="text-[#DC2626] text-sm font-normal non-italic leading-5 w-[7px]">
                  *
                </span>
              </div>
              <select
                className="w-full h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4 text-gray-700"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1 mt-3">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                Contact Number
              </div>
              <input
                className="w-full h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4"
                type="number"
                placeholder="Enter 10 digits only"
                name="contactNumber"
                pattern="[0-9]{10}"
                value={formData.contactNumber}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1 mt-5">
              {message && (
                <div className="text-center text-green-500 font-sans text-sm non-italic font-normal leading-5">
                  {message}
                </div>
              )}
            </div>
            <div className="flex w-full px-[28px] py-[14px] justify-center items-center gap-2 bg-[#403ADD] rounded-md mt-10">
              <button
                className="text-white text-center"
                // onClick={handleOtpVerification}
              >
                Verify & Create Account
              </button>
              <div className="text-white">
                <Vector1 />
              </div>
            </div>
            <div className="text-[#344054] text-sm font-medium leading-4 mt-5 flex justify-center items-center">
              Don't have an account?{" "}
              <span className="text-[#403ADD] ml-1">
                <Link to="/signup">Create Account</Link>
              </span>
            </div>
          </div>
          <div className="relative h-[42vh] bg-[#142157] w-full mt-24">
            <div className="font-sans h-fit flex flex-col sm:flex-row text-3xl non-italic font-medium text-[rgba(255,255,255,0.75)] leading-10 absolute top-[20%] left-[12%]">
              <div className="w-[24.4444px] h-5 absolute top-[-60px] left-[-60px] transform-[rotate(-53.559deg)]">
                <SparkIcon className="w-full h-full" />
              </div>
              <span className="flex-shrink-0">Find your next cotraveller</span>
              <div className="w-[24.4444px] h-5 absolute top-3 left-64"></div>
            </div>
            <div className="flex flex-col absolute top-[40%] sm:top-[35%] left-[12%] mt-6 sm:mt-0">
              <div className="text-white font-sans text-2xl non-italic font-semibold leading-[30px]">
                With
              </div>
              <div className="text-white font-sans text-2xl non-italic font-semibold leading-[30px]">
                ShareWheelz
              </div>
            </div>
            <div className="flex w-full absolute bottom-[10%]">
              <div className="flex w-full justify-center">
                <div className="flex gap-4 text-xs leading-4 text-right text-white">
                  <div className="grow">© 2024 — Copyright</div>
                  <Link to="/privacy">
                    <div className="underline">Privacy Policies</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUpWithGoogleMobile;
