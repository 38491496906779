import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Login from "./Components/Auth/Login/Login";
import LoginMobile from "./Components/Auth/Login/Mobile/Login";
import SignUp from "./Components/Auth/SignUp/SignUp";
import SignUpMobile from "./Components/Auth/SignUp/Mobile/SignUp";
import SignupOtp from "./Components/Auth/SignUp/SignupOtp";
import SignupOtpMobile from "./Components/Auth/SignUp/Mobile/SignupOtp";
import ForgotPassword from "./Components/Auth/Login/ForgotPassword";
import ForgotPasswordMobile from "./Components/Auth/Login/Mobile/ForgotPassword";
import Rides from "./Components/Rides";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.js";
import InformationSecurityPolicy from "./Components/InformationSecurityPolicy/InformationSecurityPolicy";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import RideRouteAccess from "./routes/rideRouteAccess";
import { useDispatch } from "react-redux";
import { resetRideData } from "./store/rideSlice";
import Chat from "./Components/Chat";
import MobileChat from "./Components/MobileChat";
import Navbar from "./Components/Navbar";
import { useNavigate, Navigate } from "react-router-dom";
import ForgotPasswordMobileOtp from "./Components/Auth/Login/Mobile/ForgotPasswordOtp.js";
import ForgotPasswordOtp from "./Components/Auth/Login/ForgotPasswordOtp.js";
import SignUpWithGoogle from "./Components/Auth/SignUp/SignUpWithGoogle.js";
import SignUpWithGoogleMobile from "./Components/Auth/SignUp/Mobile/SignUpWithGoogleMobile.js";
import { useSelector } from "react-redux";

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfileModalRef = useRef();
  const googleLogin = useSelector(state => state.ride.googleLogin)

  const [userProfileOpen, setUserProfileOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const shouldNotShowNavbar = ![
    "/login",
    "/signup",
    "/signup-otp",
    "/forgot-password",
    "/forgot-password-otp",
    "/signup-with-google",
  ].includes(pathname);
  const newUserId = localStorage.getItem("userIdWheelz");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pathname === "/rides") {
      dispatch(resetRideData({}));
    }
  }, [pathname]);

  const rideRef = useRef(null);
  const referRef = useRef(null);
  const offerRef = useRef(null);
  const workRef = useRef(null);

  const scrollToSection = (elementRef) => {
    if (pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        window.scrollTo({
          top: elementRef.current.offsetTop - 100,
          behavior: "smooth",
        });
      }, 200);
    } else {
      window.scrollTo({
        top: elementRef.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      {shouldNotShowNavbar && (
        <Navbar
          rideRef={rideRef}
          referRef={referRef}
          offerRef={offerRef}
          workRef={workRef}
          scrollToSection={scrollToSection}
          setUserProfileOpen={setUserProfileOpen}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              rideRef={rideRef}
              referRef={referRef}
              offerRef={offerRef}
              workRef={workRef}
              scrollToSection={scrollToSection}
              setUserProfileOpen={setUserProfileOpen}
              userProfileOpen={userProfileOpen}
            />
          }
        />
        <Route
          path="/login"
          element={
            (newUserId && googleLogin) ? (
              <Navigate to="/" />
            ) : isMobile ? (
              <LoginMobile />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/signup"
          element={
            newUserId ? (
              <Navigate to="/" />
            ) : isMobile ? (
              <SignUpMobile />
            ) : (
              <SignUp />
            )
          }
        />
        <Route
          path="/signup-otp"
          element={
            newUserId ? (
              <Navigate to="/" />
            ) : isMobile ? (
              <SignupOtpMobile />
            ) : (
              <SignupOtp />
            )
          }
        />
        <Route
          path="/signup-with-google"
          element={
            newUserId ? (
              <Navigate to="/" />
            ) : isMobile ? (
              <SignUpWithGoogleMobile />
            ) : (
              <SignUpWithGoogle />
            )
          }
        />
        <Route
          path="/forgot-password"
          element={
            newUserId ? (
              <Navigate to="/" />
            ) : isMobile ? (
              <ForgotPasswordMobile />
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path="/forgot-password-otp"
          element={
            newUserId ? (
              <Navigate to="/" />
            ) : isMobile ? (
              <ForgotPasswordMobileOtp />
            ) : (
              <ForgotPasswordOtp />
            )
          }
        />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route
          path="/information/policy"
          element={<InformationSecurityPolicy />}
        />
        <Route
          path="/rides"
          element={
            <RideRouteAccess
              element={
                <Rides
                  userProfileModalRef={userProfileModalRef}
                  setUserProfileOpen={setUserProfileOpen}
                  userProfileOpen={userProfileOpen}
                />
              }
            />
          }
        />
        <Route
          path="/messages"
          element={
            isMobile ? (
              <MobileChat
                userProfileModalRef={userProfileModalRef}
                setUserProfileOpen={setUserProfileOpen}
                userProfileOpen={userProfileOpen}
              />
            ) : (
              <Chat
                userProfileModalRef={userProfileModalRef}
                setUserProfileOpen={setUserProfileOpen}
                userProfileOpen={userProfileOpen}
              />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
