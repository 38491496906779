import React from "react";
import { ComfortIcon } from "../Icons/ComfortIcon";
import { SafeIcon } from "../Icons/SafeIcon";
import { DiscountIcon } from "../Icons/DiscountIcon";
import { FlashIcon } from "../Icons/FlashIcon";
import FramePic from "../assets/frame1686553384.jpg";

const AboutCompany = ({offerRef}) => {
  return (
    <>
      <div
      ref={offerRef}
        className="origin-top-left absolute md:left-[-506px] left-0 md:skew-y-0 -skew-y-12 md:w-[2294px] w-screen overflow-hidden h-[750.86px] bg-[#c5d5ff] md:top-[1493.2544px] top-[2750.2544px]" 
        style={ window.screen.width > 768 ? { transform: "matrix(0.9916, -0.1291, 0.1291, 0.9916, 0, 0)" } : window.screen.width < 400 ? { top: '2590px' }: {}}
      ></div>
      {window.screen.width> 768 && <div className="origin-top-left absolute left-[-410.74px] w-[2294px] h-[832.5106px] bg-[#c5d5ff] top-[3524.5391px]" style={{transform: 'matrix(0.9916, 0.1291, 0.1291, -0.9916, 0, 0)'}}></div>}
      <div className="absolute w-min overflow-hidden h-min flex flex-col items-start gap-3 md:left-[50px] left-[45px] md:top-[1535px] top-[2760px]" style={window.screen.width < 400 ? {top: '2600px'}: {}}>
        <div className="w-min h-min items-start gap-[5px]">
          <div className="text-black text-2xl leading-[22px] font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
            Now travelling is safer
          </div>
          {/* <Component1_Property1Frame16865 /> */}
        </div>
        <div className="text-black md:text-[40px] text-3xl leading-[48px] font-bold w-min h-min whitespace-nowrap flex-col font-sans">
          With ShareWheelz
        </div>
      </div>
      <div className="absolute flex md:flex-row flex-col w-min overflow-hidden h-min md:items-center items-start md:gap-16 gap-8 left-[50px] md:top-[1649px] top-[2849px]" style={window.screen.width < 400 ? {top: '2690px'}: {}}>
        <div
          className="relative md:w-[568px] w-[300px] md:left-0 left-[-40px] h-[300px] bg-center bg-no-repeat bg-cover overflow-hidden"
          style={{ backgroundImage: `url(${FramePic})` }}
        ></div>
        <div className="w-min h-min flex flex-col items-start md:gap-[65px] gap-[20px]">
          <div className="w-min h-min flex md:flex-row flex-col items-start md:gap-[162px] gap-[20px]">
            <div className="relative w-[145px] h-12">
              <div className="text-[#000000bf] text-base leading-5 font-medium absolute w-min h-min whitespace-nowrap flex-col left-[19.3103%] right-0 top-[58.3333%] bottom-0 font-sans">
                Reach on time
              </div>
              <div className="absolute w-min h-min flex self-start items-center gap-2 left-0 right-[36.5517%] top-0 bottom-[58.3333%]">
                <div className="w-5 h-5">
                  <FlashIcon className="w-full h-full" />
                </div>
                <div className="text-black text-xl leading-5 font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                  Faster
                </div>
              </div>
            </div>
            <div className="relative w-[237px] h-12">
              <div className="text-[#000000bf] text-base leading-5 font-medium absolute w-min h-min whitespace-nowrap flex-col left-[11.8143%] right-0 top-[58.3333%] bottom-0 font-sans">
                Most affordable in market
              </div>
              <div className="absolute w-min h-min flex self-start items-center gap-2 left-0 right-[50.6329%] top-0 bottom-[58.3333%]">
                <div className="w-5 h-5">
                  <DiscountIcon className="w-full h-full" />
                </div>
                <div className="text-black text-xl leading-5 font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                  Cheaper
                </div>
              </div>
            </div>
          </div>
          <div className="w-min h-min flex md:flex-row flex-col items-start md:gap-[79px] gap-[20px]">
            <div className="relative w-[228px] h-12">
              <div className="text-[#000000bf] text-base leading-5 font-medium absolute w-min h-min whitespace-nowrap flex-col left-[12.2807%] right-0 top-[58.3333%] bottom-0 font-sans">
                Your safety is our priority
              </div>
              <div className="absolute w-min h-min flex self-start items-center gap-2 left-0 right-[64.0351%] top-0 bottom-[58.3333%]">
                <div className="w-5 h-5">
                  <SafeIcon className="w-full h-full" />
                </div>
                <div className="text-black text-xl leading-5 font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                  Safer
                </div>
              </div>
            </div>
            <div className="relative w-[214px] h-12">
              <div className="text-[#000000bf] text-base leading-5 font-medium absolute w-min h-min whitespace-nowrap flex-col left-[13.0841%] right-0 top-[58.3333%] bottom-0 font-sans">
                Comfortable like home
              </div>
              <div className="absolute w-min h-min flex self-start items-center gap-2 left-0 right-[26.1682%] top-0 bottom-[58.3333%]">
                <div className="w-5 h-5">
                  <ComfortIcon className="w-full h-full" />
                </div>
                <div className="text-black text-xl leading-5 font-semibold w-min h-min whitespace-nowrap flex-col font-sans">
                  Comfortable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
