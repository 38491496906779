import { memo } from 'react';

const VectorIcon6 = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 397 298' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g
      style={{
        mixBlendMode: 'soft-light',
      }}
    >
      <path
        d='M51.0345 262.327C18.606 248.232 -16.3585 201.949 25.463 163.626C89.9581 104.531 16.5447 47.4193 123.663 25.0277C248.852 -1.1393 376.142 58.1487 381.281 164.866C385.139 244.685 307.289 282.701 285.405 288.352C102.13 268.001 51.0345 262.327 51.0345 262.327Z'
        fill='#573D72'
      />
    </g>
  </svg>
);
const Memo = memo(VectorIcon6);
export { Memo as VectorIcon6 };
