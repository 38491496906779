import { memo } from 'react';

const WindIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_49_1097)'>
      <path
        d='M9.54451 1.76665C9.80263 1.507 10.1209 1.31515 10.471 1.20814C10.8212 1.10114 11.1923 1.0823 11.5515 1.15331C11.9107 1.22431 12.2467 1.38296 12.5298 1.61515C12.8129 1.84733 13.0342 2.14586 13.1741 2.4842C13.314 2.82254 13.3681 3.1902 13.3317 3.5545C13.2953 3.9188 13.1694 4.26846 12.9652 4.57239C12.7611 4.87631 12.485 5.12509 12.1616 5.29661C11.8381 5.46813 11.4773 5.55708 11.1112 5.55554H1.11118M12.8778 18.2333C13.136 18.493 13.4542 18.6848 13.8044 18.7918C14.1545 18.8988 14.5256 18.9177 14.8848 18.8467C15.244 18.7757 15.58 18.617 15.8631 18.3848C16.1462 18.1526 16.3675 17.8541 16.5074 17.5158C16.6473 17.1774 16.7015 16.8098 16.665 16.4455C16.6286 16.0812 16.5027 15.7315 16.2986 15.4276C16.0944 15.1237 15.8184 14.8749 15.4949 14.7034C15.1714 14.5318 14.8106 14.4429 14.4445 14.4444H1.11118M18.589 5.25554C18.9121 4.93319 19.3097 4.69545 19.7467 4.56333C20.1836 4.43122 20.6463 4.40881 21.0939 4.49808C21.5416 4.58736 21.9603 4.78556 22.3131 5.07517C22.6659 5.36478 22.9419 5.73687 23.1167 6.15853C23.2915 6.58019 23.3597 7.03842 23.3152 7.4927C23.2708 7.94698 23.1151 8.38331 22.8619 8.76309C22.6087 9.14288 22.2658 9.45441 21.8635 9.67015C21.4613 9.88588 21.0121 9.99917 20.5556 9.99999H1.11118'
        stroke='white'
        strokeWidth={2.22222}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_49_1097'>
        <rect width={24.4444} height={20} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(WindIcon);
export { Memo as WindIcon };
