import { Navigate } from "react-router-dom";

const RideRouteAccess = ({ element }) => {
  //   let path = window.location.href.split("/")
  //   path = path.includes('rides')
  const isLoggedIn = localStorage.getItem("JWT_TOKEN");
  const user_ride_data = sessionStorage.getItem("user_ride_data");

  return isLoggedIn && user_ride_data ? element : <Navigate to="/" replace />;
};

export default RideRouteAccess;
