import { memo } from 'react';

const RiLinkedinFillIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.20508 3.74977C5.20488 4.1476 5.04665 4.52905 4.76521 4.81021C4.48376 5.09138 4.10215 5.24922 3.70433 5.24902C3.3065 5.24882 2.92505 5.0906 2.64389 4.80915C2.36272 4.52771 2.20488 4.1461 2.20508 3.74827C2.20528 3.35045 2.3635 2.969 2.64495 2.68783C2.92639 2.40667 3.308 2.24882 3.70583 2.24902C4.10365 2.24922 4.4851 2.40745 4.76627 2.68889C5.04743 2.97034 5.20528 3.35195 5.20508 3.74977ZM5.25008 6.35977H2.25008V15.7498H5.25008V6.35977ZM9.99008 6.35977H7.00508V15.7498H9.96008V10.8223C9.96008 8.07727 13.5376 7.82227 13.5376 10.8223V15.7498H16.5001V9.80227C16.5001 5.17477 11.2051 5.34727 9.96008 7.61977L9.99008 6.35977Z'
      fill='black'
      fillOpacity={0.7}
    />
  </svg>
);
const Memo = memo(RiLinkedinFillIcon);
export { Memo as RiLinkedinFillIcon };
