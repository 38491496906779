import React, { useState } from "react";
import DatePickerComponent from "./DatePicker";
import { GgArrowsExchangeIcon } from "../Icons/GgArrowsExchangeIcon";
import { DashiconsSearchIcon } from "../Icons/DashiconsSearchIcon";
import { ReactGoogleMapSearch } from "./ReactGoogleMapSearch";
import { ReactSelect } from "./ReactSelect";

const RideForm = ({handleOpen}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="absolute w-min h-min flex flex-col md:overflow-visible z-10 items-start gap-8 -translate-x-2/4 md:left-2/4 md:top-[3815px] top-[5050px]" style={window.screen.width < 390 ? {top: '5050px'} : {}}>
      <div className="w-max h-min flex flex-col absolute md:left-[-40rem] left-[20px] items-start gap-3" style={window.screen.width < 390 ? {left: '7px'} : {}}>
        <div className="w-min h-min">
          <div className="text-black text-2xl leading-[22px] font-bold w-min h-min whitespace-nowrap flex flex-col font-sans">
            Ready to book your next ride?
          </div>
        </div>
        <div className="text-black text-[40px] leading-[48px] font-bold w-min h-min whitespace-nowrap flex flex-col font-sans">
          With ShareWheelz
        </div>
      </div>
      <div className="absolute md:w-[1264px] w-full md:overflow-visible flex flex-col md:h-32 h-screen md:z-10 items-start bg-white shadow-[0px_1px_43px_0px_#0000001a] -translate-x-2/4 rounded-lg left-2/4 md:top-[150px] top-[100px]">
        <div className="absolute w-min h-min flex-col items-start gap-2 left-6 top-6">
          <div className="text-neutral-800 text-sm leading-4 w-min h-min whitespace-nowrap flex-col">
            Origin
          </div>
          <div className="absolute w-[200px] h-14 -outline-offset-1 rounded left-0 top-6">
            <ReactGoogleMapSearch placeholder="Origin" />
          </div>
        </div>
        <div className="absolute w-min h-min flex-col items-start gap-2 md:left-[304px] left-6 md:top-6 top-28">
          <div className="text-neutral-800 text-sm leading-4 w-min h-min whitespace-nowrap flex-col top-6">
            Destination
          </div>
          <div className="absolute w-[200px] h-14 -outline-offset-1 rounded left-0 top-6">
            <ReactGoogleMapSearch placeholder="Destination" />
          </div>
        </div>
        <div className="absolute w-min h-min items-start gap-6 md:left-[569px] md:top-6 left-6 top-48 flex flex-col md:flex-row">
          <div className="relative w-[200px] flex md:h-20 h-14 items-start gap-2">
            <div className="text-neutral-800 text-sm leading-4 absolute w-min h-min whitespace-nowrap flex-col left-0 top-0">
              Seats Needed
            </div>
            <div className="absolute w-[200px] h-14 -outline-offset-1 rounded left-0 top-6">
              <ReactSelect placeholder="Number of Seats" options={[{value:1 , label: 1}, {value:2 , label: 2}, {value:3 , label:3}, { value:4, label:4}, {value:5, label:5}]} />
            </div>
          </div>
          <div className="relative w-[200px] flex md:h-20 h-16 items-start gap-2">
            <div className="text-neutral-800 text-sm leading-4 absolute w-min h-min whitespace-nowrap flex-col left-0 top-0">
              Date &amp; Time
            </div>

            <div
              className="absolute md:w-[200px] md:h-14 h-8 rounded left-0 top-6 outline-none"
              style={{
                outline: "solid 1px #2626261a",
                width:
                  window.screen.width > 400 && window.screen.width < 768
                    ? `${window.screen.width - 0.15 * window.screen.width}px`
                    : window.screen.width < 400
                    ? `${window.screen.width - 0.18 * window.screen.width}px`
                    : "",
              }}
            >
              <div>
                <DatePickerComponent
                  label="Selected Date"
                  selectedDate={selectedDate}
                  onDateChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute w-8 h-8 md:left-[248px] md:top-[60px] left-[190px] top-[90px] md:rotate-0 rotate-90">
          <GgArrowsExchangeIcon className="w-full h-full" />
        </div>
        <div className="absolute w-px max-w-full h-[132px] max-h-full bg-[#d9d9d9] -translate-y-2/4 left-[536px] top-2/4"></div>
        <button
          className="absolute md:w-[211px] md:h-32 h-10 place-content-center flex items-center gap-3 bg-[#3d67d6] p-4 md:left-[1053px] left-6 md:top-0 top-[350px]"
          style={{
            width:
              window.screen.width > 400 && window.screen.width < 768
                ? `${window.screen.width - 0.15 * window.screen.width}px`
                : window.screen.width < 400
                ? `${window.screen.width - 0.18 * window.screen.width}px`
                : "",
          }}
          onClick={handleOpen}
        >
          <div className="w-5 h-5">
            <DashiconsSearchIcon className="w-full h-full" />
          </div>
          <div className="text-white text-base leading-5 font-semibold w-min h-min whitespace-nowrap flex-col">
            Search Ride
          </div>
        </button>
      </div>
    </div>
  );
};

export default RideForm;
