import { memo } from "react";

const SparkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="106"
    height="116"
    viewBox="0 0 106 116"
    fill="none"
  >
    <g clip-path="url(#clip0_151_1694)">
      <path
        d="M25.6548 65.9226C26.529 66.1655 27.4019 66.4122 28.2694 66.6825C28.7308 66.8203 29.1927 66.9642 29.6619 67.0704C32.1666 67.6346 34.6798 68.1692 37.1707 68.7945C38.7023 69.1867 40.2297 69.5949 41.7531 70.019L44.0359 70.6622L46.3112 71.331C46.768 71.4783 47.2121 71.6623 47.639 71.881C47.8179 71.9627 47.9745 72.086 48.0959 72.2408C48.2173 72.3956 48.2999 72.5774 48.3371 72.7712C48.4518 73.3058 48.0496 73.7237 47.4019 73.6647C46.9817 73.6159 46.5696 73.5146 46.1754 73.3631C45.2705 73.0354 44.355 72.7477 43.4288 72.5001C42.5035 72.2491 41.5679 72.0362 40.6218 71.8614C40.0996 71.7644 39.5481 71.6937 39.0706 71.4918C37.5445 70.8488 35.9114 70.5476 34.3451 70.0347C33.1806 69.6542 31.9854 69.3618 30.8082 69.0163L27.6585 68.095C26.6069 67.7939 25.562 67.4662 24.5107 67.1617C24.1047 67.0315 23.7078 66.8745 23.3228 66.6918C22.963 66.51 22.6138 66.3082 22.2766 66.0873C21.9779 65.8927 22.0558 65.6689 22.4641 65.6474C23.05 65.6178 23.6369 65.6292 24.2205 65.6815C24.4601 65.7027 24.6996 65.738 24.9374 65.7833C25.1728 65.8383 25.409 65.8934 25.6438 65.9483L25.6548 65.9226Z"
        fill="white"
      />
      <path
        d="M39.0957 43.1168L39.9467 43.9046L40.7795 44.7142C41.0747 44.9998 41.3713 45.2869 41.6896 45.5458C42.1133 45.8922 42.5414 46.2334 42.9707 46.5728L44.2523 47.598L46.8188 49.642L49.9726 52.1666L53.1406 54.674C53.4545 54.9368 53.7489 55.2221 54.0217 55.5276C54.1387 55.6482 54.2257 55.7947 54.2759 55.9556C54.326 56.1164 54.3378 56.2871 54.3104 56.454C54.2447 56.916 53.8026 57.1264 53.2983 56.8826C52.974 56.7171 52.6726 56.5102 52.4017 56.267C51.1495 55.1875 49.8299 54.1891 48.4507 53.2776C48.0527 53.0481 47.6824 52.7737 47.3471 52.4597C46.363 51.4259 45.1859 50.6164 44.1469 49.6485C43.3758 48.9289 42.5517 48.264 41.776 47.5472C40.3977 46.2637 39.0331 44.9675 37.7163 43.6212C37.2109 43.1155 36.7931 42.5288 36.4798 41.8848C36.3436 41.6064 36.5198 41.4432 36.8314 41.5977C37.276 41.8225 37.7052 42.0763 38.1162 42.3576C38.4515 42.5965 38.7722 42.8553 39.0767 43.1325L39.0957 43.1168Z"
        fill="white"
      />
      <path
        d="M61.5081 32.3447C61.6663 32.9981 61.8216 33.6525 61.9741 34.3081C62.0401 34.6597 62.1269 35.0069 62.2341 35.3478C62.539 36.2491 62.8639 37.1438 63.1888 38.0378C63.5124 38.9322 63.8301 39.8279 64.133 40.7281C64.5086 41.836 64.8723 42.9474 65.2386 44.058L66.319 47.396C66.4162 47.742 66.4708 48.0989 66.4816 48.4589C66.5047 48.8043 66.2851 49.1101 65.9501 49.3699C65.4621 49.7484 64.81 49.7682 64.5173 49.3646C64.3388 49.1012 64.2171 48.8031 64.1598 48.4891C63.852 47.0805 63.3795 45.7145 62.7522 44.4194C62.5795 44.0595 62.3689 43.6944 62.3329 43.3011C62.2128 42.0472 61.7005 40.9117 61.4492 39.6992C61.2596 38.7996 60.986 37.9211 60.7794 37.0254C60.595 36.2269 60.4168 35.4271 60.2449 34.626L59.7781 32.2104C59.6529 31.581 59.6796 30.9294 59.8562 30.3099C59.8703 30.2463 59.9029 30.1882 59.95 30.1431C59.9971 30.098 60.0564 30.0679 60.1204 30.0566C60.1844 30.0454 60.2501 30.0536 60.3092 30.0801C60.3682 30.1066 60.4178 30.1502 60.4517 30.2054C60.6999 30.5682 60.9264 30.9455 61.1298 31.3356C61.2707 31.6644 61.387 32.0035 61.4779 32.35L61.5081 32.3447Z"
        fill="white"
      />
      <path
        d="M80.0575 39.9673C80.0213 40.1448 79.9916 40.3234 79.9446 40.499L79.8016 41.0257C79.7463 41.2106 79.7066 41.3996 79.6829 41.5909C79.6378 42.1031 79.612 42.6186 79.5579 43.1292C79.5097 43.6408 79.4586 44.152 79.389 44.66C79.3041 45.2858 79.2084 45.9098 79.0921 46.5302L78.7437 48.3917C78.6934 48.5841 78.6079 48.7657 78.4914 48.9271C78.3867 49.0917 78.1165 49.1631 77.7772 49.1774C77.2829 49.1984 76.7934 49.0055 76.7187 48.7332C76.6791 48.5648 76.6939 48.3877 76.7612 48.2275C77.0266 47.4973 77.1453 46.7228 77.1106 45.9488C77.1028 45.7324 77.0618 45.5015 77.1688 45.3109C77.503 44.7008 77.4942 44.0267 77.7016 43.3934C77.8592 42.9234 77.9164 42.4332 78.06 41.9596C78.3121 41.1144 78.5435 40.2659 78.8244 39.426C78.9353 39.0767 79.1515 38.7697 79.443 38.5476C79.4793 38.521 79.5208 38.5026 79.5648 38.4937C79.6087 38.4849 79.6539 38.4857 79.6974 38.4962C79.7408 38.5067 79.7813 38.5266 79.8161 38.5545C79.8509 38.5825 79.8791 38.6177 79.8987 38.6579C79.9376 38.7769 79.9722 38.8979 80.0023 39.0194C80.0342 39.1379 80.056 39.2591 80.0674 39.3815C80.0738 39.5753 80.0625 39.7694 80.0335 39.9615L80.0575 39.9673Z"
        fill="white"
      />
      <path
        d="M37.5289 90.7394C37.925 90.5642 38.3223 90.3934 38.7206 90.227C38.9336 90.1441 39.1416 90.0485 39.3434 89.9406C39.8676 89.6307 40.3962 89.326 40.9311 89.031C41.467 88.7383 42.0136 88.4662 42.5707 88.2148C43.259 87.9129 43.9568 87.6314 44.6652 87.3799C45.3735 87.1281 46.0881 86.8957 46.8091 86.6828C47.0353 86.6272 47.2689 86.6102 47.5001 86.6325C47.7247 86.6424 47.9212 86.8401 48.0847 87.1255C48.3224 87.5414 48.3303 88.0561 48.0821 88.249C47.922 88.3628 47.7357 88.4334 47.5411 88.454C46.6678 88.5817 45.8271 88.882 45.0679 89.3375C44.8557 89.4614 44.648 89.621 44.407 89.6456C43.6335 89.7216 42.9828 90.1359 42.2644 90.3674C41.9976 90.4552 41.7355 90.5574 41.4794 90.6735C41.2209 90.7841 40.9658 90.9026 40.7058 91.0103C39.7789 91.3954 38.8511 91.7906 37.9405 92.229C37.5519 92.4012 37.1193 92.4442 36.7066 92.3517C36.5058 92.3231 36.4033 92.1384 36.4703 91.8936L36.561 91.6607C36.5733 91.6238 36.5927 91.5896 36.6181 91.5601L36.6846 91.4735C36.7733 91.36 36.8686 91.2518 36.9702 91.1497C37.0525 91.0685 37.1432 90.9962 37.2406 90.934C37.3402 90.8767 37.4424 90.8234 37.5407 90.7645L37.5289 90.7394Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_151_1694">
        <rect
          width="83.0475"
          height="82.3253"
          fill="white"
          transform="translate(-10 66.8086) rotate(-53.5588)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SparkIcon);
export { Memo as SparkIcon };
