import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rideData: {},
  totalRides: 1,
  currentGroup: {},
  chatScreen: false,
  currentUserMessages: [],
  currentUserDate: "",
  groupUnreadMessages: 0,
  unreadMessagesCount: {},
  googleLogin: false
};

export const rideSlice = createSlice({
  name: "ride",
  initialState,
  reducers: {
    handleRideData: (state, action) => {
      state.rideData[action.payload[0]] = action.payload[1];
    },
    updateTotalRides: (state, action) => {
      state.totalRides = action.payload;
    },
    resetRideData: (state, action) => {
      state.rideData = action.payload;
    },
    removeRideKey: (state, action) => {
      delete state.rideData[action.payload[0]];
    },
    handleCurrentGroup: (state, action) => {
      state.currentGroup = action.payload;
    },
    showChatScreen: (state, action) => {
      state.chatScreen = action.payload;
    },
    handleCurrentUserDate: (state, action) => {
      if (action.payload == 0 || action.payload == 1) {
        state.currentUserDate = ""
      } else {
        state.currentUserDate = action.payload;
      }
    },
    handleCurrentUserMessages: (state, action) => {
      state.currentUserMessages = action.payload;
    },
    setUnreadMessagesCount(state, action) {
      const { groupId, count } = action.payload;
      state.unreadMessagesCount[groupId] = count;

      let cnt = 0
      Object.values(state.unreadMessagesCount)?.forEach((msgCount) => {
        if (msgCount > 0) {
          cnt += 1
        }
      })

      state.groupUnreadMessages = cnt
      localStorage.setItem('unreadMessagesCount', JSON.stringify(state.groupUnreadMessages));
    },
    handleGoogleLogin: (state, action) => {
      state.googleLogin = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  handleRideData,
  updateTotalRides,
  resetRideData,
  removeRideKey,
  handleCurrentGroup,
  showChatScreen,
  handleCurrentUserMessages,
  handleCurrentUserDate,
  setUnreadMessagesCount,
  handleGoogleLogin
} = rideSlice.actions;

export default rideSlice.reducer;
