import { memo } from 'react';

const GgArrowsExchangeIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.6573 17.3125C6.36767 17.3122 6.08582 17.4062 5.85437 17.5804C5.62292 17.7545 5.45447 17.9992 5.37448 18.2776C5.2945 18.556 5.30733 18.8528 5.41104 19.1233C5.51475 19.3937 5.70369 19.623 5.9493 19.7765L9.5333 23.3605C9.65719 23.4843 9.80423 23.5825 9.96606 23.6494C10.1279 23.7164 10.3013 23.7508 10.4764 23.7508C10.6516 23.7507 10.825 23.7162 10.9868 23.6491C11.1485 23.582 11.2955 23.4837 11.4193 23.3598C11.5431 23.236 11.6413 23.0889 11.7082 22.9271C11.7752 22.7653 11.8096 22.5918 11.8096 22.4167C11.8095 22.2416 11.775 22.0682 11.7079 21.9064C11.6408 21.7446 11.5425 21.5976 11.4186 21.4738L9.92397 19.9805H19.9906C20.3443 19.9805 20.6834 19.84 20.9334 19.59C21.1835 19.3399 21.324 19.0008 21.324 18.6472C21.324 18.2935 21.1835 17.9544 20.9334 17.7044C20.6834 17.4543 20.3443 17.3138 19.9906 17.3138H6.6573V17.3125Z'
      fill='#262626'
    />
    <path
      d='M25.3426 14.6883C25.6323 14.6886 25.9141 14.5945 26.1456 14.4204C26.377 14.2463 26.5455 14.0015 26.6255 13.7232C26.7054 13.4448 26.6926 13.1479 26.5889 12.8775C26.4852 12.6071 26.2962 12.3778 26.0506 12.2243L22.4666 8.64027C22.2164 8.39026 21.8772 8.24988 21.5235 8.25C21.1698 8.25013 20.8306 8.39075 20.5806 8.64094C20.3306 8.89112 20.1902 9.23038 20.1904 9.58407C20.1905 9.93777 20.3311 10.2769 20.5813 10.5269L22.076 12.0203H12.0093C11.6557 12.0203 11.3165 12.1607 11.0665 12.4108C10.8164 12.6608 10.676 13 10.676 13.3536C10.676 13.7072 10.8164 14.0464 11.0665 14.2964C11.3165 14.5465 11.6557 14.6869 12.0093 14.6869H25.3426V14.6883Z'
      fill='#262626'
    />
  </svg>
);
const Memo = memo(GgArrowsExchangeIcon);
export { Memo as GgArrowsExchangeIcon };
