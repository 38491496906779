import { memo } from 'react';

const Frame1686553372Icon2 = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 83 83' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x={0.313051} y={0.0000302376} width={82.3738} height={82.3738} rx={4.2243} fill='white' />
    <path d='M10.0981 8.44854H8.76184V9.78479H10.0981V8.44854Z' fill='black' />
    <path d='M11.4344 8.44854H10.0981V9.78479H11.4344V8.44854Z' fill='black' />
    <path d='M12.7706 8.44854H11.4344V9.78479H12.7706V8.44854Z' fill='black' />
    <path d='M14.1069 8.44854H12.7706V9.78479H14.1069V8.44854Z' fill='black' />
    <path d='M15.4431 8.44854H14.1069V9.78479H15.4431V8.44854Z' fill='black' />
    <path d='M16.7794 8.44854H15.4431V9.78479H16.7794V8.44854Z' fill='black' />
    <path d='M18.1156 8.44854H16.7794V9.78479H18.1156V8.44854Z' fill='black' />
    <path d='M20.7882 8.44854H19.4519V9.78479H20.7882V8.44854Z' fill='black' />
    <path d='M22.1244 8.44854H20.7882V9.78479H22.1244V8.44854Z' fill='black' />
    <path d='M26.1332 8.44854H24.7969V9.78479H26.1332V8.44854Z' fill='black' />
    <path d='M30.142 8.44854H28.8057V9.78479H30.142V8.44854Z' fill='black' />
    <path d='M31.4782 8.44854H30.142V9.78479H31.4782V8.44854Z' fill='black' />
    <path d='M35.487 8.44854H34.1507V9.78479H35.487V8.44854Z' fill='black' />
    <path d='M39.4958 8.44854H38.1595V9.78479H39.4958V8.44854Z' fill='black' />
    <path d='M42.1683 8.44854H40.832V9.78479H42.1683V8.44854Z' fill='black' />
    <path d='M50.1858 8.44854H48.8496V9.78479H50.1858V8.44854Z' fill='black' />
    <path d='M52.8584 8.44854H51.5221V9.78479H52.8584V8.44854Z' fill='black' />
    <path d='M56.8671 8.44854H55.5309V9.78479H56.8671V8.44854Z' fill='black' />
    <path d='M58.2034 8.44854H56.8671V9.78479H58.2034V8.44854Z' fill='black' />
    <path d='M59.5396 8.44854H58.2034V9.78479H59.5396V8.44854Z' fill='black' />
    <path d='M63.5484 8.44854H62.2122V9.78479H63.5484V8.44854Z' fill='black' />
    <path d='M66.2209 8.44854H64.8847V9.78479H66.2209V8.44854Z' fill='black' />
    <path d='M67.5572 8.44854H66.2209V9.78479H67.5572V8.44854Z' fill='black' />
    <path d='M68.8934 8.44854H67.5572V9.78479H68.8934V8.44854Z' fill='black' />
    <path d='M70.2297 8.44854H68.8934V9.78479H70.2297V8.44854Z' fill='black' />
    <path d='M71.566 8.44854H70.2297V9.78479H71.566V8.44854Z' fill='black' />
    <path d='M72.9022 8.44854H71.566V9.78479H72.9022V8.44854Z' fill='black' />
    <path d='M74.2385 8.44854H72.9022V9.78479H74.2385V8.44854Z' fill='black' />
    <path d='M10.0981 9.78479H8.76184V11.1211H10.0981V9.78479Z' fill='black' />
    <path d='M18.1156 9.78479H16.7794V11.1211H18.1156V9.78479Z' fill='black' />
    <path d='M34.1507 9.78479H32.8145V11.1211H34.1507V9.78479Z' fill='black' />
    <path d='M35.487 9.78479H34.1507V11.1211H35.487V9.78479Z' fill='black' />
    <path d='M39.4958 9.78479H38.1595V11.1211H39.4958V9.78479Z' fill='black' />
    <path d='M40.832 9.78479H39.4958V11.1211H40.832V9.78479Z' fill='black' />
    <path d='M47.5133 9.78479H46.1771V11.1211H47.5133V9.78479Z' fill='black' />
    <path d='M56.8671 9.78479H55.5309V11.1211H56.8671V9.78479Z' fill='black' />
    <path d='M58.2034 9.78479H56.8671V11.1211H58.2034V9.78479Z' fill='black' />
    <path d='M60.8759 9.78479H59.5396L59.5396 11.1211H60.8759V9.78479Z' fill='black' />
    <path d='M62.2122 9.78479H60.8759V11.1211H62.2121L62.2122 9.78479Z' fill='black' />
    <path d='M63.5484 9.78479H62.2122L62.2121 11.1211H63.5484V9.78479Z' fill='black' />
    <path d='M66.2209 9.78479H64.8847V11.1211H66.2209V9.78479Z' fill='black' />
    <path d='M74.2385 9.78479H72.9022L72.9022 11.1211H74.2385V9.78479Z' fill='black' />
    <path d='M10.0981 11.1211H8.76184V12.4573H10.0981V11.1211Z' fill='black' />
    <path d='M12.7706 11.1211H11.4344V12.4573H12.7706V11.1211Z' fill='black' />
    <path d='M14.1069 11.1211H12.7706V12.4573H14.1069V11.1211Z' fill='black' />
    <path d='M15.4431 11.1211H14.1069V12.4573H15.4431V11.1211Z' fill='black' />
    <path d='M18.1156 11.1211H16.7794V12.4573H18.1156V11.1211Z' fill='black' />
    <path d='M22.1244 11.1211H20.7882V12.4573H22.1244V11.1211Z' fill='black' />
    <path d='M23.4607 11.1211H22.1244V12.4573H23.4607V11.1211Z' fill='black' />
    <path d='M26.1332 11.1211H24.7969V12.4573H26.1332V11.1211Z' fill='black' />
    <path d='M31.4782 11.1211H30.142V12.4573H31.4782V11.1211Z' fill='black' />
    <path d='M32.8145 11.1211H31.4782V12.4573H32.8145V11.1211Z' fill='black' />
    <path d='M34.1507 11.1211H32.8145V12.4573H34.1507V11.1211Z' fill='black' />
    <path d='M35.487 11.1211H34.1507V12.4573H35.487V11.1211Z' fill='black' />
    <path d='M38.1595 11.1211H36.8233V12.4573H38.1595V11.1211Z' fill='black' />
    <path d='M39.4958 11.1211H38.1595V12.4573H39.4958V11.1211Z' fill='black' />
    <path d='M40.832 11.1211H39.4958V12.4573H40.832V11.1211Z' fill='black' />
    <path d='M42.1683 11.1211H40.832V12.4573H42.1683V11.1211Z' fill='black' />
    <path d='M44.8408 11.1211H43.5045V12.4573H44.8408V11.1211Z' fill='black' />
    <path d='M46.1771 11.1211H44.8408V12.4573H46.1771L46.1771 11.1211Z' fill='black' />
    <path d='M47.5133 11.1211H46.1771L46.1771 12.4573H47.5133V11.1211Z' fill='black' />
    <path d='M54.1946 11.1211H52.8584V12.4573H54.1946V11.1211Z' fill='black' />
    <path d='M55.5309 11.1211H54.1946V12.4573H55.5309L55.5309 11.1211Z' fill='black' />
    <path d='M58.2034 11.1211H56.8671L56.8671 12.4573H58.2034V11.1211Z' fill='black' />
    <path d='M62.2121 11.1211H60.8759L60.8759 12.4573H62.2121V11.1211Z' fill='black' />
    <path d='M63.5484 11.1211H62.2121V12.4573H63.5484V11.1211Z' fill='black' />
    <path d='M66.2209 11.1211H64.8847V12.4573H66.2209V11.1211Z' fill='black' />
    <path d='M68.8934 11.1211H67.5572V12.4573H68.8934V11.1211Z' fill='black' />
    <path d='M70.2297 11.1211H68.8934V12.4573H70.2297V11.1211Z' fill='black' />
    <path d='M71.566 11.1211H70.2297V12.4573H71.566V11.1211Z' fill='black' />
    <path d='M74.2385 11.1211H72.9022V12.4573H74.2385V11.1211Z' fill='black' />
    <path d='M10.0981 12.4573H8.76184V13.7936H10.0981V12.4573Z' fill='black' />
    <path d='M12.7706 12.4573H11.4344V13.7936H12.7706V12.4573Z' fill='black' />
    <path d='M14.1069 12.4573H12.7706V13.7936H14.1069V12.4573Z' fill='black' />
    <path d='M15.4431 12.4573H14.1069V13.7936H15.4431V12.4573Z' fill='black' />
    <path d='M18.1156 12.4573H16.7794V13.7936H18.1156V12.4573Z' fill='black' />
    <path d='M20.7882 12.4573H19.4519V13.7936H20.7882V12.4573Z' fill='black' />
    <path d='M22.1244 12.4573H20.7882V13.7936H22.1244V12.4573Z' fill='black' />
    <path d='M27.4695 12.4573H26.1332V13.7936H27.4695V12.4573Z' fill='black' />
    <path d='M28.8057 12.4573H27.4695V13.7936H28.8057V12.4573Z' fill='black' />
    <path d='M30.142 12.4573H28.8057V13.7936H30.142V12.4573Z' fill='black' />
    <path d='M31.4782 12.4573H30.142V13.7936H31.4782V12.4573Z' fill='black' />
    <path d='M40.832 12.4573H39.4958V13.7936H40.832V12.4573Z' fill='black' />
    <path d='M42.1683 12.4573H40.832V13.7936H42.1683V12.4573Z' fill='black' />
    <path d='M47.5133 12.4573H46.1771L46.1771 13.7936H47.5133V12.4573Z' fill='black' />
    <path d='M48.8496 12.4573H47.5133V13.7936H48.8496V12.4573Z' fill='black' />
    <path d='M50.1858 12.4573H48.8496V13.7936H50.1858V12.4573Z' fill='black' />
    <path d='M52.8584 12.4573H51.5221V13.7936H52.8584V12.4573Z' fill='black' />
    <path d='M54.1946 12.4573H52.8584V13.7936H54.1946V12.4573Z' fill='black' />
    <path d='M58.2034 12.4573H56.8671V13.7936H58.2034V12.4573Z' fill='black' />
    <path d='M62.2121 12.4573H60.8759V13.7936H62.2121V12.4573Z' fill='black' />
    <path d='M66.2209 12.4573H64.8847V13.7936H66.2209V12.4573Z' fill='black' />
    <path d='M68.8934 12.4573H67.5572V13.7936H68.8934V12.4573Z' fill='black' />
    <path d='M70.2297 12.4573H68.8934V13.7936H70.2297V12.4573Z' fill='black' />
    <path d='M71.566 12.4573H70.2297V13.7936H71.566V12.4573Z' fill='black' />
    <path d='M74.2385 12.4573H72.9022V13.7936H74.2385V12.4573Z' fill='black' />
    <path d='M10.0981 13.7936H8.76184V15.1298H10.0981V13.7936Z' fill='black' />
    <path d='M12.7706 13.7936H11.4344V15.1298H12.7706V13.7936Z' fill='black' />
    <path d='M14.1069 13.7936H12.7706V15.1298H14.1069V13.7936Z' fill='black' />
    <path d='M15.4431 13.7936H14.1069V15.1298H15.4431V13.7936Z' fill='black' />
    <path d='M18.1156 13.7936H16.7794V15.1298H18.1156V13.7936Z' fill='black' />
    <path d='M22.1244 13.7936H20.7882V15.1298H22.1244V13.7936Z' fill='black' />
    <path d='M26.1332 13.7936H24.7969V15.1298H26.1332V13.7936Z' fill='black' />
    <path d='M34.1507 13.7936H32.8145V15.1298H34.1507V13.7936Z' fill='black' />
    <path d='M39.4958 13.7936H38.1595V15.1298H39.4958V13.7936Z' fill='black' />
    <path d='M40.832 13.7936H39.4958V15.1298H40.832V13.7936Z' fill='black' />
    <path d='M42.1683 13.7936H40.832V15.1298H42.1683V13.7936Z' fill='black' />
    <path d='M43.5045 13.7936H42.1683V15.1298H43.5045V13.7936Z' fill='black' />
    <path d='M44.8408 13.7936H43.5045V15.1298H44.8408V13.7936Z' fill='black' />
    <path d='M50.1858 13.7936H48.8496L48.8496 15.1298H50.1858V13.7936Z' fill='black' />
    <path d='M55.5309 13.7936H54.1946L54.1946 15.1298H55.5309V13.7936Z' fill='black' />
    <path d='M56.8671 13.7936H55.5309V15.1298H56.8671L56.8671 13.7936Z' fill='black' />
    <path d='M66.2209 13.7936H64.8847V15.1298H66.2209V13.7936Z' fill='black' />
    <path d='M68.8934 13.7936H67.5572V15.1298H68.8934V13.7936Z' fill='black' />
    <path d='M70.2297 13.7936H68.8934V15.1298H70.2297V13.7936Z' fill='black' />
    <path d='M71.566 13.7936H70.2297V15.1298H71.566V13.7936Z' fill='black' />
    <path d='M74.2385 13.7936H72.9022V15.1298H74.2385V13.7936Z' fill='black' />
    <path d='M10.0981 15.1298H8.76184V16.4661H10.0981V15.1298Z' fill='black' />
    <path d='M18.1156 15.1298H16.7794V16.4661H18.1156V15.1298Z' fill='black' />
    <path d='M20.7882 15.1298H19.4519V16.4661H20.7882V15.1298Z' fill='black' />
    <path d='M23.4607 15.1298H22.1244V16.4661H23.4607V15.1298Z' fill='black' />
    <path d='M24.7969 15.1298H23.4607V16.4661H24.7969V15.1298Z' fill='black' />
    <path d='M27.4695 15.1298H26.1332V16.4661H27.4695V15.1298Z' fill='black' />
    <path d='M32.8145 15.1298H31.4782V16.4661H32.8145V15.1298Z' fill='black' />
    <path d='M34.1507 15.1298H32.8145V16.4661H34.1507V15.1298Z' fill='black' />
    <path d='M35.487 15.1298H34.1507V16.4661H35.487V15.1298Z' fill='black' />
    <path d='M36.8233 15.1298H35.487V16.4661H36.8233V15.1298Z' fill='black' />
    <path d='M38.1595 15.1298H36.8233V16.4661H38.1595V15.1298Z' fill='black' />
    <path d='M39.4958 15.1298H38.1595V16.4661H39.4958V15.1298Z' fill='black' />
    <path d='M44.8408 15.1298H43.5045V16.4661H44.8408V15.1298Z' fill='black' />
    <path d='M46.1771 15.1298H44.8408V16.4661H46.1771V15.1298Z' fill='black' />
    <path d='M47.5133 15.1298H46.1771V16.4661H47.5133V15.1298Z' fill='black' />
    <path d='M51.5221 15.1298H50.1858L50.1858 16.4661H51.5221V15.1298Z' fill='black' />
    <path d='M54.1946 15.1298H52.8584V16.4661H54.1946L54.1946 15.1298Z' fill='black' />
    <path d='M56.8671 15.1298H55.5309L55.5309 16.4661H56.8671V15.1298Z' fill='black' />
    <path d='M58.2034 15.1298H56.8671V16.4661H58.2034V15.1298Z' fill='black' />
    <path d='M60.8759 15.1298H59.5396V16.4661H60.8759V15.1298Z' fill='black' />
    <path d='M66.2209 15.1298H64.8847V16.4661H66.2209V15.1298Z' fill='black' />
    <path d='M74.2385 15.1298H72.9022V16.4661H74.2385V15.1298Z' fill='black' />
    <path d='M10.0981 16.4661H8.76184V17.8023H10.0981V16.4661Z' fill='black' />
    <path d='M11.4344 16.4661H10.0981V17.8023H11.4344V16.4661Z' fill='black' />
    <path d='M12.7706 16.4661H11.4344V17.8023H12.7706V16.4661Z' fill='black' />
    <path d='M14.1069 16.4661H12.7706V17.8023H14.1069V16.4661Z' fill='black' />
    <path d='M15.4431 16.4661H14.1069V17.8023H15.4431V16.4661Z' fill='black' />
    <path d='M16.7794 16.4661H15.4431V17.8023H16.7794V16.4661Z' fill='black' />
    <path d='M18.1156 16.4661H16.7794V17.8023H18.1156V16.4661Z' fill='black' />
    <path d='M20.7882 16.4661H19.4519V17.8023H20.7882V16.4661Z' fill='black' />
    <path d='M23.4607 16.4661H22.1244V17.8023H23.4607V16.4661Z' fill='black' />
    <path d='M26.1332 16.4661H24.7969V17.8023H26.1332V16.4661Z' fill='black' />
    <path d='M28.8057 16.4661H27.4695V17.8023H28.8057V16.4661Z' fill='black' />
    <path d='M31.4782 16.4661H30.142V17.8023H31.4782V16.4661Z' fill='black' />
    <path d='M34.1507 16.4661H32.8145V17.8023H34.1507V16.4661Z' fill='black' />
    <path d='M36.8233 16.4661H35.487V17.8023H36.8233V16.4661Z' fill='black' />
    <path d='M39.4958 16.4661H38.1595V17.8023H39.4958V16.4661Z' fill='black' />
    <path d='M42.1683 16.4661H40.832V17.8023H42.1683V16.4661Z' fill='black' />
    <path d='M44.8408 16.4661H43.5045V17.8023H44.8408V16.4661Z' fill='black' />
    <path d='M47.5133 16.4661H46.1771L46.1771 17.8023H47.5133V16.4661Z' fill='black' />
    <path d='M50.1858 16.4661H48.8496V17.8023H50.1858L50.1858 16.4661Z' fill='black' />
    <path d='M52.8584 16.4661H51.5221L51.5221 17.8023H52.8584V16.4661Z' fill='black' />
    <path d='M55.5309 16.4661H54.1946L54.1946 17.8023H55.5309L55.5309 16.4661Z' fill='black' />
    <path d='M58.2034 16.4661H56.8671L56.8671 17.8023H58.2034V16.4661Z' fill='black' />
    <path d='M60.8759 16.4661H59.5396V17.8023H60.8759V16.4661Z' fill='black' />
    <path d='M63.5484 16.4661H62.2122V17.8023H63.5484V16.4661Z' fill='black' />
    <path d='M66.2209 16.4661H64.8847V17.8023H66.2209V16.4661Z' fill='black' />
    <path d='M67.5572 16.4661H66.2209V17.8023H67.5572V16.4661Z' fill='black' />
    <path d='M68.8934 16.4661H67.5572V17.8023H68.8934V16.4661Z' fill='black' />
    <path d='M70.2297 16.4661H68.8934V17.8023H70.2297V16.4661Z' fill='black' />
    <path d='M71.566 16.4661H70.2297V17.8023H71.566V16.4661Z' fill='black' />
    <path d='M72.9022 16.4661H71.566V17.8023H72.9022L72.9022 16.4661Z' fill='black' />
    <path d='M74.2385 16.4661H72.9022L72.9022 17.8023H74.2385V16.4661Z' fill='black' />
    <path d='M22.1244 17.8023H20.7882V19.1386H22.1244V17.8023Z' fill='black' />
    <path d='M24.7969 17.8023H23.4607V19.1386H24.7969V17.8023Z' fill='black' />
    <path d='M30.142 17.8023H28.8057V19.1386H30.142V17.8023Z' fill='black' />
    <path d='M31.4782 17.8023H30.142V19.1386H31.4782V17.8023Z' fill='black' />
    <path d='M34.1507 17.8023H32.8145V19.1386H34.1507V17.8023Z' fill='black' />
    <path d='M35.487 17.8023H34.1507V19.1386H35.487V17.8023Z' fill='black' />
    <path d='M36.8233 17.8023H35.487V19.1386H36.8233V17.8023Z' fill='black' />
    <path d='M39.4958 17.8023H38.1595V19.1386H39.4958V17.8023Z' fill='black' />
    <path d='M44.8408 17.8023H43.5045V19.1386H44.8408V17.8023Z' fill='black' />
    <path d='M46.1771 17.8023H44.8408V19.1386H46.1771L46.1771 17.8023Z' fill='black' />
    <path d='M47.5133 17.8023H46.1771L46.1771 19.1386H47.5133V17.8023Z' fill='black' />
    <path d='M50.1858 17.8023H48.8496V19.1386H50.1858V17.8023Z' fill='black' />
    <path d='M54.1946 17.8023H52.8584V19.1386H54.1946L54.1946 17.8023Z' fill='black' />
    <path d='M55.5309 17.8023H54.1946L54.1946 19.1386H55.5309V17.8023Z' fill='black' />
    <path d='M58.2034 17.8023H56.8671V19.1386H58.2034V17.8023Z' fill='black' />
    <path d='M59.5396 17.8023H58.2034V19.1386H59.5396L59.5396 17.8023Z' fill='black' />
    <path d='M60.8759 17.8023H59.5396L59.5396 19.1386H60.8759V17.8023Z' fill='black' />
    <path d='M62.2122 17.8023H60.8759V19.1386H62.2121L62.2122 17.8023Z' fill='black' />
    <path d='M16.7794 19.1386H15.4431V20.4749H16.7794V19.1386Z' fill='black' />
    <path d='M18.1156 19.1386H16.7794V20.4749H18.1156V19.1386Z' fill='black' />
    <path d='M20.7882 19.1386H19.4519V20.4749H20.7882V19.1386Z' fill='black' />
    <path d='M22.1244 19.1386H20.7882V20.4749H22.1244V19.1386Z' fill='black' />
    <path d='M26.1332 19.1386H24.7969V20.4749H26.1332V19.1386Z' fill='black' />
    <path d='M27.4695 19.1386H26.1332V20.4749H27.4695V19.1386Z' fill='black' />
    <path d='M28.8057 19.1386H27.4695V20.4749H28.8057V19.1386Z' fill='black' />
    <path d='M30.142 19.1386H28.8057V20.4749H30.142V19.1386Z' fill='black' />
    <path d='M31.4782 19.1386H30.142V20.4749H31.4782V19.1386Z' fill='black' />
    <path d='M39.4958 19.1386H38.1595V20.4749H39.4958V19.1386Z' fill='black' />
    <path d='M40.832 19.1386H39.4958V20.4749H40.832V19.1386Z' fill='black' />
    <path d='M42.1683 19.1386H40.832V20.4749H42.1683V19.1386Z' fill='black' />
    <path d='M43.5045 19.1386H42.1683V20.4749H43.5045V19.1386Z' fill='black' />
    <path d='M44.8408 19.1386H43.5045V20.4749H44.8408V19.1386Z' fill='black' />
    <path d='M46.1771 19.1386H44.8408V20.4749H46.1771V19.1386Z' fill='black' />
    <path d='M48.8496 19.1386H47.5133V20.4749H48.8496L48.8496 19.1386Z' fill='black' />
    <path d='M50.1858 19.1386H48.8496L48.8496 20.4749H50.1858V19.1386Z' fill='black' />
    <path d='M55.5309 19.1386H54.1946L54.1946 20.4749H55.5309V19.1386Z' fill='black' />
    <path d='M56.8671 19.1386H55.5309V20.4749H56.8671L56.8671 19.1386Z' fill='black' />
    <path d='M58.2034 19.1386H56.8671L56.8671 20.4749H58.2034V19.1386Z' fill='black' />
    <path d='M59.5396 19.1386H58.2034V20.4749H59.5396V19.1386Z' fill='black' />
    <path d='M62.2121 19.1386H60.8759L60.8759 20.4749H62.2121V19.1386Z' fill='black' />
    <path d='M63.5484 19.1386H62.2121V20.4749H63.5484V19.1386Z' fill='black' />
    <path d='M64.8847 19.1386H63.5484V20.4749H64.8847V19.1386Z' fill='black' />
    <path d='M67.5572 19.1386H66.2209V20.4749H67.5572V19.1386Z' fill='black' />
    <path d='M70.2297 19.1386H68.8934V20.4749H70.2297V19.1386Z' fill='black' />
    <path d='M74.2385 19.1386H72.9022V20.4749H74.2385V19.1386Z' fill='black' />
    <path d='M10.0981 20.4749H8.76184V21.8111H10.0981V20.4749Z' fill='black' />
    <path d='M12.7706 20.4749H11.4344V21.8111H12.7706V20.4749Z' fill='black' />
    <path d='M19.4519 20.4749H18.1156V21.8111H19.4519V20.4749Z' fill='black' />
    <path d='M20.7882 20.4749H19.4519V21.8111H20.7882V20.4749Z' fill='black' />
    <path d='M22.1244 20.4749H20.7882V21.8111H22.1244V20.4749Z' fill='black' />
    <path d='M23.4607 20.4749H22.1244V21.8111H23.4607V20.4749Z' fill='black' />
    <path d='M27.4695 20.4749H26.1332V21.8111H27.4695V20.4749Z' fill='black' />
    <path d='M35.487 20.4749H34.1507V21.8111H35.487V20.4749Z' fill='black' />
    <path d='M39.4958 20.4749H38.1595V21.8111H39.4958V20.4749Z' fill='black' />
    <path d='M43.5045 20.4749H42.1683V21.8111H43.5045V20.4749Z' fill='black' />
    <path d='M51.5221 20.4749H50.1858L50.1858 21.8111H51.5221V20.4749Z' fill='black' />
    <path d='M54.1946 20.4749H52.8584V21.8111H54.1946L54.1946 20.4749Z' fill='black' />
    <path d='M58.2034 20.4749H56.8671L56.8671 21.8111H58.2034V20.4749Z' fill='black' />
    <path d='M64.8847 20.4749H63.5484V21.8111H64.8847V20.4749Z' fill='black' />
    <path d='M67.5572 20.4749H66.2209V21.8111H67.5572V20.4749Z' fill='black' />
    <path d='M70.2297 20.4749H68.8934V21.8111H70.2297V20.4749Z' fill='black' />
    <path d='M72.9022 20.4749H71.566V21.8111H72.9022L72.9022 20.4749Z' fill='black' />
    <path d='M14.1069 21.8111H12.7706V23.1474H14.1069V21.8111Z' fill='black' />
    <path d='M16.7794 21.8111H15.4431V23.1474H16.7794V21.8111Z' fill='black' />
    <path d='M18.1156 21.8111H16.7794V23.1474H18.1156V21.8111Z' fill='black' />
    <path d='M20.7882 21.8111H19.4519V23.1474H20.7882V21.8111Z' fill='black' />
    <path d='M22.1244 21.8111H20.7882V23.1474H22.1244V21.8111Z' fill='black' />
    <path d='M28.8057 21.8111H27.4695V23.1474H28.8057V21.8111Z' fill='black' />
    <path d='M36.8233 21.8111H35.487V23.1474H36.8233V21.8111Z' fill='black' />
    <path d='M47.5133 21.8111H46.1771V23.1474H47.5133V21.8111Z' fill='black' />
    <path d='M50.1858 21.8111H48.8496V23.1474H50.1858L50.1858 21.8111Z' fill='black' />
    <path d='M51.5221 21.8111H50.1858L50.1858 23.1474H51.5221V21.8111Z' fill='black' />
    <path d='M54.1946 21.8111H52.8584V23.1474H54.1946V21.8111Z' fill='black' />
    <path d='M56.8671 21.8111H55.5309V23.1474H56.8671L56.8671 21.8111Z' fill='black' />
    <path d='M62.2121 21.8111H60.8759V23.1474H62.2121V21.8111Z' fill='black' />
    <path d='M64.8847 21.8111H63.5484V23.1474H64.8847V21.8111Z' fill='black' />
    <path d='M67.5572 21.8111H66.2209V23.1474H67.5572V21.8111Z' fill='black' />
    <path d='M71.566 21.8111H70.2297L70.2297 23.1474H71.566L71.566 21.8111Z' fill='black' />
    <path d='M74.2385 21.8111H72.9022L72.9022 23.1474H74.2385V21.8111Z' fill='black' />
    <path d='M11.4344 23.1474H10.0981V24.4836H11.4344V23.1474Z' fill='black' />
    <path d='M12.7706 23.1474H11.4344V24.4836H12.7706V23.1474Z' fill='black' />
    <path d='M14.1069 23.1474H12.7706V24.4836H14.1069V23.1474Z' fill='black' />
    <path d='M16.7794 23.1474H15.4431V24.4836H16.7794V23.1474Z' fill='black' />
    <path d='M19.4519 23.1474H18.1156V24.4836H19.4519V23.1474Z' fill='black' />
    <path d='M20.7882 23.1474H19.4519V24.4836H20.7882V23.1474Z' fill='black' />
    <path d='M27.4695 23.1474H26.1332V24.4836H27.4695V23.1474Z' fill='black' />
    <path d='M28.8057 23.1474H27.4695V24.4836H28.8057V23.1474Z' fill='black' />
    <path d='M30.142 23.1474H28.8057V24.4836H30.142V23.1474Z' fill='black' />
    <path d='M31.4782 23.1474H30.142V24.4836H31.4782V23.1474Z' fill='black' />
    <path d='M32.8145 23.1474H31.4782V24.4836H32.8145V23.1474Z' fill='black' />
    <path d='M34.1507 23.1474H32.8145V24.4836H34.1507V23.1474Z' fill='black' />
    <path d='M35.487 23.1474H34.1507V24.4836H35.487V23.1474Z' fill='black' />
    <path d='M40.832 23.1474H39.4958V24.4836H40.832V23.1474Z' fill='black' />
    <path d='M44.8408 23.1474H43.5045V24.4836H44.8408V23.1474Z' fill='black' />
    <path d='M46.1771 23.1474H44.8408V24.4836H46.1771L46.1771 23.1474Z' fill='black' />
    <path d='M47.5133 23.1474H46.1771L46.1771 24.4836H47.5133V23.1474Z' fill='black' />
    <path d='M48.8496 23.1474H47.5133V24.4836H48.8496L48.8496 23.1474Z' fill='black' />
    <path d='M51.5221 23.1474H50.1858L50.1858 24.4836H51.5221V23.1474Z' fill='black' />
    <path d='M56.8671 23.1474H55.5309V24.4836H56.8671V23.1474Z' fill='black' />
    <path d='M59.5396 23.1474H58.2034V24.4836H59.5396V23.1474Z' fill='black' />
    <path d='M60.8759 23.1474H59.5396V24.4836H60.8759L60.8759 23.1474Z' fill='black' />
    <path d='M62.2121 23.1474H60.8759L60.8759 24.4836H62.2121V23.1474Z' fill='black' />
    <path d='M66.2209 23.1474H64.8847L64.8847 24.4836H66.2209L66.2209 23.1474Z' fill='black' />
    <path d='M67.5572 23.1474H66.2209L66.2209 24.4836H67.5572V23.1474Z' fill='black' />
    <path d='M70.2297 23.1474H68.8934V24.4836H70.2297L70.2297 23.1474Z' fill='black' />
    <path d='M72.9022 23.1474H71.566L71.566 24.4836H72.9022L72.9022 23.1474Z' fill='black' />
    <path d='M10.0981 24.4836H8.76184V25.8199H10.0981V24.4836Z' fill='black' />
    <path d='M11.4344 24.4836H10.0981V25.8199H11.4344V24.4836Z' fill='black' />
    <path d='M14.1069 24.4836H12.7706V25.8199H14.1069V24.4836Z' fill='black' />
    <path d='M16.7794 24.4836H15.4431V25.8199H16.7794V24.4836Z' fill='black' />
    <path d='M18.1156 24.4836H16.7794V25.8199H18.1156V24.4836Z' fill='black' />
    <path d='M19.4519 24.4836H18.1156V25.8199H19.4519V24.4836Z' fill='black' />
    <path d='M20.7882 24.4836H19.4519V25.8199H20.7882V24.4836Z' fill='black' />
    <path d='M24.7969 24.4836H23.4607V25.8199H24.7969V24.4836Z' fill='black' />
    <path d='M26.1332 24.4836H24.7969V25.8199H26.1332V24.4836Z' fill='black' />
    <path d='M30.142 24.4836H28.8057V25.8199H30.142V24.4836Z' fill='black' />
    <path d='M36.8233 24.4836H35.487V25.8199H36.8233V24.4836Z' fill='black' />
    <path d='M39.4958 24.4836H38.1595V25.8199H39.4958V24.4836Z' fill='black' />
    <path d='M43.5045 24.4836H42.1683V25.8199H43.5045V24.4836Z' fill='black' />
    <path d='M46.1771 24.4836H44.8408V25.8199H46.1771V24.4836Z' fill='black' />
    <path d='M50.1858 24.4836H48.8496L48.8496 25.8199H50.1858L50.1858 24.4836Z' fill='black' />
    <path d='M51.5221 24.4836H50.1858L50.1858 25.8199H51.5221V24.4836Z' fill='black' />
    <path d='M55.5309 24.4836H54.1946V25.8199H55.5309L55.5309 24.4836Z' fill='black' />
    <path d='M56.8671 24.4836H55.5309L55.5309 25.8199H56.8671V24.4836Z' fill='black' />
    <path d='M59.5396 24.4836H58.2034V25.8199H59.5396V24.4836Z' fill='black' />
    <path d='M60.8759 24.4836H59.5396V25.8199H60.8759V24.4836Z' fill='black' />
    <path d='M66.2209 24.4836H64.8847V25.8199H66.2209V24.4836Z' fill='black' />
    <path d='M67.5572 24.4836H66.2209V25.8199H67.5572V24.4836Z' fill='black' />
    <path d='M74.2385 24.4836H72.9022L72.9022 25.8199H74.2385V24.4836Z' fill='black' />
    <path d='M11.4344 25.8199H10.0981V27.1561H11.4344V25.8199Z' fill='black' />
    <path d='M12.7706 25.8199H11.4344V27.1561H12.7706V25.8199Z' fill='black' />
    <path d='M15.4431 25.8199H14.1069V27.1561H15.4431V25.8199Z' fill='black' />
    <path d='M16.7794 25.8199H15.4431V27.1561H16.7794V25.8199Z' fill='black' />
    <path d='M24.7969 25.8199H23.4607V27.1561H24.7969V25.8199Z' fill='black' />
    <path d='M26.1332 25.8199H24.7969V27.1561H26.1332V25.8199Z' fill='black' />
    <path d='M27.4695 25.8199H26.1332V27.1561H27.4695V25.8199Z' fill='black' />
    <path d='M28.8057 25.8199H27.4695V27.1561H28.8057V25.8199Z' fill='black' />
    <path d='M30.142 25.8199H28.8057V27.1561H30.142V25.8199Z' fill='black' />
    <path d='M31.4782 25.8199H30.142V27.1561H31.4782V25.8199Z' fill='black' />
    <path d='M32.8145 25.8199H31.4782V27.1561H32.8145V25.8199Z' fill='black' />
    <path d='M36.8233 25.8199H35.487V27.1561H36.8233V25.8199Z' fill='black' />
    <path d='M39.4958 25.8199H38.1595V27.1561H39.4958V25.8199Z' fill='black' />
    <path d='M42.1683 25.8199H40.832V27.1561H42.1683V25.8199Z' fill='black' />
    <path d='M43.5045 25.8199H42.1683V27.1561H43.5045V25.8199Z' fill='black' />
    <path d='M44.8408 25.8199H43.5045V27.1561H44.8408V25.8199Z' fill='black' />
    <path d='M47.5133 25.8199H46.1771L46.1771 27.1561H47.5133V25.8199Z' fill='black' />
    <path d='M48.8496 25.8199H47.5133V27.1561H48.8496L48.8496 25.8199Z' fill='black' />
    <path d='M52.8584 25.8199H51.5221L51.5221 27.1561H52.8584V25.8199Z' fill='black' />
    <path d='M56.8671 25.8199H55.5309L55.5309 27.1561H56.8671V25.8199Z' fill='black' />
    <path d='M59.5396 25.8199H58.2034V27.1561H59.5396V25.8199Z' fill='black' />
    <path d='M60.8759 25.8199H59.5396V27.1561H60.8759V25.8199Z' fill='black' />
    <path d='M63.5484 25.8199H62.2122V27.1561H63.5484V25.8199Z' fill='black' />
    <path d='M66.2209 25.8199H64.8847V27.1561H66.2209V25.8199Z' fill='black' />
    <path d='M71.566 25.8199H70.2297V27.1561H71.566V25.8199Z' fill='black' />
    <path d='M72.9022 25.8199H71.566V27.1561H72.9022L72.9022 25.8199Z' fill='black' />
    <path d='M74.2385 25.8199H72.9022L72.9022 27.1561H74.2385V25.8199Z' fill='black' />
    <path d='M10.0981 27.1561H8.76184V28.4924H10.0981V27.1561Z' fill='black' />
    <path d='M16.7794 27.1561H15.4431V28.4924H16.7794V27.1561Z' fill='black' />
    <path d='M18.1156 27.1561H16.7794V28.4924H18.1156V27.1561Z' fill='black' />
    <path d='M22.1244 27.1561H20.7882V28.4924H22.1244V27.1561Z' fill='black' />
    <path d='M28.8057 27.1561H27.4695V28.4924H28.8057V27.1561Z' fill='black' />
    <path d='M31.4782 27.1561H30.142V28.4924H31.4782V27.1561Z' fill='black' />
    <path d='M35.487 27.1561H34.1507V28.4924H35.487V27.1561Z' fill='black' />
    <path d='M36.8233 27.1561H35.487V28.4924H36.8233V27.1561Z' fill='black' />
    <path d='M38.1595 27.1561H36.8233V28.4924H38.1595V27.1561Z' fill='black' />
    <path d='M42.1683 27.1561H40.832V28.4924H42.1683V27.1561Z' fill='black' />
    <path d='M44.8408 27.1561H43.5045V28.4924H44.8408V27.1561Z' fill='black' />
    <path d='M46.1771 27.1561H44.8408V28.4924H46.1771L46.1771 27.1561Z' fill='black' />
    <path d='M47.5133 27.1561H46.1771L46.1771 28.4924H47.5133V27.1561Z' fill='black' />
    <path d='M48.8496 27.1561H47.5133V28.4924H48.8496V27.1561Z' fill='black' />
    <path d='M51.5221 27.1561H50.1858V28.4924H51.5221L51.5221 27.1561Z' fill='black' />
    <path d='M54.1946 27.1561H52.8584V28.4924H54.1946V27.1561Z' fill='black' />
    <path d='M58.2034 27.1561H56.8671L56.8671 28.4924H58.2034V27.1561Z' fill='black' />
    <path d='M59.5396 27.1561H58.2034V28.4924H59.5396V27.1561Z' fill='black' />
    <path d='M63.5484 27.1561H62.2122V28.4924H63.5484V27.1561Z' fill='black' />
    <path d='M64.8847 27.1561H63.5484V28.4924H64.8847L64.8847 27.1561Z' fill='black' />
    <path d='M66.2209 27.1561H64.8847L64.8847 28.4924H66.2209V27.1561Z' fill='black' />
    <path d='M68.8934 27.1561H67.5572V28.4924H68.8934V27.1561Z' fill='black' />
    <path d='M70.2297 27.1561H68.8934V28.4924H70.2297L70.2297 27.1561Z' fill='black' />
    <path d='M72.9022 27.1561H71.566L71.566 28.4924H72.9022V27.1561Z' fill='black' />
    <path d='M74.2385 27.1561H72.9022V28.4924H74.2385V27.1561Z' fill='black' />
    <path d='M14.1069 28.4924H12.7706V29.8287H14.1069V28.4924Z' fill='black' />
    <path d='M16.7794 28.4924H15.4431V29.8287H16.7794V28.4924Z' fill='black' />
    <path d='M22.1244 28.4924H20.7882V29.8287H22.1244V28.4924Z' fill='black' />
    <path d='M26.1332 28.4924H24.7969V29.8287H26.1332V28.4924Z' fill='black' />
    <path d='M28.8057 28.4924H27.4695V29.8287H28.8057V28.4924Z' fill='black' />
    <path d='M30.142 28.4924H28.8057V29.8287H30.142V28.4924Z' fill='black' />
    <path d='M31.4782 28.4924H30.142V29.8287H31.4782V28.4924Z' fill='black' />
    <path d='M32.8145 28.4924H31.4782V29.8287H32.8145V28.4924Z' fill='black' />
    <path d='M36.8233 28.4924H35.487V29.8287H36.8233V28.4924Z' fill='black' />
    <path d='M38.1595 28.4924H36.8233V29.8287H38.1595V28.4924Z' fill='black' />
    <path d='M40.832 28.4924H39.4958V29.8287H40.832V28.4924Z' fill='black' />
    <path d='M42.1683 28.4924H40.832V29.8287H42.1683V28.4924Z' fill='black' />
    <path d='M43.5045 28.4924H42.1683V29.8287H43.5045V28.4924Z' fill='black' />
    <path d='M50.1858 28.4924H48.8496L48.8496 29.8287H50.1858L50.1858 28.4924Z' fill='black' />
    <path d='M54.1946 28.4924H52.8584V29.8287H54.1946V28.4924Z' fill='black' />
    <path d='M56.8671 28.4924H55.5309V29.8287H56.8671L56.8671 28.4924Z' fill='black' />
    <path d='M59.5396 28.4924H58.2034V29.8287H59.5396V28.4924Z' fill='black' />
    <path d='M62.2122 28.4924H60.8759V29.8287H62.2121L62.2122 28.4924Z' fill='black' />
    <path d='M64.8847 28.4924H63.5484V29.8287H64.8847V28.4924Z' fill='black' />
    <path d='M68.8934 28.4924H67.5572V29.8287H68.8934V28.4924Z' fill='black' />
    <path d='M70.2297 28.4924H68.8934V29.8287H70.2297V28.4924Z' fill='black' />
    <path d='M74.2385 28.4924H72.9022L72.9022 29.8287H74.2385V28.4924Z' fill='black' />
    <path d='M11.4344 29.8287H10.0981V31.1649H11.4344V29.8287Z' fill='black' />
    <path d='M12.7706 29.8287H11.4344V31.1649H12.7706V29.8287Z' fill='black' />
    <path d='M18.1156 29.8287H16.7794V31.1649H18.1156V29.8287Z' fill='black' />
    <path d='M20.7882 29.8287H19.4519V31.1649H20.7882V29.8287Z' fill='black' />
    <path d='M23.4607 29.8287H22.1244V31.1649H23.4607V29.8287Z' fill='black' />
    <path d='M24.7969 29.8287H23.4607V31.1649H24.7969V29.8287Z' fill='black' />
    <path d='M28.8057 29.8287H27.4695V31.1649H28.8057V29.8287Z' fill='black' />
    <path d='M32.8145 29.8287H31.4782V31.1649H32.8145V29.8287Z' fill='black' />
    <path d='M35.487 29.8287H34.1507V31.1649H35.487V29.8287Z' fill='black' />
    <path d='M36.8233 29.8287H35.487V31.1649H36.8233V29.8287Z' fill='black' />
    <path d='M42.1683 29.8287H40.832V31.1649H42.1683V29.8287Z' fill='black' />
    <path d='M43.5045 29.8287H42.1683V31.1649H43.5045V29.8287Z' fill='black' />
    <path d='M46.1771 29.8287H44.8408V31.1649H46.1771V29.8287Z' fill='black' />
    <path d='M47.5133 29.8287H46.1771V31.1649H47.5133V29.8287Z' fill='black' />
    <path d='M48.8496 29.8287H47.5133V31.1649H48.8496L48.8496 29.8287Z' fill='black' />
    <path d='M51.5221 29.8287H50.1858L50.1858 31.1649H51.5221V29.8287Z' fill='black' />
    <path d='M55.5309 29.8287H54.1946L54.1946 31.1649H55.5309L55.5309 29.8287Z' fill='black' />
    <path d='M56.8671 29.8287H55.5309L55.5309 31.1649H56.8671V29.8287Z' fill='black' />
    <path d='M60.8759 29.8287H59.5396L59.5396 31.1649H60.8759L60.8759 29.8287Z' fill='black' />
    <path d='M62.2121 29.8287H60.8759L60.8759 31.1649H62.2121V29.8287Z' fill='black' />
    <path d='M66.2209 29.8287H64.8847L64.8847 31.1649H66.2209V29.8287Z' fill='black' />
    <path d='M10.0981 31.1649H8.76184V32.5012H10.0981V31.1649Z' fill='black' />
    <path d='M14.1069 31.1649H12.7706V32.5012H14.1069V31.1649Z' fill='black' />
    <path d='M23.4607 31.1649H22.1244V32.5012H23.4607V31.1649Z' fill='black' />
    <path d='M26.1332 31.1649H24.7969V32.5012H26.1332V31.1649Z' fill='black' />
    <path d='M27.4695 31.1649H26.1332V32.5012H27.4695V31.1649Z' fill='black' />
    <path d='M32.8145 31.1649H31.4782V32.5012H32.8145V31.1649Z' fill='black' />
    <path d='M34.1507 31.1649H32.8145V32.5012H34.1507V31.1649Z' fill='black' />
    <path d='M35.487 31.1649H34.1507V32.5012H35.487V31.1649Z' fill='black' />
    <path d='M39.4958 31.1649H38.1595V32.5012H39.4958V31.1649Z' fill='black' />
    <path d='M40.832 31.1649H39.4958V32.5012H40.832V31.1649Z' fill='black' />
    <path d='M47.5133 31.1649H46.1771L46.1771 32.5012H47.5133V31.1649Z' fill='black' />
    <path d='M48.8496 31.1649H47.5133V32.5012H48.8496V31.1649Z' fill='black' />
    <path d='M51.5221 31.1649H50.1858V32.5012H51.5221V31.1649Z' fill='black' />
    <path d='M52.8584 31.1649H51.5221V32.5012H52.8584V31.1649Z' fill='black' />
    <path d='M54.1946 31.1649H52.8584V32.5012H54.1946L54.1946 31.1649Z' fill='black' />
    <path d='M56.8671 31.1649H55.5309L55.5309 32.5012H56.8671V31.1649Z' fill='black' />
    <path d='M58.2034 31.1649H56.8671V32.5012H58.2034V31.1649Z' fill='black' />
    <path d='M59.5396 31.1649H58.2034V32.5012H59.5396L59.5396 31.1649Z' fill='black' />
    <path d='M60.8759 31.1649H59.5396L59.5396 32.5012H60.8759V31.1649Z' fill='black' />
    <path d='M64.8847 31.1649H63.5484V32.5012H64.8847L64.8847 31.1649Z' fill='black' />
    <path d='M66.2209 31.1649H64.8847L64.8847 32.5012H66.2209V31.1649Z' fill='black' />
    <path d='M68.8934 31.1649H67.5572V32.5012H68.8934V31.1649Z' fill='black' />
    <path d='M71.566 31.1649H70.2297V32.5012H71.566V31.1649Z' fill='black' />
    <path d='M72.9022 31.1649H71.566V32.5012H72.9022V31.1649Z' fill='black' />
    <path d='M74.2385 31.1649H72.9022V32.5012H74.2385V31.1649Z' fill='black' />
    <path d='M12.7706 32.5012H11.4344V33.8374H12.7706V32.5012Z' fill='black' />
    <path d='M14.1069 32.5012H12.7706V33.8374H14.1069V32.5012Z' fill='black' />
    <path d='M18.1156 32.5012H16.7794V33.8374H18.1156V32.5012Z' fill='black' />
    <path d='M20.7882 32.5012H19.4519V33.8374H20.7882V32.5012Z' fill='black' />
    <path d='M23.4607 32.5012H22.1244V33.8374H23.4607V32.5012Z' fill='black' />
    <path d='M27.4695 32.5012H26.1332V33.8374H27.4695V32.5012Z' fill='black' />
    <path d='M28.8057 32.5012H27.4695V33.8374H28.8057V32.5012Z' fill='black' />
    <path d='M30.142 32.5012H28.8057V33.8374H30.142V32.5012Z' fill='black' />
    <path d='M31.4782 32.5012H30.142V33.8374H31.4782V32.5012Z' fill='black' />
    <path d='M32.8145 32.5012H31.4782V33.8374H32.8145V32.5012Z' fill='black' />
    <path d='M34.1507 32.5012H32.8145V33.8374H34.1507V32.5012Z' fill='black' />
    <path d='M36.8233 32.5012H35.487V33.8374H36.8233V32.5012Z' fill='black' />
    <path d='M39.4958 32.5012H38.1595V33.8374H39.4958V32.5012Z' fill='black' />
    <path d='M43.5045 32.5012H42.1683V33.8374H43.5045V32.5012Z' fill='black' />
    <path d='M47.5133 32.5012H46.1771V33.8374H47.5133V32.5012Z' fill='black' />
    <path d='M52.8584 32.5012H51.5221L51.5221 33.8374H52.8584V32.5012Z' fill='black' />
    <path d='M54.1946 32.5012H52.8584V33.8374H54.1946V32.5012Z' fill='black' />
    <path d='M56.8671 32.5012H55.5309V33.8374H56.8671V32.5012Z' fill='black' />
    <path d='M58.2034 32.5012H56.8671V33.8374H58.2034V32.5012Z' fill='black' />
    <path d='M66.2209 32.5012H64.8847L64.8847 33.8374H66.2209V32.5012Z' fill='black' />
    <path d='M67.5572 32.5012H66.2209V33.8374H67.5572L67.5572 32.5012Z' fill='black' />
    <path d='M12.7706 33.8374H11.4344V35.1737H12.7706V33.8374Z' fill='black' />
    <path d='M14.1069 33.8374H12.7706V35.1737H14.1069V33.8374Z' fill='black' />
    <path d='M16.7794 33.8374H15.4431V35.1737H16.7794V33.8374Z' fill='black' />
    <path d='M19.4519 33.8374H18.1156V35.1737H19.4519V33.8374Z' fill='black' />
    <path d='M20.7882 33.8374H19.4519V35.1737H20.7882V33.8374Z' fill='black' />
    <path d='M23.4607 33.8374H22.1244V35.1737H23.4607V33.8374Z' fill='black' />
    <path d='M24.7969 33.8374H23.4607V35.1737H24.7969V33.8374Z' fill='black' />
    <path d='M26.1332 33.8374H24.7969V35.1737H26.1332V33.8374Z' fill='black' />
    <path d='M28.8057 33.8374H27.4695V35.1737H28.8057V33.8374Z' fill='black' />
    <path d='M30.142 33.8374H28.8057V35.1737H30.142V33.8374Z' fill='black' />
    <path d='M39.4958 33.8374H38.1595V35.1737H39.4958V33.8374Z' fill='black' />
    <path d='M42.1683 33.8374H40.832V35.1737H42.1683V33.8374Z' fill='black' />
    <path d='M46.1771 33.8374H44.8408V35.1737H46.1771L46.1771 33.8374Z' fill='black' />
    <path d='M47.5133 33.8374H46.1771L46.1771 35.1737H47.5133V33.8374Z' fill='black' />
    <path d='M51.5221 33.8374H50.1858V35.1737H51.5221L51.5221 33.8374Z' fill='black' />
    <path d='M52.8584 33.8374H51.5221L51.5221 35.1737H52.8584V33.8374Z' fill='black' />
    <path d='M55.5309 33.8374H54.1946L54.1946 35.1737H55.5309L55.5309 33.8374Z' fill='black' />
    <path d='M59.5396 33.8374H58.2034V35.1737H59.5396V33.8374Z' fill='black' />
    <path d='M64.8847 33.8374H63.5484V35.1737H64.8847L64.8847 33.8374Z' fill='black' />
    <path d='M66.2209 33.8374H64.8847L64.8847 35.1737H66.2209V33.8374Z' fill='black' />
    <path d='M67.5572 33.8374H66.2209V35.1737H67.5572V33.8374Z' fill='black' />
    <path d='M68.8934 33.8374H67.5572V35.1737H68.8934V33.8374Z' fill='black' />
    <path d='M70.2297 33.8374H68.8934V35.1737H70.2297V33.8374Z' fill='black' />
    <path d='M72.9022 33.8374H71.566V35.1737H72.9022V33.8374Z' fill='black' />
    <path d='M18.1156 35.1737H16.7794V36.5099H18.1156V35.1737Z' fill='black' />
    <path d='M19.4519 35.1737H18.1156V36.5099H19.4519V35.1737Z' fill='black' />
    <path d='M24.7969 35.1737H23.4607V36.5099H24.7969V35.1737Z' fill='black' />
    <path d='M26.1332 35.1737H24.7969V36.5099H26.1332V35.1737Z' fill='black' />
    <path d='M32.8145 35.1737H31.4782V36.5099H32.8145V35.1737Z' fill='black' />
    <path d='M34.1507 35.1737H32.8145V36.5099H34.1507V35.1737Z' fill='black' />
    <path d='M35.487 35.1737H34.1507V36.5099H35.487V35.1737Z' fill='black' />
    <path d='M36.8233 35.1737H35.487V36.5099H36.8233V35.1737Z' fill='black' />
    <path d='M44.8408 35.1737H43.5045V36.5099H44.8408V35.1737Z' fill='black' />
    <path d='M47.5133 35.1737H46.1771L46.1771 36.5099H47.5133V35.1737Z' fill='black' />
    <path d='M51.5221 35.1737H50.1858V36.5099H51.5221V35.1737Z' fill='black' />
    <path d='M52.8584 35.1737H51.5221V36.5099H52.8584V35.1737Z' fill='black' />
    <path d='M60.8759 35.1737H59.5396L59.5396 36.5099H60.8759V35.1737Z' fill='black' />
    <path d='M64.8847 35.1737H63.5484V36.5099H64.8847V35.1737Z' fill='black' />
    <path d='M66.2209 35.1737H64.8847V36.5099H66.2209V35.1737Z' fill='black' />
    <path d='M71.566 35.1737H70.2297L70.2297 36.5099H71.566L71.566 35.1737Z' fill='black' />
    <path d='M72.9022 35.1737H71.566L71.566 36.5099H72.9022V35.1737Z' fill='black' />
    <path d='M22.1244 36.5099H20.7882V37.8462H22.1244V36.5099Z' fill='black' />
    <path d='M27.4695 36.5099H26.1332V37.8462H27.4695V36.5099Z' fill='black' />
    <path d='M28.8057 36.5099H27.4695V37.8462H28.8057V36.5099Z' fill='black' />
    <path d='M30.142 36.5099H28.8057V37.8462H30.142V36.5099Z' fill='black' />
    <path d='M31.4782 36.5099H30.142V37.8462H31.4782V36.5099Z' fill='black' />
    <path d='M36.8233 36.5099H35.487V37.8462H36.8233V36.5099Z' fill='black' />
    <path d='M39.4958 36.5099H38.1595V37.8462H39.4958V36.5099Z' fill='black' />
    <path d='M44.8408 36.5099H43.5045V37.8462H44.8408V36.5099Z' fill='black' />
    <path d='M47.5133 36.5099H46.1771V37.8462H47.5133V36.5099Z' fill='black' />
    <path d='M48.8496 36.5099H47.5133V37.8462H48.8496V36.5099Z' fill='black' />
    <path d='M50.1858 36.5099H48.8496V37.8462H50.1858L50.1858 36.5099Z' fill='black' />
    <path d='M52.8584 36.5099H51.5221L51.5221 37.8462H52.8584V36.5099Z' fill='black' />
    <path d='M56.8671 36.5099H55.5309V37.8462H56.8671V36.5099Z' fill='black' />
    <path d='M59.5396 36.5099H58.2034V37.8462H59.5396L59.5396 36.5099Z' fill='black' />
    <path d='M60.8759 36.5099H59.5396L59.5396 37.8462H60.8759V36.5099Z' fill='black' />
    <path d='M63.5484 36.5099H62.2122V37.8462H63.5484V36.5099Z' fill='black' />
    <path d='M67.5572 36.5099H66.2209L66.2209 37.8462H67.5572V36.5099Z' fill='black' />
    <path d='M70.2297 36.5099H68.8934V37.8462H70.2297L70.2297 36.5099Z' fill='black' />
    <path d='M74.2385 36.5099H72.9022L72.9022 37.8462H74.2385V36.5099Z' fill='black' />
    <path d='M10.0981 37.8462H8.76184V39.1825H10.0981V37.8462Z' fill='black' />
    <path d='M15.4431 37.8462H14.1069V39.1825H15.4431V37.8462Z' fill='black' />
    <path d='M16.7794 37.8462H15.4431V39.1825H16.7794V37.8462Z' fill='black' />
    <path d='M18.1156 37.8462H16.7794V39.1825H18.1156V37.8462Z' fill='black' />
    <path d='M19.4519 37.8462H18.1156V39.1825H19.4519V37.8462Z' fill='black' />
    <path d='M20.7882 37.8462H19.4519V39.1825H20.7882V37.8462Z' fill='black' />
    <path d='M22.1244 37.8462H20.7882V39.1825H22.1244V37.8462Z' fill='black' />
    <path d='M23.4607 37.8462H22.1244V39.1825H23.4607V37.8462Z' fill='black' />
    <path d='M27.4695 37.8462H26.1332V39.1825H27.4695V37.8462Z' fill='black' />
    <path d='M28.8057 37.8462H27.4695V39.1825H28.8057V37.8462Z' fill='black' />
    <path d='M34.1507 37.8462H32.8145V39.1825H34.1507V37.8462Z' fill='black' />
    <path d='M35.487 37.8462H34.1507V39.1825H35.487V37.8462Z' fill='black' />
    <path d='M36.8233 37.8462H35.487V39.1825H36.8233V37.8462Z' fill='black' />
    <path d='M39.4958 37.8462H38.1595V39.1825H39.4958V37.8462Z' fill='black' />
    <path d='M40.832 37.8462H39.4958V39.1825H40.832V37.8462Z' fill='black' />
    <path d='M42.1683 37.8462H40.832V39.1825H42.1683V37.8462Z' fill='black' />
    <path d='M43.5045 37.8462H42.1683V39.1825H43.5045V37.8462Z' fill='black' />
    <path d='M44.8408 37.8462H43.5045V39.1825H44.8408V37.8462Z' fill='black' />
    <path d='M48.8496 37.8462H47.5133V39.1825H48.8496V37.8462Z' fill='black' />
    <path d='M50.1858 37.8462H48.8496V39.1825H50.1858V37.8462Z' fill='black' />
    <path d='M55.5309 37.8462H54.1946V39.1825H55.5309L55.5309 37.8462Z' fill='black' />
    <path d='M59.5396 37.8462H58.2034V39.1825H59.5396V37.8462Z' fill='black' />
    <path d='M60.8759 37.8462H59.5396V39.1825H60.8759V37.8462Z' fill='black' />
    <path d='M62.2122 37.8462H60.8759V39.1825H62.2121L62.2122 37.8462Z' fill='black' />
    <path d='M63.5484 37.8462H62.2122L62.2121 39.1825H63.5484V37.8462Z' fill='black' />
    <path d='M64.8847 37.8462H63.5484V39.1825H64.8847V37.8462Z' fill='black' />
    <path d='M66.2209 37.8462H64.8847V39.1825H66.2209L66.2209 37.8462Z' fill='black' />
    <path d='M67.5572 37.8462H66.2209L66.2209 39.1825H67.5572V37.8462Z' fill='black' />
    <path d='M68.8934 37.8462H67.5572V39.1825H68.8934V37.8462Z' fill='black' />
    <path d='M70.2297 37.8462H68.8934V39.1825H70.2297V37.8462Z' fill='black' />
    <path d='M72.9022 37.8462H71.566V39.1825H72.9022L72.9022 37.8462Z' fill='black' />
    <path d='M74.2385 37.8462H72.9022L72.9022 39.1825H74.2385V37.8462Z' fill='black' />
    <path d='M10.0981 39.1825H8.76184V40.5187H10.0981V39.1825Z' fill='black' />
    <path d='M11.4344 39.1825H10.0981V40.5187H11.4344V39.1825Z' fill='black' />
    <path d='M12.7706 39.1825H11.4344V40.5187H12.7706V39.1825Z' fill='black' />
    <path d='M15.4431 39.1825H14.1069V40.5187H15.4431V39.1825Z' fill='black' />
    <path d='M20.7882 39.1825H19.4519V40.5187H20.7882V39.1825Z' fill='black' />
    <path d='M22.1244 39.1825H20.7882V40.5187H22.1244V39.1825Z' fill='black' />
    <path d='M23.4607 39.1825H22.1244V40.5187H23.4607V39.1825Z' fill='black' />
    <path d='M24.7969 39.1825H23.4607V40.5187H24.7969V39.1825Z' fill='black' />
    <path d='M26.1332 39.1825H24.7969V40.5187H26.1332V39.1825Z' fill='black' />
    <path d='M30.142 39.1825H28.8057V40.5187H30.142V39.1825Z' fill='black' />
    <path d='M31.4782 39.1825H30.142V40.5187H31.4782V39.1825Z' fill='black' />
    <path d='M32.8145 39.1825H31.4782V40.5187H32.8145V39.1825Z' fill='black' />
    <path d='M38.1595 39.1825H36.8233V40.5187H38.1595V39.1825Z' fill='black' />
    <path d='M39.4958 39.1825H38.1595V40.5187H39.4958V39.1825Z' fill='black' />
    <path d='M44.8408 39.1825H43.5045V40.5187H44.8408V39.1825Z' fill='black' />
    <path d='M46.1771 39.1825H44.8408V40.5187H46.1771V39.1825Z' fill='black' />
    <path d='M47.5133 39.1825H46.1771V40.5187H47.5133V39.1825Z' fill='black' />
    <path d='M50.1858 39.1825H48.8496L48.8496 40.5187H50.1858V39.1825Z' fill='black' />
    <path d='M51.5221 39.1825H50.1858V40.5187H51.5221V39.1825Z' fill='black' />
    <path d='M54.1946 39.1825H52.8584V40.5187H54.1946L54.1946 39.1825Z' fill='black' />
    <path d='M56.8671 39.1825H55.5309L55.5309 40.5187H56.8671V39.1825Z' fill='black' />
    <path d='M58.2034 39.1825H56.8671V40.5187H58.2034V39.1825Z' fill='black' />
    <path d='M63.5484 39.1825H62.2121L62.2122 40.5187H63.5484V39.1825Z' fill='black' />
    <path d='M68.8934 39.1825H67.5572L67.5572 40.5187H68.8934V39.1825Z' fill='black' />
    <path d='M70.2297 39.1825H68.8934V40.5187H70.2297V39.1825Z' fill='black' />
    <path d='M71.566 39.1825H70.2297V40.5187H71.566L71.566 39.1825Z' fill='black' />
    <path d='M72.9022 39.1825H71.566L71.566 40.5187H72.9022V39.1825Z' fill='black' />
    <path d='M10.0981 40.5187H8.76184V41.855H10.0981V40.5187Z' fill='black' />
    <path d='M11.4344 40.5187H10.0981V41.855H11.4344V40.5187Z' fill='black' />
    <path d='M12.7706 40.5187H11.4344V41.855H12.7706V40.5187Z' fill='black' />
    <path d='M14.1069 40.5187H12.7706V41.855H14.1069V40.5187Z' fill='black' />
    <path d='M15.4431 40.5187H14.1069V41.855H15.4431V40.5187Z' fill='black' />
    <path d='M18.1156 40.5187H16.7794V41.855H18.1156V40.5187Z' fill='black' />
    <path d='M20.7882 40.5187H19.4519V41.855H20.7882V40.5187Z' fill='black' />
    <path d='M22.1244 40.5187H20.7882V41.855H22.1244V40.5187Z' fill='black' />
    <path d='M26.1332 40.5187H24.7969V41.855H26.1332V40.5187Z' fill='black' />
    <path d='M30.142 40.5187H28.8057V41.855H30.142V40.5187Z' fill='black' />
    <path d='M31.4782 40.5187H30.142V41.855H31.4782V40.5187Z' fill='black' />
    <path d='M32.8145 40.5187H31.4782V41.855H32.8145V40.5187Z' fill='black' />
    <path d='M34.1507 40.5187H32.8145V41.855H34.1507V40.5187Z' fill='black' />
    <path d='M36.8233 40.5187H35.487V41.855H36.8233V40.5187Z' fill='black' />
    <path d='M38.1595 40.5187H36.8233V41.855H38.1595V40.5187Z' fill='black' />
    <path d='M39.4958 40.5187H38.1595V41.855H39.4958V40.5187Z' fill='black' />
    <path d='M42.1683 40.5187H40.832V41.855H42.1683V40.5187Z' fill='black' />
    <path d='M44.8408 40.5187H43.5045V41.855H44.8408V40.5187Z' fill='black' />
    <path d='M46.1771 40.5187H44.8408V41.855H46.1771V40.5187Z' fill='black' />
    <path d='M47.5133 40.5187H46.1771V41.855H47.5133V40.5187Z' fill='black' />
    <path d='M50.1858 40.5187H48.8496V41.855H50.1858V40.5187Z' fill='black' />
    <path d='M55.5309 40.5187H54.1946L54.1946 41.855H55.5309L55.5309 40.5187Z' fill='black' />
    <path d='M60.8759 40.5187H59.5396V41.855H60.8759V40.5187Z' fill='black' />
    <path d='M63.5484 40.5187H62.2122V41.855H63.5484V40.5187Z' fill='black' />
    <path d='M66.2209 40.5187H64.8847V41.855H66.2209V40.5187Z' fill='black' />
    <path d='M68.8934 40.5187H67.5572V41.855H68.8934V40.5187Z' fill='black' />
    <path d='M70.2297 40.5187H68.8934V41.855H70.2297V40.5187Z' fill='black' />
    <path d='M71.566 40.5187H70.2297V41.855H71.566V40.5187Z' fill='black' />
    <path d='M72.9022 40.5187H71.566V41.855H72.9022V40.5187Z' fill='black' />
    <path d='M74.2385 40.5187H72.9022V41.855H74.2385V40.5187Z' fill='black' />
    <path d='M10.0981 41.855H8.76184V43.1912H10.0981V41.855Z' fill='black' />
    <path d='M15.4431 41.855H14.1069V43.1912H15.4431V41.855Z' fill='black' />
    <path d='M20.7882 41.855H19.4519V43.1912H20.7882V41.855Z' fill='black' />
    <path d='M23.4607 41.855H22.1244V43.1912H23.4607V41.855Z' fill='black' />
    <path d='M26.1332 41.855H24.7969V43.1912H26.1332V41.855Z' fill='black' />
    <path d='M27.4695 41.855H26.1332V43.1912H27.4695V41.855Z' fill='black' />
    <path d='M31.4782 41.855H30.142V43.1912H31.4782V41.855Z' fill='black' />
    <path d='M32.8145 41.855H31.4782V43.1912H32.8145V41.855Z' fill='black' />
    <path d='M36.8233 41.855H35.487V43.1912H36.8233V41.855Z' fill='black' />
    <path d='M38.1595 41.855H36.8233V43.1912H38.1595V41.855Z' fill='black' />
    <path d='M39.4958 41.855H38.1595V43.1912H39.4958V41.855Z' fill='black' />
    <path d='M44.8408 41.855H43.5045V43.1912H44.8408V41.855Z' fill='black' />
    <path d='M50.1858 41.855H48.8496V43.1912H50.1858V41.855Z' fill='black' />
    <path d='M51.5221 41.855H50.1858V43.1912H51.5221V41.855Z' fill='black' />
    <path d='M52.8584 41.855H51.5221V43.1912H52.8584V41.855Z' fill='black' />
    <path d='M54.1946 41.855H52.8584V43.1912H54.1946L54.1946 41.855Z' fill='black' />
    <path d='M56.8671 41.855H55.5309L55.5309 43.1912H56.8671V41.855Z' fill='black' />
    <path d='M58.2034 41.855H56.8671V43.1912H58.2034V41.855Z' fill='black' />
    <path d='M60.8759 41.855H59.5396V43.1912H60.8759V41.855Z' fill='black' />
    <path d='M63.5484 41.855H62.2122V43.1912H63.5484V41.855Z' fill='black' />
    <path d='M68.8934 41.855H67.5572V43.1912H68.8934V41.855Z' fill='black' />
    <path d='M71.566 41.855H70.2297L70.2297 43.1912H71.566V41.855Z' fill='black' />
    <path d='M14.1069 43.1912H12.7706V44.5275H14.1069V43.1912Z' fill='black' />
    <path d='M15.4431 43.1912H14.1069V44.5275H15.4431V43.1912Z' fill='black' />
    <path d='M16.7794 43.1912H15.4431V44.5275H16.7794V43.1912Z' fill='black' />
    <path d='M18.1156 43.1912H16.7794V44.5275H18.1156V43.1912Z' fill='black' />
    <path d='M19.4519 43.1912H18.1156V44.5275H19.4519V43.1912Z' fill='black' />
    <path d='M20.7882 43.1912H19.4519V44.5275H20.7882V43.1912Z' fill='black' />
    <path d='M26.1332 43.1912H24.7969V44.5275H26.1332V43.1912Z' fill='black' />
    <path d='M28.8057 43.1912H27.4695V44.5275H28.8057V43.1912Z' fill='black' />
    <path d='M30.142 43.1912H28.8057V44.5275H30.142V43.1912Z' fill='black' />
    <path d='M32.8145 43.1912H31.4782V44.5275H32.8145V43.1912Z' fill='black' />
    <path d='M39.4958 43.1912H38.1595V44.5275H39.4958V43.1912Z' fill='black' />
    <path d='M40.832 43.1912H39.4958V44.5275H40.832V43.1912Z' fill='black' />
    <path d='M42.1683 43.1912H40.832V44.5275H42.1683V43.1912Z' fill='black' />
    <path d='M43.5045 43.1912H42.1683V44.5275H43.5045V43.1912Z' fill='black' />
    <path d='M44.8408 43.1912H43.5045V44.5275H44.8408V43.1912Z' fill='black' />
    <path d='M48.8496 43.1912H47.5133V44.5275H48.8496L48.8496 43.1912Z' fill='black' />
    <path d='M50.1858 43.1912H48.8496L48.8496 44.5275H50.1858V43.1912Z' fill='black' />
    <path d='M55.5309 43.1912H54.1946L54.1946 44.5275H55.5309L55.5309 43.1912Z' fill='black' />
    <path d='M58.2034 43.1912H56.8671L56.8671 44.5275H58.2034V43.1912Z' fill='black' />
    <path d='M59.5396 43.1912H58.2034V44.5275H59.5396L59.5396 43.1912Z' fill='black' />
    <path d='M62.2122 43.1912H60.8759L60.8759 44.5275H62.2121L62.2122 43.1912Z' fill='black' />
    <path d='M63.5484 43.1912H62.2122L62.2121 44.5275H63.5484V43.1912Z' fill='black' />
    <path d='M64.8847 43.1912H63.5484V44.5275H64.8847V43.1912Z' fill='black' />
    <path d='M66.2209 43.1912H64.8847V44.5275H66.2209V43.1912Z' fill='black' />
    <path d='M67.5572 43.1912H66.2209V44.5275H67.5572L67.5572 43.1912Z' fill='black' />
    <path d='M68.8934 43.1912H67.5572L67.5572 44.5275H68.8934V43.1912Z' fill='black' />
    <path d='M70.2297 43.1912H68.8934V44.5275H70.2297L70.2297 43.1912Z' fill='black' />
    <path d='M74.2385 43.1912H72.9022V44.5275H74.2385V43.1912Z' fill='black' />
    <path d='M15.4431 44.5275H14.1069V45.8638H15.4431V44.5275Z' fill='black' />
    <path d='M23.4607 44.5275H22.1244V45.8638H23.4607V44.5275Z' fill='black' />
    <path d='M24.7969 44.5275H23.4607V45.8638H24.7969V44.5275Z' fill='black' />
    <path d='M26.1332 44.5275H24.7969V45.8638H26.1332V44.5275Z' fill='black' />
    <path d='M28.8057 44.5275H27.4695V45.8638H28.8057V44.5275Z' fill='black' />
    <path d='M35.487 44.5275H34.1507V45.8638H35.487V44.5275Z' fill='black' />
    <path d='M36.8233 44.5275H35.487V45.8638H36.8233V44.5275Z' fill='black' />
    <path d='M40.832 44.5275H39.4958V45.8638H40.832V44.5275Z' fill='black' />
    <path d='M42.1683 44.5275H40.832V45.8638H42.1683V44.5275Z' fill='black' />
    <path d='M43.5045 44.5275H42.1683V45.8638H43.5045V44.5275Z' fill='black' />
    <path d='M46.1771 44.5275H44.8408V45.8638H46.1771V44.5275Z' fill='black' />
    <path d='M50.1858 44.5275H48.8496L48.8496 45.8638H50.1858V44.5275Z' fill='black' />
    <path d='M51.5221 44.5275H50.1858V45.8638H51.5221V44.5275Z' fill='black' />
    <path d='M52.8584 44.5275H51.5221V45.8638H52.8584V44.5275Z' fill='black' />
    <path d='M54.1946 44.5275H52.8584V45.8638H54.1946L54.1946 44.5275Z' fill='black' />
    <path d='M55.5309 44.5275H54.1946L54.1946 45.8638H55.5309V44.5275Z' fill='black' />
    <path d='M56.8671 44.5275H55.5309V45.8638H56.8671L56.8671 44.5275Z' fill='black' />
    <path d='M58.2034 44.5275H56.8671L56.8671 45.8638H58.2034V44.5275Z' fill='black' />
    <path d='M63.5484 44.5275H62.2121L62.2122 45.8638H63.5484V44.5275Z' fill='black' />
    <path d='M67.5572 44.5275H66.2209L66.2209 45.8638H67.5572V44.5275Z' fill='black' />
    <path d='M68.8934 44.5275H67.5572V45.8638H68.8934V44.5275Z' fill='black' />
    <path d='M70.2297 44.5275H68.8934V45.8638H70.2297V44.5275Z' fill='black' />
    <path d='M71.566 44.5275H70.2297V45.8638H71.566V44.5275Z' fill='black' />
    <path d='M15.4431 45.8638H14.1069V47.2H15.4431V45.8638Z' fill='black' />
    <path d='M18.1156 45.8638H16.7794V47.2H18.1156V45.8638Z' fill='black' />
    <path d='M20.7882 45.8638H19.4519V47.2H20.7882V45.8638Z' fill='black' />
    <path d='M24.7969 45.8638H23.4607V47.2H24.7969V45.8638Z' fill='black' />
    <path d='M26.1332 45.8638H24.7969V47.2H26.1332V45.8638Z' fill='black' />
    <path d='M34.1507 45.8638L32.8145 45.8638V47.2H34.1507V45.8638Z' fill='black' />
    <path d='M36.8233 45.8638H35.487V47.2H36.8233V45.8638Z' fill='black' />
    <path d='M39.4958 45.8638L38.1595 45.8638V47.2H39.4958V45.8638Z' fill='black' />
    <path d='M40.832 45.8638H39.4958V47.2H40.832V45.8638Z' fill='black' />
    <path d='M42.1683 45.8638H40.832V47.2H42.1683V45.8638Z' fill='black' />
    <path d='M43.5045 45.8638H42.1683V47.2H43.5045V45.8638Z' fill='black' />
    <path d='M44.8408 45.8638H43.5045V47.2H44.8408V45.8638Z' fill='black' />
    <path d='M46.1771 45.8638H44.8408V47.2H46.1771V45.8638Z' fill='black' />
    <path d='M50.1858 45.8638H48.8496V47.2H50.1858V45.8638Z' fill='black' />
    <path d='M51.5221 45.8638H50.1858V47.2H51.5221V45.8638Z' fill='black' />
    <path d='M54.1946 45.8638H52.8584V47.2H54.1946V45.8638Z' fill='black' />
    <path d='M55.5309 45.8638H54.1946V47.2H55.5309V45.8638Z' fill='black' />
    <path d='M56.8671 45.8638H55.5309V47.2H56.8671V45.8638Z' fill='black' />
    <path d='M58.2034 45.8638H56.8671V47.2H58.2034V45.8638Z' fill='black' />
    <path d='M60.8759 45.8638H59.5396V47.2H60.8759V45.8638Z' fill='black' />
    <path d='M62.2122 45.8638L60.8759 45.8638V47.2H62.2121L62.2122 45.8638Z' fill='black' />
    <path d='M68.8934 45.8638H67.5572L67.5572 47.2H68.8934V45.8638Z' fill='black' />
    <path d='M71.566 45.8638H70.2297L70.2297 47.2H71.566V45.8638Z' fill='black' />
    <path d='M74.2385 45.8638H72.9022V47.2H74.2385V45.8638Z' fill='black' />
    <path d='M10.0981 47.2H8.76184V48.5363H10.0981V47.2Z' fill='black' />
    <path d='M11.4344 47.2H10.0981V48.5363H11.4344V47.2Z' fill='black' />
    <path d='M15.4431 47.2H14.1069V48.5363H15.4431V47.2Z' fill='black' />
    <path d='M20.7882 47.2H19.4519V48.5363H20.7882V47.2Z' fill='black' />
    <path d='M22.1244 47.2H20.7882V48.5363H22.1244V47.2Z' fill='black' />
    <path d='M23.4607 47.2H22.1244V48.5363H23.4607V47.2Z' fill='black' />
    <path d='M24.7969 47.2H23.4607V48.5363H24.7969V47.2Z' fill='black' />
    <path d='M26.1332 47.2H24.7969V48.5363H26.1332V47.2Z' fill='black' />
    <path d='M28.8057 47.2H27.4695V48.5363H28.8057V47.2Z' fill='black' />
    <path d='M30.142 47.2H28.8057V48.5363H30.142V47.2Z' fill='black' />
    <path d='M31.4782 47.2H30.142V48.5363H31.4782V47.2Z' fill='black' />
    <path d='M34.1507 47.2H32.8145V48.5363H34.1507V47.2Z' fill='black' />
    <path d='M36.8233 47.2H35.487V48.5363H36.8233V47.2Z' fill='black' />
    <path d='M40.832 47.2H39.4958V48.5363H40.832V47.2Z' fill='black' />
    <path d='M42.1683 47.2H40.832V48.5363H42.1683V47.2Z' fill='black' />
    <path d='M48.8496 47.2H47.5133V48.5363H48.8496L48.8496 47.2Z' fill='black' />
    <path d='M52.8584 47.2H51.5221L51.5221 48.5363H52.8584V47.2Z' fill='black' />
    <path d='M58.2034 47.2H56.8671L56.8671 48.5363H58.2034V47.2Z' fill='black' />
    <path d='M60.8759 47.2H59.5396V48.5363H60.8759V47.2Z' fill='black' />
    <path d='M64.8847 47.2H63.5484V48.5363H64.8847V47.2Z' fill='black' />
    <path d='M66.2209 47.2H64.8847V48.5363H66.2209V47.2Z' fill='black' />
    <path d='M71.566 47.2H70.2297V48.5363H71.566V47.2Z' fill='black' />
    <path d='M74.2385 47.2H72.9022V48.5363H74.2385V47.2Z' fill='black' />
    <path d='M14.1069 48.5363L12.7706 48.5363V49.8725H14.1069V48.5363Z' fill='black' />
    <path d='M15.4431 48.5363H14.1069V49.8725H15.4431V48.5363Z' fill='black' />
    <path d='M16.7794 48.5363L15.4431 48.5363V49.8725H16.7794V48.5363Z' fill='black' />
    <path d='M18.1156 48.5363H16.7794V49.8725H18.1156V48.5363Z' fill='black' />
    <path d='M20.7882 48.5363H19.4519V49.8725H20.7882V48.5363Z' fill='black' />
    <path d='M24.7969 48.5363H23.4607V49.8725H24.7969V48.5363Z' fill='black' />
    <path d='M26.1332 48.5363H24.7969V49.8725H26.1332V48.5363Z' fill='black' />
    <path d='M31.4782 48.5363H30.142V49.8725H31.4782V48.5363Z' fill='black' />
    <path d='M34.1507 48.5363H32.8145V49.8725H34.1507V48.5363Z' fill='black' />
    <path d='M40.832 48.5363H39.4958V49.8725H40.832V48.5363Z' fill='black' />
    <path d='M47.5133 48.5363L46.1771 48.5363V49.8725H47.5133V48.5363Z' fill='black' />
    <path d='M51.5221 48.5363L50.1858 48.5363V49.8725H51.5221L51.5221 48.5363Z' fill='black' />
    <path d='M54.1946 48.5363L52.8584 48.5363V49.8725H54.1946V48.5363Z' fill='black' />
    <path d='M55.5309 48.5363H54.1946V49.8725H55.5309V48.5363Z' fill='black' />
    <path d='M56.8671 48.5363L55.5309 48.5363V49.8725H56.8671L56.8671 48.5363Z' fill='black' />
    <path d='M58.2034 48.5363H56.8671L56.8671 49.8725H58.2034V48.5363Z' fill='black' />
    <path d='M63.5484 48.5363L62.2122 48.5363V49.8725H63.5484V48.5363Z' fill='black' />
    <path d='M68.8934 48.5363H67.5572V49.8725H68.8934V48.5363Z' fill='black' />
    <path d='M74.2385 48.5363H72.9022V49.8725H74.2385V48.5363Z' fill='black' />
    <path d='M11.4344 49.8725H10.0981V51.2088H11.4344V49.8725Z' fill='black' />
    <path d='M12.7706 49.8725L11.4344 49.8725V51.2088H12.7706V49.8725Z' fill='black' />
    <path d='M14.1069 49.8725H12.7706V51.2088H14.1069V49.8725Z' fill='black' />
    <path d='M19.4519 49.8725H18.1156V51.2088H19.4519V49.8725Z' fill='black' />
    <path d='M20.7882 49.8725H19.4519V51.2088H20.7882V49.8725Z' fill='black' />
    <path d='M22.1244 49.8725L20.7882 49.8725V51.2088H22.1244V49.8725Z' fill='black' />
    <path d='M23.4607 49.8725L22.1244 49.8725V51.2088H23.4607V49.8725Z' fill='black' />
    <path d='M24.7969 49.8725H23.4607V51.2088H24.7969V49.8725Z' fill='black' />
    <path d='M27.4695 49.8725L26.1332 49.8725V51.2088H27.4695V49.8725Z' fill='black' />
    <path d='M28.8057 49.8725H27.4695V51.2088H28.8057V49.8725Z' fill='black' />
    <path d='M31.4782 49.8725H30.142V51.2088H31.4782V49.8725Z' fill='black' />
    <path d='M36.8233 49.8725H35.487V51.2088H36.8233V49.8725Z' fill='black' />
    <path d='M38.1595 49.8725H36.8233V51.2088H38.1595V49.8725Z' fill='black' />
    <path d='M42.1683 49.8725L40.832 49.8725V51.2088H42.1683V49.8725Z' fill='black' />
    <path d='M43.5045 49.8725H42.1683V51.2088H43.5045V49.8725Z' fill='black' />
    <path d='M44.8408 49.8725H43.5045V51.2088H44.8408V49.8725Z' fill='black' />
    <path d='M46.1771 49.8725L44.8408 49.8725V51.2088H46.1771L46.1771 49.8725Z' fill='black' />
    <path d='M50.1858 49.8725L48.8496 49.8725V51.2088H50.1858L50.1858 49.8725Z' fill='black' />
    <path d='M52.8584 49.8725H51.5221L51.5221 51.2088H52.8584V49.8725Z' fill='black' />
    <path d='M54.1946 49.8725H52.8584V51.2088H54.1946V49.8725Z' fill='black' />
    <path d='M56.8671 49.8725H55.5309L55.5309 51.2088H56.8671V49.8725Z' fill='black' />
    <path d='M58.2034 49.8725H56.8671V51.2088H58.2034V49.8725Z' fill='black' />
    <path d='M59.5396 49.8725L58.2034 49.8725V51.2088H59.5396V49.8725Z' fill='black' />
    <path d='M60.8759 49.8725H59.5396V51.2088H60.8759V49.8725Z' fill='black' />
    <path d='M62.2122 49.8725L60.8759 49.8725V51.2088H62.2121L62.2122 49.8725Z' fill='black' />
    <path d='M66.2209 49.8725H64.8847V51.2088H66.2209V49.8725Z' fill='black' />
    <path d='M68.8934 49.8725H67.5572V51.2088H68.8934V49.8725Z' fill='black' />
    <path d='M71.566 49.8725H70.2297V51.2088H71.566V49.8725Z' fill='black' />
    <path d='M11.4344 51.2088H10.0981V52.545H11.4344V51.2088Z' fill='black' />
    <path d='M12.7706 51.2088H11.4344V52.545H12.7706V51.2088Z' fill='black' />
    <path d='M15.4431 51.2088L14.1069 51.2088V52.545H15.4431V51.2088Z' fill='black' />
    <path d='M16.7794 51.2088H15.4431V52.545H16.7794V51.2088Z' fill='black' />
    <path d='M18.1156 51.2088L16.7794 51.2088V52.545H18.1156V51.2088Z' fill='black' />
    <path d='M23.4607 51.2088H22.1244V52.545H23.4607V51.2088Z' fill='black' />
    <path d='M26.1332 51.2088H24.7969V52.545H26.1332V51.2088Z' fill='black' />
    <path d='M28.8057 51.2088H27.4695V52.545H28.8057V51.2088Z' fill='black' />
    <path d='M31.4782 51.2088H30.142V52.545H31.4782V51.2088Z' fill='black' />
    <path d='M32.8145 51.2088L31.4782 51.2088V52.545H32.8145V51.2088Z' fill='black' />
    <path d='M34.1507 51.2088H32.8145V52.545H34.1507V51.2088Z' fill='black' />
    <path d='M35.487 51.2088L34.1507 51.2088V52.545H35.487V51.2088Z' fill='black' />
    <path d='M39.4958 51.2088L38.1595 51.2088V52.545H39.4958V51.2088Z' fill='black' />
    <path d='M40.832 51.2088L39.4958 51.2088V52.545H40.832V51.2088Z' fill='black' />
    <path d='M42.1683 51.2088H40.832V52.545H42.1683V51.2088Z' fill='black' />
    <path d='M43.5045 51.2088H42.1683V52.545H43.5045V51.2088Z' fill='black' />
    <path d='M46.1771 51.2088H44.8408V52.545H46.1771V51.2088Z' fill='black' />
    <path d='M47.5133 51.2088L46.1771 51.2088V52.545H47.5133V51.2088Z' fill='black' />
    <path d='M52.8584 51.2088H51.5221V52.545H52.8584V51.2088Z' fill='black' />
    <path d='M54.1946 51.2088H52.8584V52.545H54.1946V51.2088Z' fill='black' />
    <path d='M58.2034 51.2088H56.8671L56.8671 52.545H58.2034V51.2088Z' fill='black' />
    <path d='M59.5396 51.2088H58.2034V52.545H59.5396V51.2088Z' fill='black' />
    <path d='M60.8759 51.2088H59.5396V52.545H60.8759V51.2088Z' fill='black' />
    <path d='M63.5484 51.2088L62.2121 51.2088L62.2122 52.545H63.5484V51.2088Z' fill='black' />
    <path d='M64.8847 51.2088L63.5484 51.2088V52.545H64.8847L64.8847 51.2088Z' fill='black' />
    <path d='M67.5572 51.2088H66.2209L66.2209 52.545H67.5572L67.5572 51.2088Z' fill='black' />
    <path d='M70.2297 51.2088H68.8934V52.545H70.2297L70.2297 51.2088Z' fill='black' />
    <path d='M71.566 51.2088H70.2297L70.2297 52.545H71.566V51.2088Z' fill='black' />
    <path d='M72.9022 51.2088L71.566 51.2088V52.545H72.9022V51.2088Z' fill='black' />
    <path d='M74.2385 51.2088H72.9022V52.545H74.2385V51.2088Z' fill='black' />
    <path d='M10.0981 52.545H8.76184V53.8813H10.0981V52.545Z' fill='black' />
    <path d='M11.4344 52.545H10.0981V53.8813H11.4344V52.545Z' fill='black' />
    <path d='M12.7706 52.545H11.4344V53.8813H12.7706V52.545Z' fill='black' />
    <path d='M15.4431 52.545H14.1069V53.8813H15.4431V52.545Z' fill='black' />
    <path d='M16.7794 52.545H15.4431V53.8813H16.7794V52.545Z' fill='black' />
    <path d='M20.7882 52.545H19.4519V53.8813H20.7882V52.545Z' fill='black' />
    <path d='M24.7969 52.545H23.4607V53.8813H24.7969V52.545Z' fill='black' />
    <path d='M27.4695 52.545H26.1332V53.8813H27.4695V52.545Z' fill='black' />
    <path d='M30.142 52.545H28.8057V53.8813H30.142V52.545Z' fill='black' />
    <path d='M31.4782 52.545H30.142V53.8813H31.4782V52.545Z' fill='black' />
    <path d='M32.8145 52.545H31.4782V53.8813H32.8145V52.545Z' fill='black' />
    <path d='M34.1507 52.545H32.8145V53.8813H34.1507V52.545Z' fill='black' />
    <path d='M35.487 52.545H34.1507V53.8813H35.487V52.545Z' fill='black' />
    <path d='M36.8233 52.545H35.487V53.8813H36.8233V52.545Z' fill='black' />
    <path d='M38.1595 52.545H36.8233V53.8813H38.1595V52.545Z' fill='black' />
    <path d='M44.8408 52.545H43.5045V53.8813H44.8408V52.545Z' fill='black' />
    <path d='M64.8847 52.545H63.5484V53.8813H64.8847V52.545Z' fill='black' />
    <path d='M68.8934 52.545H67.5572L67.5572 53.8813H68.8934V52.545Z' fill='black' />
    <path d='M72.9022 52.545H71.566L71.566 53.8813H72.9022V52.545Z' fill='black' />
    <path d='M74.2385 52.545H72.9022V53.8813H74.2385V52.545Z' fill='black' />
    <path d='M11.4344 53.8813H10.0981V55.2176H11.4344V53.8813Z' fill='black' />
    <path d='M16.7794 53.8813H15.4431V55.2176H16.7794V53.8813Z' fill='black' />
    <path d='M18.1156 53.8813L16.7794 53.8813V55.2176H18.1156V53.8813Z' fill='black' />
    <path d='M19.4519 53.8813L18.1156 53.8813V55.2176H19.4519V53.8813Z' fill='black' />
    <path d='M20.7882 53.8813H19.4519V55.2176H20.7882V53.8813Z' fill='black' />
    <path d='M23.4607 53.8813L22.1244 53.8813V55.2176H23.4607V53.8813Z' fill='black' />
    <path d='M24.7969 53.8813H23.4607V55.2176H24.7969V53.8813Z' fill='black' />
    <path d='M26.1332 53.8813H24.7969V55.2176H26.1332V53.8813Z' fill='black' />
    <path d='M27.4695 53.8813H26.1332V55.2176H27.4695V53.8813Z' fill='black' />
    <path d='M34.1507 53.8813H32.8145V55.2176H34.1507V53.8813Z' fill='black' />
    <path d='M35.487 53.8813H34.1507V55.2176H35.487V53.8813Z' fill='black' />
    <path d='M36.8233 53.8813H35.487V55.2176H36.8233V53.8813Z' fill='black' />
    <path d='M39.4958 53.8813L38.1595 53.8813V55.2176H39.4958V53.8813Z' fill='black' />
    <path d='M40.832 53.8813H39.4958V55.2176H40.832V53.8813Z' fill='black' />
    <path d='M43.5045 53.8813L42.1683 53.8813V55.2176H43.5045V53.8813Z' fill='black' />
    <path d='M47.5133 53.8813H46.1771V55.2176H47.5133V53.8813Z' fill='black' />
    <path d='M54.1946 53.8813H52.8584V55.2176H54.1946V53.8813Z' fill='black' />
    <path d='M56.8671 53.8813H55.5309V55.2176H56.8671V53.8813Z' fill='black' />
    <path d='M59.5396 53.8813H58.2034V55.2176H59.5396V53.8813Z' fill='black' />
    <path d='M68.8934 53.8813H67.5572V55.2176H68.8934V53.8813Z' fill='black' />
    <path d='M71.566 53.8813L70.2297 53.8813V55.2176H71.566L71.566 53.8813Z' fill='black' />
    <path d='M74.2385 53.8813H72.9022L72.9022 55.2176H74.2385V53.8813Z' fill='black' />
    <path d='M14.1069 55.2176H12.7706V56.5538H14.1069V55.2176Z' fill='black' />
    <path d='M19.4519 55.2176H18.1156V56.5538H19.4519V55.2176Z' fill='black' />
    <path d='M22.1244 55.2176H20.7882V56.5538H22.1244V55.2176Z' fill='black' />
    <path d='M23.4607 55.2176H22.1244V56.5538H23.4607V55.2176Z' fill='black' />
    <path d='M26.1332 55.2176H24.7969V56.5538H26.1332V55.2176Z' fill='black' />
    <path d='M32.8145 55.2176L31.4782 55.2176V56.5538H32.8145V55.2176Z' fill='black' />
    <path d='M36.8233 55.2176H35.487V56.5538H36.8233V55.2176Z' fill='black' />
    <path d='M39.4958 55.2176H38.1595V56.5538H39.4958V55.2176Z' fill='black' />
    <path d='M42.1683 55.2176H40.832V56.5538H42.1683V55.2176Z' fill='black' />
    <path d='M44.8408 55.2176L43.5045 55.2176V56.5538H44.8408V55.2176Z' fill='black' />
    <path d='M46.1771 55.2176L44.8408 55.2176V56.5538H46.1771L46.1771 55.2176Z' fill='black' />
    <path d='M47.5133 55.2176H46.1771L46.1771 56.5538H47.5133V55.2176Z' fill='black' />
    <path d='M48.8496 55.2176L47.5133 55.2176V56.5538H48.8496V55.2176Z' fill='black' />
    <path d='M51.5221 55.2176H50.1858V56.5538H51.5221V55.2176Z' fill='black' />
    <path d='M54.1946 55.2176H52.8584V56.5538H54.1946V55.2176Z' fill='black' />
    <path d='M55.5309 55.2176H54.1946V56.5538H55.5309L55.5309 55.2176Z' fill='black' />
    <path d='M56.8671 55.2176H55.5309L55.5309 56.5538H56.8671V55.2176Z' fill='black' />
    <path d='M60.8759 55.2176L59.5396 55.2176L59.5396 56.5538H60.8759V55.2176Z' fill='black' />
    <path d='M62.2121 55.2176H60.8759V56.5538H62.2121V55.2176Z' fill='black' />
    <path d='M63.5484 55.2176H62.2121V56.5538H63.5484V55.2176Z' fill='black' />
    <path d='M66.2209 55.2176H64.8847V56.5538H66.2209V55.2176Z' fill='black' />
    <path d='M67.5572 55.2176L66.2209 55.2176V56.5538H67.5572L67.5572 55.2176Z' fill='black' />
    <path d='M70.2297 55.2176H68.8934V56.5538H70.2297L70.2297 55.2176Z' fill='black' />
    <path d='M71.566 55.2176H70.2297L70.2297 56.5538H71.566V55.2176Z' fill='black' />
    <path d='M72.9022 55.2176H71.566V56.5538H72.9022L72.9022 55.2176Z' fill='black' />
    <path d='M11.4344 56.5538H10.0981V57.8901H11.4344V56.5538Z' fill='black' />
    <path d='M15.4431 56.5538L14.1069 56.5538V57.8901H15.4431V56.5538Z' fill='black' />
    <path d='M16.7794 56.5538H15.4431V57.8901H16.7794V56.5538Z' fill='black' />
    <path d='M18.1156 56.5538L16.7794 56.5538V57.8901H18.1156V56.5538Z' fill='black' />
    <path d='M19.4519 56.5538H18.1156V57.8901H19.4519V56.5538Z' fill='black' />
    <path d='M20.7882 56.5538H19.4519V57.8901H20.7882V56.5538Z' fill='black' />
    <path d='M24.7969 56.5538H23.4607V57.8901H24.7969V56.5538Z' fill='black' />
    <path d='M26.1332 56.5538H24.7969V57.8901H26.1332V56.5538Z' fill='black' />
    <path d='M27.4695 56.5538L26.1332 56.5538V57.8901H27.4695V56.5538Z' fill='black' />
    <path d='M28.8057 56.5538H27.4695V57.8901H28.8057V56.5538Z' fill='black' />
    <path d='M31.4782 56.5538L30.142 56.5538V57.8901H31.4782V56.5538Z' fill='black' />
    <path d='M34.1507 56.5538L32.8145 56.5538V57.8901H34.1507V56.5538Z' fill='black' />
    <path d='M35.487 56.5538L34.1507 56.5538V57.8901H35.487V56.5538Z' fill='black' />
    <path d='M36.8233 56.5538H35.487V57.8901H36.8233V56.5538Z' fill='black' />
    <path d='M38.1595 56.5538H36.8233V57.8901H38.1595V56.5538Z' fill='black' />
    <path d='M39.4958 56.5538H38.1595V57.8901H39.4958V56.5538Z' fill='black' />
    <path d='M40.832 56.5538H39.4958V57.8901H40.832V56.5538Z' fill='black' />
    <path d='M42.1683 56.5538H40.832V57.8901H42.1683V56.5538Z' fill='black' />
    <path d='M44.8408 56.5538H43.5045V57.8901H44.8408V56.5538Z' fill='black' />
    <path d='M46.1771 56.5538H44.8408V57.8901H46.1771V56.5538Z' fill='black' />
    <path d='M47.5133 56.5538H46.1771V57.8901H47.5133V56.5538Z' fill='black' />
    <path d='M54.1946 56.5538H52.8584V57.8901H54.1946V56.5538Z' fill='black' />
    <path d='M56.8671 56.5538H55.5309L55.5309 57.8901H56.8671V56.5538Z' fill='black' />
    <path d='M58.2034 56.5538L56.8671 56.5538V57.8901H58.2034V56.5538Z' fill='black' />
    <path d='M62.2121 56.5538H60.8759L60.8759 57.8901H62.2121V56.5538Z' fill='black' />
    <path d='M63.5484 56.5538H62.2121V57.8901H63.5484V56.5538Z' fill='black' />
    <path d='M66.2209 56.5538H64.8847V57.8901H66.2209V56.5538Z' fill='black' />
    <path d='M67.5572 56.5538H66.2209V57.8901H67.5572V56.5538Z' fill='black' />
    <path d='M68.8934 56.5538H67.5572V57.8901H68.8934V56.5538Z' fill='black' />
    <path d='M70.2297 56.5538H68.8934V57.8901H70.2297V56.5538Z' fill='black' />
    <path d='M71.566 56.5538H70.2297V57.8901H71.566V56.5538Z' fill='black' />
    <path d='M74.2385 56.5538L72.9022 56.5538L72.9022 57.8901H74.2385V56.5538Z' fill='black' />
    <path d='M10.0981 57.8901H8.76184V59.2263H10.0981V57.8901Z' fill='black' />
    <path d='M11.4344 57.8901H10.0981V59.2263H11.4344V57.8901Z' fill='black' />
    <path d='M22.1244 57.8901H20.7882V59.2263H22.1244V57.8901Z' fill='black' />
    <path d='M26.1332 57.8901H24.7969V59.2263H26.1332V57.8901Z' fill='black' />
    <path d='M28.8057 57.8901H27.4695V59.2263H28.8057V57.8901Z' fill='black' />
    <path d='M30.142 57.8901H28.8057V59.2263H30.142V57.8901Z' fill='black' />
    <path d='M32.8145 57.8901H31.4782V59.2263H32.8145V57.8901Z' fill='black' />
    <path d='M35.487 57.8901H34.1507V59.2263H35.487V57.8901Z' fill='black' />
    <path d='M36.8233 57.8901H35.487V59.2263H36.8233V57.8901Z' fill='black' />
    <path d='M38.1595 57.8901H36.8233V59.2263H38.1595V57.8901Z' fill='black' />
    <path d='M39.4958 57.8901H38.1595V59.2263H39.4958V57.8901Z' fill='black' />
    <path d='M46.1771 57.8901H44.8408V59.2263H46.1771V57.8901Z' fill='black' />
    <path d='M51.5221 57.8901H50.1858V59.2263H51.5221V57.8901Z' fill='black' />
    <path d='M55.5309 57.8901H54.1946L54.1946 59.2263H55.5309L55.5309 57.8901Z' fill='black' />
    <path d='M59.5396 57.8901H58.2034V59.2263H59.5396V57.8901Z' fill='black' />
    <path d='M66.2209 57.8901H64.8847V59.2263H66.2209V57.8901Z' fill='black' />
    <path d='M68.8934 57.8901H67.5572L67.5572 59.2263H68.8934V57.8901Z' fill='black' />
    <path d='M70.2297 57.8901H68.8934V59.2263H70.2297V57.8901Z' fill='black' />
    <path d='M72.9022 57.8901H71.566L71.566 59.2263H72.9022L72.9022 57.8901Z' fill='black' />
    <path d='M74.2385 57.8901H72.9022L72.9022 59.2263H74.2385V57.8901Z' fill='black' />
    <path d='M11.4344 59.2263H10.0981V60.5626H11.4344V59.2263Z' fill='black' />
    <path d='M16.7794 59.2263H15.4431V60.5626H16.7794V59.2263Z' fill='black' />
    <path d='M18.1156 59.2263H16.7794V60.5626H18.1156V59.2263Z' fill='black' />
    <path d='M19.4519 59.2263H18.1156V60.5626H19.4519V59.2263Z' fill='black' />
    <path d='M20.7882 59.2263L19.4519 59.2263V60.5626H20.7882V59.2263Z' fill='black' />
    <path d='M24.7969 59.2263L23.4607 59.2263V60.5626H24.7969V59.2263Z' fill='black' />
    <path d='M26.1332 59.2263H24.7969V60.5626H26.1332V59.2263Z' fill='black' />
    <path d='M28.8057 59.2263H27.4695V60.5626H28.8057V59.2263Z' fill='black' />
    <path d='M31.4782 59.2263H30.142V60.5626H31.4782V59.2263Z' fill='black' />
    <path d='M32.8145 59.2263H31.4782V60.5626H32.8145V59.2263Z' fill='black' />
    <path d='M34.1507 59.2263H32.8145V60.5626H34.1507V59.2263Z' fill='black' />
    <path d='M36.8233 59.2263H35.487V60.5626H36.8233V59.2263Z' fill='black' />
    <path d='M40.832 59.2263L39.4958 59.2263V60.5626H40.832V59.2263Z' fill='black' />
    <path d='M44.8408 59.2263L43.5045 59.2263V60.5626H44.8408V59.2263Z' fill='black' />
    <path d='M48.8496 59.2263H47.5133V60.5626H48.8496V59.2263Z' fill='black' />
    <path d='M50.1858 59.2263L48.8496 59.2263V60.5626H50.1858L50.1858 59.2263Z' fill='black' />
    <path d='M51.5221 59.2263H50.1858L50.1858 60.5626H51.5221V59.2263Z' fill='black' />
    <path d='M52.8584 59.2263L51.5221 59.2263V60.5626H52.8584V59.2263Z' fill='black' />
    <path d='M54.1946 59.2263L52.8584 59.2263V60.5626H54.1946L54.1946 59.2263Z' fill='black' />
    <path d='M55.5309 59.2263H54.1946L54.1946 60.5626H55.5309V59.2263Z' fill='black' />
    <path d='M60.8759 59.2263L59.5396 59.2263L59.5396 60.5626H60.8759V59.2263Z' fill='black' />
    <path d='M62.2121 59.2263H60.8759V60.5626H62.2121V59.2263Z' fill='black' />
    <path d='M64.8847 59.2263L63.5484 59.2263V60.5626H64.8847L64.8847 59.2263Z' fill='black' />
    <path d='M66.2209 59.2263H64.8847L64.8847 60.5626H66.2209V59.2263Z' fill='black' />
    <path d='M72.9022 59.2263H71.566V60.5626H72.9022V59.2263Z' fill='black' />
    <path d='M74.2385 59.2263H72.9022V60.5626H74.2385V59.2263Z' fill='black' />
    <path d='M11.4344 60.5626H10.0981V61.8988H11.4344V60.5626Z' fill='black' />
    <path d='M12.7706 60.5626L11.4344 60.5626V61.8988H12.7706V60.5626Z' fill='black' />
    <path d='M14.1069 60.5626H12.7706V61.8988H14.1069V60.5626Z' fill='black' />
    <path d='M20.7882 60.5626H19.4519V61.8988H20.7882V60.5626Z' fill='black' />
    <path d='M22.1244 60.5626L20.7882 60.5626V61.8988H22.1244V60.5626Z' fill='black' />
    <path d='M23.4607 60.5626L22.1244 60.5626V61.8988H23.4607V60.5626Z' fill='black' />
    <path d='M27.4695 60.5626H26.1332V61.8988H27.4695V60.5626Z' fill='black' />
    <path d='M28.8057 60.5626H27.4695V61.8988H28.8057V60.5626Z' fill='black' />
    <path d='M35.487 60.5626H34.1507V61.8988H35.487V60.5626Z' fill='black' />
    <path d='M36.8233 60.5626H35.487V61.8988H36.8233V60.5626Z' fill='black' />
    <path d='M39.4958 60.5626L38.1595 60.5626V61.8988H39.4958V60.5626Z' fill='black' />
    <path d='M42.1683 60.5626L40.832 60.5626V61.8988H42.1683V60.5626Z' fill='black' />
    <path d='M43.5045 60.5626L42.1683 60.5626V61.8988H43.5045V60.5626Z' fill='black' />
    <path d='M48.8496 60.5626H47.5133V61.8988H48.8496V60.5626Z' fill='black' />
    <path d='M50.1858 60.5626H48.8496V61.8988H50.1858V60.5626Z' fill='black' />
    <path d='M54.1946 60.5626H52.8584V61.8988H54.1946V60.5626Z' fill='black' />
    <path d='M56.8671 60.5626L55.5309 60.5626L55.5309 61.8988H56.8671V60.5626Z' fill='black' />
    <path d='M66.2209 60.5626H64.8847L64.8847 61.8988H66.2209V60.5626Z' fill='black' />
    <path d='M68.8934 60.5626H67.5572V61.8988H68.8934V60.5626Z' fill='black' />
    <path d='M71.566 60.5626L70.2297 60.5626V61.8988H71.566L71.566 60.5626Z' fill='black' />
    <path d='M10.0981 61.8988L8.76184 61.8988V63.2351H10.0981V61.8988Z' fill='black' />
    <path d='M11.4344 61.8988H10.0981V63.2351H11.4344V61.8988Z' fill='black' />
    <path d='M12.7706 61.8988H11.4344V63.2351H12.7706V61.8988Z' fill='black' />
    <path d='M18.1156 61.8988H16.7794V63.2351H18.1156V61.8988Z' fill='black' />
    <path d='M19.4519 61.8988L18.1156 61.8988V63.2351H19.4519V61.8988Z' fill='black' />
    <path d='M24.7969 61.8988L23.4607 61.8988V63.2351H24.7969V61.8988Z' fill='black' />
    <path d='M27.4695 61.8988H26.1332V63.2351H27.4695V61.8988Z' fill='black' />
    <path d='M31.4782 61.8988H30.142V63.2351H31.4782V61.8988Z' fill='black' />
    <path d='M34.1507 61.8988L32.8145 61.8988V63.2351H34.1507V61.8988Z' fill='black' />
    <path d='M39.4958 61.8988H38.1595V63.2351H39.4958V61.8988Z' fill='black' />
    <path d='M40.832 61.8988H39.4958V63.2351H40.832V61.8988Z' fill='black' />
    <path d='M42.1683 61.8988H40.832V63.2351H42.1683V61.8988Z' fill='black' />
    <path d='M43.5045 61.8988H42.1683V63.2351H43.5045V61.8988Z' fill='black' />
    <path d='M44.8408 61.8988L43.5045 61.8988V63.2351H44.8408V61.8988Z' fill='black' />
    <path d='M51.5221 61.8988L50.1858 61.8988L50.1858 63.2351H51.5221V61.8988Z' fill='black' />
    <path d='M54.1946 61.8988H52.8584V63.2351H54.1946V61.8988Z' fill='black' />
    <path d='M55.5309 61.8988H54.1946V63.2351H55.5309L55.5309 61.8988Z' fill='black' />
    <path d='M56.8671 61.8988H55.5309L55.5309 63.2351H56.8671V61.8988Z' fill='black' />
    <path d='M58.2034 61.8988L56.8671 61.8988V63.2351H58.2034V61.8988Z' fill='black' />
    <path d='M59.5396 61.8988H58.2034V63.2351H59.5396V61.8988Z' fill='black' />
    <path d='M62.2121 61.8988H60.8759V63.2351H62.2121V61.8988Z' fill='black' />
    <path d='M63.5484 61.8988H62.2121V63.2351H63.5484V61.8988Z' fill='black' />
    <path d='M64.8847 61.8988L63.5484 61.8988V63.2351H64.8847L64.8847 61.8988Z' fill='black' />
    <path d='M66.2209 61.8988H64.8847L64.8847 63.2351H66.2209V61.8988Z' fill='black' />
    <path d='M67.5572 61.8988H66.2209V63.2351H67.5572L67.5572 61.8988Z' fill='black' />
    <path d='M68.8934 61.8988H67.5572L67.5572 63.2351H68.8934V61.8988Z' fill='black' />
    <path d='M74.2385 61.8988H72.9022V63.2351H74.2385V61.8988Z' fill='black' />
    <path d='M20.7882 63.2351H19.4519V64.5714H20.7882V63.2351Z' fill='black' />
    <path d='M23.4607 63.2351H22.1244V64.5714H23.4607V63.2351Z' fill='black' />
    <path d='M27.4695 63.2351H26.1332V64.5714H27.4695V63.2351Z' fill='black' />
    <path d='M28.8057 63.2351H27.4695V64.5714H28.8057V63.2351Z' fill='black' />
    <path d='M30.142 63.2351H28.8057V64.5714H30.142V63.2351Z' fill='black' />
    <path d='M34.1507 63.2351H32.8145V64.5714H34.1507V63.2351Z' fill='black' />
    <path d='M35.487 63.2351H34.1507V64.5714H35.487V63.2351Z' fill='black' />
    <path d='M38.1595 63.2351H36.8233V64.5714H38.1595V63.2351Z' fill='black' />
    <path d='M39.4958 63.2351H38.1595V64.5714H39.4958V63.2351Z' fill='black' />
    <path d='M44.8408 63.2351H43.5045V64.5714H44.8408V63.2351Z' fill='black' />
    <path d='M47.5133 63.2351H46.1771V64.5714H47.5133V63.2351Z' fill='black' />
    <path d='M48.8496 63.2351H47.5133V64.5714H48.8496V63.2351Z' fill='black' />
    <path d='M59.5396 63.2351H58.2034V64.5714H59.5396V63.2351Z' fill='black' />
    <path d='M63.5484 63.2351H62.2121L62.2122 64.5714H63.5484V63.2351Z' fill='black' />
    <path d='M68.8934 63.2351H67.5572L67.5572 64.5714H68.8934V63.2351Z' fill='black' />
    <path d='M70.2297 63.2351H68.8934V64.5714H70.2297V63.2351Z' fill='black' />
    <path d='M74.2385 63.2351H72.9022V64.5714H74.2385V63.2351Z' fill='black' />
    <path d='M10.0981 64.5714H8.76184V65.9076H10.0981V64.5714Z' fill='black' />
    <path d='M11.4344 64.5714H10.0981V65.9076H11.4344V64.5714Z' fill='black' />
    <path d='M12.7706 64.5714H11.4344V65.9076H12.7706V64.5714Z' fill='black' />
    <path d='M14.1069 64.5714H12.7706V65.9076H14.1069V64.5714Z' fill='black' />
    <path d='M15.4431 64.5714H14.1069V65.9076H15.4431V64.5714Z' fill='black' />
    <path d='M16.7794 64.5714H15.4431V65.9076H16.7794V64.5714Z' fill='black' />
    <path d='M18.1156 64.5714H16.7794V65.9076H18.1156V64.5714Z' fill='black' />
    <path d='M24.7969 64.5714L23.4607 64.5714V65.9076H24.7969V64.5714Z' fill='black' />
    <path d='M31.4782 64.5714L30.142 64.5714V65.9076H31.4782V64.5714Z' fill='black' />
    <path d='M36.8233 64.5714H35.487V65.9076H36.8233V64.5714Z' fill='black' />
    <path d='M39.4958 64.5714H38.1595V65.9076H39.4958V64.5714Z' fill='black' />
    <path d='M42.1683 64.5714H40.832V65.9076H42.1683V64.5714Z' fill='black' />
    <path d='M44.8408 64.5714H43.5045V65.9076H44.8408V64.5714Z' fill='black' />
    <path d='M46.1771 64.5714H44.8408V65.9076H46.1771L46.1771 64.5714Z' fill='black' />
    <path d='M47.5133 64.5714H46.1771L46.1771 65.9076H47.5133V64.5714Z' fill='black' />
    <path d='M48.8496 64.5714H47.5133V65.9076H48.8496V64.5714Z' fill='black' />
    <path d='M50.1858 64.5714L48.8496 64.5714V65.9076H50.1858V64.5714Z' fill='black' />
    <path d='M51.5221 64.5714H50.1858V65.9076H51.5221V64.5714Z' fill='black' />
    <path d='M54.1946 64.5714H52.8584V65.9076H54.1946V64.5714Z' fill='black' />
    <path d='M55.5309 64.5714H54.1946V65.9076H55.5309V64.5714Z' fill='black' />
    <path d='M56.8671 64.5714H55.5309V65.9076H56.8671V64.5714Z' fill='black' />
    <path d='M58.2034 64.5714L56.8671 64.5714V65.9076H58.2034V64.5714Z' fill='black' />
    <path d='M59.5396 64.5714H58.2034V65.9076H59.5396V64.5714Z' fill='black' />
    <path d='M60.8759 64.5714L59.5396 64.5714V65.9076H60.8759V64.5714Z' fill='black' />
    <path d='M63.5484 64.5714H62.2122V65.9076H63.5484V64.5714Z' fill='black' />
    <path d='M66.2209 64.5714H64.8847V65.9076H66.2209V64.5714Z' fill='black' />
    <path d='M68.8934 64.5714H67.5572V65.9076H68.8934V64.5714Z' fill='black' />
    <path d='M71.566 64.5714L70.2297 64.5714L70.2297 65.9076H71.566V64.5714Z' fill='black' />
    <path d='M10.0981 65.9076H8.76184V67.2439H10.0981V65.9076Z' fill='black' />
    <path d='M18.1156 65.9076H16.7794V67.2439H18.1156V65.9076Z' fill='black' />
    <path d='M20.7882 65.9076H19.4519V67.2439H20.7882V65.9076Z' fill='black' />
    <path d='M23.4607 65.9076L22.1244 65.9076V67.2439H23.4607V65.9076Z' fill='black' />
    <path d='M27.4695 65.9076H26.1332V67.2439H27.4695V65.9076Z' fill='black' />
    <path d='M28.8057 65.9076H27.4695V67.2439H28.8057V65.9076Z' fill='black' />
    <path d='M34.1507 65.9076H32.8145V67.2439H34.1507V65.9076Z' fill='black' />
    <path d='M35.487 65.9076L34.1507 65.9076V67.2439H35.487V65.9076Z' fill='black' />
    <path d='M39.4958 65.9076H38.1595V67.2439H39.4958V65.9076Z' fill='black' />
    <path d='M44.8408 65.9076H43.5045V67.2439H44.8408V65.9076Z' fill='black' />
    <path d='M46.1771 65.9076H44.8408V67.2439H46.1771V65.9076Z' fill='black' />
    <path d='M50.1858 65.9076H48.8496L48.8496 67.2439H50.1858V65.9076Z' fill='black' />
    <path d='M52.8584 65.9076H51.5221L51.5221 67.2439H52.8584V65.9076Z' fill='black' />
    <path d='M54.1946 65.9076H52.8584V67.2439H54.1946V65.9076Z' fill='black' />
    <path d='M55.5309 65.9076H54.1946V67.2439H55.5309V65.9076Z' fill='black' />
    <path d='M59.5396 65.9076H58.2034V67.2439H59.5396V65.9076Z' fill='black' />
    <path d='M62.2122 65.9076H60.8759L60.8759 67.2439H62.2121L62.2122 65.9076Z' fill='black' />
    <path d='M63.5484 65.9076H62.2122L62.2121 67.2439H63.5484V65.9076Z' fill='black' />
    <path d='M68.8934 65.9076H67.5572V67.2439H68.8934V65.9076Z' fill='black' />
    <path d='M72.9022 65.9076L71.566 65.9076L71.566 67.2439H72.9022V65.9076Z' fill='black' />
    <path d='M10.0981 67.2439H8.76184V68.5801H10.0981V67.2439Z' fill='black' />
    <path d='M12.7706 67.2439H11.4344V68.5801H12.7706V67.2439Z' fill='black' />
    <path d='M14.1069 67.2439H12.7706V68.5801H14.1069V67.2439Z' fill='black' />
    <path d='M15.4431 67.2439H14.1069V68.5801H15.4431V67.2439Z' fill='black' />
    <path d='M18.1156 67.2439H16.7794V68.5801H18.1156V67.2439Z' fill='black' />
    <path d='M26.1332 67.2439L24.7969 67.2439V68.5801H26.1332V67.2439Z' fill='black' />
    <path d='M28.8057 67.2439H27.4695V68.5801H28.8057V67.2439Z' fill='black' />
    <path d='M30.142 67.2439L28.8057 67.2439V68.5801H30.142V67.2439Z' fill='black' />
    <path d='M31.4782 67.2439H30.142V68.5801H31.4782V67.2439Z' fill='black' />
    <path d='M32.8145 67.2439L31.4782 67.2439V68.5801H32.8145V67.2439Z' fill='black' />
    <path d='M34.1507 67.2439H32.8145V68.5801H34.1507V67.2439Z' fill='black' />
    <path d='M35.487 67.2439H34.1507V68.5801H35.487V67.2439Z' fill='black' />
    <path d='M38.1595 67.2439L36.8233 67.2439V68.5801H38.1595V67.2439Z' fill='black' />
    <path d='M39.4958 67.2439H38.1595V68.5801H39.4958V67.2439Z' fill='black' />
    <path d='M40.832 67.2439L39.4958 67.2439V68.5801H40.832V67.2439Z' fill='black' />
    <path d='M42.1683 67.2439H40.832V68.5801H42.1683V67.2439Z' fill='black' />
    <path d='M43.5045 67.2439L42.1683 67.2439V68.5801H43.5045V67.2439Z' fill='black' />
    <path d='M44.8408 67.2439H43.5045V68.5801H44.8408V67.2439Z' fill='black' />
    <path d='M47.5133 67.2439L46.1771 67.2439L46.1771 68.5801H47.5133V67.2439Z' fill='black' />
    <path d='M48.8496 67.2439L47.5133 67.2439V68.5801H48.8496L48.8496 67.2439Z' fill='black' />
    <path d='M50.1858 67.2439H48.8496L48.8496 68.5801H50.1858V67.2439Z' fill='black' />
    <path d='M52.8584 67.2439H51.5221V68.5801H52.8584V67.2439Z' fill='black' />
    <path d='M55.5309 67.2439H54.1946L54.1946 68.5801H55.5309V67.2439Z' fill='black' />
    <path d='M56.8671 67.2439L55.5309 67.2439V68.5801H56.8671V67.2439Z' fill='black' />
    <path d='M60.8759 67.2439H59.5396L59.5396 68.5801H60.8759L60.8759 67.2439Z' fill='black' />
    <path d='M63.5484 67.2439H62.2121L62.2122 68.5801H63.5484V67.2439Z' fill='black' />
    <path d='M64.8847 67.2439L63.5484 67.2439V68.5801H64.8847V67.2439Z' fill='black' />
    <path d='M66.2209 67.2439H64.8847V68.5801H66.2209V67.2439Z' fill='black' />
    <path d='M67.5572 67.2439L66.2209 67.2439V68.5801H67.5572L67.5572 67.2439Z' fill='black' />
    <path d='M68.8934 67.2439H67.5572L67.5572 68.5801H68.8934V67.2439Z' fill='black' />
    <path d='M70.2297 67.2439L68.8934 67.2439V68.5801H70.2297V67.2439Z' fill='black' />
    <path d='M71.566 67.2439L70.2297 67.2439V68.5801H71.566L71.566 67.2439Z' fill='black' />
    <path d='M72.9022 67.2439H71.566L71.566 68.5801H72.9022V67.2439Z' fill='black' />
    <path d='M74.2385 67.2439L72.9022 67.2439V68.5801H74.2385V67.2439Z' fill='black' />
    <path d='M10.0981 68.5801H8.76184V69.9164H10.0981V68.5801Z' fill='black' />
    <path d='M12.7706 68.5801H11.4344V69.9164H12.7706V68.5801Z' fill='black' />
    <path d='M14.1069 68.5801H12.7706V69.9164H14.1069V68.5801Z' fill='black' />
    <path d='M15.4431 68.5801H14.1069V69.9164H15.4431V68.5801Z' fill='black' />
    <path d='M18.1156 68.5801H16.7794V69.9164H18.1156V68.5801Z' fill='black' />
    <path d='M23.4607 68.5801H22.1244V69.9164H23.4607V68.5801Z' fill='black' />
    <path d='M24.7969 68.5801H23.4607V69.9164H24.7969V68.5801Z' fill='black' />
    <path d='M36.8233 68.5801H35.487V69.9164H36.8233V68.5801Z' fill='black' />
    <path d='M39.4958 68.5801H38.1595V69.9164H39.4958V68.5801Z' fill='black' />
    <path d='M44.8408 68.5801H43.5045V69.9164H44.8408V68.5801Z' fill='black' />
    <path d='M48.8496 68.5801H47.5133V69.9164H48.8496V68.5801Z' fill='black' />
    <path d='M50.1858 68.5801H48.8496V69.9164H50.1858V68.5801Z' fill='black' />
    <path d='M52.8584 68.5801H51.5221V69.9164H52.8584V68.5801Z' fill='black' />
    <path d='M58.2034 68.5801H56.8671L56.8671 69.9164H58.2034V68.5801Z' fill='black' />
    <path d='M60.8759 68.5801H59.5396V69.9164H60.8759V68.5801Z' fill='black' />
    <path d='M64.8847 68.5801H63.5484V69.9164H64.8847V68.5801Z' fill='black' />
    <path d='M66.2209 68.5801H64.8847V69.9164H66.2209V68.5801Z' fill='black' />
    <path d='M74.2385 68.5801H72.9022L72.9022 69.9164H74.2385V68.5801Z' fill='black' />
    <path d='M10.0981 69.9164H8.76184V71.2527H10.0981V69.9164Z' fill='black' />
    <path d='M12.7706 69.9164H11.4344V71.2527H12.7706V69.9164Z' fill='black' />
    <path d='M14.1069 69.9164H12.7706V71.2527H14.1069V69.9164Z' fill='black' />
    <path d='M15.4431 69.9164H14.1069V71.2527H15.4431V69.9164Z' fill='black' />
    <path d='M18.1156 69.9164H16.7794V71.2527H18.1156V69.9164Z' fill='black' />
    <path d='M28.8057 69.9164H27.4695V71.2527H28.8057V69.9164Z' fill='black' />
    <path d='M34.1507 69.9164H32.8145V71.2527H34.1507V69.9164Z' fill='black' />
    <path d='M35.487 69.9164L34.1507 69.9164V71.2527H35.487V69.9164Z' fill='black' />
    <path d='M39.4958 69.9164H38.1595V71.2527H39.4958V69.9164Z' fill='black' />
    <path d='M42.1683 69.9164H40.832V71.2527H42.1683V69.9164Z' fill='black' />
    <path d='M44.8408 69.9164H43.5045V71.2527H44.8408V69.9164Z' fill='black' />
    <path d='M47.5133 69.9164L46.1771 69.9164V71.2527H47.5133V69.9164Z' fill='black' />
    <path d='M50.1858 69.9164H48.8496L48.8496 71.2527H50.1858V69.9164Z' fill='black' />
    <path d='M51.5221 69.9164H50.1858V71.2527H51.5221L51.5221 69.9164Z' fill='black' />
    <path d='M52.8584 69.9164H51.5221L51.5221 71.2527H52.8584V69.9164Z' fill='black' />
    <path d='M59.5396 69.9164H58.2034V71.2527H59.5396L59.5396 69.9164Z' fill='black' />
    <path d='M60.8759 69.9164H59.5396L59.5396 71.2527H60.8759V69.9164Z' fill='black' />
    <path d='M64.8847 69.9164H63.5484V71.2527H64.8847V69.9164Z' fill='black' />
    <path d='M66.2209 69.9164H64.8847V71.2527H66.2209V69.9164Z' fill='black' />
    <path d='M67.5572 69.9164L66.2209 69.9164V71.2527H67.5572V69.9164Z' fill='black' />
    <path d='M71.566 69.9164H70.2297V71.2527H71.566V69.9164Z' fill='black' />
    <path d='M74.2385 69.9164H72.9022V71.2527H74.2385V69.9164Z' fill='black' />
    <path d='M10.0981 71.2527H8.76184V72.5889H10.0981V71.2527Z' fill='black' />
    <path d='M18.1156 71.2527H16.7794V72.5889H18.1156V71.2527Z' fill='black' />
    <path d='M26.1332 71.2526H24.7969V72.5889H26.1332V71.2526Z' fill='black' />
    <path d='M30.142 71.2526L28.8057 71.2527V72.5889H30.142V71.2526Z' fill='black' />
    <path d='M34.1507 71.2527H32.8145V72.5889H34.1507V71.2527Z' fill='black' />
    <path d='M35.487 71.2527H34.1507V72.5889H35.487V71.2527Z' fill='black' />
    <path d='M36.8233 71.2526L35.487 71.2527V72.5889H36.8233V71.2526Z' fill='black' />
    <path d='M38.1595 71.2527L36.8233 71.2526V72.5889H38.1595V71.2527Z' fill='black' />
    <path d='M39.4958 71.2527H38.1595V72.5889H39.4958V71.2527Z' fill='black' />
    <path d='M42.1683 71.2527H40.832V72.5889H42.1683V71.2527Z' fill='black' />
    <path d='M43.5045 71.2527H42.1683V72.5889H43.5045V71.2527Z' fill='black' />
    <path d='M46.1771 71.2527H44.8408V72.5889H46.1771L46.1771 71.2527Z' fill='black' />
    <path d='M50.1858 71.2527H48.8496V72.5889H50.1858V71.2527Z' fill='black' />
    <path d='M55.5309 71.2526H54.1946V72.5889H55.5309V71.2526Z' fill='black' />
    <path d='M59.5396 71.2527H58.2034V72.5889H59.5396V71.2527Z' fill='black' />
    <path d='M63.5484 71.2527L62.2122 71.2526V72.5889H63.5484V71.2527Z' fill='black' />
    <path d='M66.2209 71.2527H64.8847L64.8847 72.5889H66.2209V71.2527Z' fill='black' />
    <path d='M67.5572 71.2527H66.2209V72.5889H67.5572V71.2527Z' fill='black' />
    <path d='M68.8934 71.2526L67.5572 71.2527V72.5889H68.8934V71.2526Z' fill='black' />
    <path d='M71.566 71.2527H70.2297V72.5889H71.566V71.2527Z' fill='black' />
    <path d='M74.2385 71.2527H72.9022V72.5889H74.2385V71.2527Z' fill='black' />
    <path d='M10.0981 72.5889H8.76184V73.9252H10.0981V72.5889Z' fill='black' />
    <path d='M11.4344 72.5889L10.0981 72.5889V73.9252H11.4344V72.5889Z' fill='black' />
    <path d='M12.7706 72.5889H11.4344V73.9252H12.7706V72.5889Z' fill='black' />
    <path d='M14.1069 72.5889H12.7706V73.9252H14.1069V72.5889Z' fill='black' />
    <path d='M15.4431 72.5889H14.1069V73.9252H15.4431V72.5889Z' fill='black' />
    <path d='M16.7794 72.5889L15.4431 72.5889V73.9252H16.7794V72.5889Z' fill='black' />
    <path d='M18.1156 72.5889H16.7794V73.9252H18.1156V72.5889Z' fill='black' />
    <path d='M22.1244 72.5889H20.7882V73.9252H22.1244V72.5889Z' fill='black' />
    <path d='M23.4607 72.5889H22.1244V73.9252H23.4607V72.5889Z' fill='black' />
    <path d='M24.7969 72.5889L23.4607 72.5889V73.9252H24.7969V72.5889Z' fill='black' />
    <path d='M26.1332 72.5889H24.7969V73.9252H26.1332V72.5889Z' fill='black' />
    <path d='M27.4695 72.5889L26.1332 72.5889V73.9252H27.4695V72.5889Z' fill='black' />
    <path d='M28.8057 72.5889L27.4695 72.5889V73.9252H28.8057V72.5889Z' fill='black' />
    <path d='M34.1507 72.5889H32.8145V73.9252H34.1507V72.5889Z' fill='black' />
    <path d='M38.1595 72.5889H36.8233V73.9252H38.1595V72.5889Z' fill='black' />
    <path d='M39.4958 72.5889H38.1595V73.9252H39.4958V72.5889Z' fill='black' />
    <path d='M42.1683 72.5889H40.832V73.9252H42.1683V72.5889Z' fill='black' />
    <path d='M44.8408 72.5889H43.5045V73.9252H44.8408V72.5889Z' fill='black' />
    <path d='M46.1771 72.5889H44.8408V73.9252H46.1771V72.5889Z' fill='black' />
    <path d='M48.8496 72.5889L47.5133 72.5889V73.9252H48.8496L48.8496 72.5889Z' fill='black' />
    <path d='M50.1858 72.5889H48.8496L48.8496 73.9252H50.1858V72.5889Z' fill='black' />
    <path d='M55.5309 72.5889H54.1946V73.9252H55.5309V72.5889Z' fill='black' />
    <path d='M58.2034 72.5889L56.8671 72.5889V73.9252H58.2034V72.5889Z' fill='black' />
    <path d='M59.5396 72.5889H58.2034V73.9252H59.5396V72.5889Z' fill='black' />
    <path d='M60.8759 72.5889L59.5396 72.5889V73.9252H60.8759V72.5889Z' fill='black' />
    <path d='M62.2122 72.5889L60.8759 72.5889V73.9252H62.2121L62.2122 72.5889Z' fill='black' />
    <path d='M66.2209 72.5889H64.8847V73.9252H66.2209V72.5889Z' fill='black' />
    <path d='M70.2297 72.5889H68.8934V73.9252H70.2297L70.2297 72.5889Z' fill='black' />
    <path d='M71.566 72.5889H70.2297L70.2297 73.9252H71.566V72.5889Z' fill='black' />
    <path d='M74.2385 72.5889H72.9022V73.9252H74.2385V72.5889Z' fill='black' />
  </svg>
);
const Memo = memo(Frame1686553372Icon2);
export { Memo as Frame1686553372Icon2 };
