import React, { useState, useEffect } from "react";
import Logo from "../assets/image24.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";
import apiServiceHandler from "../service/apiService";
import { handleGoogleLogin, setUnreadMessagesCount } from "../store/rideSlice";
import { EyeIcon, LogOut } from "lucide-react";

const getColorFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

const Navbar = ({
  rideRef,
  referRef,
  offerRef,
  workRef,
  scrollToSection,
  setUserProfileOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [emailInitial, setEmailInitial] = useState("");
  const [unreadMsgs, setUnreadMsgs] = useState(0);
  const dispatch = useDispatch();

  const userName = localStorage.getItem('userName')?.split(" ")[0]

  const groupUnreadMessages = useSelector(
    (state) => state.ride.groupUnreadMessages
  );

  const [contact, setContact] = useState([]);
  const [userId, setUserId] = useState("");

  const fetchChatGroupData = async (newUserId) => {
    const response = await apiServiceHandler("GET", "api/group/list");
    setContact(response.data);
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedIn);
    if (loggedIn) {
      const email = localStorage.getItem("email") || "";
      const initial = email.charAt(0).toUpperCase();
      setEmailInitial(initial);
    }
  }, []);

  useEffect(() => {
    const newUserId = localStorage.getItem("userIdWheelz");
    setUserId(newUserId);
    fetchChatGroupData(newUserId);
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("userIdWheelz");
    localStorage.removeItem("userName");
    localStorage.removeItem("JWT_TOKEN");
    dispatch(handleGoogleLogin(false))
    navigate("/login");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    setUnreadMsgs(groupUnreadMessages);
  }, [groupUnreadMessages]);

  useEffect(() => {
    const unsubscribes = [];

    const fetchDocuments = () => {
      contact?.forEach((contact) => {
        let groupRefId = `group/${contact._id}/messages`;
        const q = query(
          collection(firestore, groupRefId),
          orderBy("createdAt")
        );

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const messages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const unreadCount = messages.reduce((count, message) => {
            message?.lastSeen?.forEach((seenInfo) => {
              if (seenInfo.id === userId && !seenInfo.seen) {
                count++;
              }
            });

            return count;
          }, 0);

          dispatch(
            setUnreadMessagesCount({ groupId: contact._id, count: unreadCount })
          );
        });

        unsubscribes.push(unsubscribe);
      });
    };

    if (contact?.length > 0) {
      fetchDocuments();
    }

    return () => {
      unsubscribes?.forEach((unsubscribe) => unsubscribe());
    };
  }, [contact, userId, dispatch]);

  return (
    <nav className="bg-white fixed w-full z-20 top-0 start-0 shadow-md">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3">
          <img src={Logo} className="md:h-16 h-10" alt="Flowbite Logo" />
        </Link>
        <div className="flex items-center md:order-2">
          {isLoggedIn ? (
            <div className="relative md:mr-4 flex items-center">
              {/* <div className="font-bold text-gray-500 cursor-pointer" onClick={toggleDropdown}>{userName}</div> */}
              <button
                type="button"
                className="text-black focus:outline-none font-medium rounded-full text-sm p-2"
                onClick={toggleDropdown}
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    backgroundColor: getColorFromString(
                      localStorage.getItem("email")
                    ),
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {emailInitial}
                </div>
              </button>
              {isDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg z-10"
                  style={{ left: "10px", top: "50px", padding: "5px" }}
                >
                  <div className=" flex justify-between items-center">
                    <EyeIcon className="ml-2" />

                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700  text-base"
                      onClick={() => {setUserProfileOpen(true); setIsDropdownOpen(false)}}
                    >
                      View Profile{" "}
                    </button>
                  </div>
                  <div className="flex justify-between items-center">
                    <LogOut className="ml-2" />

                    <button
                      className="block w-full text-left px-4 py-2 text-gray-700 text-base"
                      onClick={handleLogout}
                    >
                      Logout{" "}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            window.screen.width < 640 && (
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-xs px-2 py-2 text-center"
                onClick={() => navigate("/login")}
              >
                Login Now
              </button>
            )
          )}
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none"
            aria-controls="navbar-sticky"
            aria-expanded={isOpen}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
            {unreadMsgs > 0 && (
              <span className="absolute top-4 left-80 flex items-center justify-center text-xs w-6 h-6 bg-blue-500 rounded-xl text-white">
                {unreadMsgs}
              </span>
            )}
          </button>
        </div>

        <div
          className={`${
            isOpen ? "block" : "hidden"
          } items-center justify-between w-full md:flex md:w-auto md:order-1`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-black rounded md:bg-transparent md:text-black md:p-0"
                onClick={() => {
                  scrollToSection(rideRef);
                }}
              >
                Book a ride
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-black rounded md:bg-transparent md:text-black md:p-0"
                onClick={() => {
                  scrollToSection(referRef);
                }}
              >
                Refer & Earn
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-black rounded md:bg-transparent md:text-black md:p-0"
                onClick={() => {
                  scrollToSection(offerRef);
                }}
              >
                Our Offerings
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-black rounded md:bg-transparent md:text-black md:p-0"
                onClick={() => {
                  scrollToSection(workRef);
                }}
              >
                How it works?
              </Link>
            </li>
            {isLoggedIn && (
              <li>
                <Link
                  to="/messages"
                  className="relative py-2 px-3 text-black rounded md:bg-transparent md:text-black md:p-0"
                  onClick={() => setIsOpen(false)}
                >
                  Messages
                  {unreadMsgs > 0 && (
                    <span
                      className={`absolute ${
                        window.screen.width > 640
                          ? "top-[-10px] left-16"
                          : "top-[-4px] left-[4.5rem]"
                      } flex items-center justify-center text-xs w-6 h-6 bg-blue-500 rounded-xl text-white`}
                    >
                      {unreadMsgs}
                    </span>
                  )}
                </Link>
              </li>
            )}
          </ul>
          {!isLoggedIn && window.screen.width > 768 && (
            <div className="md:ml-10 flex flex-row justify-between mt-2 md:mt-0">
              <button
                type="button"
                className="text-black md:mx-2 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center border border-gray-400"
                onClick={() => navigate("/signup")}
              >
                SignUp
              </button>
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => navigate("/login")}
              >
                Login Now
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
