import { memo, useEffect, useRef, useState } from 'react';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import { handleRideData, removeRideKey } from '../store/rideSlice';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: window.screen.width > 768 ? '200px' : window.screen.width < 400 ? (window.screen.width - (0.18 * window.screen.width)) + 'px' : (window.screen.width - (0.15 * window.screen.width)) + 'px',
    height: window.screen.width > 768 ? '56px' : '36px',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    width: window.screen.width > 768 ? '200px' : '150px',
    height: '56px'
    // width: '200px' // Ensure the menu width matches the control width
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '150px', // Adjust as necessary
    overflowY: 'auto',
    width: window.screen.width > 768 ? '200px' : window.screen.width < 400 ? (window.screen.width - (0.18 * window.screen.width)) + 'px' : (window.screen.width - (0.15 * window.screen.width)) + 'px',
    height: '110px'
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'lightgrey' : 'white', // Change the background color as needed
    color: 'black'
  })
};

const options = [];


export const ReactGoogleMapSearch = memo(function ReactSelect(props = {}) {
  const searchBoxRef = useRef(null);
  const dispatch = useDispatch()
  const [inputVal, setInputVal] = useState('')

  const handlePlaceSelected = () => {
      const places = searchBoxRef.current.getPlaces();
      if (places.length > 0) {
          options.push({ value: places[0].name, label: places[0].name})
      }
      dispatch(handleRideData([props.placeholder, places[0].name]))
      sessionStorage.setItem(props.placeholder, JSON.stringify(places[0].name))
  };

  const handleChange = (e) => {
    setInputVal(e.target.value)
  }

  useEffect(() => {
    if (inputVal.length === 0) {
      dispatch(removeRideKey([props.placeholder]))
    }
  }, [inputVal])
  

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
        <LoadScript googleMapsApiKey="AIzaSyAcMKbrC6dkmUhq3J7dC439pJ3wV_IBScI" libraries={['places']}>
          <StandaloneSearchBox componentRestrictions={{ country: 'in' }} onLoad={ref => (searchBoxRef.current = ref)} onPlacesChanged={handlePlaceSelected}>
            <input
                type="text"
                styles={customStyles}
                placeholder={props.placeholder}
                className="h-9 md:h-14 p-3 border border-[rgb(204,204,204)] rounded outline-none"
                value={inputVal}
                onChange={handleChange}
                // style={{ width: '100%', padding: '10px', fontSize: '16px',  border: '1px solid grey',  borderRadius: '4px',  outline: 'none', transition: 'border-color 0.3s' }}
                style={{width: window.screen.width > 768 ? '200px' : window.screen.width < 400 ? (window.screen.width - (0.18 * window.screen.width)) + 'px' : (window.screen.width - (0.15 * window.screen.width)) + 'px'}}
              />
          </StandaloneSearchBox>
      </LoadScript>
    </div>
  );
});
