import React from "react";
import { ForestIcon } from "../Icons/ForestIcon";
import { BuildingIcon } from "../Icons/BuildingIcon";
import { SaveMoneyIcon } from "../Icons/SaveMoneyIcon";

const Heading = () => {
  return (
    <div className="absolute md:w-min h-min flex flex-col items-center gap-12 w-full overflow-hidden md:left-[305px] left-0 md:top-[877px] top-[calc(100vh+50em)]">
      <div className="md:w-min w-screen justify-center h-min text-center items-center whitespace-nowrap flex-col">
        <div className="text-black md:text-[40px] text-2xl leading-[48px] font-sans font-bold">How we are making an impact</div>
        <div className="text-black md:text-[40px] text-2xl leading-[48px] font-sans font-bold mt-0">in commune services?</div>
      </div> 
      <div className="w-min h-min flex md:flex-row flex-col items-start md:gap-36 gap-12">
        <div className="relative w-[157px] h-[220px]">
          <div className="absolute w-min h-min self-start flex-col items-center gap-2 top-[67.2727%] bottom-0 inset-x-[2.5478%]">
            <div className="text-black text-2xl leading-6 font-semibold w-min h-min text-center items-center whitespace-nowrap flex-col font-sans">250+ Tones</div>
            <div className="text-[#0009] text-base leading-5 font-medium w-[149px] h-min text-center items-center flex-col font-sans">
            of carbon footprints reduced is our goal.
            </div>
          </div>
          <div className="absolute w-[157px] h-[156px] items-start bg-[linear-gradient(180deg,#3d67d626_0%,#20367000_100%)] bg-center bg-no-repeat bg-auto overflow-hidden top-0 bottom-[29.0909%] inset-x-0">
            <div className="absolute left-[calc(50%_-_-0.5px)] w-[72px] h-[72px] -translate-x-2/4 top-[42px]">
              <ForestIcon className="w-full h-full" />
            </div>
          </div>
        </div>
        <div className="relative w-[157px] h-[220px]">
          <div className="absolute w-min h-min self-start flex-col items-center gap-2 top-[67.2727%] bottom-0 inset-x-[2.5478%]">
            <div className="text-black text-2xl leading-6 font-semibold w-min h-min text-center items-center whitespace-nowrap flex-col font-sans">4,000+</div>
            <div className="text-[#0009] text-base leading-5 font-medium w-[149px] h-min text-center items-center flex-col font-sans">
              Cities We Serve In Across India
            </div>
          </div>
          <div className="absolute w-[157px] h-[156px] items-start bg-[linear-gradient(180deg,#3d67d626_0%,#20367000_100%)] bg-center bg-no-repeat bg-auto overflow-hidden top-0 bottom-[29.0909%] inset-x-0">
            <div className="absolute left-[calc(50%_-_-0.5px)] w-[72px] h-[72px] -translate-x-2/4 top-[42px]">
              <BuildingIcon className="w-full h-full" />
            </div>
          </div>
        </div>
        <div className="relative w-[157px] h-[220px]">
          <div className="absolute w-min h-min self-start flex-col items-center gap-2 top-[67.2727%] bottom-0 inset-x-[2.5478%]">
            <div className="text-black text-2xl leading-6 font-semibold w-min h-min text-center items-center whitespace-nowrap flex-col font-sans">Upto 50%</div>
            <div className="text-[#0009] text-base leading-5 font-medium w-[149px] h-min text-center items-center flex-col font-sans">
              Cheaper Than Other Services
            </div>
          </div>
          <div className="absolute w-[157px] h-[156px] items-start bg-[linear-gradient(180deg,#3d67d626_0%,#20367000_100%)] bg-center bg-no-repeat bg-auto overflow-hidden top-0 bottom-[29.0909%] inset-x-0">
            <div className="absolute left-[calc(50%_-_-0.5px)] w-[72px] h-[72px] -translate-x-2/4 top-[42px]">
              <SaveMoneyIcon className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heading;
