import React, { useState } from "react";
import { auth, googleProvider } from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../../service/apiService";

const AgeModal = ({ ageModalRef, setAgeModalOpen }) => {
  const [formData, setFormData] = useState({
    contactNumber: null,
    gender: null,
    referral_code: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const genderOptions = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const signInWithGoogle = () => {
    if(!(formData.contactNumber && formData.gender)) {
      toast.error("Gender and Contact Number is a required field")
      return false
    }
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        let body = {
          email: result.user.email,
          givenName: result.user.displayName,
          device_token: "",
          contactNumber: formData.contactNumber,
          gender: formData.gender,
          referral_code: formData.referral_code
        };
        const response = await apiServiceHandler(
          "POST",
          "api/auth/signIn/google",
          body
        );
        console.log(response, 'response value is here')
        if (response.status) {
          setIsLoading(true);
          toast.success(response.message);
          localStorage.setItem("JWT_TOKEN", response.secret);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("email", result.user.email);
          localStorage.setItem("userIdWheelz", response.userId);
          localStorage.setItem("userName", result.user.displayName);

          setTimeout(() => {
            setIsLoading(false);
            navigate("/");
            setAgeModalOpen(false)
          }, 2000); // Navigate after 2 seconds
        } else {
          toast.error(response.message);
          setTimeout(() => {
            setIsLoading(false);
            setAgeModalOpen(false)
          }, 2000); // Navigate after 2 seconds
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setAgeModalOpen(false)
        console.log(error);
      });
  };

  const handleOverlayClick = (e) => {
    // Close the modal if clicking on the overlay (background)
    if (e.target === e.currentTarget) {
      setAgeModalOpen(false);
    }
  };


  return (
    <>
    <ToastContainer />
      <div
        id="timeline-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onClick={handleOverlayClick}
      >
        <div
          className="flex flex-col px-6 pb-6 bg-white rounded-xl md:max-w-[480px] w-[95%] max-md:px-5"
          ref={ageModalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col gap-5 mt-4">
            <div className="flex flex-col gap-1 mt-3">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                Gender
              </div>
              <select
                className="w-full h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4 text-gray-700"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1 mt-3">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                Contact Number
              </div>
              <input
                className="w-full h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4"
                type="text"
                placeholder="Enter 10 digits only"
                name="contactNumber"
                pattern="[0-9]{10}"
                value={formData.contactNumber}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1 mt-3">
              <div className="text-[#667085] font-sans text-sm non-italic font-normal leading-5">
                Referral Code
              </div>
              <input
                className="w-full h-[46px] shrink-0 rounded-md border-2 border-[#EAECF0] px-4"
                type="text"
                name="referral_code"
                value={formData.referral_code}
                onChange={handleChange}
              />
            </div>
          </div>

          <button className="w-[-webkit-fill-available]" onClick={signInWithGoogle}>
            <div className="justify-center px-2.5 py-3.5 mt-6 text-sm font-medium text-center text-white bg-indigo-700 rounded-md max-md:max-w-full cursor-pointer">
              Save
            </div>{" "}
          </button>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AgeModal;
