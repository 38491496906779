import { memo } from "react";

const Line = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="2"
    viewBox="0 0 50 2"
    fill="none"
  >
    <path
      d="M0 1H161"
      stroke="black"
      stroke-opacity="0.6"
      stroke-width="1.5"
      stroke-dasharray="2 2"
    />
  </svg>
);
const Memo = memo(Line);
export { Memo as Line };
