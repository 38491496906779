import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServiceHandler from "../service/apiService";

const UserProfile = ({ userProfileModalRef, setUserProfileOpen }) => {

  const fileInputRef = React.useRef(null);
  const [name, setName] = useState(null);
  const [contact, setContact] = useState(null);
  const [ linkedIn, setLinkedIn] = useState(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const fetchProfileData = async() => {
   const data = await apiServiceHandler("GET", "api/user/profile");
   if(data.status) {
    setName(data.data.name)
    setContact(data.data.contact_number)
    setLinkedIn(data.data.linkedin_profile)
   }
  }

  const handleSubmit = async() => {
    try {
      const body = {
        name: name,
        contact_number: contact,
        linkedin_profile: linkedIn
      }
      if(!( name || contact || linkedIn)) {
        toast.error('Nothing to update, Please input something to update your profile')
        return
      }
      await apiServiceHandler("POST", "api/user/update/profile", body);
      toast.success('Profile update successfully')
      setTimeout(() => {
        setUserProfileOpen(false)
      },2000)
    }
    catch (error) {
      toast.error('Something went wrong! Please try again later')
      setTimeout(() => {
        setUserProfileOpen(false)
      },2000)
    }
  }

  useEffect(() => {
    fetchProfileData()
  }, [])

  return (
    <>
      <ToastContainer />
      <div
      id="timeline-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div
        className="flex flex-col px-6 pb-6 bg-white rounded-xl md:max-w-[480px] w-[95%] max-md:px-5"
        ref={userProfileModalRef}
      >
        <div className="mt-4 flex justify-center">
          <img
            class="w-[72px] h-[72px] rounded-full"
            src="https://i2.pickpik.com/photos/711/14/431/smile-profile-face-male-preview.jpg"
            alt="Rounded avatar"
          />
        </div>
        <div className="self-center mt-1.5 text-sm font-normal text-center text-[#3b82f6]">
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={(e) => {
              // handle file change here
              console.log(e.target.files[0]);
            }}
          />
          <button onClick={handleClick} className="cursor-pointer">
            Upload New Profile Pic
          </button>
        </div>

        <div className="flex flex-col gap-5 mt-4">
          <div class="relative w-full">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 26 26"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-user-round-pen w-4 h-4 text-gray-500 dark:text-gray-400"
              >
                <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                <path d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                <circle cx="10" cy="8" r="5" />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="bg-white h-12 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Full Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div class="relative w-full">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 26 26"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-phone w-4 h-4 text-gray-500 dark:text-gray-400"
              >
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="bg-white h-12 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Phone Number"
              onChange={(e) => setContact(e.target.value)}
              value={contact}
              required
            />
          </div>
          <div class="relative w-full">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 26 26"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-linkedin w-4 h-4 text-gray-500"
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                <rect width="4" height="12" x="2" y="9" />
                <circle cx="4" cy="4" r="2" />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="bg-white h-12 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="LinkedIn"
              required
              onChange={(e) => setLinkedIn(e.target.value)}
              value={linkedIn}
            />
          </div>
        </div>

        <button className="w-[-webkit-fill-available]" onClick={handleSubmit}>
          <div className="justify-center px-2.5 py-3.5 mt-6 text-sm font-medium text-center text-white bg-indigo-700 rounded-md max-md:max-w-full cursor-pointer">
            Save
          </div>{" "}
        </button>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UserProfile;
