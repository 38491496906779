import React, { useEffect, useState, useRef } from "react";
import { BlackVector } from "../Icons/BlackVector";
import { Line } from "../Icons/Line";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";
import { updateTotalRides } from "../store/rideSlice";
import apiServiceHandler from "../service/apiService";
import { useLocation } from "react-router-dom";
import CarPoolProfile from "./CarPoolProfile";
import UserProfile from "./UserProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";

const getColorFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};
const Rides = ({userProfileModalRef, setUserProfileOpen, userProfileOpen}) => {
  const location = useLocation();
  const { noOfcotraveller } = location.state || {};

  const [ride, setRideData] = useState([]);
  const [passengers, setPassengers] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [disabledItems, setDisabledItems] = useState({});
  const [checkedOption, setCheckedOption] = useState("");
  const [isCheckedToggle, setIsCheckedToggle] = useState(false);
  const [filterOption, setFilterOption] = useState({});
  const [filtersVisible, setFiltersVisible] = useState(false);

  const filterApiData = async (newBody) => {
    setIsLoading(true);
    const response = await apiServiceHandler("POST", "api/ride/match", newBody);
    setRideData(response.data?.ride);
    dispatch(updateTotalRides(response.data?.ride.length));
    setIsLoading(false);
  };

  const increasePassenger = () => {
    let passenger = passengers;
    setPassengers((prev) => prev + 1);
    let obj = filterOption;
    obj["number_passenger"] = passenger + 1;
    setFilterOption(obj);
    let newBody = Object.assign({}, obj, body);
    filterApiData(newBody);
  };

  const decreasePassenger = () => {
    let passenger = passengers;
    if (passengers > 1) {
      passenger = passenger - 1;
      setPassengers((prev) => prev - 1);
    }
    let obj = filterOption;
    obj["number_passenger"] = passenger;
    setFilterOption(obj);
    let newBody = Object.assign({}, obj, body);
    filterApiData(newBody);
  };

  const user_ride_data = JSON.parse(sessionStorage.getItem("user_ride_data"));

  const origin = user_ride_data.Origin;
  const destination = user_ride_data.Destination;
  const departureDate = user_ride_data["Selected Date"];
  const rideInquiryId = sessionStorage.getItem("rideInquiryId");
  const body = {
    origin: origin,
    destination: destination,
    departureTime: departureDate,
  };

  const fetchingRideDetails = async () => {
    try {
      const response = await apiServiceHandler("POST", "api/ride/match", body);

      setRideData(response.data?.ride);
      dispatch(updateTotalRides(response.data?.ride.length));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error value is here");
    }
  };
  useEffect(() => {
    fetchingRideDetails();
  }, []);

  const formatDate = (date) => {
    date = new Date(date);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const day = days[date.getDay()]; // Day of the week
    const dayOfMonth =
      Number(date.getDate()) < 10
        ? "0" + Number(date.getDate())
        : date.getDate(); // Day of the month
    const month = date.getMonth() + 1; // Full month name
    const year = date.getFullYear(); // Year

    const currMonth = Number(month) < 10 ? "0" + month : month;

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    const time = `${hours}:${minutes} ${ampm}`;

    return `${day}, ${dayOfMonth}-${currMonth}-${year} ${time}`;
  };

  const formatDepartureDate = (date) => {
    date = new Date(date);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const day = days[date.getDay()]; // Day of the week
    const dayOfMonth = date.getDate(); // Day of the month
    const month = date.toLocaleString("default", { month: "long" }); // Full month name
    const year = date.getFullYear(); // Year

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    const time = `${hours}:${minutes} ${ampm}`;

    return `${day}, ${dayOfMonth} ${month} ${year}, ${time}`;
  };

  const apiRequestRide = async (rideData) => {
    try {
      const body = {
        rideId: rideData.ride_id,
        rideInquiryId: JSON.parse(rideInquiryId),
      };
      const response = await apiServiceHandler(
        "POST",
        "api/ride/request",
        body
      );
      if (response.status) {
        setDisabledItems((rideData) => ({
          ...rideData,
          [rideData._id]: true,
        }));
        toast.success("Great! Your ride request has been successfully sent.");
      }
    } catch (error) {
      console.log(error, "error value is here");
      toast.error("something went wrong");
    }
  };

  const requestRide = (rideData) => {
    apiRequestRide(rideData);
  };

  const handleCheckboxChange = async (option) => {
    let obj = filterOption;
    if (checkedOption == option) {
      delete obj["date"];
      setCheckedOption("");
    } else {
      obj["date"] = option;
      setCheckedOption(option);
    }
    setFilterOption(obj);
    let newBody = Object.assign({}, obj, body);
    filterApiData(newBody);
  };

  const handleToggle = () => {
    let obj = filterOption;
    if (!isCheckedToggle) {
      obj["female"] = true;
    } else {
      delete obj["female"];
    }
    setFilterOption(obj);
    setIsCheckedToggle(!isCheckedToggle);
    let newBody = Object.assign({}, obj, body);
    filterApiData(newBody);
  };

  const [carPoolOpen, setCarPoolOpen] = useState(false);
  const carPoolRef = useRef();

  const handleClickOutside = (event) => {
    if (carPoolRef.current && !carPoolRef.current.contains(event.target)) {
      setCarPoolOpen(false);
    }

    if (userProfileModalRef.current && !userProfileModalRef.current.contains(event.target)) {
      setUserProfileOpen(false);
    }
  };

  const handleOpen = () => {
    setCarPoolOpen(true)
  }

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  useEffect(() => {
    if (carPoolOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [carPoolOpen]);

  useEffect(() => {
    if (userProfileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userProfileOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setCarPoolOpen(false)
        setUserProfileOpen(false)
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  return (
    <div className="flex flex-col bg-gray-100">
      {/* <Navbar /> */}
      <ToastContainer />
      {carPoolOpen && <CarPoolProfile carPoolRef={carPoolRef} setCarPoolOpen={setCarPoolOpen} />}
      {userProfileOpen && <UserProfile userProfileModalRef={userProfileModalRef} setUserProfileOpen= {setUserProfileOpen} />}
      <div className="flex md:flex-row flex-col md:items-start items-center gap-8 w-screen h-screen absolute md:top-36 top-24 md:left-12">
        {/* Mobile view filter toggle button */}
      <div className="md:hidden w-full flex px-5 mb-4">
          <button
            onClick={toggleFilters}
            className={`p-3 rounded-full shadow-lg transition-all ${
              filtersVisible ? "bg-blue-600 text-white" : "bg-white text-blue-600"
            }`}
          >
             <FontAwesomeIcon icon={faSlidersH} size="lg" />
          </button>
        </div>

        <div className={`flex flex-col py-6 bg-white rounded-xl border border-solid shadow-2xl border-black border-opacity-0 md:max-w-[452px] w-[92%] ${filtersVisible ? 'block' : 'hidden md:block'}`}>
          <div className="self-start ml-5 text-base font-semibold text-black text-opacity-80">
            Filters
          </div>
          <div className="mt-6 w-full bg-gray-300 min-h-[1px]" />
          <div className="self-start mt-5 ml-5 text-sm font-semibold text-black">
            Travel Date
          </div>
          <div className="flex gap-2 self-start mt-3 ml-5 text-sm leading-5 whitespace-nowrap text-black text-opacity-70">
            <input
              type="checkbox"
              className="shrink-0 w-5 h-5 bg-white rounded-md border border-gray-300 border-solid"
              checked={checkedOption == "earliest"}
              onChange={() => handleCheckboxChange("earliest")}
            />
            <div>Earliest</div>
          </div>
          <div className="flex gap-2 self-start mt-2 ml-5 text-sm leading-5 whitespace-nowrap text-black text-opacity-70">
            <input
              type="checkbox"
              className="shrink-0 w-5 h-5 bg-white rounded-md border border-gray-300 border-solid"
              checked={checkedOption == "fastest"}
              onChange={() => handleCheckboxChange("fastest")}
            />
            <div>Fastest</div>
          </div>
          <div className="mt-5 w-full bg-gray-300 min-h-[1px]" />
          <div className="self-center mt-5 text-sm font-semibold text-black">
            Number Of Passengers
          </div>
          <div className="flex gap-5 justify-between self-center px-5 mt-5 text-sm font-medium leading-4 text-neutral-900">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d2452af934cd20b7d5a36b3f3b49caaf1add2e6c9582bf4333e299d053ca799?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
              className="shrink-0 w-6 aspect-square cursor-pointer"
              onClick={decreasePassenger}
            />
            <div className="my-auto">{passengers} passengers</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/edc0fffe249bcc16eeff1e62905f51af34e526f3168f07ec20f0bd62117d2ece?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
              className="shrink-0 w-6 aspect-square cursor-pointer"
              onClick={increasePassenger}
            />
          </div>
          <div className="mt-5 w-full bg-gray-300 min-h-[1px]" />
          <div className="inline-flex flex-col items-start gap-3 ml-5">
            <div className="self-start mt-5 text-sm font-semibold text-black">
              Female Passengers
            </div>
            <label className="flex cursor-pointer">
              <input
                type="checkbox"
                value={isCheckedToggle}
                className="sr-only peer"
                checked={isCheckedToggle}
                onChange={handleToggle}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Minimum one female passenger
              </span>
            </label>
          </div>
        </div>
        <div className="flex flex-col items-center grow-[2] gap-6">
          <div className="flex flex-col items-start py-5 md:pr-20 pl-5 rounded-xl border border-gray-200 border-solid bg-[#F9F9FF] md:w-[85%] w-[96%] max-md:pr-5">
            <div className="text-lg font-semibold text-black">
              {formatDepartureDate(departureDate)}
            </div>
            <div className="flex gap-3 mt-2 text-sm font-medium leading-4 text-black text-opacity-80">
              <div>{origin}</div>
              <div className="m-auto">
                <BlackVector />
              </div>
              <div>{destination}</div>
            </div>
          </div>
          {!isLoading && ride?.length > 0 ? (
            ride.map((item, index) => (
              <div className="flex flex-col gap-2 py-5 bg-white rounded-xl border border-solid shadow-2xl border-black border-opacity-0 md:w-[85%] w-[96%]">
                <div className="text-sm font-semibold text-gray-600 w-max mx-5 my-1">
                  {formatDate(item.start_at)}
                </div>
                <div className="flex md:flex-row flex-col gap-5 justify-between md:w-full w-screen max-md:flex-wrap max-md:max-w-full">
                  <div className="flex md:flex-row flex-col md:gap-8 gap-0 justify-center px-5 font-medium md:w-auto w-[96%]">
                    <div className="flex flex-col">
                      <div className="text-base text-black">
                        {/* {item.origin_main_text} */}
                        {
                          JSON.parse(sessionStorage.getItem("user_ride_data"))
                            .Origin
                        }
                      </div>
                      <div className="text-xs leading-4 text-black text-opacity-60">
                        2km away
                      </div>
                    </div>
                    <div className="flex my-auto text-xs leading-3 text-black text-opacity-80">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bdc03aae028c464e9a9627612dfc2720b861e6324529b2a5a65074ce2a83588?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                        className="shrink-0 w-6 aspect-square"
                      />
                      <div className="m-auto">
                        <Line />
                      </div>
                      <div className="flex flex-col justify-center max-md:px-5 z-10">
                        <div className="justify-center p-1.5 bg-slate-50 rounded-[80px]">
                          {item.approx_time}
                        </div>
                      </div>
                      <div className="m-auto">
                        <Line />
                      </div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a072bea466f8f92ef3ed9c5d752a4a2215b4042b84436d84f8cf17a2f13f64a7?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                        className="shrink-0 w-4 aspect-[0.67]"
                      />
                    </div>
                    <div className="flex flex-col md:pl-0 pl-[10px] items-end">
                      <div className="text-base text-black">
                        {/* {item.destination_main_text} */}
                        {
                          JSON.parse(sessionStorage.getItem("user_ride_data"))
                            .Destination
                        }
                      </div>
                      <div className="text-xs leading-4 text-black text-opacity-60">
                        4km away
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 justify-center px-5">
                    <div className="flex md:gap-2 justify-start">
                      {Array.from(
                        { length: item.seat_required - item.seat_occupied },
                        (v, i) => (
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d90d3ac58a36386f898dde9296a3ca76cf2948d02941cd25f5d20a2dcc02eb7b?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                            className="shrink-0 w-5 aspect-square"
                          />
                        )
                      )}
                    </div>

                    <div className="md:flex-none flex justify-start text-xs font-medium leading-4 text-black text-opacity-60">
                      {item.seat_required - item.seat_occupied} Seats Available
                    </div>
                  </div>
                </div>
                <div className="self-stretch mt-5 w-full bg-[#D9D9D9] min-h-[1px] max-md:max-w-full" />
                <div className="flex gap-5 justify-between mt-5 w-full font-medium max-md:flex-wrap max-md:max-w-full px-4">
                  <div className="flex gap-2">
                    <div
                      onClick={handleOpen}
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        backgroundColor: getColorFromString(
                          item.publisher_name
                        ),
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                        lineHeight: "40px",
                        cursor: 'pointer'
                      }}
                    >
                      {item.publisher_name.charAt(0).toUpperCase()}
                    </div>
                    <div className="flex flex-col px-5 my-auto">
                      <div className="text-sm leading-4 text-black text-opacity-80">
                        {item.publisher_name || "Shubham"}
                      </div>
                      <div className="text-xs leading-4 text-black text-opacity-60">
                        Pool Creator
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => requestRide(item)}
                    disabled={
                      item.already_requested ||
                      !!disabledItems[item.id] ||
                      noOfcotraveller > item.seat_required
                    }
                    className={`justify-center flex p-2.5 text-sm leading-5 text-center rounded-md min-w-[100px] ${
                      item.already_requested ||
                      !!disabledItems[item.id] ||
                      noOfcotraveller > item.seat_required
                        ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                        : "bg-indigo-700 text-white"
                    }`}
                  >
                    <div>
                      {noOfcotraveller > item.seat_required
                        ? "Full"
                        : "Request Ride"}
                    </div>
                  </button>
                </div>
              </div>
            ))
          ) : ride.length === 0 && !isLoading ? (
            <section className="bg-white dark:bg-gray-900">
              <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                  <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                    No rides were found.
                  </p>
                  <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                    Sorry, we can't find any rides for requested route.{" "}
                  </p>
                  <Link
                    to="/"
                    className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                  >
                    Back to Homepage
                  </Link>
                </div>
              </div>
            </section>
          ) : (
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rides;

