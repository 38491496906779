import { memo } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { handleRideData } from "../store/rideSlice";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width:
      window.screen.width > 768
        ? "200px"
        : window.screen.width < 400
        ? window.screen.width - 0.18 * window.screen.width + "px"
        : window.screen.width - 0.15 * window.screen.width + "px",
    height: window.screen.width > 768 ? "56px" : "36px",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    width: window.screen.width > 768 ? "200px" : "150px",
    height: "56px",
    // width: '200px' // Ensure the menu width matches the control width
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "150px", // Adjust as necessary
    overflowY: "auto",
    width:
      window.screen.width > 768
        ? "200px"
        : window.screen.width < 400
        ? window.screen.width - 0.18 * window.screen.width + "px"
        : window.screen.width - 0.15 * window.screen.width + "px",
    height: "110px",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "lightgrey" : "white", // Change the background color as needed
    color: "black",
  }),
};

export const ReactSelect = memo(function ReactSelect(props = {}) {
  const dispatch = useDispatch();
  return (
    <div style={{ position: "relative", overflow: "visible" }}>
      <Select
        options={props.options}
        styles={customStyles}
        className=""
        onChange={(e) => dispatch(handleRideData([props.placeholder, e.value]))}
        placeholder={props.placeholder}
        menuPortalTarget={document.body} // Ensure the menu is rendered in the body for proper z-index handling
      />
    </div>
  );
});
