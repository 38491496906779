import React, { useEffect, useState, useRef } from "react";
import apiServiceHandler from "../service/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const getColorFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};
const FindRideModal = ({ modalRef, setOpen }) => {
  const [requests, setRequests] = useState([]);
  const [rideIds, setRideIds] = useState([]);
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const sliderRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const fetchRequestList = async () => {
    try {
      const response = await apiServiceHandler("GET", "api/ride/request/list");
      if (response.status && response.data.length > 0) {
        const allRequests = response.data.reduce((acc, item) => {
          acc = acc.concat(item.inquiryData);
          return acc;
        }, []);
        const rideIds = response.data.map((item) => item.rideId);
        setRequests(allRequests);
        setRideIds(rideIds);
      } else {
        toast.error("Something went wrong. Please try again later.", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error, "error value is here");
    }
  };

  const acceptCotravellerRequest = async () => {
    try {
      const body = {
        rideId: rideIds[currentRequestIndex],
        rideInquiryId: requests[currentRequestIndex]?._id || "",
        requestedUserId: requests[currentRequestIndex]?.published_by,
      };

      const response = await apiServiceHandler("POST", "api/ride/accept", body);

      if (response.status) {
        toast.success("Successfully accepted the ride");
        const updatedRequests = [...requests];
        const updatedRideIds = [...rideIds];
        updatedRequests.splice(currentRequestIndex, 1); // removes accepted ride from requests and ride id array
        updatedRideIds.splice(currentRequestIndex, 1);

        setRequests(updatedRequests);
        setRideIds(updatedRideIds);

        if (updatedRequests.length === 0) {
          setTimeout(() => {
            setOpen(false);
            window.location.reload()
          }, 2000);
        }

        if (currentRequestIndex >= updatedRequests.length) {
          setCurrentRequestIndex(updatedRequests.length - 1);
        }

        //after that updates the index
        setIsPrevDisabled(currentRequestIndex === 0);
        setIsNextDisabled(currentRequestIndex === updatedRequests.length - 1);
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      throw new Error(error.message || error);
    }
  };

  useEffect(() => {
    fetchRequestList();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (index) => {
      setCurrentRequestIndex(index);
      setIsPrevDisabled(index === 0);
      setIsNextDisabled(index === requests.length - 1);
    },
  };
  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const LeftArrowSVG = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 18L9 12L15 6"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const RightArrowSVG = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  console.log(requests);
  return (
    <>
      <ToastContainer />
      <div
        id="timeline-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div
          className="flex flex-col px-6 pb-6 text-sm bg-white rounded-xl md:max-w-[480px] w-[90%]"
          ref={modalRef}
        >
          <div className="w-full flex justify-center mt-10">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/df8419c1e3d99b2ac60ef65f9ba546d4638b5e6ab39202307b1fa33e8b5bb3b1?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
              className="aspect-square w-[82px]"
            />
          </div>
          <div className="mt-7 text-lg font-semibold leading-6 text-center text-black">
            {/* Few drivers have accepted your request */}
          </div>
          {requests.length > 0 && requests.length > 0 ? (
            <Slider ref={sliderRef} {...settings}>
              {requests.map((request, index) => (
                <div
                  key={index}
                  className="flex flex-col items-start self-stretch p-3.5 mt-6 bg-[#F9F9FF] rounded-2xl border border-solid bg-slate-50 border-slate-400 border-opacity-20"
                >
                  <div className="flex gap-3.5 text-sm text-gray-700">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bda0bc21a5c2b5c6b7fb7303fc01557e55624c7e9367fecca862f4c355255502?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                      className="shrink-0 aspect-[0.13] w-[9px]"
                    />
                    <div className="flex flex-col my-auto">
                      <div>{request.origin_address}</div>
                      <div className="mt-5">{request.destination_address}</div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-6">
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        backgroundColor: getColorFromString(
                          request.publisher_name
                        ),
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {request.publisher_name.charAt(0).toUpperCase()}
                    </div>
                    <div className="flex flex-col self-start">
                      <div className="text-sm font-bold text-gray-700">
                        {request.publisher_name}
                      </div>
                      <div className="flex gap-0.5 text-xs text-zinc-600">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fee461b89af32e34e0c2f161c9df9ed24382cc697768b3aa3c210fc5df3fd31d?apiKey=9b2e3c40790d4276bb0f2e16ac695d01&"
                          className="shrink-0 my-auto w-2.5 aspect-square"
                        />
                        <div>4.5 rating</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center">
              <h2 className="font-bold text-xl">You have accepted all requests</h2>
            </div>
          )}
          <div className="flex justify-center gap-2 mt-6 text-xs font-medium text-gray-700">
            <button
              onClick={goToPrevSlide}
              disabled={isPrevDisabled}
              className={`flex items-center justify-center w-6 h-6 rounded-full border border-black ${
                isPrevDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <LeftArrowSVG />
            </button>
            <div className="my-auto">See other rides</div>
            <button
              onClick={goToNextSlide}
              disabled={isNextDisabled}
              className={`flex items-center justify-center w-6 h-6 rounded-full border border-black ${
                isNextDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <RightArrowSVG />
            </button>
          </div>
          <div className="flex gap-3 self-stretch mt-6 text-sm font-medium text-center w-full bg-pink-50">
            <button
              className="justify-center px-2.5 py-3.5 text-white bg-indigo-700 rounded-md w-full"
              onClick={acceptCotravellerRequest}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindRideModal;
