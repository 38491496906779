import { memo } from 'react';

const ComfortIcon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.0287 1.82234C5.43285 1.82234 1.70724 5.54795 1.70724 10.1438C1.70724 14.7395 5.43285 18.4652 10.0287 18.4652C14.6244 18.4652 18.3501 14.7395 18.3501 10.1438C18.3501 5.54795 14.6245 1.82234 10.0287 1.82234ZM6.4799 10.4317H3.73602V9.56838H6.4799V10.4317ZM10.0003 15.842C8.55559 15.842 7.38033 14.7944 7.38033 13.5067H8.24364C8.24364 14.3185 9.0317 14.9787 10.0003 14.9787C10.9687 14.9787 11.7567 14.3182 11.7567 13.5067H12.62C12.62 14.794 11.4448 15.842 10.0003 15.842ZM13.5202 10.4317V9.56838H16.2641V10.4317H13.5202Z'
      fill='black'
    />
  </svg>
);
const Memo = memo(ComfortIcon);
export { Memo as ComfortIcon };
