import React from 'react'
import { WindIcon } from '../Icons/WindIcon'
import mainFrame from "../assets/main_frame.png"

const Rectangle = () => {
  return (
    <div className='absolute left-[calc(50%_-_0.5px)] w-full h-64 md:w-[1371px] md:h-[342px] items-start overflow-hidden -translate-x-2/4 top-[90px]'>
    <div className="absolute left-[480px] h-[250px] w-[1371px] md:h-[298px] bg-[linear-gradient(-1.2deg,#000000bf_0%,#6660_100%)] bg-center bg-no-repeat bg-auto -translate-x-2/4 md:left-2/4 top-0"
     style={window.screen.width > 640 ? { backgroundImage: `url(${mainFrame})`} : { backgroundImage: `url(${mainFrame})`, backgroundSize: 'contain'}}>
    </div>
  </div>
  )
}

export default Rectangle